import {Box, Stack} from '@chakra-ui/react';
import CustomTab from 'components/CustomTab';
import {useAdminPermissionSettings} from 'hooks/datahook/adminPermission';
import {useAuth} from 'hooks/useAuth';
import {find, get, last, split, trim} from 'lodash';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet, useMatches, useNavigate} from 'react-router-dom';
import {adminPaths, paths} from 'routes/path';

const CampaignsLayout = () => {
    const {t} = useTranslation('app');
    const matches = useMatches();
    const matchPath = get(last(matches), 'pathname');
    const mainPath = last(split(trim(matchPath, '/'), '/'));
    const navigate = useNavigate();
    const {roles} = useAuth();
    const {getPermission} = useAdminPermissionSettings({
        roles,
    });
    const tabs = [
        {
            label: t('campaigns'),
            value: 'campaignsOverview',
            path: adminPaths.informationCampaigns,
        },
        {
            label: t('registerClients'),
            value: 'registeredClients',
            path: adminPaths.informationCampaignsRegisteredClients,
        },
        {
            label: t('convertedClients'),
            value: 'convertedClients',
            path: adminPaths.informationCampaignsConvertedClients,
        },
        {
            label: t('campaignsLogs'),
            value: 'campaignsRecords',
            path: adminPaths.informationCampaignsLogs,
        },
    ];
    const value =
        find(tabs, (o) => o.path === matchPath)?.value || tabs[0]?.value;

    return (
        <Stack spacing={4}>
            <CustomTab
                tabs={tabs}
                tabOnly
                value={value}
                onChange={(v) => {
                    navigate(find(tabs, (o) => o.value === v)?.path);
                }}
            />
            <Box width={'100%'}>
                <Outlet />
            </Box>
        </Stack>
    );
};

export default CampaignsLayout;
