import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAuth} from 'hooks/useAuth';
import {get, replace} from 'lodash';
import useCustomToast from 'hooks/useCustomToast';
import CustomDialog from './CustomDialog';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    HStack,
    Stack,
    Text,
} from '@chakra-ui/react';
import BtnGroup from 'components/BtnGroup';
import {useForm} from 'components/FormGenerator/hooks';
import {useColorModeValueKey} from 'hooks/useColors';
import {companyRefereeForm, individualRefereeForm} from 'forms/refereeForm';
import { getErrorMessage } from 'utils/error';
import { initeUser_ } from 'hooks/datahook/referral';

const InviteDialog = (props) => {
    const {open, onClose, profile} = props;
    const {t} = useTranslation('app');
    const {isUser} = useAuth();
    const [isLoading,setIsLoading] = useState(false);
    const toast = useCustomToast();
    const [type, setType] = useState('individual');

    const btns = [
        {
            label: t('individual'),
            value: 'individual',
        },
        {
            label: t('business'),
            value: 'company',
        },
    ];
    const form = (
        type === 'individual' ? individualRefereeForm : companyRefereeForm
    )({i18n: t});
    const {handleSubmit, formGenerator, formik} = useForm({
        fields: form,
        i18n: t,
        defaultValues: {},
        onSubmit: async (values) => {
            console.log(values);
            try {
                setIsLoading(true)
                await initeUser_({
                    type,
                    region: get(values, 'region'),
                    email: get(values, 'email'),
                    phone: replace(get(values, 'phone'), '@', ''),
                    firstname: get(
                        values,
                        'firstname',
                        get(values, 'contactFirstname'),
                    ),
                    lastname: get(
                        values,
                        'lastname',
                        get(values, 'contactLastname'),
                    ),
                    companyName: get(values, 'companyName'),
                    website: get(values, 'website'),
                });
                toast.show({
                    title: t('inviteSuccess'),
                    status: 'success',
                });
                onClose()
            } catch (error) {
                toast.show({
                    title: t('inviteFailed'),
                    status: 'error',
                    desc: getErrorMessage(error),
                });
            }finally{
                setIsLoading(false)
            }
        },
    });
    useEffect(() => {
        if (!open) {
            formik.resetForm();
        }
    }, [open]);
    return (
        <CustomDialog
            open={open}
            onClose={onClose}
            title={t('invite')}
            actions={
                <Button
                    variant="primary"
                    size="sm"
                    onClick={handleSubmit}
                    isLoading={isLoading}
                >
                    {t('send')}
                </Button>
            }
        >
            <Stack>
                <FormControl>
                    <FormLabel fontSize={'xs'} fontWeight={'400'}>
                        {t('userType')}
                    </FormLabel>
                    <BtnGroup btns={btns} onSelect={setType} selected={type} />
                </FormControl>
                {formGenerator().map((item, index) => (
                    <Box key={form[index].name || index}>{item}</Box>
                ))}
            </Stack>
        </CustomDialog>
    );
};

export default InviteDialog;
