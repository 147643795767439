import React from 'react';
import {useDateField, useDateSegment} from 'react-aria';
import {useDateFieldState} from 'react-stately';
import {createCalendar} from '@internationalized/date';
import {useColorModeValueKey} from 'hooks/useColors';
import {capitalize, toUpper} from 'lodash';
import {useTranslation} from 'react-i18next';
import {
    Box,
    HStack,
    Stack,
    useColorModeValue,
    useToken,
} from '@chakra-ui/react';

const DateSegment = ({segment, state}) => {
    let ref = React.useRef(null);
    let {segmentProps} = useDateSegment(segment, state, ref);
    const gray = useToken('colors', useColorModeValueKey('gray'));
    const primary = useToken('colors', useColorModeValueKey('primary'));
    // console.log(segment);
    return (
        <Box
            {...segmentProps}
            ref={ref}
            sx={{
                fontSize: '12px',
                padding: '2px 2px',
                fontVariantNumeric: 'tabular-nums',
                textAlign: 'end',
                ...(segment.isPlaceholder || segment.type === 'literal'
                    ? {
                          color: gray,
                      }
                    : {}),
                borderWidth: 0,
                minWidth:
                    segment.maxValue != null &&
                    String(segment.maxValue).length + 'ch',
                outline: '2px solid transparent',
                outlineOffset: '2px',
                ':focus': {
                    backgroundColor: primary,
                    color: 'white',
                    borderRadius: '2px',
                },
                fontFamily: 'Inter',
            }}
            _disabled={{
                color: 'gray',
            }}
        >
            {segment.type !== 'literal' && segment.text === '月'
                ? 'MM'
                : segment.type !== 'literal' && segment.text === '年'
                ? 'YYYY'
                : segment.type !== 'literal' && segment.text === '日'
                ? 'DD'
                : toUpper(segment.text)}
        </Box>
    );
};

const DateField = (props) => {
    const {
        value,
        dateValue,
        variant,
        InputLeftElement,
        InputRightElement,
        isDisabled,
        ...other
    } = props;
    // const newProps = {
    //     ...other,
    //     dateValue: value,
    // };
    const {i18n} = useTranslation('app');
    let state = useDateFieldState({
        ...props,
        locale: i18n.language,
        createCalendar,
    });

    let ref = React.useRef(null);
    let {labelProps, fieldProps} = useDateField(props, state, ref);
    const brighterBg = useColorModeValueKey('brighterBg');
    const variants = {
        outlined: {
            borderColor:
                state.validationState === 'invalid'
                    ? 'red'
                    : useColorModeValueKey('border'),
            borderStyle: 'solid',
            borderWidth: 1,
            _hover: {
                borderColor: 'inherit',
            },
            _focusWithin: {
                borderColor: useColorModeValueKey('primary'),
            },
        },
        filled: {
            backgroundColor: brighterBg,
            borderColor:
                state.validationState === 'invalid' ? 'red' : 'transparent',

            _hover: {
                borderColor: 'transparent',
                backgroundColor: useColorModeValue('gray.100', 'gray.700'),
                _focusWithin: {
                    backgroundColor: brighterBg,
                },
            },
            _focusWithin: {
                borderColor: useColorModeValueKey('primary'),
            },
        },
    };
    return (
        <HStack
            alignItems={'center'}
            justifyContent={'space-between'}
            flex={1}
            borderWidth={1}
            borderStyle="solid"
            borderColor={useColorModeValueKey('border')}
            rounded={'md'}
            transition={'all 0.2s linear'}
            {...(variants[variant] || {})}
        >
            <HStack flex={1} alignItems={'center'}>
                {InputLeftElement}
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    {...fieldProps}
                    ref={ref}
                    flex={1}
                    px={{base: 1, md: 2}}
                    py={1}
                    // sx={{
                    //     py: variant === 'filled' ? '7px' : '8px',
                    // }}
                    spacing={{base: 0, md: 1}}
                >
                    {state.segments.map((segment, i) => (
                        <DateSegment
                            variant={variant}
                            key={i}
                            segment={segment}
                            state={state}
                            isDisabled={isDisabled}
                        />
                    ))}
                </Stack>
                {InputRightElement}
            </HStack>
        </HStack>
    );
};

export default DateField;
