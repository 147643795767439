import {inputAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';
import {colorByMode} from 'theme/themeHelper';
const {definePartsStyle, defineMultiStyleConfig} =
    createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle((props) => {
    const {colorMode} = props;
    return {
        // define the part you're going to style
        list: {
            // this will style the MenuList component
            // py: '4',
            py: 0,
            overflow: 'hidden',
            borderRadius: 'md',
            // border: 'none',
            bg: colorByMode('brighterBg', colorMode),
            shadow: 'lg',
        },
        item: {
            py: 2,
            bg: colorByMode('brighterBg', colorMode),
            _hover: {
                bg: colorByMode('menuHover', colorMode),
                _active: {
                    bg: colorByMode('menuActive', colorMode),
                },
            },
        },
        divider:{
            my:0
        }
    };
});

const Menu = defineMultiStyleConfig({
    // The styles all button have in common
    baseStyle: baseStyle,
    // sizes: {},
    variants: {},
    // // The default size and variant values
    defaultProps: {},
});

export default Menu;
