// import CurrencySelector from 'components/common/CurrencySelector';
import React from 'react';
import FormWrapper from './FormWrapper';
import CSelector from 'components/CurrencySelector'

const CurrencySelector = (props) => {
    const {
        formik,
        i18n,
        index,
        name,
        label,
        title,
        required = false,
        disabled,
        readOnly,
        helper,
        excluedCurrencies,
        platformCurrencyOnly=false,
        withdrawalCurrencyOnly=false,
        options = [],
    } = props;
    const value = formik.values[name];
    return (
        <FormWrapper {...props}>
            <CSelector
                value={value}
                onValueChange={(value) => {
                    formik.setFieldValue(name, value);
                }}
                isDisabled={disabled}
                options={options?.length ? options : null}
                excluedCurrencies={excluedCurrencies}
                platformCurrencyOnly={platformCurrencyOnly}
                withdrawalCurrencyOnly={withdrawalCurrencyOnly}
            />
        </FormWrapper>
    );
};

// export default CurrencySelector;

export default React.memo(
    CurrencySelector,
    (prev, next) =>{
        const name = prev.name
        return (
            prev.formik.values[name] === next.formik.values[name] &&
            prev.formik.errors[name] === next.formik.errors[name] &&
            prev.formik.touched[name] === next.formik.touched[name] &&
            prev.disabled === next.disabled &&
            prev.i18n === next.i18n &&
            prev.options === next.options
        );
    }
);

