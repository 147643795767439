import {get} from 'lodash';
import {
    RateFormator,
    currencyFormatorOfficial,
    formatDate, currencyFormatorWithDecimal,
} from 'utils/formator';
import {t} from 'i18next';

export const withdrawalReceiptList = ({i18n, data, isClientSide}) => {
    return [
        ...(!get(data, 'mtTicket')
            ? []
            : [
                {
                    label: i18n('ticket'),
                    value: get(data, 'mtTicket'),
                },
            ]),
        ...(!get(data, 'ticket')
            ? []
            : [
                {
                    label: i18n('crmTicket'),
                    value: get(data, 'ticket'),
                },
            ]),
        {
            label: i18n('lastUpdateOn'),
            value: get(data, 'updatedAt')
                ? formatDate(get(data, 'updatedAt'))
                : get(data, 'createdAt') ? formatDate(get(data, 'createdAt')) : '',
        },
        {
            label: i18n('status'),
            value: t(get(data, 'status')),
        },
        {
            label: i18n('transactionType'),
            value: i18n('withdrawal'),
        },
        {
            label: t('fromAccount'),
            value: get(data, 'fromAccount'),
        },
        {
            label: t('fromAmount'),
            value: get(data, 'fromAmount') ? currencyFormatorWithDecimal(
                Math.abs(get(data, 'fromAmount')),
                get(data, 'fromCurrency') || get(data, 'fromCryptoCurrency'),
                get(data, 'fromCryptoCurrency') ? 8 : 2, true) : '',
        },
        {
            label: t('toAccount'),
            value: get(data, 'toAccount'),
        },
        {
            label: t('toAmount'),
            value: get(data, 'to_amount'),
        },
        {
            label: i18n('method'),
            value: get(data, 'method') ? get(data, 'method') : '',
        },
        {
            label: i18n('rate'),
            value: `${RateFormator(get(data, 'rate')) || 1} : 1`,
        },
        {
            label: i18n('fee'),
            value: currencyFormatorOfficial(
                get(data, 'fee') || 0,
                get(
                    data, 'fromCurrency',
                ),
                true,
            ),
        },
        {
            label: i18n('comment'),
            value: get(data, 'comment'),
        },
        ...(!isClientSide && get(data, 'paymentReference') ?
                [{
                    label: i18n('paymentReference'),
                    value: get(data, 'paymentReference'),
                }] :
                []
        ),
    ];
};