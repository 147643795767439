import {Box} from '@chakra-ui/react';
import {useState, useEffect} from 'react';
// hooks
import {useColorModeValueKey} from 'hooks/useColors';
import {useTranslation} from 'react-i18next';

export default ({status}) => {
    const [sta, setSta] = useState(status);
    const {t} = useTranslation('app');
    const statusColor = {
        pending: {color: '#fff', bg: '#3182ce'},
        activated: {color: '#fff', bg: useColorModeValueKey('success')},
        error: {color: '#fff', bg: useColorModeValueKey('error')},
        success: {color: '#fff', bg: useColorModeValueKey('success')},
        warning: {color: '#fff', bg: useColorModeValueKey('warning')},
    };
    useEffect(() => {
        setSta(status);
    }, [status]);
    return (
        <Box
            color={statusColor?.[sta]?.color || '#fff'}
            bg={statusColor?.[sta]?.bg || useColorModeValueKey('success')}
            display="inline-block"
            fontSize="xs"
            borderRadius={12}
            px={2}
            py={0.5}
        >
            {t(status)}
        </Box>
    );
};
