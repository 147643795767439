import { get } from "lodash";

export const processSystemEvent = (rawData) => {
    // console.log(
    //     JSON.stringify([
    //         {
    //             name: 'register user name',
    //             code: 'registerUserName',
    //             description: 'register user name',
    //             example: 'John Doe',
    //             type: 'text',
    //         },
    //         {
    //             name: 'register date',
    //             code: 'registerDate',
    //             description: 'register date',
    //             example: '2023-08-25T06:10:53.413Z',
    //             type: 'datetime',
    //         },
    //     ]),
    // );
    return {
        id: get(rawData, 'id'),
        name: get(rawData, 'name'),
        code: get(rawData, 'code'),
        description: '',
        params:
            typeof get(rawData, 'params') === 'string'
                ? JSON.parse(get(rawData, 'params', '[]'))
                : get(rawData, 'params'),
        // params: JSON.parse(get(rawData, 'params', '[]')).map((item) => ({
        //     name: get(item,'name'),
        //     code: get(item,'code'),
        //     description: get(item,'description'),
        //     example: get(item,'example'),
        //     type: get(item,'type'),
        //     rawData: item,
        // })),
        // params:[],
        rawData,
        // params: [
        //     {
        //         id: 1,
        //         name: 'register user name',
        //         code: 'registerUserName',
        //         description: 'register user name',
        //         example: 'John Doe',
        //         type: 'text',
        //     },
        //     {
        //         id: 2,
        //         name: 'register date',
        //         code: 'registerDate',
        //         description: 'register date',
        //         example: '2023-08-25T06:10:53.413Z',
        //         type: 'datetime',
        //     },
        // ],
    };
};
