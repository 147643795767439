import React, {useEffect, useRef} from 'react';
import FormWrapper from './FormWrapper';
import { Input } from '@chakra-ui/react';
import Selector from 'components/IndustrySelector';

const IndustrySelector = (props) => {
    const {
        formik,
        i18n,
        index,
        name,
        label,
        title,
        required = false,
        disabled,
        readOnly,
        helper,
        inputProps,
    } = props;
    const value = formik.values[name];
    const valueOther = formik.values[`${name}Name`] || '';

    return (
        <FormWrapper {...props}>
            <Selector
                value={value}
                onChangeValue={(value) => {
                    console.log(value);
                    formik.setFieldValue(name, value);
                }}
                isDisabled={disabled}
            />
            <Input
                mt={2}
                size="sm"
                isDisabled={disabled}
                isReadOnly={readOnly}
                onChange={(e) => {
                    formik.setFieldValue(`${name}Name`, e.target.value);
                    formik.setFieldValue(name, '');
                }}
                value={valueOther}
                autoCapitalize="none"
                // size="lg"
                placeholder={i18n('ifOtherPlaseSpecify')}
                {...inputProps}
            />
        </FormWrapper>
    );
};

export default IndustrySelector;
