import {find} from 'lodash';
import {get} from 'lodash';

export const processRegulationRegion = (rawData) => {
    return {
        id: get(rawData, 'id'),
        code: get(rawData, 'code'),
        name: get(rawData, 'name'),
        desc: get(rawData, 'desc'),
        termsAndCondition: get(rawData, 'termsAndCondition'),
        isDefaultGlobal: Boolean(get(rawData, 'isDefaultGlobal')),
        isRestricted: Boolean(get(rawData, 'isRestricted')),
        leverage: get(rawData, 'leverage')?.split(',') || [],
        region: get(rawData, 'region'),
        visibleLevel: get(rawData, 'ibVisibleLevel') || 0,
        visibleDetailLevel: get(rawData, 'ibVisibleDetailLevel') || 0,
        ibPercent: Number(get(rawData, 'ibPercent')) || 0,
        rawData,
    };
};
