import { get } from "lodash";

export const processRiskLevel = (rawData) => {
    return {
        ...rawData,
        name: get(rawData, 'name'),
        maxExemptionLimit: get(rawData, 'amount'),
        desc: get(rawData, 'desc'),
        id: get(rawData, 'id'),
        rawData
    };
};
