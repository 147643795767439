import {Text, Box, Flex} from '@chakra-ui/react';
import {useEffect, useState} from 'react';
import {get} from 'lodash';
// components
import Page from './Page';
// hooks
import {useColorModeValueKey} from 'hooks/useColors';
import {useTranslation} from 'react-i18next';
// utils
import {formatDateTimes} from 'utils/formator';

export default (props) => {
    const {data, type} = props;
    const [current, setCurrent] = useState(null);
    const [line, setLine] = useState(null);
    const {t} = useTranslation('app');
    useEffect(() => {
        const status = get(data, 'status');
        if (type === 'adjustment') {
            setLine([
                {
                    label: t('requestCreatedAt'),
                    date: get(data, 'createdAt')
                        ? formatDateTimes(get(data, 'createdAt'))
                        : '',
                    support: get(data, ':createBy.name') || get(data, ':user.name') || '-',
                    value: 'pending',
                },
            ]);
        } else {
            if (status === 'pending') {
                setLine([
                    {
                        label: t('requestCreatedAt'),
                        date: get(data, 'createdAt')
                            ? formatDateTimes(get(data, 'createdAt'))
                            : '',
                        support: get(data, ':createBy.name') || get(data, ':user.name') || '-',
                        value: 'pending',
                    },
                ]);
            } else {
                setLine([
                    {
                        label: t('requestCreatedAt'),
                        date: get(data, 'createdAt')
                            ? formatDateTimes(get(data, 'createdAt'))
                            : '',
                        support: get(data, ':createBy.name') || get(data, ':user.name') || '-',
                        value: 'pending',
                    },
                    ...(type === 'request'
                        ? [
                              ...(get(data, 'status') === 'processed'
                                  ? [
                                        {
                                            label: t('requestProcessedAt'),
                                            date: get(
                                                data,
                                                'statusProcessedTimestamp',
                                            )
                                                ? formatDateTimes(
                                                      get(
                                                          data,
                                                          'statusProcessedTimestamp',
                                                      ),
                                                  )
                                                : '',
                                            value: 'processed',
                                            support:
                                                get(
                                                    data,
                                                    'processingMethod',
                                                ) === 'Auto'
                                                    ? t('autoProcess')
                                                    : get(
                                                          data,
                                                          'processBy.name',
                                                      ) ||
                                                      get(
                                                          data,
                                                          'approveBy.name',
                                                      ) ||
                                                      '-',
                                        },
                                    ]
                                  : [
                                        {
                                            label: t('requestFailedAt'),
                                            date: get(
                                                data,
                                                'statusFailedTimestamp',
                                            )
                                                ? formatDateTimes(
                                                      get(
                                                          data,
                                                          'statusFailedTimestamp',
                                                      ),
                                                  )
                                                : '',
                                            value: 'failed',
                                            support:
                                                get(data, 'processBy.name') ||
                                                get(data, 'approveBy.name') ||
                                                '-',
                                        },
                                    ]),
                          ]
                        : []),
                    ...(type === 'approval'
                        ? [
                              get(data, ':approval.status') === 'approved'
                                  ? {
                                        label: t('requestApprovedAt'),
                                        date: get(
                                            data,
                                            ':approval.statusApprovedTimestamp',
                                        )
                                            ? formatDateTimes(
                                                  get(
                                                      data,
                                                      ':approval.statusApprovedTimestamp',
                                                  ),
                                              )
                                            : '',
                                        value: 'processed',
                                        support:
                                            get(data, 'processingMethod') ===
                                            'Auto'
                                                ? t('autoApprove')
                                                : get(data, 'approveBy.name') ||
                                                  get(data, 'processBy.name') ||
                                                  '-',
                                    }
                                  : {
                                        label: t('requestDeclinedAt'),
                                        date: get(
                                            data,
                                            ':approval.statusDeclinedTimestamp',
                                        )
                                            ? formatDateTimes(
                                                  get(
                                                      data,
                                                      ':approval.statusDeclinedTimestamp',
                                                  ),
                                              )
                                            : '',
                                        value: 'failed',
                                        support:
                                            get(data, 'approveBy.name') ||
                                            get(data, 'processBy.name') ||
                                            '-',
                                    },
                          ]
                        : []),
                    ...(type === 'task'
                        ? [
                              get(data, ':task.status') === 'processed'
                                  ? {
                                        label: t('requestProcessedAt'),
                                        date: get(
                                            data,
                                            ':task.statusProcessedTimestamp',
                                        )
                                            ? formatDateTimes(
                                                  get(
                                                      data,
                                                      ':task.statusProcessedTimestamp',
                                                  ),
                                              )
                                            : '',
                                        value: 'processed',
                                        support:
                                            get(data, 'processingMethod') ===
                                            'Auto'
                                                ? t('autoProcess')
                                                : get(data, 'processBy.name') ||
                                                  get(data, 'approveBy.name') ||
                                                  '-',
                                    }
                                  : {
                                        label: t('requestDeclinedAt'),
                                        date: formatDateTimes(
                                            get(
                                                data,
                                                ':task.statusDeclinedTimestamp',
                                            ) || get(data, ':task.createdAt'),
                                        )
                                            ? formatDateTimes(
                                                  get(
                                                      data,
                                                      ':task.statusDeclinedTimestamp',
                                                  ) ||
                                                      get(
                                                          data,
                                                          ':task.createdAt',
                                                      ),
                                              )
                                            : '',
                                        value: 'failed',
                                        support:
                                            get(data, 'processBy.name') ||
                                            get(data, 'approveBy.name') ||
                                            '-',
                                    },
                          ]
                        : []),
                ]);
            }
        }
    }, [data?.id]);
    useEffect(() => {
        const select = line?.findIndex(
            (item) => item.value === get(data, 'status'),
        );
        setCurrent(select);
    }, [data?.status, line]);
    return (
        <Box>
            {line?.map((item, index) => {
                return (
                    <Box position={'relative'} mt={3}>
                        {index === line.length - 1 ? (
                            <></>
                        ) : (
                            <Box
                                sx={{
                                    context: '',
                                    display: 'block',
                                    width: '2px',
                                    height: '55px',
                                    backgroundColor:
                                        index <= current - 1
                                            ? useColorModeValueKey('active')
                                            : useColorModeValueKey('border'),
                                    top: 7,
                                    left: '5px',
                                }}
                                position="absolute"
                            />
                        )}
                        <Flex alignItems={'center'} w={'100%'}>
                            <Box
                                w={3}
                                h={3}
                                bg={
                                    index <= current
                                        ? useColorModeValueKey('active')
                                        : useColorModeValueKey('border')
                                }
                                borderRadius="50%"
                            />
                            <Box ml={3} fontWeight={700} fontSize={18}>
                                <Text>{get(item, 'label')}</Text>
                            </Box>
                        </Flex>
                        <Text fontWeight={400} ml={6} fontSize={14}>
                            {get(item, 'support')}
                        </Text>
                        <Text ml={6} fontSize={15} mt={1}>
                            {get(item, 'date')}
                        </Text>
                    </Box>
                );
            })}
        </Box>
    );
};
