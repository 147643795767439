import appConfig from 'config/app';
import {create} from 'zustand';
import {persist} from 'zustand/middleware';

export const useMarketingRecordStore = create(
    persist(
        (set, get) => ({
            recordId: null,
            setRecordId: (value) => set(() => ({recordId: value})),
        }),
        {name: `${appConfig.storePrefix}-marketingRecordStore`},
    ),
);
