import html2canvas from 'html2canvas';
import React from 'react';
import {useTranslation} from 'react-i18next';
import referralBg from 'assets/images/referral_background.png';
import {get} from 'lodash';
import QRCode from 'react-qr-code';
import {useAuth} from 'hooks/useAuth';
import {
    Box,
    Center,
    Heading,
    Image,
    LightMode,
    Stack,
    Text,
    VStack,
    useBreakpointValue,
} from '@chakra-ui/react';

export const saveAs = (uri, filename) => {
    var link = document.createElement('a');

    if (typeof link.download === 'string') {
        link.href = uri;
        link.download = filename;

        //Firefox requires the link to be in the body
        document.body.appendChild(link);

        //simulate click
        link.click();

        //remove the link when done
        document.body.removeChild(link);
    } else {
        window.open(uri);
    }
};

export const printDocument = ({id}) => {
    const input = document.getElementById(id);
    html2canvas(input).then((canvas) => {
        saveAs(canvas.toDataURL(), 'brochure.png');
    });
};

const Brochure = (props) => {
    const {profile, platformBasicInfo} = props;
    const {t} = useTranslation('app');
    const {isUser} = useAuth();
    const copyId = 'brochure';
    const whRate = 1.6666666666666667;
    const wh = useBreakpointValue({
        base: {
            width: '360px',
            height: `${360 * whRate}px`,
        },
        md: {
            width: '500px',
            height: `${500 * whRate}px`,
        },
    });
    const link = `${
        isUser ? window.location.origin : `https://portal.oxsecurities.com`
    }/auth/sign-Up?code=${get(profile, 'referralReference')}`;

    return (
        <LightMode>
            <Center>
                <Box
                    id={copyId}
                    sx={{
                        backgroundColor: '#7CC7E8',
                        backgroundImage: `url(${referralBg})`,
                        backgroundPosition: 'bottom right',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '100%',
                    }}
                    width={wh.width}
                    height={wh.height}
                    position={'relative'}
                    overflow={'hidden'}
                >
                    {/* <Image
                        position={'absolute'}
                        bottom={0}
                        right={0}
                        size={{md: '500px', base: '400px'}}
                        src={referralBg}
                    /> */}
                    <Heading
                        color={'white'}
                        fontSize={{base: '4xl', md: '5xl'}}
                        fontWeight={'600'}
                        px={6}
                        pt={12}
                    >
                        {t('joinForexToday',{shortName: get(platformBasicInfo, 'companyShortName')})}
                    </Heading>
                    <Stack spacing={2} mt={10} px={6}>
                        <Text color={'white'} fontSize={{base: 'xs', md: 'md'}}>
                            {t('brochureTextLine1',
                                {
                                    shortName: get(platformBasicInfo, 'companyShortName'),
                                    username: get(profile, 'name'),
                                })}
                        </Text>
                        <Text color={'white'} fontSize={{base: 'xs', md: 'md'}}>
                            {t('referralCodeIs', {
                                code: get(profile, 'referralReference'),
                            })}
                        </Text>
                    </Stack>
                    {isUser && (
                        <VStack px={6} mt={10}>
                            <Box>
                                <QRCode value={link} size={120} />
                            </Box>
                        </VStack>
                    )}
                </Box>
            </Center>
        </LightMode>
    );
};

export default Brochure;
