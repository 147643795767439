import React from 'react';
import {useTranslation} from 'react-i18next';
import TwoFactorConfirmDialog from './TwoFactorConfirmDialog';
import {Center, Text} from '@chakra-ui/react';

const CommonFactorConfirmDialog = (props) => {
    const {
        open,
        onClose,
        onFinalSumit,
        limitMethod,
        title = 'confirmSubmitKyc',
        allowOnlyEmail,
        mandatoryMobile2FA,
        needTwoFact=true
    } = props;
    const {t: currenciesT} = useTranslation('currencies');
    const {t} = useTranslation('app');
    const customReview = (
        <Center>
            <Text fontSize={'md'}>
                {t('InOrderToProtectYourAccountPleaseConfirmTheTwofactor')}
            </Text>
        </Center>
    );

    return (
        <TwoFactorConfirmDialog
            open={open}
            onClose={onClose}
            customReviewTitle={t(title)}
            onFinalSumit={onFinalSumit}
            customReview={customReview}
            needTwoFact={needTwoFact}
            limitMethod={limitMethod}
            allowOnlyEmail={allowOnlyEmail}
            mandatoryMobile2FA={mandatoryMobile2FA}
        />
    );
};

export default CommonFactorConfirmDialog;
