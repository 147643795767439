import { get } from "lodash"
import { processAddress } from "./common"

export const processContact = (rawData)=>{
    return {
        id: get(rawData, 'id'),
        email: get(rawData, 'email'),
        mobile: get(rawData, 'mobile'),
        ...processAddress(get(rawData, ':address'), 'contact'),
    }
}