import {
    Box,
    Button,
    Divider,
    Flex,
    HStack,
    Hide,
    Stack,
    Tab,
    Text,
    useToken,
} from '@chakra-ui/react';
import CardContainer from 'components/CardContainer';
import CustomTab from 'components/CustomTab';
import Page from 'components/Page';
import {useAdminPermissionSettings} from 'hooks/datahook/adminPermission';
import {useAdminProfile, useProfile} from 'hooks/datahook/auth';
import {useAuth} from 'hooks/useAuth';
import {useColorModeValueKey} from 'hooks/useColors';
import {find, get, last, split, trim} from 'lodash';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet, useMatches, useNavigate} from 'react-router-dom';
import {adminPaths, paths} from 'routes/path';
import tinycolor from 'tinycolor2';

const MetadataLayout = () => {
    const {t} = useTranslation('app');
    const matches = useMatches();
    const matchPath = get(last(matches), 'pathname');
    const mainPath = last(split(trim(matchPath, '/'), '/'));
    const navigate = useNavigate();
    const {roles} = useAuth();
    const {getPermission} = useAdminPermissionSettings({
        roles,
    });
    const tabs = [
        {
            label: t('metadataCategory'),
            value: 'metadataCategory',
            path: adminPaths.metadataCategory,
            hasPermission: getPermission(
                'platformSettings.metadata.metadataCategory',
            ),
        },
        {
            label: t('metadataEdit'),
            value: 'metadataEdit',
            path: adminPaths.metadataEdit,
            hasPermission: getPermission(
                'platformSettings.metadata.metadataEdit',
            ),
        },
    ].filter((item) => item.hasPermission !== false);
    const value =
        find(tabs, (o) => o.path === matchPath)?.value || tabs[0]?.value;

    return (
        <Stack spacing={4}>
            <CustomTab
                tabs={tabs}
                tabOnly
                value={value}
                onChange={(v) => {
                    navigate(find(tabs, (o) => o.value === v)?.path);
                }}
            />
            <Box width={'100%'}>
                <Outlet />
            </Box>
        </Stack>
    );
};

export default MetadataLayout;
