import { get } from "lodash"

export const processAnnouncement = (rawData) =>{
    const contents = {}
    const titles = {}
    get(rawData, ':contents',[]).forEach(item=>{
        contents[item.local] = item.content
        titles[item.local] = item.title
    })
    return {
        id: get(rawData, 'id'),
        createdAt: get(rawData, 'createdAt'),
        titles: titles,
        contents: contents,
        status: get(rawData, 'status'),
        name: get(rawData, 'name'),
        locales: get(rawData, 'locales'),
    };
}