import appSettings from 'config/app';
import {get} from 'lodash';
import HttpClient from 'utils/HttpClient';

const client = new HttpClient(appSettings.apiEndpoint);
export const authClient = new HttpClient(appSettings.authApiEndpoint);
export const authAdminClient = new HttpClient(appSettings.adminAuthApiEndpoint);
export const adminClient = new HttpClient(appSettings.adminApiEndpoint);
export const saleClient = new HttpClient(appSettings.saleApiEndpoint);
export const explorerClient = new HttpClient(appSettings.explorerApiEndpoint);

if (localStorage.getItem(`${process.env.REACT_APP_STORE_PREFIX}-authStore`)) {
    const state = JSON.parse(
        localStorage.getItem(`${process.env.REACT_APP_STORE_PREFIX}-authStore`),
    )?.state;
    const token = state.token;
    const isAdmin = get(state, 'user.roles', []).includes('ADMIN');
    const isSupport = get(state, 'user.roles', []).includes('DEALER');
    const isSale = get(state, 'user.roles', []).includes('SALE');
    const isUser = get(state, 'user.roles', []).includes('USER');
    if (token) {
        authClient.onSending = (req) => {
            req.set('Authorization', `Bearer ${token}`);
        };
        if (isUser) {
            authClient.onSending = (req) => {
                req.set('Authorization', `Bearer ${token}`);
            };
            client.onSending = (req) => {
                req.set('Authorization', `Bearer ${token}`);
            };
            explorerClient.onSending = (req) => {
                req.set('Authorization', `Bearer ${token}`);
            };
        }else{
            // if (isAdmin || isSupport || isSale) {
                authAdminClient.onSending = (req) => {
                    req.set('Authorization', `Bearer ${token}`);
                };
                adminClient.onSending = (req) => {
                    req.set('Authorization', `Bearer ${token}`);
                };
            // }

        }
        // if (isSale) {
        //     saleClient.onSending = (req) => {
        //         req.set('Authorization', `Bearer ${token}`);
        //     };
        //     authClient.onSending = (req) => {
        //         req.set('Authorization', `Bearer ${token}`);
        //     };
        // }
    }
}

export default client;
