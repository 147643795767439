// import {Ionicons} from '@expo/vector-icons';
// import { OptionsPickerModal} from 'components/Pickers';
// import ChevornDown from 'components/common/BaseIcon/ChevornDown';
import {find, get} from 'lodash';
import React, {useEffect, useState} from 'react';
import FormWrapper from './FormWrapper';
import Selector from 'components/Selector';
import {useCryptoNetwork} from '../../../hooks/datahook/common';

const NetworkSelector = (props) => {
    const {
        formik,
        i18n,
        index,
        name,
        disabled,
        readOnly,
        options = [],
        inputProps,
        helper,
        label,
    } = props;
    const {data} = useCryptoNetwork();
    const value = formik.values[name];


    return (
        <FormWrapper {...props}>
            <Selector
                value={value}
                onChangeValue={(v) => {
                    console.log(v);
                    formik.setFieldValue(name, v);
                }}
                isDisabled={disabled}
                options={(get(data, ':networks') || [])
                    .filter((item) => get(item, ':chain.code') !== 'BSC')
                    .map((item) => {
                        return {
                            label: get(item, ':chain.name'),
                            value: get(item, ':chain.code'),
                        };
                    })}
            />
        </FormWrapper>
    );
};

export default React.memo(NetworkSelector, (prev, next) => {
    const name = prev.name;
    return (
        prev.formik.values[name] === next.formik.values[name] &&
        prev.formik.errors[name] === next.formik.errors[name] &&
        prev.formik.touched[name] === next.formik.touched[name] &&
        prev.disabled === next.disabled &&
        prev.readOnly === next.readOnly &&
        prev.i18n === next.i18n
    );
});
