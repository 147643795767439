import {split} from 'lodash';
import {get} from 'lodash';
import {formatMonthDataAndYear} from 'utils/formator';
import {getAddressPayload, processAddress} from './common';

export const porcessLegalRepresentative = (rawData) => {
    const phoneCode = get(rawData, 'phoneCode') || '';
    const phoneNo = get(rawData, 'phoneNo') || '';
    const address = processAddress(
        get(rawData, ':address'),
        'legalRepresentative',
    );
    return {
        id: get(rawData, 'id', ''),
        title: get(rawData, 'title', ''),
        firstname: get(rawData, 'firstname', ''),
        lastname: get(rawData, 'lastname', ''),
        middlename: get(rawData, 'middlename', ''),
        firstNameNative: get(rawData, 'nativeLanguageFirstname', ''),
        middleNameNative: get(rawData, 'nativeLanguageMiddlename', ''),
        lastNameNative: get(rawData, 'nativeLanguageLastname', ''),
        dob: get(rawData, 'dob', ''),
        phoneCode,
        phoneNo,
        phone: `${phoneCode}@${phoneNo}`,
        primaryDocumentFrontSide: get(rawData, 'frontPhotoOfPrimary', ''),
        primaryDocumentBackSide: get(rawData, 'backPhotoOfPrimary', ''),
        secondaryIdentificationDocument: get(rawData, 'photoOfSecondary', ''),
        additionalPhotoIdentification: get(rawData, 'photoOfAdditional', ''),
        countryOfResidence: get(rawData, ':address.country', ''),
        email: get(rawData, 'email', ''),
        position: get(rawData, 'position', ''),
        isLR: Boolean(get(rawData, 'isLR')),
        isAP: Boolean(get(rawData, 'isAP')),
        region: get(rawData, 'region', ''),
        ...address,
        rawData,
    };
};

export const getLegalRepresentativePayloadObj = (processed) => {
    const phone = get(processed, 'phone', '');
    const phoneCode = split(phone, '@')?.[0] || '';
    const phoneNo = split(phone, '@')?.[1] || '';
    const address = getAddressPayload(processed, 'legalRepresentative');
    // console.log(processed);
    return {
        title: get(processed, 'title', ''),
        firstname: get(processed, 'firstname', ''),
        middlename: get(processed, 'middlename', ''),
        lastname: get(processed, 'lastname', ''),
        nativeLanguageFirstname: get(processed, 'firstNameNative', ''),
        nativeLanguageMiddlename: get(processed, 'middleNameNative', ''),
        nativeLanguageLastname: get(processed, 'lastNameNative', ''),
        phoneCode,
        phoneNo,
        region: get(processed, 'region', '') || get(address,'country'),
        dob: get(processed, 'dob', '')
            ? formatMonthDataAndYear(get(processed, 'dob', ''))
            : null,
        email: get(processed, 'email', ''),
        position: get(processed, 'position', ''),
        frontPhotoOfPrimary: get(processed, 'primaryDocumentFrontSide'),
        backPhotoOfPrimary: get(processed, 'primaryDocumentBackSide'),
        photoOfSecondary: get(processed, 'secondaryIdentificationDocument'),
        photoOfAdditional:
            get(processed, 'additionalPhotoIdentification') || null,
        address,
    };
};
