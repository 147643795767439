import React from 'react';
import {toLower, toUpper} from 'lodash';
import {TextIcon} from './TextIcon';
import { cryptoIconsMap } from 'constant/cryptoIcons';

const CryptoIcon = (props) => {
    const {crypto, size = 24, ...other} = props;
    if (!(crypto in cryptoIconsMap)) {
        return <TextIcon name={crypto} size={size} />;
    }
    return (
        <img
            src={require(`assets/cryptoIcons/${toLower(
                crypto,
            )}.svg`)}
            width={size}
            height={size}
            {...other}
        />
    );
};

export default CryptoIcon;
