import {useToast} from '@chakra-ui/react';

export default () => {
    const toast = useToast();
    return {
        show: (config = {}) => {
            if (!toast.isActive(config.id) || config.id === undefined) {
                toast({
                    position: 'top-right',
                    title: config.title,
                    description: config.description || config.desc,
                    status: config.status,
                    duration: 5000,
                    isClosable: true,
                    id: config?.id,
                });
            }
        },
        closeAll: toast.closeAll,
    };
};
