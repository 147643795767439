export const isImage = (file) => {
    return [
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/gif',
        'image/svg+xml',
    ].some((item) => file.type == item);
};
export const isVideo = (file) => {
    return ['video/mp4', 'video/webm', 'video/ogg'].some(
        (item) => file.type == item,
    );
};


export const isImageExtension = (url) => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url?.toLowerCase());
};
export const isPDFExtension = (url) => {
    return /\.(pdf)$/.test(url?.toLowerCase());
};
export const isSvgExtension = (url) => {
    return /\.(svg)$/.test(url?.toLowerCase());
};