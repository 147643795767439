import {useAuth} from 'hooks/useAuth';
import {api, getCurrentUserType} from './common';
import {cleanObjectNilValue} from 'utils/clean';
import {useAdminProfile, useProfile} from './auth';
import {get, isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useUIStore} from 'stores/uiStore';
import {useSWRTemplate} from 'hooks/dataHooksTemplate';

export const updatePassword_ = async ({oldPassword, password}) => {
    const {result} = await api().post(['password'], {
        oldPassword,
        password,
    });
    return result;
};

export const useUserPreference = () => {
    const {isUser} = useAuth();
    const swr = (isUser ? useProfile : useAdminProfile)();
    const {i18n} = useTranslation('app');
    const [firstLanguageCounter, setFirstLanguageCounter] = useUIStore(
        (state) => [state.firstLanguageCounter, state.setFirstLanguageCounter],
    );
    return {
        ...swr,
        data: get(swr, 'data.preference'),
        twoFactorEnabled: Boolean(get(swr, 'data.preference.twoFactor')),
        init: () => {
            if (
                !swr.isLoading &&
                !isEmpty(swr.data) &&
                firstLanguageCounter === 1
            ) {
                if (get(swr, 'data.preference.locale') !== i18n.language) {
                    i18n.changeLanguage(get(swr, 'data.preference.locale'));
                }
                setFirstLanguageCounter(0);
            }
        },
    };
};

export const updatePhishingCode_ = async (id, data) => {
    const {result} = await api().put(['profile', id], data);
    return result;
}

export const updateUserSettings_ = async (payload) => {
    const userTypes = getCurrentUserType();
    const {result} = await api().post(
        [
            userTypes.isAdmin || userTypes.isSupport
                ? 'owner-setting'
                : 'setting',
            'base',
        ],
        payload,
    );
    return result;
};

export const updateUserAvatar_ = async ({avatar}) => {
    const userTypes = getCurrentUserType();
    const {result} = await api().post(
        [
            userTypes.isAdmin || userTypes.isSupport
                ? 'owner-setting'
                : 'setting',
            'avatar',
        ],
        {avatar},
    );
    return result;
};

export const updateContact_ = async (id, payload) => {
    const {result} = await api().put(
        ['profile', id],
        cleanObjectNilValue(payload),
    );
    return result;
};

export const useIbSettings = () => {
    return useSWRTemplate({
        key: ['ib-settings'],
        request: async () => {
            const {result} = await api().get(['setting', 'ib']);
            return result;
        },
    });
};

export const useBasicSetting = () => {
    const swr = useSWRTemplate({
        key: ['ib-settings'],
        request: async () => {
            const {result} = await api().get(['setting', 'basic-setting']);
            return result;
        },
    });
    return {
        ...swr,
        updateBasicSetting: async (id, data) => {
            const {result} = await api().put(
                ['setting', 'basic-setting', id],
                data,
            );
            return result;
        },
    };
};

export const useKycSetting = () => {
    const swr = useSWRTemplate({
        key: ['kyc-settings'],
        request: async () => {
            const {result} = await api().get(['setting', 'kyc']);
            return result;
        },
    });
    return {
        ...swr,
        updateKycSetting: async (data) => {
            const {result} = await api().put(
                ['setting', 'kyc', 'KYC_DEFAULT_SETTINGS'],
                data,
            );
            return result;
        },
    };
};

export const useMaintenanceSetting = () => {
    const swr = useSWRTemplate({
        key: ['maintenance-settings'],
        request: async () => {
            const {result} = await api().get([
                'setting',
                'maintenance-setting',
            ]);
            return result;
        },
    });
    return {
        ...swr,
        updateMaintenanceSetting: async (data) => {
            const {result} = await api().put(
                ['setting', 'maintenance-setting', 'MTS'],
                data,
            );
            return result;
        },
    };
};

export const useMT4Setting = (type) => {
    const swr = useSWRTemplate({
        key: ['mt4-settings', type],
        request: async () => {
            const {result} = await api().get([
                'setting', 'mt4',
            ], { type });
            return result;
        },
    });
    return {
        ...swr,
        updateMT4Setting: async (data, type) => {
            const {result} = await api().put(
                ['setting', 'mt4', type === 's1'?'MT4_SERVER_SETTINGS': type === 'demo'? 'MT4_DEMO_SERVER_SETTINGS': type === 's3'? 'MT4_S3_SERVER_SETTINGS' : 'MT4_S2_SERVER_SETTINGS'],
                cleanObjectNilValue(data),
            );
            return result;
        },
    };
};

export const useAutoLeverageSetting = (source = 'MT4') => {
    const swr = useSWRTemplate({
        key: ['auto-leverage-setting', source],
        request: async () => {
            const {result} = await api().get(
                ['setting', 'leverage-approval-setting'],
                {source},
            );
            return result;
        },
    });
    return {
        ...swr,
        updateMT45AutoLeverage: async (data) => {
            const {result} = await api().post(
                ['setting', 'leverage-approval-setting'],
                cleanObjectNilValue(data),
            );
            return result;
        },
    };
};

export const useMT5Setting = () => {
    const swr = useSWRTemplate({
        key: ['mt5-settings'],
        request: async () => {
            const {result} = await api().get(['setting', 'mt5']);
            return result;
        },
    });
    return {
        ...swr,
        updateMT5Setting: async (data) => {
            const {result} = await api().put(
                ['setting', 'mt5', 'MT5_SERVER_SETTINGS'],
                data,
            );
            return result;
        },
    };
};

export const testConnect = async () => {
    const {result} = await api().post(['test-connect'], {
        host: 'localhost',
        user: 'root',
        password: 'yangleicheng',
    });
    return result;
};

export const usePlatformProduct = () => {
    const swr = useSWRTemplate({
        key: ['platform-product'],
        request: async () => {
            const {result} = await api().get(['platform', 'product']);
            return result;
        },
    });
    return {
        ...swr,
        stockPermissions: (swr.data || []).filter(
            (item) => get(item, 'name') === 'Stock' && get(item, 'enable'),
        ),
    };
};

export const editPlatformProduct_ = async (data) => {
    const {result} = await api().put(
        ['platform', 'product', 'Stock'],
        cleanObjectNilValue(data),
    );
    return result;
};
