import {format, endOfMonth, startOfMonth, addMonths, parseISO} from 'date-fns';
import {get} from 'lodash';
import {raw} from '@storybook/react';

export const processTotalTrades = (rawData) => {
    return {
        ...rawData
    }
};

export const topGainOrLoss = (rawData) => {
    return {
        clientDetail: {
            name: rawData.client,
            id: rawData.userId,
        },
        ...rawData,
    };
};

export const keepTwoDecimalPlaces = (rawData) => {
    const newItem = {...rawData};
    for (const key in newItem) {
        if (key !== 'mtDate') {
            newItem[key] = Number(newItem[key]).toFixed(2);
        }
    }
    return newItem;
};

export const processAccountByYear = (rowData, year) => {
    const start = parseISO(`${year}-01-01`);
    const end = parseISO(`${year}-12-31`);
    let dates = [];

    let currentMonthStart = startOfMonth(start);
    let endMonth = endOfMonth(end);
    while (currentMonthStart <= endMonth) {
        dates.push(format(currentMonthStart, 'yyyy-MM-dd'));
        currentMonthStart = addMonths(currentMonthStart, 1);
    }

    const groupedByDate = rowData.reduce((acc, item) => {
        const key = item.mtDate;
        if (!acc[key]) {
            acc[key] = {
                MT4s1: 0,
                MT4s2: 0,
                MT4s3: 0,
                MT5: 0,
                mtDate: format(new Date(item.mtDate), 'yyyy-MM-01'),
            };
        }
        acc[key][item.source] += item.value;
        return acc;
    }, {});

    // 由于我们只需要一个日期（'2024-01-02 00:00:00'）的结果，我们可以直接从这个分组对象中获取它
    // 但如果有多个日期，你可能需要遍历Object.values(groupedByDate)来获取所有结果
    const result = Object.values(groupedByDate).map((group) => ({
        MT4s1: group.MT4s1 || 0,
        MT4s2: group.MT4s2 || 0,
        MT4s3: group.MT4s3 || 0,
        MT5: group.MT5 || 0,
        mtDate: group.mtDate,
    }));

    // dates.forEach((date) => {
    //     const currentDate = result.find((item) => item.mtDate === date);
    //     if (!currentDate) {
    //         const emptyData = {
    //             mtDate: date,
    //             Total: 0,
    //         };
    //         ['MT4s1', 'MT4s2', 'MT4s3', 'MT5'].forEach((source) => {
    //             emptyData[source] = 0;
    //         });
    //         result.push(emptyData);
    //     }
    // });
    return result.map(item=>{
        return {
            ...item,
            date: format(new Date(get(item, 'mtDate')), 'PP').split(' '),
            Total: get(item, 'MT4s1')+ get(item, 'MT4s2')+ get(item, 'MT4s3')+ get(item, 'MT5')
        }
    });
}

export const byCurrencies = (rawData) => {
    const keyArr = ['totalBalance', 'totalCredit', 'totalEquity', 'totalPnl'];
    keyArr.forEach((key) => (rawData[key] = rawData[key] || 0));
    return rawData;
};

export const byCurrenciesDetails = (rawData) => {
    const keyArr = [
        'TotalLots',
        'USD',
        'SGD',
        'EUR',
        'AUD',
        'CAD',
        'GBP',
        'USC',
    ];
    keyArr.forEach((key) => (rawData[key] = rawData[key] || 0));
    return rawData;
};
