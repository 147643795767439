export const allPermissions = [
    {
        key: 'clientsHome',
        write: true,
        read: true,
        sub: [
            {
                key: 'clients',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'clientDetail',
                        write: true,
                        read: true,
                        sub: [
                            {
                                key: 'profile',
                                write: true,
                                read: true,
                                sub: [
                                    {
                                        key: 'contact',
                                        write: true,
                                        read: true,
                                    },
                                    {
                                        key: 'regulation',
                                        write: true,
                                        read: true,
                                    },
                                    {
                                        key: 'riskLevel',
                                        write: true,
                                        read: true,
                                    },
                                ],
                            },
                            {
                                key: 'kyc',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'fxAccounts',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'stock',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'transactions',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'aml',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'statements',
                                write: true,
                                read: true,
                            },
                            // {
                            //     key: 'referees',
                            //     write: true,
                            //     read: true,
                            // },
                            // {
                            //     key: 'ibAndcommissions',
                            //     write: true,
                            //     read: true,
                            // },
                            {
                                key: 'ibAgent',
                                write: true,
                                read: true,
                                sub: [
                                    {
                                        key: 'overview',
                                        write: true,
                                        read: true,
                                    },
                                    {
                                        key: 'subLevels',
                                        write: true,
                                        read: true,
                                    },
                                    {
                                        key: 'pendingInvitations',
                                        write: true,
                                        read: true,
                                    },
                                ],
                            },
                            {
                                key: 'notes',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'additionalFiles',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'security',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'api',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'withdrawalAccounts',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'clientsCCSubAccounts',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'interactions',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'referees',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'cryptoWallet',
                                write: true,
                                read: true,
                                sub: [
                                    {
                                        key: 'privateKey',
                                        write: true,
                                        read: true,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                key: 'clientsLeads',
                write: true,
                read: true,
            },
            {
                key: 'clientsAccounts',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'createClientAccount',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'rewire',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'changeLeverage',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'changeGroup',
                        write: true,
                        read: true,
                    }
                ],
            },
            {
                key: 'clientsRewireAccounts',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'remove',
                        write: false,
                        read: true,
                    },
                ],
            },
            {
                key: 'clientsPammAccounts',
                write: false,
                read: true,
            },
            {
                key: 'clientsCCSubAccounts',
                write: true,
                read: true,
            },
            {
                key: 'clientsIbAdmin',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'rewire',
                        write: false,
                        read: false,
                    },
                    {
                        key: 'changeIBTree',
                        write: false,
                        read: false,
                    },
                ],
            },
            {
                key: 'managerSupports',
                write: false,
                read: false,
            },
            {
                key: 'managerSales',
                write: false,
                read: false,
            },
            {
                key: 'clientWithdrawalBanks',
                write: true,
                read: true,
            },
            {
                key: 'clientWithdrawalWallets',
                write: true,
                read: true,
            },
            {
                key: 'clientDepositWallets',
                write: true,
                read: true,
            },
            {
                key: 'demoAccounts',
                write: true,
                read: true,
            },
            {
                key: 'batchAssign',
                write: false,
                read: true
            },
            {
                key: 'debitCards',
                write: true,
                read: true
            }
        ],
    },
    {
        key: 'tasks',
        write: true,
        read: true,
        sub: [
            {
                key: 'requests',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'requestSummary',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'requestDeposits',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'requestWithdrawals',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'requestTransfer',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'requestLeverage',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'requestPassword',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'requestKyc',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'requestIB',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'requestWithdrawalAccount',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'requestWithdrawalWallet',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'requestGeneral',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'requestAccount',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'myTasks',
                write: true,
                read: true,
            },
            {
                key: 'taskboard',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'taskboardKyc',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'taskboardDeposits',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'taskboardWithdrawals',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'taskboardTransfer',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'taskboardPassword',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'taskboardLeverage',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'taskboardGeneral',
                        write: true,
                        read: true,
                    },
                ],
            },
        ],
    },
    {
        key: 'riskManagement',
        write: true,
        read: true,
        sub: [
            {
                key: 'approvals',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'approvalsMt45Accounts',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'approvalsIbApplications',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'approvalsFxWithdrawals',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'approvalsWithdrawalAccount',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'approvalsKyc',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'approvalsWithdrawalWallet',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'riskReports',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'salesReports',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'riskMtTradeMonitor',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'total',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'currency',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'symbol',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'profitsAnalysis',
                write: true,
                read: true,
            },
            {
                key: 'commissionAnalysis',
                write: true,
                read: true,
            },
            {
                key: 'equityReports',
                write: true,
                read: true,
            },
            {
                key: 'taskReports',
                write: true,
                read: true,
            },
            {
                key: 'commissionReports',
                write: true,
                read: true,
            },
        ],
    },
    {
        key: 'platformSettings',
        write: true,
        read: true,
        sub: [
            {
                key: 'riskLevel',
                write: true,
                read: true,
            },
            {
                key: 'stockProducts',
                write: true,
                read: true,
            },
            {
                key: 'regulationRegion',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'regulation',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'restrictedRegion',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'metadata',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'metadataCategory',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'metadataEdit',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'currencies',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'currenciesPlatform',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'currenciesAccounts',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'currenciesDefination',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'currenciesWithdrawal',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'currenciesServiceFee',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'paymentChannels',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'paymentGateway',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'blockchainGateway',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'commonGateway',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'mt45Settings',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'mt4Settings',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'mt4DemoSettings',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'mt5Settings',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'settings',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'integration',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'KYCProvider',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'SMSEmailProvider',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'MessagerProvider',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'accessControl',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'systemRoles',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'systemRoleAccess',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'userAutherization',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'adminAccessLog',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'clientAccessLog',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'generalSettings',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'systemSettings',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'clientPolicies',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'ibSettings',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'systemProducts',
                write: true,
                read: true,
            },
        ],
    },
    {
        key: 'applicationManagement',
        write: true,
        read: true,
        sub: [
            {
                key: 'apiManagement',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'apiAutherization',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'apiAccessLog',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'apiErrorTracking',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'systemMonitorHome',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'systemStatus',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'systemLog',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'systemAlert',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'systemErrorTracking',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'systemScheduledTasks',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'securityManagement',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'ipAccessLog',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'ipBlacklist',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'securityPolicy',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'authRules',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'bulkData',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'clientsBulkData',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'banksBulkData',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'transactionsBulkData',
                        write: true,
                        read: true,
                    },
                ],
            },
        ],
    },
    {
        key: 'finance',
        write: true,
        read: true,
        sub: [
            {
                key: 'financeSummary',
                write: true,
                read: true,
            },
            {
                key: 'financeTransaction',
                write: true,
                read: true,
            },
            {
                key: 'financeLedge',
                write: true,
                read: true,
            },
            {
                key: 'financeExpenses',
                write: true,
                read: true,
            },
            {
                key: 'financeRequests',
                write: true,
                read: true,
            },
        ],
    },
    {
        key: 'manageReports',
        write: true,
        read: true,
        sub: [
            {
                key: 'equityReports',
                write: true,
                read: true,
            },
            {
                key: 'netReports',
                write: true,
                read: true,
            },
            {
                key: 'clientReports',
                write: true,
                read: true,
            },
            {
                key: 'manageCommissionReports',
                write: true,
                read: true,
            },
        ],
    },
    {
        key: 'informationHome',
        write: true,
        read: true,
        sub: [
            {
                key: 'informationAnnouncements',
                write: true,
                read: true,
            },
            {
                key: 'informationNews',
                write: true,
                read: true,
            },
            {
                key: 'informationAds',
                write: true,
                read: true,
            },
            {
                key: 'informationEmails',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'emails',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'emailTemplates',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'informationMessages',
                write: true,
                read: true,
            },
            {
                key: 'informationAccessSource',
                write: true,
                read: true,
            },
        ],
    },
    {
        key: 'supports',
        write: true,
        read: true,
        sub: [
            {
                key: 'faq',
                write: true,
                read: true,
            },
            {
                key: 'incidents',
                write: true,
                read: true,
            },
            {
                key: 'onlineSupport',
                write: true,
                read: true,
            },
            {
                key: 'tickets',
                write: true,
                read: true,
            },
            {
                key: 'newTicket',
                write: true,
                read: true,
            },
            {
                key: 'editFaq',
                write: true,
                read: true,
            },
            {
                key: 'newFaq',
                write: true,
                read: true,
            },
        ],
    },
    {
        key: 'transactions',
        write: true,
        read: true,
        sub: [
            {
                key: 'transactionSummary',
                write: true,
                read: true,
            },
            {
                key: 'transactionDeposits',
                write: true,
                read: true,
            },
            {
                key: 'transactionWithdrawals',
                write: true,
                read: true,
            },
            {
                key: 'transactionTransfers',
                write: true,
                read: true,
            },
            {
                key: 'transactionCommissions',
                write: true,
                read: true,
            },
            {
                key: 'transactionAdjustments',
                write: true,
                read: true,
            },
            {
                key: 'transactionMoneyMoves',
                write: true,
                read: true,
            },
            {
                key: 'transactionHDWalletOperations',
                write: true,
                read: true,
            },
        ],
    },
    {
        key: 'notification',
        write: true,
        read: true,
    },
    {
        key: 'stocksHome',
        write: true,
        read: true,
        sub: [
            {
                key: 'stockAccounts',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'newAdjustment',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'sendPassword',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'disable',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'stockTransactions',
                write: true,
                read: true,
            },
            {
                key: 'stockPositions',
                write: true,
                read: true,
            },
            {
                key: 'stockProducts',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'editStatus',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'stockRequests',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'requestSummary',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'requestDeposits',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'requestWithdrawals',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'requestTransfer',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'requestAccount',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'stockTaskBoard',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'taskboardDeposits',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'taskboardWithdrawals',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'taskboardTransfer',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'myStockTasks',
                write: true,
                read: true,
            },
        ],
    },
];

export const permissionSettings = {
    ADMIN: allPermissions,
    FINANCE: [
        {
            key: 'tasks',
            write: true,
            read: true,
            sub: [
                {
                    key: 'requests',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'requestSummary',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestDeposits',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestWithdrawals',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestTransfer',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestLeverage',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestPassword',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestWithdrawalAccount',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestGeneral',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestKyc',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestIB',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestWithdrawalWallet',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestAccount',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'myTasks',
                    write: true,
                    read: true,
                },
                {
                    key: 'taskboard',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'taskboardKyc',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardWithdrawalAccount',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardDeposits',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardWithdrawals',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardGeneral',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardTransfer',
                            write: true,
                            read: true,
                        },
                    ],
                },
            ],
        },
        {
            key: 'clientsHome',
            write: false,
            read: true,
            sub: [
                {
                    key: 'clients',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'clientDetail',
                            write: false,
                            read: true,
                            sub: [
                                {
                                    key: 'profile',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'fxAccounts',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'stock',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'transactions',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'aml',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'statements',
                                    write: false,
                                    read: true,
                                },
                                // {
                                //     key: 'referees',
                                //     write: false,
                                //     read: true,
                                // },
                                // {
                                //     key: 'ibAndcommissions',
                                //     write: false,
                                //     read: true,
                                // },
                                {
                                    key: 'ibAgent',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'notes',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'additionalFiles',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'withdrawalAccounts',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'interactions',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'cryptoWallet',
                                    write: true,
                                    read: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    key: 'clientsLeads',
                    write: false,
                    read: true,
                },
                {
                    key: 'demoAccounts',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'finance',
            write: false,
            read: true,
            sub: [
                {
                    key: 'financeSummary',
                    write: false,
                    read: true,
                },
                {
                    key: 'financeTransaction',
                    write: true,
                    read: true,
                },
                {
                    key: 'financeLedge',
                    write: false,
                    read: true,
                },
                {
                    key: 'financeExpenses',
                    write: false,
                    read: true,
                },
                {
                    key: 'financeRequests',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'transactions',
            write: true,
            read: true,
            sub: [
                {
                    key: 'transactionSummary',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionDeposits',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionWithdrawals',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionTransfers',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionCommissions',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionAdjustments',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionMoneyMoves',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionHDWalletOperations',
                    write: false,
                    read: true,
                },
            ],
        },
    ],
    SUPPORT: [
        {
            key: 'tasks',
            write: true,
            read: true,
            sub: [
                {
                    key: 'requests',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'requestSummary',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestDeposits',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestWithdrawals',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestTransfer',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestLeverage',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestPassword',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestWithdrawalAccount',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestGeneral',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestKyc',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestIB',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestWithdrawalWallet',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'myTasks',
                    write: true,
                    read: true,
                },
                {
                    key: 'taskboard',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'taskboardKyc',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardWithdrawalAccount',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardDeposits',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardWithdrawals',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardGeneral',
                            write: true,
                            read: true,
                        },
                    ],
                },
            ],
        },
        {
            key: 'riskManagement',
            write: true,
            read: true,
            sub: [
                {
                    key: 'approvals',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'approvalsMt45Accounts',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'approvalsIbApplications',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'approvalsKyc',
                            write: true,
                            read: true,
                        },
                    ],
                },
            ],
        },
        {
            key: 'clientsHome',
            write: true,
            read: true,
            sub: [
                {
                    key: 'clients',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'clientDetail',
                            write: true,
                            read: true,
                            sub: [
                                {
                                    key: 'profile',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'kyc',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'fxAccounts',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'stock',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'transactions',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'aml',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'statements',
                                    write: true,
                                    read: true,
                                },
                                // {
                                //     key: 'referees',
                                //     write: true,
                                //     read: true,
                                // },
                                // {
                                //     key: 'ibAndcommissions',
                                //     write: true,
                                //     read: true,
                                // },
                                {
                                    key: 'ibAgent',
                                    write: true,
                                    read: true,
                                    sub: [
                                        {
                                            key: 'overview',
                                            write: true,
                                            read: true,
                                        },
                                        {
                                            key: 'subLevels',
                                            write: true,
                                            read: true,
                                        },
                                        {
                                            key: 'pendingInvitations',
                                            write: true,
                                            read: true,
                                        },
                                    ],
                                },
                                {
                                    key: 'referees',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'notes',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'additionalFiles',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'security',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'withdrawalAccounts',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'clientsCCSubAccounts',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'interactions',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'cryptoWallet',
                                    write: true,
                                    read: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    key: 'clientsLeads',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientsAccounts',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'createClientAccount',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'rewire',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'changeLeverage',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'clientsRewireAccounts',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'remove',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'clientsPammAccounts',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientsCCSubAccounts',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientsIbAdmin',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientWithdrawalBanks',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientWithdrawalWallets',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientDepositWallets',
                    write: true,
                    read: true,
                },
                {
                    key: 'demoAccounts',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'transactions',
            write: true,
            read: true,
            sub: [
                {
                    key: 'transactionSummary',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionDeposits',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionWithdrawals',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionTransfers',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionCommissions',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionAdjustments',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactionMoneyMoves',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'supports',
            write: true,
            read: true,
            sub: [
                {
                    key: 'faq',
                    write: true,
                    read: true,
                },
                {
                    key: 'incidents',
                    write: true,
                    read: true,
                },
                {
                    key: 'onlineSupport',
                    write: true,
                    read: true,
                },
                {
                    key: 'tickets',
                    write: true,
                    read: true,
                },
                {
                    key: 'newTicket',
                    write: true,
                    read: true,
                },
                {
                    key: 'editFaq',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'manageReports',
            write: true,
            read: true,
            sub: [
                // {
                //     key: 'equityReports',
                //     write: true,
                //     read: true
                // },
                // {
                //     key: 'netReports',
                //     write: true,
                //     read: true
                // },
                // {
                //     key: 'clientReports',
                //     write: true,
                //     read: true
                // },
                {
                    key: 'manageCommissionReports',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'applicationManagement',
            write: false,
            read: true,
            sub: [
                {
                    key: 'apiManagement',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'apiAutherization',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'apiAccessLog',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'apiErrorTracking',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'systemMonitorHome',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'systemStatus',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'systemLog',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'systemAlert',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'systemErrorTracking',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'securityManagement',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'ipAccessLog',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'ipBlacklist',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'securityPolicy',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'authRules',
                            write: false,
                            read: true,
                        },
                    ],
                },
            ],
        },
    ],
    SUPPORT_MANAGER: [
        {
            key: 'tasks',
            write: true,
            read: true,
            sub: [
                {
                    key: 'requests',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'requestSummary',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestDeposits',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestWithdrawals',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestTransfer',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestLeverage',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestPassword',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestWithdrawalAccount',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestGeneral',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestKyc',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestIB',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestWithdrawalWallet',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'myTasks',
                    write: true,
                    read: true,
                },
                {
                    key: 'taskboard',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'taskboardKyc',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardWithdrawalAccount',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardDeposits',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardWithdrawals',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardGeneral',
                            write: true,
                            read: true,
                        },
                    ],
                },
            ],
        },
        {
            key: 'riskManagement',
            write: true,
            read: true,
            sub: [
                {
                    key: 'approvals',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'approvalsMt45Accounts',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'approvalsIbApplications',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'approvalsKyc',
                            write: true,
                            read: true,
                        },
                    ],
                },
            ],
        },
        {
            key: 'clientsHome',
            write: true,
            read: true,
            sub: [
                {
                    key: 'clients',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'clientDetail',
                            write: true,
                            read: true,
                            sub: [
                                {
                                    key: 'profile',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'kyc',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'fxAccounts',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'stock',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'transactions',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'aml',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'statements',
                                    write: true,
                                    read: true,
                                },
                                // {
                                //     key: 'referees',
                                //     write: true,
                                //     read: true,
                                // },
                                // {
                                //     key: 'ibAndcommissions',
                                //     write: true,
                                //     read: true,
                                // },
                                {
                                    key: 'ibAgent',
                                    write: true,
                                    read: true,
                                    sub: [
                                        {
                                            key: 'overview',
                                            write: true,
                                            read: true,
                                        },
                                        {
                                            key: 'subLevels',
                                            write: true,
                                            read: true,
                                        },
                                        {
                                            key: 'pendingInvitations',
                                            write: true,
                                            read: true,
                                        },
                                    ],
                                },
                                {
                                    key: 'referees',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'notes',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'additionalFiles',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'security',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'withdrawalAccounts',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'clientsCCSubAccounts',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'interactions',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'cryptoWallet',
                                    write: true,
                                    read: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    key: 'clientsLeads',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientsAccounts',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'createClientAccount',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'rewire',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'changeLeverage',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'clientsRewireAccounts',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'remove',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'clientsPammAccounts',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientsCCSubAccounts',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientsIbAdmin',
                    write: true,
                    read: true,
                },
                {
                    key: 'managerSupports',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientWithdrawalBanks',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientWithdrawalWallets',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientDepositWallets',
                    write: true,
                    read: true,
                },
                {
                    key: 'demoAccounts',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'transactions',
            write: true,
            read: true,
            sub: [
                {
                    key: 'transactionSummary',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionDeposits',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionWithdrawals',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionTransfers',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionCommissions',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionAdjustments',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionMoneyMoves',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'supports',
            write: true,
            read: true,
            sub: [
                {
                    key: 'faq',
                    write: true,
                    read: true,
                },
                {
                    key: 'incidents',
                    write: true,
                    read: true,
                },
                {
                    key: 'onlineSupport',
                    write: true,
                    read: true,
                },
                {
                    key: 'tickets',
                    write: true,
                    read: true,
                },
                {
                    key: 'newTicket',
                    write: true,
                    read: true,
                },
                {
                    key: 'editFaq',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'applicationManagement',
            write: false,
            read: true,
            sub: [
                {
                    key: 'apiManagement',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'apiAutherization',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'apiAccessLog',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'apiErrorTracking',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'systemMonitorHome',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'systemStatus',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'systemLog',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'systemAlert',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'systemErrorTracking',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'securityManagement',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'ipAccessLog',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'ipBlacklist',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'securityPolicy',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'authRules',
                            write: false,
                            read: true,
                        },
                    ],
                },
            ],
        },
    ],
    RISK_COMPLIANCE: [
        {
            key: 'clientsHome',
            write: true,
            read: true,
            sub: [
                {
                    key: 'clients',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'clientDetail',
                            write: true,
                            read: true,
                            sub: [
                                {
                                    key: 'profile',
                                    write: true,
                                    read: true,
                                    sub: [
                                        {
                                            key: 'contact',
                                            write: true,
                                            read: true,
                                        },
                                        {
                                            key: 'regulation',
                                            write: true,
                                            read: true,
                                        },
                                        {
                                            key: 'riskLevel',
                                            write: true,
                                            read: true,
                                        },
                                    ],
                                },
                                {
                                    key: 'kyc',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'fxAccounts',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'stock',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'transactions',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'aml',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'statements',
                                    write: true,
                                    read: true,
                                },
                                // {
                                //     key: 'referees',
                                //     write: true,
                                //     read: true,
                                // },
                                // {
                                //     key: 'ibAndcommissions',
                                //     write: true,
                                //     read: true,
                                // },
                                {
                                    key: 'ibAgent',
                                    write: true,
                                    read: true,
                                    sub: [
                                        {
                                            key: 'overview',
                                            write: false,
                                            read: true,
                                        },
                                        {
                                            key: 'subLevels',
                                            write: false,
                                            read: true,
                                        },
                                        {
                                            key: 'pendingInvitations',
                                            write: false,
                                            read: true,
                                        },
                                    ],
                                },
                                {
                                    key: 'notes',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'additionalFiles',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'withdrawalAccounts',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'clientsCCSubAccounts',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'interactions',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'cryptoWallet',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'security',
                                    write: true,
                                    read: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    key: 'clientsLeads',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientsAccounts',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientsRewireAccounts',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientsPammAccounts',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientsCCSubAccounts',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientsIbAdmin',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientWithdrawalBanks',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientWithdrawalWallets',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientDepositWallets',
                    write: true,
                    read: true,
                },
                {
                    key: 'demoAccounts',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'tasks',
            write: true,
            read: true,
            sub: [
                {
                    key: 'requests',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'requestSummary',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestDeposits',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'requestWithdrawals',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'requestTransfer',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'requestLeverage',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestPassword',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestKyc',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestIB',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestWithdrawalAccount',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestWithdrawalWallet',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestGeneral',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestAccount',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'taskboard',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'taskboardKyc',
                            write: true,
                            read: true,
                        },

                        {
                            key: 'taskboardDeposits',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardWithdrawals',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardTransfer',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardPassword',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardLeverage',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardGeneral',
                            write: true,
                            read: true,
                        },
                    ],
                },
            ],
        },
        {
            key: 'riskManagement',
            write: true,
            read: true,
            sub: [
                {
                    key: 'approvals',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'approvalsMt45Accounts',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'approvalsIbApplications',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'approvalsFxWithdrawals',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'approvalsWithdrawalAccount',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'approvalsKyc',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'approvalsWithdrawalWallet',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'riskReports',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'salesReports',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'riskMtTradeMonitor',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'total',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'currency',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'symbol',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'profitsAnalysis',
                    write: true,
                    read: true,
                },
                {
                    key: 'commissionAnalysis',
                    write: true,
                    read: true,
                },
                {
                    key: 'equityReports',
                    write: true,
                    read: true,
                },
                {
                    key: 'taskReports',
                    write: true,
                    read: true,
                },
                {
                    key: 'commissionReports',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'platformSettings',
            write: true,
            read: true,
            sub: [
                {
                    key: 'riskLevel',
                    write: true,
                    read: true,
                },
                {
                    key: 'stockProducts',
                    write: true,
                    read: true,
                },
                {
                    key: 'regulationRegion',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'regulation',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'restrictedRegion',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'generalSettings',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'systemSettings',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'clientPolicies',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'ibSettings',
                            write: true,
                            read: true,
                        },
                    ],
                },
            ],
        },
        {
            key: 'informationHome',
            write: true,
            read: true,
            sub: [
                {
                    key: 'informationAnnouncements',
                    write: true,
                    read: true,
                },
                {
                    key: 'informationNews',
                    write: true,
                    read: true,
                },
                {
                    key: 'informationAds',
                    write: true,
                    read: true,
                },
                {
                    key: 'informationEmails',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'emails',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'emailTemplates',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'informationMessages',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'supports',
            write: true,
            read: true,
            sub: [
                {
                    key: 'faq',
                    write: true,
                    read: true,
                },
                {
                    key: 'incidents',
                    write: true,
                    read: true,
                },
                {
                    key: 'onlineSupport',
                    write: true,
                    read: true,
                },
                {
                    key: 'tickets',
                    write: true,
                    read: true,
                },
                {
                    key: 'newTicket',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'transactions',
            write: true,
            read: true,
            sub: [
                {
                    key: 'transactionSummary',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionDeposits',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionWithdrawals',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionTransfers',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionCommissions',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionAdjustments',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactionMoneyMoves',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionHDWalletOperations',
                    write: false,
                    read: true,
                },
            ],
        },
    ],
    IT_ADMIN: [
        {
            key: 'clientsHome',
            write: false,
            read: true,
            sub: [
                {
                    key: 'clients',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'clientDetail',
                            write: false,
                            read: true,
                            sub: [
                                {
                                    key: 'profile',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'fxAccounts',
                                    write: false,
                                    read: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    key: 'clientsLeads',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'tasks',
            write: false,
            read: false,
            sub: [
                {
                    key: 'requests',
                    write: false,
                    read: false,
                },
                {
                    key: 'taskboard',
                    write: false,
                    read: false,
                    sub: [
                        {
                            key: 'taskboardKyc',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'taskboardWithdrawalAccount',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'taskboardDeposits',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'taskboardWithdrawals',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'taskboardGeneral',
                            write: false,
                            read: true,
                        },
                    ],
                },
            ],
        },
        {
            key: 'platformSettings',
            write: true,
            read: true,
            sub: [
                {
                    key: 'metadata',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'metadataCategory',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'metadataEdit',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'paymentChannels',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'paymentGateway',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'blockchainGateway',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'commonGateway',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'mt45Settings',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'mt4Settings',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'mt4DemoSettings',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'mt5Settings',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'settings',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'integration',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'KYCProvider',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'SMSEmailProvider',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'accessControl',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'systemRoles',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'systemRoleAccess',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'userAutherization',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'adminAccessLog',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'clientAccessLog',
                            write: true,
                            read: true,
                        },
                    ],
                },
            ],
        },
        {
            key: 'applicationManagement',
            write: true,
            read: true,
            sub: [
                {
                    key: 'apiManagement',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'apiAutherization',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'apiAccessLog',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'apiErrorTracking',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'systemMonitorHome',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'systemStatus',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'systemLog',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'systemAlert',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'systemErrorTracking',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'systemScheduledTasks',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'securityManagement',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'ipAccessLog',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'ipBlacklist',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'securityPolicy',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'authRules',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'bulkData',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'clientsBulkData',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'banksBulkData',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'transactionsBulkData',
                            write: true,
                            read: true,
                        },
                    ],
                },
            ],
        },
        {
            key: 'supports',
            write: true,
            read: true,
            sub: [
                {
                    key: 'faq',
                    write: true,
                    read: true,
                },
                {
                    key: 'incidents',
                    write: true,
                    read: true,
                },
                {
                    key: 'onlineSupport',
                    write: true,
                    read: true,
                },
                {
                    key: 'tickets',
                    write: true,
                    read: true,
                },
                {
                    key: 'newTicket',
                    write: true,
                    read: true,
                },
            ],
        },
    ],
    MARKETING: [
        {
            key: 'clientsHome',
            write: false,
            read: false,
            sub: [
                {
                    key: 'clients',
                    write: false,
                    read: false,
                    sub: [
                        {
                            key: 'clientDetail',
                            write: false,
                            read: false,
                            sub: [
                                {
                                    key: 'profile',
                                    write: false,
                                    read: false,
                                },
                            ],
                        },
                    ],
                },
                {
                    key: 'clientsLeads',
                    write: false,
                    read: false,
                },
            ],
        },
        {
            key: 'informationHome',
            write: true,
            read: true,
            sub: [
                {
                    key: 'informationAnnouncements',
                    write: true,
                    read: true,
                },
                {
                    key: 'informationNews',
                    write: true,
                    read: true,
                },
                {
                    key: 'informationAds',
                    write: true,
                    read: true,
                },
                {
                    key: 'informationEmails',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'emails',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'emailTemplates',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'informationMessages',
                    write: true,
                    read: true,
                },
                {
                    key: 'informationAccessSource',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'supports',
            write: false,
            read: false,
            sub: [
                {
                    key: 'faq',
                    write: false,
                    read: false,
                },
                {
                    key: 'tickets',
                    write: false,
                    read: false,
                },
                {
                    key: 'newTicket',
                    write: false,
                    read: false,
                },
            ],
        },
    ],
    SALE: [
        {
            key: 'clientsHome',
            write: false,
            read: true,
            sub: [
                {
                    key: 'clients',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'clientDetail',
                            write: false,
                            read: true,
                            sub: [
                                {
                                    key: 'profile',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'kyc',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'fxAccounts',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'stock',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'transactions',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'aml',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'statements',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'referees',
                                    write: false,
                                    read: true,
                                },
                                // {
                                //     key: 'ibAndcommissions',
                                //     write: false,
                                //     read: true,
                                // },
                                {
                                    key: 'ibAgent',
                                    write: false,
                                    read: true,
                                    sub: [
                                        {
                                            key: 'overview',
                                            write: false,
                                            read: true,
                                        },
                                        {
                                            key: 'subLevels',
                                            write: false,
                                            read: true,
                                        },
                                        {
                                            key: 'pendingInvitations',
                                            write: false,
                                            read: true,
                                        },
                                    ],
                                },
                                {
                                    key: 'notes',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'additionalFiles',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'withdrawalAccounts',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'clientsCCSubAccounts',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'interactions',
                                    write: false,
                                    read: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    key: 'clientsLeads',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientsAccounts',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientsRewireAccounts',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientsPammAccounts',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientsCCSubAccounts',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientsIbAdmin',
                    write: false,
                    read: false,
                },
                {
                    key: 'demoAccounts',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'tasks',
            write: false,
            read: false,
            sub: [
                {
                    key: 'requests',
                    write: false,
                    read: false,
                },
                {
                    key: 'taskboard',
                    write: false,
                    read: false,
                    sub: [
                        {
                            key: 'taskboardKyc',
                            write: false,
                            read: false,
                        },
                        {
                            key: 'taskboardWithdrawalAccount',
                            write: false,
                            read: false,
                        },
                        {
                            key: 'taskboardDeposits',
                            write: false,
                            read: false,
                        },
                        {
                            key: 'taskboardWithdrawals',
                            write: false,
                            read: false,
                        },
                        {
                            key: 'taskboardGeneral',
                            write: false,
                            read: false,
                        },
                    ],
                },
            ],
        },
        {
            key: 'riskManagement',
            write: false,
            read: false,
            sub: [
                {
                    key: 'approvals',
                    write: false,
                    read: false,
                    sub: [
                        {
                            key: 'approvalsMt45Accounts',
                            write: false,
                            read: false,
                        },
                        {
                            key: 'approvalsIbApplications',
                            write: false,
                            read: false,
                        },
                        {
                            key: 'approvalsFxWithdrawals',
                            write: false,
                            read: false,
                        },
                    ],
                },
            ],
        },
        {
            key: 'supports',
            write: true,
            read: true,
            sub: [
                {
                    key: 'faq',
                    write: false,
                    read: false,
                },
                {
                    key: 'incidents',
                    write: false,
                    read: true,
                },
                {
                    key: 'onlineSupport',
                    write: false,
                    read: true,
                },
                {
                    key: 'tickets',
                    write: true,
                    read: true,
                },
                {
                    key: 'newTicket',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'transactions',
            write: false,
            read: true,
            sub: [
                {
                    key: 'transactionSummary',
                    write: false,
                    read: false,
                },
                {
                    key: 'transactionDeposits',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactionWithdrawals',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactionTransfers',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactionCommissions',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactionAdjustments',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactionMoneyMoves',
                    write: true,
                    read: true,
                },
            ],
        },
    ],
    SALE_MANAGER: [
        {
            key: 'clientsHome',
            write: true,
            read: true,
            sub: [
                {
                    key: 'clients',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'clientDetail',
                            write: false,
                            read: true,
                            sub: [
                                {
                                    key: 'profile',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'kyc',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'fxAccounts',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'stock',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'transactions',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'aml',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'statements',
                                    write: false,
                                    read: true,
                                },
                                // {
                                //     key: 'referees',
                                //     write: false,
                                //     read: true,
                                // },
                                // {
                                //     key: 'ibAndcommissions',
                                //     write: false,
                                //     read: true,
                                // },
                                {
                                    key: 'ibAgent',
                                    write: false,
                                    read: true,
                                    sub: [
                                        {
                                            key: 'overview',
                                            write: false,
                                            read: true,
                                        },
                                        {
                                            key: 'subLevels',
                                            write: false,
                                            read: true,
                                        },
                                        {
                                            key: 'pendingInvitations',
                                            write: false,
                                            read: true,
                                        },
                                    ],
                                },
                                {
                                    key: 'notes',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'additionalFiles',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'withdrawalAccounts',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'clientsCCSubAccounts',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'interactions',
                                    write: false,
                                    read: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    key: 'clientsLeads',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientsAccounts',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientsRewireAccounts',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientsPammAccounts',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientsCCSubAccounts',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientsIbAdmin',
                    write: false,
                    read: true,
                },
                {
                    key: 'managerSales',
                    write: true,
                    read: true,
                },
                {
                    key: 'demoAccounts',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'tasks',
            write: false,
            read: false,
            sub: [
                {
                    key: 'requests',
                    write: false,
                    read: false,
                },
                {
                    key: 'taskboard',
                    write: false,
                    read: false,
                    sub: [
                        {
                            key: 'taskboardKyc',
                            write: false,
                            read: false,
                        },
                        {
                            key: 'taskboardWithdrawalAccount',
                            write: false,
                            read: false,
                        },
                        {
                            key: 'taskboardDeposits',
                            write: false,
                            read: false,
                        },
                        {
                            key: 'taskboardWithdrawals',
                            write: false,
                            read: false,
                        },
                        {
                            key: 'taskboardGeneral',
                            write: false,
                            read: false,
                        },
                    ],
                },
            ],
        },
        {
            key: 'riskManagement',
            write: false,
            read: false,
            sub: [
                {
                    key: 'approvals',
                    write: false,
                    read: false,
                    sub: [
                        {
                            key: 'approvalsMt45Accounts',
                            write: false,
                            read: false,
                        },
                        {
                            key: 'approvalsIbApplications',
                            write: false,
                            read: false,
                        },
                        {
                            key: 'approvalsFxWithdrawals',
                            write: true,
                            read: false,
                        },
                    ],
                },
            ],
        },
        {
            key: 'supports',
            write: true,
            read: true,
            sub: [
                {
                    key: 'faq',
                    write: false,
                    read: true,
                },
                {
                    key: 'incidents',
                    write: false,
                    read: true,
                },
                {
                    key: 'onlineSupport',
                    write: false,
                    read: true,
                },
                {
                    key: 'tickets',
                    write: true,
                    read: true,
                },
                {
                    key: 'newTicket',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'transactions',
            write: true,
            read: true,
            sub: [
                {
                    key: 'transactionSummary',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactionDeposits',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactionWithdrawals',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactionTransfers',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactionCommissions',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactionAdjustments',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactionMoneyMoves',
                    write: true,
                    read: true,
                },
            ],
        },
    ],
    STOCK_SUPPORT: [
        {
            key: 'clientsHome',
            write: true,
            read: true,
            sub: [
                {
                    key: 'clients',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'clientDetail',
                            write: true,
                            read: true,
                            sub: [
                                {
                                    key: 'profile',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'kyc',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'fxAccounts',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'stock',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'transactions',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'aml',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'statements',
                                    write: true,
                                    read: true,
                                },
                                // {
                                //     key: 'referees',
                                //     write: true,
                                //     read: true,
                                // },
                                // {
                                //     key: 'ibAndcommissions',
                                //     write: true,
                                //     read: true,
                                // },
                                {
                                    key: 'ibAgent',
                                    write: true,
                                    read: true,
                                    sub: [
                                        {
                                            key: 'overview',
                                            write: true,
                                            read: true,
                                        },
                                        {
                                            key: 'subLevels',
                                            write: true,
                                            read: true,
                                        },
                                        {
                                            key: 'pendingInvitations',
                                            write: true,
                                            read: true,
                                        },
                                    ],
                                },
                                {
                                    key: 'referees',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'notes',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'additionalFiles',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'security',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'withdrawalAccounts',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'interactions',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'cryptoWallet',
                                    write: true,
                                    read: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    key: 'clientsLeads',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientsAccounts',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'createClientAccount',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'rewire',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'changeLeverage',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'clientsRewireAccounts',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'remove',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'clientsPammAccounts',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientsIbAdmin',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientWithdrawalBanks',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientWithdrawalWallets',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientDepositWallets',
                    write: true,
                    read: true,
                },
                {
                    key: 'demoAccounts',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'stocksHome',
            write: true,
            read: true,
            sub: [
                {
                    key: 'stockAccounts',
                    write: true,
                    read: true,
                },
                {
                    key: 'stockTransactions',
                    write: true,
                    read: true,
                },
                {
                    key: 'stockPositions',
                    write: true,
                    read: true,
                },
                {
                    key: 'stockProducts',
                    write: true,
                    read: true,
                },
                {
                    key: 'stockRequests',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'requestSummary',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestDeposits',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestWithdrawals',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestTransfer',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestAccount',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'stockTaskBoard',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'taskboardDeposits',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardWithdrawals',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardTransfer',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'myStockTasks',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'tasks',
            write: true,
            read: true,
            sub: [
                {
                    key: 'requests',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'requestSummary',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestDeposits',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestWithdrawals',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestTransfer',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestLeverage',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestPassword',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestWithdrawalAccount',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestGeneral',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestKyc',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestIB',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestWithdrawalWallet',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'myTasks',
                    write: true,
                    read: true,
                },
                {
                    key: 'taskboard',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'taskboardKyc',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardWithdrawalAccount',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardDeposits',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardWithdrawals',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardGeneral',
                            write: true,
                            read: true,
                        },
                    ],
                },
            ],
        },
    ],
    STOCK_SUPPORT_MANAGER: [
        {
            key: 'clientsHome',
            write: true,
            read: true,
            sub: [
                {
                    key: 'clients',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'clientDetail',
                            write: true,
                            read: true,
                            sub: [
                                {
                                    key: 'profile',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'kyc',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'fxAccounts',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'stock',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'transactions',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'aml',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'statements',
                                    write: true,
                                    read: true,
                                },
                                // {
                                //     key: 'referees',
                                //     write: true,
                                //     read: true,
                                // },
                                // {
                                //     key: 'ibAndcommissions',
                                //     write: true,
                                //     read: true,
                                // },
                                {
                                    key: 'ibAgent',
                                    write: true,
                                    read: true,
                                    sub: [
                                        {
                                            key: 'overview',
                                            write: true,
                                            read: true,
                                        },
                                        {
                                            key: 'subLevels',
                                            write: true,
                                            read: true,
                                        },
                                        {
                                            key: 'pendingInvitations',
                                            write: true,
                                            read: true,
                                        },
                                    ],
                                },
                                {
                                    key: 'referees',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'notes',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'additionalFiles',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'security',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'withdrawalAccounts',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'interactions',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'cryptoWallet',
                                    write: true,
                                    read: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    key: 'clientsLeads',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientsAccounts',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'createClientAccount',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'rewire',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'changeLeverage',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'clientsRewireAccounts',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'remove',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'clientsPammAccounts',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientsIbAdmin',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientWithdrawalBanks',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientWithdrawalWallets',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientDepositWallets',
                    write: true,
                    read: true,
                },
                {
                    key: 'demoAccounts',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'stocksHome',
            write: true,
            read: true,
            sub: [
                {
                    key: 'stockAccounts',
                    write: true,
                    read: true,
                },
                {
                    key: 'stockTransactions',
                    write: true,
                    read: true,
                },
                {
                    key: 'stockPositions',
                    write: true,
                    read: true,
                },
                {
                    key: 'stockProducts',
                    write: true,
                    read: true,
                },
                {
                    key: 'stockRequests',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'requestSummary',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestDeposits',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestWithdrawals',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestTransfer',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestAccount',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'stockTaskBoard',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'taskboardDeposits',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardWithdrawals',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardTransfer',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'myStockTasks',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'tasks',
            write: true,
            read: true,
            sub: [
                {
                    key: 'requests',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'requestSummary',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestDeposits',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestWithdrawals',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestTransfer',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestLeverage',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestPassword',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestWithdrawalAccount',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestGeneral',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestKyc',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestIB',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'requestWithdrawalWallet',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'myTasks',
                    write: true,
                    read: true,
                },
                {
                    key: 'taskboard',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'taskboardKyc',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardWithdrawalAccount',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardDeposits',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardWithdrawals',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'taskboardGeneral',
                            write: true,
                            read: true,
                        },
                    ],
                },
            ],
        },
    ],
    IB_ADMIN: [
        {
            key: 'clientsHome',
            write: true,
            read: true,
            sub: [
                {
                    key: 'clientsRewireAccounts',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'remove',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'clientsIbAdmin',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'rewire',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'changeIBTree',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'batchAssign',
                    write: true,
                    read: true
                }
            ],
        },
    ],
};
