import {useTranslation} from 'react-i18next';
import {enAU, zhCN, zhTW} from 'date-fns/locale';

export const useLocale = () => {
    const {i18n} = useTranslation();
    return {
        locale: i18n.language,
        fnsLngLib:
            i18n.language === 'zh-CN'
                ? zhCN
                : i18n.language === 'zh-TW'
                ? zhTW
                : enAU,
        ...i18n,
    };
};
