import { Box, Stack } from '@chakra-ui/react';
import CustomTab from 'components/CustomTab';
import { find, get, last, trimEnd } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatches, useNavigate } from 'react-router-dom';
import { paths } from 'routes/path';

const DebitCardClientLayout = () => {
    const { t } = useTranslation('app');
    const matches = useMatches();
    const matchPath = get(last(matches), 'pathname');
    const navigate = useNavigate();
    const tabs = [
        {
            label: t('debitCard'),
            value: 'debitCard',
            path: paths.debitCard,
        },
        {
            label: t('application'),
            value: 'application',
            path: paths.application,
        },
        {
            label: t('feeAndTopups'),
            value: 'feeAndTopups',
            path: paths.feeAndTopups,
        },
    ];
    const value =
        find(
            tabs,
            (o) =>
                trimEnd(matchPath, '/') === o.path ||
                trimEnd(matchPath, '/').includes(o.path),
        )?.value || tabs[0]?.value;

    return (
        <Stack spacing={4} width="100%">
            <CustomTab
                tabs={tabs}
                tabOnly
                value={value}
                onChange={(v) => {
                    navigate(find(tabs, (o) => o.value === v)?.path);
                }}
            />
            <Box width={'100%'}>
                <Outlet />
            </Box>
        </Stack>
    );
};

export default DebitCardClientLayout;