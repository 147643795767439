import {tagAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers, defineStyle} from '@chakra-ui/react';
import { colorByMode } from 'theme/themeHelper';

const {definePartsStyle, defineMultiStyleConfig} =
    createMultiStyleConfigHelpers(tagAnatomy.keys);


const xs = defineStyle({
    px: '2',
    py: '1',
    fontSize: '9px',
});

const sizes = {
    xs: definePartsStyle({container: xs, label: xs}),
};

const grayOutline = definePartsStyle((props) => ({
    container: {
        color: colorByMode('gray', props.colorMode),
        boxShadow: `inset 0 0 0px 1px ${colorByMode('gray', props.colorMode)}`,
    },
    label: {
        fontSize: '9px',
    },
}));



export const tagTheme = defineMultiStyleConfig({sizes, variants: {grayOutline}});
