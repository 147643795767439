import {
    Box,
    Button,
    Center,
    Divider,
    Flex,
    HStack,
    Icon,
    IconButton,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Spinner,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tag,
    Text,
    useColorMode,
    useToken,
} from '@chakra-ui/react';
import {useMessage, useUnreadMessageCount} from 'hooks/datahook/message';
import {useAuth} from 'hooks/useAuth';
import React, {useEffect, useState} from 'react';
import {HiBell} from 'react-icons/hi';
import Bell from './Icon/Bell';
import {useTranslation} from 'react-i18next';
import {ChevronLeftIcon, CloseIcon} from '@chakra-ui/icons';
import {useColorModeValueKey} from 'hooks/useColors';
import {formatRelativeDay} from 'utils/formator';
import {formatDateTime} from 'utils/formator';
import {BsCheck2Circle} from 'react-icons/bs';
import CustomPagination from './CustomPagination';
import tinycolor from 'tinycolor2';
import {HiBellAlert, HiInformationCircle} from 'react-icons/hi2';
import {IoInformationCircle, IoNewspaper, IoWarning} from 'react-icons/io5';
import {useNavigate} from 'react-router-dom';
import {paths, adminPaths} from '../routes/path';
import {useAnnouncements} from 'hooks/datahook/announcement';
import {split} from 'lodash';

const NotificationItem = (props) => {
    const {item, onClick, onRead} = props;
    const {type, title, message, createdAt, readFlag, id} = item;
    const grayColor = useColorModeValueKey('gray');
    const bgColor = useColorModeValueKey('bg');
    const brighterBgColor = useColorModeValueKey('brighterBg');
    const primary = useColorModeValueKey('primary');
    const hoverColor = tinycolor(useToken('colors', bgColor))
        .setAlpha(0.4)
        .toRgbString();
    const {t} = useTranslation('app');
    const {colorMode} = useColorMode();
    const typeColorScheme =
        type === 'info'
            ? 'blue'
            : type === 'failed'
            ? `${colorMode}.error`
            : type === 'success'
            ? `${colorMode}.success`
            : type === 'warning'
            ? 'yellow'
            : 'gray';
    return (
        <Stack
            px={4}
            py={3}
            pl={3}
            spacing={2}
            borderLeft={'4px'}
            borderColor={!readFlag ? bgColor : 'transparent'}
            transition={'all 0.2s linear'}
            _hover={{
                backgroundColor: hoverColor,
            }}
            cursor={'pointer'}
            onClick={() => {
                if (onClick) {
                    onClick(item);
                    if (!readFlag) onRead(id);
                }
            }}
        >
            <Stack spacing={0}>
                <Flex
                    alignItems={'flex-start'}
                    justifyContent={'space-between'}
                >
                    <Text noOfLines={2} flex={1} fontSize={'xs'}>
                        {title}
                    </Text>
                    <Box
                        rounded="full"
                        h={2}
                        w={2}
                        mt={1.5}
                        bg={primary}
                        opacity={!readFlag ? 1 : 0}
                    />
                </Flex>
                <Text fontSize={'2xs'} color={grayColor}>
                    {formatDateTime(createdAt)}
                </Text>
                <HStack pt={1}>
                    <Tag
                        size={'sm'}
                        variant="solid"
                        colorScheme={typeColorScheme}
                    >
                        {t(type)}
                    </Tag>
                </HStack>
            </Stack>
            <Stack rounded={'md'} bg={bgColor} px={2} py={1}>
                <Text fontSize={'xs'} noOfLines={2}>
                    {message}
                </Text>
            </Stack>
        </Stack>
    );
};

const AnnouncementItem = (props) => {
    const {item, onClick} = props;
    const {i18n} = useTranslation('app');
    const currentLanguage = i18n.language;
    const {titles, contents, createdAt, id} = item;
    const grayColor = useColorModeValueKey('gray');
    const bgColor = useColorModeValueKey('bg');
    const brighterBgColor = useColorModeValueKey('brighterBg');
    const hoverColor = tinycolor(useToken('colors', bgColor))
        .setAlpha(0.4)
        .toRgbString();
    const {t} = useTranslation('app');
    const {colorMode} = useColorMode();

    return (
        <Stack
            px={4}
            py={3}
            pl={4}
            spacing={2}
            transition={'all 0.2s linear'}
            _hover={{
                backgroundColor: hoverColor,
            }}
            cursor={'pointer'}
            onClick={() => {
                if (onClick) {
                    onClick(item);
                }
            }}
        >
            <Stack spacing={0}>
                <Flex
                    alignItems={'flex-start'}
                    justifyContent={'space-between'}
                >
                    <Text noOfLines={2} flex={1} fontSize={'xs'}>
                        {titles[currentLanguage] || titles['en-US']}
                    </Text>
                </Flex>
                <Text fontSize={'2xs'} color={grayColor}>
                    {formatDateTime(createdAt)}
                </Text>
            </Stack>
            <Stack rounded={'md'} bg={bgColor} px={2} py={1}>
                <Text fontSize={'xs'} noOfLines={2}>
                    {contents[currentLanguage] || contents['en-US']}
                </Text>
            </Stack>
        </Stack>
    );
};

const NotificationDetailContent = (props) => {
    const {item, onGoBack, onClose} = props;
    const {type, title, message, createdAt, readFlag, id} = item;
    const {t} = useTranslation('app');
    const {colorMode} = useColorMode();
    const grayColor = useColorModeValueKey('gray');
    const bgColor = useColorModeValueKey('bg');
    const typeColorScheme =
        type === 'info'
            ? 'blue'
            : type === 'failed'
            ? `${colorMode}.error`
            : type === 'success'
            ? `${colorMode}.success`
            : type === 'warning'
            ? 'yellow'
            : 'gray';
    return (
        <PopoverContent>
            <HStack
                alignItems={'center'}
                justifyContent={'space-between'}
                px={4}
                py={2}
            >
                <HStack alignItems={'center'}>
                    <IconButton
                        onClick={onGoBack}
                        size="xs"
                        icon={<ChevronLeftIcon boxSize={5} />}
                    />
                    <Text fontWeight={'600'}>{t('detail')}</Text>
                </HStack>
                <IconButton onClick={onClose} size="xs" icon={<CloseIcon />} />
            </HStack>
            <Divider />
            <Stack px={4} py={3} spacing={2}>
                <Stack spacing={0}>
                    <Flex
                        alignItems={'flex-start'}
                        justifyContent={'space-between'}
                    >
                        <Text noOfLines={2} flex={1} fontSize={'xs'}>
                            {title}
                        </Text>
                    </Flex>
                    <Text fontSize={'2xs'} color={grayColor}>
                        {formatDateTime(createdAt)}
                    </Text>
                    <HStack pt={1}>
                        <Tag
                            size={'sm'}
                            variant="solid"
                            colorScheme={typeColorScheme}
                        >
                            {t(type)}
                        </Tag>
                    </HStack>
                </Stack>
                <Stack rounded={'md'} bg={bgColor} px={2} py={1}>
                    <Text fontSize={'xs'}>{message}</Text>
                </Stack>
            </Stack>
        </PopoverContent>
    );
};

const AnnouncementsListSubContent = (props) => {
    const {type, onSelect} = props;
    const {t} = useTranslation('app');
    const {
        data,
        isLoading,
        totalPage,
        size,
        page,
        setPage,
        totalItems,
        pageSize,
    } = useAnnouncements({request: true, type: type});
    const grayColor = useColorModeValueKey('gray');
    return (
        <>
            <Stack maxH={'400px'} overflow={'scroll'}>
                {isLoading ? (
                    <Center py={6}>
                        <Spinner size={'sm'} />
                    </Center>
                ) : (
                    <Stack spacing={0} divider={<Divider />}>
                        {data.length > 0 ? (
                            data.map((item) => (
                                <AnnouncementItem
                                    key={item.id}
                                    item={item}
                                    onClick={onSelect}
                                />
                            ))
                        ) : (
                            <Center py={6}>
                                <Text color={grayColor}>
                                    {t('noAnnouncement')}
                                </Text>
                            </Center>
                        )}
                    </Stack>
                )}
            </Stack>
            {/* <PopoverFooter>
                <Flex alignItems={'center'} justifyContent={'flex-end'}>
                    <CustomPagination
                        totalPage={totalPage}
                        page={page}
                        onChange={setPage}
                        pageSize={pageSize}
                        totalItems={totalItems}
                    />
                </Flex>
            </PopoverFooter> */}
        </>
    );
};

const NotificationListSubContent = (props) => {
    const {type, onSelect} = props;
    const {t} = useTranslation('app');
    const {
        data,
        isLoading,
        totalPage,
        size,
        page,
        setPage,
        totalItems,
        pageSize,
        markReadFlag_,
    } = useMessage({request: true, type: type});
    const markAllAsRead = () => {};
    const grayColor = useColorModeValueKey('gray');
    return (
        <>
            <Stack maxH={'400px'} overflow={'scroll'}>
                {isLoading ? (
                    <Center py={6}>
                        <Spinner size={'sm'} />
                    </Center>
                ) : (
                    <Stack spacing={0} divider={<Divider />}>
                        {data.length > 0 ? (
                            data.map((item) => (
                                <NotificationItem
                                    key={item.id}
                                    item={item}
                                    onRead={markReadFlag_}
                                    onClick={onSelect}
                                />
                            ))
                        ) : (
                            <Center py={6}>
                                <Text color={grayColor}>
                                    {t('noNotificationType', {
                                        name: split(type, ',')
                                            .map((type) => t(type))
                                            .join(', '),
                                    })}
                                </Text>
                            </Center>
                        )}
                    </Stack>
                )}
            </Stack>
            <PopoverFooter>
                <Flex alignItems={'center'} justifyContent={'flex-end'}>
                    {/*<Button*/}
                    {/*    color={grayColor}*/}
                    {/*    variant={'ghost'}*/}
                    {/*    size="xs"*/}
                    {/*    fontSize={'xs'}*/}
                    {/*    onClick={markAllAsRead}*/}
                    {/*    leftIcon={*/}
                    {/*        <Icon as={BsCheck2Circle} color={grayColor} />*/}
                    {/*    }*/}
                    {/*>*/}
                    {/*    {t('markAllAsRead')}*/}
                    {/*</Button>*/}
                    <CustomPagination
                        totalPage={totalPage}
                        page={page}
                        onChange={setPage}
                        pageSize={pageSize}
                        totalItems={totalItems}
                    />
                </Flex>
            </PopoverFooter>
        </>
    );
};

const NotificationListContent = ({
    onSelect,
    onClose,
    open,
    tabIndex,
    setTabIndex,
}) => {
    const {isUser} = useAuth();
    const {t} = useTranslation('app');
    const navigate = useNavigate();
    const {data: count} = useUnreadMessageCount();
    const primary = useColorModeValueKey('primary');
    const tabs = [
        ...(isUser
            ? [
                  {
                      label: t('announcements'),
                      icon: HiBell,
                      component: <AnnouncementsListSubContent />,
                  },
              ]
            : []),
        {
            label: t('notifications'),
            icon: IoInformationCircle,
            component: (
                <NotificationListSubContent
                    type="info,success"
                    onSelect={onSelect}
                />
            ),
        },
        {
            label: t('alerts'),
            icon: IoWarning,
            component: (
                <NotificationListSubContent
                    type="warning,failed"
                    onSelect={onSelect}
                />
            ),
        },
        // {
        //     label: t('news'),
        //     icon: IoNewspaper,
        //     component: <>Under developing</>,
        // },
    ];
    const clickable = isUser ? tabIndex === 1 || tabIndex === 2 : true;
    return (
        <PopoverContent>
            <HStack
                alignItems={'center'}
                justifyContent={'space-between'}
                px={4}
                py={2}
            >
                <Text fontWeight={'600'}>
                    {tabIndex === 0 && isUser
                        ? t('announcements')
                        : t('notifications')}{' '}
                    {count > 0 && tabIndex !== 0 && `(${count} ${t('unread')})`}
                </Text>
                <Text
                    onClick={
                        clickable
                            ? () => {
                                  onClose();
                                  isUser
                                      ? navigate(paths.notification)
                                      : navigate(adminPaths.notification);
                              }
                            : null
                    }
                    fontSize={12}
                    cursor={clickable ? 'pointer' : undefined}
                    color={primary}
                    textDecoration='underline'
                >
                    {tabIndex === 0 && isUser ? '' : t('more')}
                </Text>
                <IconButton onClick={onClose} size="xs" icon={<CloseIcon />} />
            </HStack>
            <Divider />
            <Tabs index={tabIndex} onChange={setTabIndex}>
                <Stack overflowX={'scroll'}>
                    <TabList borderBottomWidth={1}>
                        {tabs.map((tab, index) => (
                            <Tab key={index} borderBottomWidth={3}>
                                <Icon as={tab.icon} boxSize={4} />
                            </Tab>
                        ))}
                    </TabList>
                </Stack>
                <TabPanels p={0}>
                    {tabs.map((tab, index) => (
                        <TabPanel key={index} p={0}>
                            {tab.component}
                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        </PopoverContent>
    );
};

const NotificationBtn = (props) => {
    const {t} = useTranslation('app');
    const {isUser} = useAuth();
    const [open, setOpen] = useState(false);
    const {data: count} = useUnreadMessageCount();
    // const count = 0
    const [openedItem, setOpenedItem] = useState(null);
    const [tabIndex, setTabIndex] = useState(isUser ? 1 : 0);
    const primary = useColorModeValueKey('primary');
    useEffect(() => {
        setTabIndex(isUser ? 1 : 0);
    }, [open]);
    return (
        <Popover isLazy isOpen={open} onClose={() => setOpen(false)}>
            <PopoverTrigger>
                <Box pos={'relative'}>
                    <IconButton
                        aria-label="color mode"
                        icon={<HiBell />}
                        variant={'ghost'}
                        onClick={() => {
                            setOpen((c) => !c);
                        }}
                    />
                    {count > 0 && (
                        <Center
                            bg={primary}
                            position={'absolute'}
                            w={2}
                            h={2}
                            rounded={'full'}
                            top={2}
                            right={2}
                        ></Center>
                    )}
                </Box>
            </PopoverTrigger>
            {openedItem ? (
                <NotificationDetailContent
                    item={openedItem}
                    onGoBack={() => setOpenedItem(null)}
                    onClose={() => setOpen(false)}
                />
            ) : (
                <NotificationListContent
                    onSelect={(item) => setOpenedItem(item)}
                    onClose={() => setOpen(false)}
                    open={open}
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                />
            )}
        </Popover>
    );
};

export default NotificationBtn;
