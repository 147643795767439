import {
    Box,
    Button,
    Divider,
    Flex,
    HStack,
    Hide,
    Stack,
    Tab,
    Text,
    useToken,
} from '@chakra-ui/react';
import CardContainer from 'components/CardContainer';
import CustomTab from 'components/CustomTab';
import Page from 'components/Page';
import {useAdminProfile, useProfile} from 'hooks/datahook/auth';
// import { useClientHDWallet, useClientPlatformHDWalletStatus } from 'hooks/datahook/hdwallet';
import {useAuth} from 'hooks/useAuth';
import {useColorModeValueKey} from 'hooks/useColors';
import {find, get, last, split, trim} from 'lodash';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet, useMatches, useNavigate} from 'react-router-dom';
import {adminPaths, paths} from 'routes/path';
import tinycolor from 'tinycolor2';

const ProfileLayout = () => {
    const {t} = useTranslation('app');
    const {isUser} = useAuth();
    const {
        data: profileData,
        isCompany,
        kycVerified,
    } = (isUser ? useProfile : useAdminProfile)();
    const matches = useMatches();
    const matchPath = get(last(matches), 'pathname');
    const mainPath = last(split(trim(matchPath, '/'), '/'));
    const navigate = useNavigate();
    const primary = useColorModeValueKey('primary');
    const primaryColorValue = useToken('colors', primary);
    const naviItemHoverColor = tinycolor(primaryColorValue)
        .setAlpha(0.1)
        .toRgbString();
    const naviItemActivedColor = tinycolor(primaryColorValue)
        .setAlpha(0.5)
        .toRgbString();

    // const {data: status} =
    //     useClientPlatformHDWalletStatus();

    const tabs = [
        {
            label: t('profile'),
            value: 'profile',
            path: isUser ? paths.profile : adminPaths.profile,
        },
        ...(isUser
            ? [
                  {
                      label: t(isCompany ? 'kyb' : 'kyc'),
                      value: 'kyc',
                      path: paths.profileKycReview,
                  },
              ]
            : []),
        // {
        //     label: t('notification'),
        //     value: 'notification',
        //     path: isUser
        //         ? paths.notificationSetting
        //         : adminPaths.notificationSetting,
        // },
        {
            label: t('preference'),
            value: 'preference',
            path: isUser ? paths.preference : adminPaths.preference,
        },
        {
            label: t('security'),
            value: 'security',
            path: isUser ? paths.security : adminPaths.security,
        },
        // ...(isUser && status.enable && kycVerified
        //     ? [
        //           {
        //               label: t('cryptoWallet'),
        //               value: 'crypto-wallet',
        //               path: paths.profileCryptoWallet,
        //           },
        //       ]
        //     : []),
    ];
    return (
        <Page title={t('profile')}>
            <Stack minH={'400px'}>
                <CardContainer overflow={'visiable'}>
                    <Stack
                        direction={{base: 'column', md: 'row'}}
                        spacing={4}
                        alignItems={'flex-start'}
                    >
                        <Box>
                            <Hide below="md">
                                <Stack width={'150px'}>
                                    {tabs.map((item) => (
                                        <Button
                                            variant={'ghost'}
                                            alignItems={'center'}
                                            justifyContent={'flex-start'}
                                            key={item.value}
                                            isActive={mainPath === item.value}
                                            fontSize={'sm'}
                                            size="sm"
                                            fontWeight={'400'}
                                            _active={{
                                                bg: naviItemActivedColor,
                                                // color: 'white',
                                            }}
                                            _hover={{
                                                bg: naviItemHoverColor,
                                            }}
                                            onClick={() => {
                                                navigate(item.path);
                                            }}
                                        >
                                            {item.label}
                                        </Button>
                                    ))}
                                </Stack>
                            </Hide>
                            <Hide above="md">
                                <CustomTab
                                    tabOnly
                                    tabs={tabs}
                                    value={mainPath}
                                    onChange={(v) => {
                                        const found = find(tabs, {value: v});
                                        navigate(found.path);
                                    }}
                                />
                            </Hide>
                        </Box>
                        <Box width={'100%'}>
                            <Outlet />
                        </Box>
                    </Stack>
                </CardContainer>
            </Stack>
        </Page>
    );
};

export default ProfileLayout;
