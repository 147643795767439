import {
    useSWRPaginationTemplate,
    useSWRTemplate,
} from 'hooks/dataHooksTemplate';
import {useAuth} from 'hooks/useAuth';
import {get} from 'lodash';
import {cleanObjectNilValue} from 'utils/clean';
import {api} from './common';
import {processMessage} from 'data/message';

export const useMessage = (options = {}) => {
    const {request = true, type} = options;
    const {isUser, id} = useAuth();
    const {mutate: refreshMessgeCount} = useUnreadMessageCount();
    const swr = useSWRPaginationTemplate({
        key: id && request ? ['message', id, type] : null,
        request: async (_key, _pageIndex, _pageSize) => {
            const [_,_id,_type] = _key;
            const {result} = await api().get(
                ['message'],
                cleanObjectNilValue({
                    pageSize: _pageSize,
                    pageIndex: _pageIndex - 1,
                    type: _type,
                }),
            );

            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map(processMessage),
                },
            };
        },
        defaultValue: [],
        // pageSize:30,
    });
    return {
        ...swr,
        markReadFlag_: async (id) => {
            await markReadFlag_(id);
            swr.mutate();
            refreshMessgeCount();
        },
    };
};

export const markReadFlag_ = async (id) => {
    const {result} = await api().put(['message', id]);
};

export const useUnreadMessageCount = () => {
    const {id, isUser} = useAuth();
    return useSWRTemplate({
        key: isUser ? ['unRead-message-count', id] : null,
        request: async (_key) => {
            const [_] = _key;
            const {result} = await api().get(['unRead','message']);
            return result;
        },
        defaultValue: 0,
    });
};
