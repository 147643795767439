import {
    Box,
    Icon,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Portal,
    Tooltip,
    Spinner,
    Divider,
} from '@chakra-ui/react';
import {isFunction} from 'lodash';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {HiBars3} from 'react-icons/hi2';

const TableMenu = (props) => {
    const {menu, customHeader, rowData, isLoading = false} = props;
    const {t} = useTranslation('app');
    const header = customHeader;
    return (
        <Box onClick={(e) => e.stopPropagation()}>
            <Menu isLazy>
                <Tooltip label={t('actions')}>
                    {isLoading ? (
                        <Spinner size="sm" ml={3} />
                    ) : (
                        <MenuButton
                            height={'auto'}
                            p={0}
                            as={IconButton}
                            icon={<Icon as={HiBars3} />}
                            variant={'unstyled'}
                        />
                    )}
                </Tooltip>
                <Portal>
                    <MenuList>
                        {header && (
                            <>
                                <MenuItem>{header}</MenuItem>
                                <Divider />
                            </>
                        )}
                        {menu.map((item, index) => (
                            <Tooltip
                                label={item?.tooltip}
                                key={item.value || index}
                            >
                                <MenuItem
                                    key={item.value || index}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (item.onClick) {
                                            item.onClick(rowData);
                                        }
                                    }}
                                    icon={
                                        item.icon && (
                                            <Icon as={item.icon} boxSize={5} />
                                        )
                                    }
                                    isDisabled={item.disabled}
                                >
                                    {item.label}
                                </MenuItem>
                            </Tooltip>
                        ))}
                    </MenuList>
                </Portal>
            </Menu>
        </Box>
    );
};

export default TableMenu;
