import React from 'react';
import * as CountryIcons from 'country-flag-icons/string/1x1';
import * as CountryIcons32 from 'country-flag-icons/string/3x2';
import {hasFlag} from 'country-flag-icons';
import { TextIcon } from './TextIcon';
import { Box } from '@chakra-ui/react';

const CountryIcon = (props) => {
    const {country,size=24,variant='3x',rounded='sm',...other} = props

    const check = hasFlag(country) || false;
    const height = variant === '1x1' ? size : (size / 3) * 2;
    const width = size;
    if (!check && country) {
        return <TextIcon name={country} size={size} height={height} width={width} />;
    }
    if (!check && !country) {
        return null;
    }
    const xml = (variant === '1x1' ? CountryIcons : CountryIcons32)[country];
    return (
        <Box rounded={rounded} overflow="hidden">
            <img
                src={`data:image/svg+xml;utf8,${encodeURIComponent(xml)}`}
                width={width}
                height={height}
                {...other}
            />
        </Box>
    );
};

export default CountryIcon;
