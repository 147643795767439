import {t} from 'i18next';
import {get} from 'lodash';
import {
    RateFormator,
    currencyFormatorWithDecimal,
    formatDate,
} from 'utils/formator';

export const depositReceiptList = ({i18n, data, isClientSide}) => {
    const isDirectDeposit = get(data, 'actionType') === 'DirectDeposit';
    return [
        ...(!get(data, 'mtTicket')
            ? []
            : [
                  {
                      label: i18n('ticket'),
                      value: get(data, 'mtTicket'),
                  },
              ]),
        ...(!get(data, 'ticket')
            ? []
            : [
                  {
                      label: i18n('crmTicket'),
                      value: get(data, 'ticket'),
                  },
              ]),
        {
            label: i18n('transactionType'),
            value: i18n(get(data, 'type')),
        },
        {
            label: i18n('date'),
            value: get(data, 'createdAt')
                ? formatDate(get(data, 'createdAt'))
                : null,
        },
        ...(isClientSide
            ? []
            : [
                  {
                      label: i18n('client'),
                      value: `${get(data, 'clientDetail.name')} ${
                          get(data, ':user.reference') || ''
                      }`,
                      key: 'client',
                  },
              ]),
        {
            label: i18n('method'),
            value:
                !isClientSide && get(data, 'subMethod') === 'VA'
                    ? 'CC Subaccount'
                    : t(get(data, 'method')) || t(get(data, 'paymentGateway')),
        },
        // {
        //     label: t('fromAccount'),
        //     value: get(data, 'fromAccount'),
        // },
        // {
        //     label: t('fromAmount'),
        //     value: get(data, 'fromAmount') ? currencyFormatorWithDecimal(
        //         Math.abs(get(data, 'fromAmount')),
        //         get(data, 'fromCurrency') || get(data, 'fromCryptoCurrency'),
        //         get(data, 'fromCryptoCurrency') ? 8 : 2,true) : '',
        // },
        ...(get(data, 'paymentGateway') === 'Worldpay'?[{
            label: t('fromAmount'),
            value: get(data, 'from_amount')
        }]: []),
        {
            label: t('toAccount'),
            value: get(data, 'toAccount'),
        },
        {
            label: t('toAmount'),
            value: get(data, 'to_amount'),
        },
        {
            label: t('rate'),
            // value: currencyFormatorWithDecimal(
            //     get(data, 'rate') || 0,
            //     get(data, 'fromCurrency'),
            //     true,
            // ),
            value: `${RateFormator(get(data, 'rate')) || 1} : 1`,
        },
        {
            label: t('fee'),
            value: currencyFormatorWithDecimal(
                get(data, 'fee') || 0,
                get(data, 'fromCurrency') || get(data, 'fromCryptoCurrency'),
                get(data, 'fromCryptoCurrency') ? 8 : 2,
                true,
            ),
        },
        {
            label: i18n('status'),
            value: i18n(get(data, 'status')),
        },
        {
            label: i18n('comment'),
            value: get(data, 'comment'),
        },
        ...(get(data, 'attachment') && get(data, 'ticket')
            ? [
                  {
                      label: i18n('attachment'),
                      value: get(data, 'attachment'),
                      file: true,
                  },
              ]
            : []),
    ];
};
