import * as React from 'react';
import {get} from 'lodash';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {useState, useEffect} from 'react';

const ImageViewer = (props) => {
    const {images, open, onClose} = props;
    const [photoIndex, setPhotoIndex] = useState(0);
    const [key, setKey] = useState(false);
    useEffect(() => {
        if (open) {
            setTimeout(() => setKey(key + 1));
        }
    }, [open]);
    return (
        open && (
            <Lightbox
                key={key}
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={
                    images[(photoIndex + images.length - 1) % images.length]
                }
                onCloseRequest={onClose}
                onMovePrevRequest={() =>
                    setPhotoIndex((c) => c + images.length - 1) % images.length
                }
                onMoveNextRequest={() =>
                    setPhotoIndex((c) => (c + 1) % images.length)
                }
            />
        )
    );
};

export default ImageViewer;
