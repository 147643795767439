import {get} from 'lodash';
import { processUserProfile } from './userProfile';

export const getAccountStructure = ({
    routingCodeType,
    paymentType,
    accountNumber,
    routingCode,
    accountNumberType,
    currency,
}) => {
    switch (routingCodeType) {
        case 'bic_swift':
        case 'bicSwift':
            return {
                method:
                    currency === 'EUR' && paymentType === 'local'
                        ? 'sepa'
                        : 'swift',
                // accountNumberType === 'iban' && paymentType === 'local'
                //     ? 'iban'
                //     : accountNumberType === 'iban'
                //     ? 'iban'
                //     : paymentType === 'local'
                //     ? 'swift'
                //     : 'swift',
                ...(accountNumberType === 'iban'
                    ? {
                          iban: accountNumber,
                      }
                    : {
                          accountNumber: accountNumber,
                      }),
                swift: routingCode,
            };
        case 'bsb':
        case 'bsb_':
            return {
                method: 'bsb',
                accountNumber: accountNumber,
                bsb: routingCode,
            };
        case 'aba':
            return {
                method: 'aba',
                accountNumber: accountNumber,
                aba: routingCode,
            };
        case 'sortCode':
        case 'sort_code':
            return {
                method: 'ukFasterPayment',
                accountNumber: accountNumber,
                sortCode: routingCode,
            };
        case 'wire':
        case 'wire_routing_number':
            return {
                method: 'fedwire',
                accountNumber: accountNumber,
                fedwireRoutingNumber: routingCode,
            };
        case 'ach':
        case 'ach_routing_number':
            return {
                method: 'ach',
                accountNumber: accountNumber,
                achRoutingNumber: routingCode,
            };
        case 'routingCode':
        case 'routing_code':
            return {
                method: currency === 'CAD' ? 'eft' : 'routingCode',
                accountNumber: accountNumber,
                routingCode: routingCode,
            };
        case 'payNow':
            return {
                method: 'payNow',
                accountNumber: accountNumber,
                routingCode: routingCode,
            };
        default: {
        }
    }
};

export const processCCBankInfo = (bankInfo) => {
    return {
        id: get(bankInfo, 'id'),
        currency: get(bankInfo, 'currency'),
        accountHolderName: get(bankInfo, 'accountHolderName'),
        bankCountry: get(bankInfo, 'bankCountry'),
        bankAddress: get(bankInfo, 'bankAddress'),
        bankName: get(bankInfo, 'bankName'),
        ...getAccountStructure({
            routingCodeType: get(bankInfo, 'routingCodeType'),
            paymentType:
                get(bankInfo, 'paymentType') === 'regular'
                    ? 'local'
                    : 'international',
            accountNumber: get(bankInfo, 'accountNumber'),
            routingCode: get(bankInfo, 'routingCode'),
            accountNumberType: get(bankInfo, 'accountNumberType'),
            currency: get(bankInfo, 'currency'),
        }),
        rawData: bankInfo,
    };
};

export const methodKeyLineMap = {
    bsb: [
        'currency',
        'accountHolderName',
        'bsb',
        'accountNumber',
        'bankName',
        'bankAddress',
        'bankCountry',
    ],
    swift: [
        'currency',
        'accountHolderName',
        'accountNumber',
        'swift',
        'iban',
        'bankName',
        'bankAddress',
        'bankCountry',
    ],
    sepa: [
        'currency',
        'accountHolderName',
        'accountNumber',
        'swift',
        'iban',
        'bankName',
        'bankAddress',
        'bankCountry',
    ],
    iban: [
        'currency',
        'accountHolderName',
        'iban',
        'swift',
        'bankName',
        'bankAddress',
        'bankCountry',
    ],
    ibanLocal: [
        'currency',
        'accountHolderName',
        'iban',
        'swift',
        'bankName',
        'bankAddress',
        'bankCountry',
    ],
    swiftLocal: [
        'currency',
        'accountHolderName',
        'accountNumber',
        'swift',
        'bankName',
        'bankAddress',
        'bankCountry',
    ],
    ach: [
        'currency',
        'accountHolderName',
        'achRoutingNumber',
        'accountNumber',
        'bankName',
        'bankAddress',
        'bankCountry',
    ],
    fedwire: [
        'currency',
        'accountHolderName',
        'fedwireRoutingNumber',
        'accountNumber',
        'bankName',
        'bankAddress',
        'bankCountry',
    ],
    ukFasterPayment: [
        'currency',
        'accountHolderName',
        'sortCode',
        'accountNumber',
        'bankName',
        'bankAddress',
        'bankCountry',
    ],
    routingCode: [
        'currency',
        'accountHolderName',
        'routingCode',
        'accountNumber',
        'bankName',
        'bankAddress',
        'bankCountry',
    ],
    eft: [
        'currency',
        'accountHolderName',
        'routingCode',
        'accountNumber',
        'bankName',
        'bankAddress',
        'bankCountry',
    ],
    payNow: [
        'currency',
        'accountHolderName',
        'routingCode',
        'accountNumber',
        'bankName',
        'bankAddress',
        'bankCountry',
    ],
};


export const paymentMethodDesc = {
    GBP: {
        ukFasterPayment: {
            beneficiaryRouteType: 'sortCode',
            name: 'UK Faster',
            desc: 'GBPUKFasterPaymentDesc',
        },
        swift: {
            beneficiaryRouteType: 'bicSwift',
            name: 'SWIFT',
            desc: 'GBPUKSwiftDesc',
        },
    },
    USD: {
        fedwire: {
            beneficiaryRouteType: null,
            name: 'Fedwire',
            desc: 'USDFedwireDesc',
        },
        ach: {
            beneficiaryRouteType: null,
            name: 'ACH',
            desc: 'USDAchDesc',
        },
        swift: {
            beneficiaryRouteType: 'bicSwift',
            name: 'SWIFT',
            desc: 'USDSwiftDesc',
        },
        aba: {
            beneficiaryRouteType: 'aba',
            name: 'ABA',
            desc: 'USDAbaDesc',
        },
    },
    EUR: {
        sepa: {
            beneficiaryRouteType: 'ibanOnly',
            name: 'SEPA',
            desc: 'EURSepaDesc',
        },
        swift: {
            beneficiaryRouteType: 'bicSwift',
            name: 'SWIFT',
            desc: 'EURSwiftDesc',
        },
    },
    CAD: {
        eft: {
            beneficiaryRouteType: 'bankCode',
            name: 'EFT',
            desc: 'CADEftDesc',
        },
        swift: {
            beneficiaryRouteType: 'bicSwift',
            name: 'SWIFT',
            desc: 'CADSwiftDesc',
        },
    },
    AUD: {
        bsb: {
            beneficiaryRouteType: 'bsbCode',
            name: 'BSB',
            desc: 'AUDBsbDesc',
        },
        swift: {
            beneficiaryRouteType: 'bicSwift',
            name: 'SWIFT',
            desc: 'AUDSwiftDesc',
        },
    },
    SGD: {
        payNow: {
            beneficiaryRouteType: 'payNow',
            name: 'payNow',
            desc: 'SGDPayNowDesc',
        },
        swift: {
            beneficiaryRouteType: 'bicSwift',
            name: 'SWIFT',
            desc: 'AUDSwiftDesc',
        },
    },
    'AUD.L': {
        bsb: {
            beneficiaryRouteType: 'bsbCode',
            name: 'BSB',
            desc: 'For local Australia payments.',
        },
    },
    CNY: {
        cnaps: {
            beneficiaryRouteType: 'cnaps',
            name: 'CNSAPS',
            desc: 'CNYCnapsDesc',
        },
    },
    Other: {
        swift: {
            beneficiaryRouteType: 'bicSwift',
            name: 'SWIFT',
            desc: 'USDSwiftDesc',
        },
    },
};

export const processCCAccount = (rawData)=>{
    return {
        id: get(rawData, 'id'),
        rawData: rawData,
        reference: get(rawData, 'reference'),
        currency: get(rawData, 'currency'),
        createdAt: get(rawData, 'createdAt'),
        refId: get(rawData, 'refId'),
        status: get(rawData, 'status'),
        userDetail: processUserProfile(get(rawData, ':user')),
        user: get(rawData, 'user'),
        bankInfos: get(rawData, ':bankInfos', []).map(processCCBankInfo),
    };
}