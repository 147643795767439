import {Box, Stack} from '@chakra-ui/react';
import CustomTab from 'components/CustomTab';
import {find, get, last, split, trim} from 'lodash';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet, useMatches, useNavigate} from 'react-router-dom';
import {paths} from 'routes/path';

const NewsLayout = () => {
    const {t} = useTranslation('app');
    const matches = useMatches();
    const matchPath = get(last(matches), 'pathname');
    const navigate = useNavigate();

    const tabs = [
        {
            label: t('news'),
            value: 'news',
            path: paths.news,
        },
        {
            label: t('announcements'),
            value: 'announcements',
            path: paths.announcements,
        },
    ];
    const value =
        find(tabs, (o) => o.path === matchPath)?.value || tabs[0]?.value;

    return (
        <Stack spacing={4} width='100%'>
            <CustomTab
                tabs={tabs}
                tabOnly
                value={value}
                onChange={(v) => {
                    navigate(find(tabs, (o) => o.value === v)?.path);
                }}
            />
            <Box width={'100%'}>
                <Outlet />
            </Box>
        </Stack>
    );
};

export default NewsLayout;
