import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet-async';
import {forwardRef} from 'react';
import {usePlatformBasicInfo} from 'hooks/datahook/auth';

// ----------------------------------------------------------------------

const Page = forwardRef(({children, title = '', meta, ico, ...other}, ref) => {
    const {data: platformBasicInfo, isLoading} = usePlatformBasicInfo();

    return (
        <>
            <Helmet>
                <title>
                    {' '}
                    {`${platformBasicInfo?.companyShortName} | ${title}`}
                </title>
                <link
                    id="favicon"
                    rel="icon"
                    type="image/x-icon"
                    href={`/${ico}`}
                />
                {meta}
            </Helmet>

            <div ref={ref} {...other}>
                {children}
            </div>
        </>
    );
});

Page.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    meta: PropTypes.node,
};

export default Page;
