import {popoverAnatomy as parts} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';
import { colorByMode } from 'theme/themeHelper';
import {cssVar} from '@chakra-ui/theme-tools';
const $arrowBg = cssVar('popper-arrow-bg');
const $arrowShadowColor = cssVar('popper-arrow-shadow-color');
const {definePartsStyle, defineMultiStyleConfig} =
    createMultiStyleConfigHelpers(parts.keys);
const baseStyle = definePartsStyle((props) => ({
    // define the part you're going to style
    body: {
        // bg: colorByMode('brighterBg', props.colorMode), // change the background of the body to gray.800
    },
    content: {
        bg: colorByMode('brighterBg', props.colorMode),
        padding: 0, // change the padding of the content
    },
    arrow: {
        [$arrowBg.variable]: colorByMode('brighterBg', props.colorMode),
        [$arrowShadowColor.variable]: `colors.gray.200`,
    },
}));
export const popoverTheme = defineMultiStyleConfig({baseStyle});
