import {
    Center,
    HStack,
    Spinner,
    Stack,
    Text,
    useColorMode,
} from '@chakra-ui/react';
import {useColorModeValueKey} from 'hooks/useColors';
import React, { forwardRef } from 'react';

const CardContainer = forwardRef((props,ref) => {
    const {children, title, actions, noPaddingContent, isLoading, h, overflow='hidden',spacing = 0,...other} = props;
    return (
        <Stack
            flex={1}
            rounded={'md'}
            bg={useColorModeValueKey('brighterBg')}
            transition="background-color 200ms linear"
            p={noPaddingContent ? 0 : {base: 3, md: 4}}
            px={noPaddingContent ? 0 : {base: 3, md: 4}}
            boxShadow={'sm'}
            spacing={spacing}
            pos={'relative'}
            overflow={overflow}
            h={h}
            ref={ref}
            {...other}
        >
            {isLoading ? (
                <Center>
                    <Spinner />
                </Center>
            ) : (
                <>
                    {(title || actions) && (
                        <HStack
                            alignItems={'flex-start'}
                            justifyContent={'space-between'}
                            p={noPaddingContent ? {base: 3, md: 4} : 0}
                            px={noPaddingContent ? {base: 3, md: 4} : 0}
                        >
                            <Text
                                flex={1}
                                fontWeight={'500'}
                                fontSize={'lg'}
                                noOfLines={2}
                            >
                                {title}
                            </Text>
                            <Stack>{actions}</Stack>
                        </HStack>
                    )}
                    {children}
                </>
            )}
        </Stack>
    );
})

export default CardContainer;

