import { adminClient } from "helper/httpClient";
import { useSWRTemplate } from "hooks/dataHooksTemplate";

export const usePspCallbackLog = (options = {}) => {
    const {transactionRef} = options;
    const swr = useSWRTemplate({
        key: transactionRef ? ['admin/pspCallbackLog', transactionRef] : null,
        request: async ([_, _transactionRef]) => {
            const {result} = await adminClient.get([
                'psp-callback-log',
                _transactionRef,
            ]);
            return result.map(item=>JSON.parse(item.body||'{}'))
        },
        defaultValue: [],
    });
    return swr;
};

export const useUserInfoLog = (options = {}) => {
    const {transactionId} = options;
    const swr = useSWRTemplate({
        key: transactionId ? ['admin/pspUserInfoLog', transactionId] : null,
        request: async ([_, _transactionId]) => {
            const {result} = await adminClient.get([
                'psp-user-info-log',
                transactionId,
            ]);
            if(result){
                return {
                    ...result,
                    body: JSON.parse(result?.body || '{}'),
                };
            }else{
                return {
                    body:null
                }
            }
        },
        defaultValue: {},
    });
    return swr;
};


export const togglePspUserInfoLogAdminVerify_ = async (transactionId, adminVerify) => {
    const {result} = await adminClient.put(
        ['psp-user-info-log', transactionId],
        {
            adminVerify,
        },
    );
    return result;
};