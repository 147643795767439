import PropTypes from 'prop-types';
import {useState, useEffect} from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';

import {useAuth} from 'hooks/useAuth';
import {useAuthStore} from 'stores/authStore';
import {adminPaths, authPaths, paths} from 'routes/path';
import {useAdminPermissionSettings} from 'hooks/datahook/adminPermission';
import {Center, Spinner} from '@chakra-ui/react';
import {get, last, split, trim} from 'lodash';
import {useNavigate, useMatches} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Stack, Box} from '@chakra-ui/react';
import CustomTab from 'components/CustomTab';
import {find} from 'lodash';
// ----------------------------------------------------------------------

export default function ConfidencialLayout() {
    const {t} = useTranslation('app');
    const matches = useMatches();
    const matchPath = get(last(matches), 'pathname');
    const mainPath = last(split(trim(matchPath, '/'), '/'));
    const navigate = useNavigate();
    const tabs = [
        {
            label: t('pammAccountSummary'),
            value: 'pammAccountSummary',
            path: adminPaths.pammAccountSummary,
        },
        {
            label: t('pammAccountList'),
            value: 'pammAccountList',
            path: adminPaths.pammAccountList,
        },
    ];
    console.log(matchPath)
    const value =
        find(tabs, (o) => o.path === matchPath)?.value || tabs[0]?.value;
    const userState = useAuthStore();
    // if (!get(userState, 'user.canViewPamm')) {
    //     return <Navigate to={adminPaths[404]} replace />;
    // }
    return (
        <Stack spacing={4}>
            <CustomTab
                tabs={tabs}
                tabOnly
                value={value}
                onChange={(v) => {
                    navigate(find(tabs, (o) => o.value === v)?.path);
                }}
            />
            <Box width={'100%'}>
                <Outlet />
            </Box>
        </Stack>
    );
}
