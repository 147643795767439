import {
    Box,
    Button,
    Divider,
    Flex,
    HStack,
    Hide,
    Stack,
    Tab,
    Text,
    useToken,
} from '@chakra-ui/react';
import CustomTab from 'components/CustomTab';
import Page from 'components/Page';
import { useAdminPermissionSettings } from 'hooks/datahook/adminPermission';
import { useAuth } from 'hooks/useAuth';
import {find, get, last, split, trim} from 'lodash';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet, useMatches, useNavigate} from 'react-router-dom';
import {adminPaths, paths} from 'routes/path';

const PaymentGatewayLayout = () => {
    const {t} = useTranslation('app');
    const matches = useMatches();
    const matchPath = get(last(matches), 'pathname');
    const mainPath = last(split(trim(matchPath, '/'), '/'));
    const navigate = useNavigate();
    const {roles} = useAuth();
    const {getPermission} = useAdminPermissionSettings({
        roles,
    });
    const tabs = [
        {
            label: t('paymentGateway'),
            value: 'paymentGateway',
            path: adminPaths.paymentGateway,
            hasPermission: getPermission(
                'platformSettings.paymentChannels.paymentGateway',
            ),
        },
        {
            label: t('blockchain'),
            value: 'blockchain',
            path: adminPaths.blockchainGateway,
            hasPermission: getPermission(
                'platformSettings.paymentChannels.blockchainGateway',
            ),
        },
        {
            label: t('commonGateway'),
            value: 'commonGateway',
            path: adminPaths.commonGateway,
            hasPermission: getPermission(
                'platformSettings.paymentChannels.commonGateway',
            ),
        },
    ].filter((item) => item.hasPermission !== false);
    const value =
        find(tabs, (o) => o.path === matchPath)?.value || tabs[0]?.value;

    return (
        <Stack spacing={4}>
            <CustomTab
                tabs={tabs}
                tabOnly
                value={value}
                onChange={(v) => {
                    navigate(find(tabs, (o) => o.value === v)?.path);
                }}
            />
            <Box width={'100%'}>
                <Outlet />
            </Box>
        </Stack>
    );
};

export default PaymentGatewayLayout;
