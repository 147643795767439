import {useColorModeValueKey} from 'hooks/useColors';
import {get, isNil, reduce, set} from 'lodash';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInterval} from 'react-use';
import useCustomToast from 'hooks/useCustomToast';
import {getErrorMessage} from 'utils/error';
import {useUserPreference} from 'hooks/datahook/settings';
import BtnGroup from 'components/BtnGroup';
import {useProfile} from 'hooks/datahook/auth';
import {
    Box,
    Button,
    Center,
    Divider,
    FormControl,
    FormLabel,
    HStack,
    IconButton,
    Input,
    Link,
    PinInput,
    PinInputField,
    Stack,
    Text,
} from '@chakra-ui/react';
import {sendVerifyCode_, verifyEmailCode_} from 'hooks/datahook/common';
import CustomDialog from '../CustomDialog';

const TwoFactorConfirmDialog = (props) => {
    const {
        open,
        onClose,
        customReview,
        customReviewTitle,
        onFinalSumit,
        needTwoFact = false,
        limitMethod,
        nextDisabled = false,
        customNext = null,
        allowOnlyEmail = false,
        mandatoryMobile2FA = false,
    } = props;
    const {
        data: settings,
        twoFactorEnabled: userEneableTwoFact,
        isLoading: isLoadingSetting,
    } = useUserPreference();
    const allowOnlyEmail_ = (allowOnlyEmail || get(settings, 'disableMobile2FA')) && !mandatoryMobile2FA
    const twoFactorEnabled = needTwoFact && userEneableTwoFact;
    const {data: profile} = useProfile();
    const [step, setStep] = useState(1);
    const {t} = useTranslation('app');
    const [sessionExpired, setSessionExpired] = useState(false);
    const [submiting, setSubmiting] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [nextLoading, setNextLoading] = useState(false);
    const [code, setCode] = useState('');
    const [resendCounter, setResendCounter] = useState(0);
    const verifyRef = useRef(null);
    const toast = useCustomToast();
    const [firstVerifyMethod, setFirstVerifyMethod] = useState(null)
    const [twoFactorMethod, setTwoFactorMethod] = useState(() =>
        get(settings, 'enableEmailFact')
            ? 'email'
            : get(settings, 'enableOtpFact')
            ? 'otp'
            : get(settings, 'enableSmsFact')
            ? 'sms'
            : null,
    );
    useEffect(() => {
        if (!open) {
            setStep(1);
            // setSessionExpired(false);
            setCode('');
            setTwoFactorMethod(
                get(settings, 'enableEmailFact')
                    ? 'email'
                    : get(settings, 'enableOtpFact')
                    ? 'otp'
                    : get(settings, 'enableSmsFact')
                    ? 'sms'
                    : null,
            );
        }
    }, [open, settings]);
    // useInterval(
    //     () => {
    //         if (countDown - 1 == 0) {
    //             setSessionExpired(true);
    //         }
    //         setCountDown((c) => c - 1);
    //     },
    //     countDown === 0 ? null : 1000,
    // );

    useInterval(
        () => {
            setResendCounter((c) => c - 1);
        },
        resendCounter === 0 ? null : 1000,
    );
    const inCoolDown = resendCounter > 0;

    const onExpired = (error) => {
        if (error === 'Verification information has expired') {
            setStep(1);
            setResendCounter(0);
            setTwoFactorMethod(null);
            verifyRef.current = null;
        }
    };
    const onSendVerify = async (twoFactorMethod, currentStep, method) => {
        const steps = currentStep ? currentStep : step;
        const firstMethod = method ? method : firstVerifyMethod
        try {
            setIsSending(true);
            const {issueId} = await sendVerifyCode_({
                method: twoFactorMethod,
                issueId: get(settings, 'enableEmailFact') 
                    ? verifyRef?.current && steps !== 1 && firstMethod !== 'email'
                        ? verifyRef?.current
                        : null
                    : null,
            });
            verifyRef.current = issueId;
            setResendCounter(60);
        } catch (error) {
            onExpired(getErrorMessage(error));
            toast.show({
                title: t('sendFailed'),
                status: 'error',
                description: getErrorMessage(error),
            });
            console.log(error);
        } finally {
            setIsSending(false);
        }
    };

    const twoFactorMethodBtns = [
        {
            label: t('otp'),
            value: 'otp',
            enabled: get(settings, 'enableOtpFact'),
        },
        // {
        //     label: t('email'),
        //     value: 'email',
        //     enabled: get(settings, 'enableEmailFact'),
        // },
        {
            label: t('sms'),
            value: 'sms',
            enabled: get(settings, 'enableSmsFact'),
        },
    ]
        .filter((item) => item.enabled)
        .filter((item) =>
            limitMethod
                ? (typeof limitMethod === 'string'
                      ? [limitMethod]
                      : limitMethod
                  ).includes(item.value)
                : true,
        );

    const needPick =
        reduce(
            twoFactorMethodBtns,
            (acc, item) => acc + (item.enabled ? 1 : 0),
            0,
        ) > 1;

    const emailFactor = (
        <Stack space={2}>
            {get(settings, 'enableEmailFact') && userEneableTwoFact ? (
                <Stack spacing={3}>
                    <Stack spacing={0}>
                        <Text textAlign={'center'} fontSize={'sm'}>
                            {t('verificaitonCodeWillBeSentToYourEmail')}
                        </Text>
                        <Text textAlign={'center'} fontSize={'md'}>
                            {get(profile, 'email')}
                        </Text>
                    </Stack>
                    <FormControl>
                        <Center>
                            <HStack>
                                <PinInput
                                    value={code}
                                    onChange={setCode}
                                    variant={'outline'}
                                >
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                </PinInput>
                            </HStack>
                        </Center>
                    </FormControl>
                    <HStack justifyContent={'center'}>
                        {inCoolDown ? (
                            <Text
                                fontSize={'sm'}
                                color={useColorModeValueKey('gray')}
                            >
                                {t('youCanResendIn', {seconds: resendCounter})}
                            </Text>
                        ) : (
                            <Text fontSize={'sm'}>
                                <Link
                                    color={useColorModeValueKey('primary')}
                                    onClick={() => onSendVerify('email')}
                                    isDisabled={isSending}
                                >
                                    {t('Click here')}
                                </Link>{' '}
                                {t('to resend code')}
                            </Text>
                        )}
                    </HStack>
                </Stack>
            ) : (
                <Stack>
                    <Text>{t('mobileTwoFactorIsMandatoryDesc')}</Text>
                </Stack>
            )}
        </Stack>
    );
    const twoFactor = (
        <Stack spacing={2}>
            {needPick && (
                <BtnGroup
                    btns={twoFactorMethodBtns}
                    selected={twoFactorMethod}
                    onSelect={(v) => {
                        // if (v !== 'otp') {
                            // }
                        setTwoFactorMethod(v);
                        onSendVerify(v, step, v);
                    }}
                />
            )}
            {/* {twoFactorMethod === 'email' && (
                <Stack spacing={3}>
                    <Stack spacing={0}>
                        <Text textAlign={'center'} fontSize={'sm'}>
                            {t('verificaitonCodeWillBeSentToYourEmail')}
                        </Text>
                        <Text textAlign={'center'} fontSize={'md'}>
                            {get(profile, 'email')}
                        </Text>
                    </Stack>
                    <FormControl>
                        <Center>
                            <HStack>
                                <PinInput
                                    value={code}
                                    onChange={setCode}
                                    variant={'outline'}
                                >
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                    <PinInputField />
                                </PinInput>
                            </HStack>
                        </Center>
                    </FormControl>
                    <HStack justifyContent={'center'}>
                        {inCoolDown ? (
                            <Text
                                fontSize={'sm'}
                                color={useColorModeValueKey('gray')}
                            >
                                {t('youCanResendIn', {seconds: resendCounter})}
                            </Text>
                        ) : (
                            <Text fontSize={'sm'}>
                                <Link
                                    color={useColorModeValueKey('primary')}
                                    onClick={() => onSendVerify('email')}
                                    isDisabled={isSending}
                                >
                                    {t('Click here')}
                                </Link>{' '}
                                {t('to resend code')}
                            </Text>
                        )}
                    </HStack>
                </Stack>
            )} */}
            {!(
                get(settings, 'enableOtpFact') || get(settings, 'enableSmsFact')
            ) ? (
                <Stack>
                    <Text>{t('mobileTwoFactorIsMandatoryDesc')}</Text>
                </Stack>
            ) : (
                <>
                    {twoFactorMethod === 'sms' && (
                        <Stack spacing={3}>
                            <Stack spacing={3}>
                                <Stack spacing={0}>
                                    <Text textAlign={'center'} fontSize={'sm'}>
                                        {t(
                                            'verificaitonCodeWillBeSentToYourMobile',
                                        )}
                                    </Text>
                                    <Text textAlign={'center'} fontSize={'md'}>
                                        {get(profile, 'mobile')}
                                    </Text>
                                </Stack>
                                <FormControl>
                                    <Center>
                                        <HStack>
                                            <PinInput
                                                value={code}
                                                onChange={setCode}
                                                variant={'outline'}
                                            >
                                                <PinInputField />
                                                <PinInputField />
                                                <PinInputField />
                                                <PinInputField />
                                                <PinInputField />
                                                <PinInputField />
                                            </PinInput>
                                        </HStack>
                                    </Center>
                                </FormControl>
                                <HStack justifyContent={'center'}>
                                    {inCoolDown ? (
                                        <Text
                                            fontSize={'sm'}
                                            color={useColorModeValueKey('gray')}
                                        >
                                            {t('youCanResendIn', {
                                                seconds: resendCounter,
                                            })}
                                        </Text>
                                    ) : (
                                        <Text fontSize={'sm'}>
                                            <Link
                                                color={useColorModeValueKey(
                                                    'primary',
                                                )}
                                                onClick={() =>
                                                    onSendVerify('sms', step, 'sms')
                                                }
                                                isDisabled={isSending}
                                            >
                                                {t('Click here')}
                                            </Link>{' '}
                                            {t('to resend code')}
                                        </Text>
                                    )}
                                </HStack>
                            </Stack>
                        </Stack>
                    )}
                    {twoFactorMethod === 'otp' && (
                        <Stack spacing={3}>
                            <Stack spacing={3}>
                                <Stack spacing={0}>
                                    <Text textAlign={'center'} fontSize={'sm'}>
                                        {t(
                                            'inputVerificationCodeInYourAuthenticatorApp',
                                        )}
                                    </Text>
                                </Stack>
                                <FormControl>
                                    <Center>
                                        <HStack>
                                            <PinInput
                                                value={code}
                                                onChange={setCode}
                                                variant={'outline'}
                                            >
                                                <PinInputField />
                                                <PinInputField />
                                                <PinInputField />
                                                <PinInputField />
                                                <PinInputField />
                                                <PinInputField />
                                            </PinInput>
                                        </HStack>
                                    </Center>
                                </FormControl>
                            </Stack>
                        </Stack>
                    )}
                </>
            )}
        </Stack>
    );

    const onVerifyEmail = async () => {
        try {
            setNextLoading(true);
            const {issueId, nextMethod} = await verifyEmailCode_({
                code,
                issueId: verifyRef?.current,
                method: twoFactorMethod,
            });
            verifyRef.current = issueId;
            setTwoFactorMethod(nextMethod);
            setCode('');
            setStep(step + 1);
            console.log(step)

            if (nextMethod === 'sms') {
                setFirstVerifyMethod(null)
                onSendVerify('sms', step + 1, 'sms');
            }
        } catch (error) {
            onExpired(getErrorMessage(error));
            toast.show({
                title: t('verifyFailed'),
                status: 'error',
                description: getErrorMessage(error),
            });
            console.log(error);
        } finally {
            setNextLoading(false);
        }
    };

    const onConfirmBtn_ = async () => {
        try {
            setSubmiting(true);
            if (twoFactorEnabled) {
                if (step === 1) {
                    setStep(2);
                    // onSendVerify(twoFactorMethod);
                    console.log(twoFactorMethod);
                    // if (twoFactorMethod === 'email') {
                    onSendVerify(twoFactorMethod);
                    setFirstVerifyMethod(twoFactorMethod)
                    // }
                } else if (step === 2) {
                    if (allowOnlyEmail_ && twoFactorMethod === 'email') {
                        await onFinalSumit({
                            method: twoFactorMethod,
                            code,
                            issueId: verifyRef?.current,
                        });
                        setTwoFactorMethod(null);
                        verifyRef.current = null;
                        onClose();
                    } else if (get(settings, 'enableEmailFact') && (get(settings, 'enableOtpFact')|| get(settings, 'enableSmsFact'))) {
                        onVerifyEmail();
                    } else {
                        await onFinalSumit({
                            method: twoFactorMethod,
                            code,
                            issueId: verifyRef?.current,
                        });
                        setTwoFactorMethod(null);
                        verifyRef.current = null;
                        onClose();
                    }
                } else if (step === 3) {
                    await onFinalSumit({
                        method: twoFactorMethod,
                        code,
                        issueId: verifyRef?.current,
                    });
                    setTwoFactorMethod(null);
                    onClose();
                }
            } else {
                await onFinalSumit({});
                onClose();
            }
        } catch (error) {
            throw error;
        } finally {
            setSubmiting(false);
        }
    };

    const confirmText = sessionExpired
        ? t('close')
        : !twoFactorEnabled
        ? t('confirm')
        : step === 1
        ? t('next')
        : get(settings, 'enableEmailFact') && step === 2
        ? t('next')
        : t('confirm');

    const getDisable = () => {
        const {enableEmailFact, enableOtpFact, enableSmsFact} = settings;
        const hasOtpOrSms = enableOtpFact || enableSmsFact;
        const hasEmail = enableEmailFact;

        switch (step) {
            case 2:
                return !(
                    (hasOtpOrSms && code?.length === 6) ||
                    (hasEmail && code?.length === 6)
                );
            case 3:
                return !(hasOtpOrSms && code?.length === 6);
            default:
                return false;
        }
    };
    const disabled =
        (twoFactorEnabled && (getDisable() || isNil(twoFactorMethod))) ||
        nextDisabled;
    // (twoFactorEnabled &&
    //     step === 2 &&
    //     (code.length != 6 || isNil(twoFactorMethod))) ||
    // nextDisabled;

    const steps = [
        {
            title: customReviewTitle || t('summary'),
            component: customReview,
        },
        ...(get(settings, 'enableEmailFact') && twoFactorEnabled
            ? [
                  {
                      title: t('emailFactor'),
                      component: emailFactor,
                  },
              ]
            : []),
        ...(twoFactorEnabled
            ? [
                  {
                      title: t('twoFactor'),
                      component: twoFactor,
                  },
              ]
            : []),
    ];

    const matchedStep = steps[step - 1];
    return (
        <CustomDialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="xs"
            title={
                sessionExpired && !submiting
                    ? t('transactionExpired')
                    : matchedStep?.title
            }
        >
            <Stack bg="whiteColor" rounded={'md'} width={'100%'} space={3}>
                <Stack px={0} py={1}>
                    {sessionExpired && !submiting ? (
                        <Stack>
                            <Text>{t('transactionExpiredDesc')}</Text>
                        </Stack>
                    ) : (
                        matchedStep?.component
                    )}
                </Stack>
                {/* <Divider /> */}
                <HStack
                    pb={3}
                    alignItems={'center'}
                    justifyContent={'flex-end'}
                >
                    {/* {expiredIn && !sessionExpired ? (
                        <Text fontSize={'sm'}>
                            {t('transactionExpiredIn', {second: countDown})}
                        </Text>
                    ) : (
                        <Box />
                    )} */}
                    <HStack alignItems={'center'} spacing={2}>
                        {!sessionExpired && (
                            <Button
                                size={'sm'}
                                variant="ghost"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                {t('cancel')}
                            </Button>
                        )}
                        <Button
                            size={'sm'}
                            isLoading={submiting || nextLoading}
                            onClick={
                                sessionExpired
                                    ? onClose
                                    : customNext && step === 1
                                    ? () => customNext(onConfirmBtn_)
                                    : onConfirmBtn_
                            }
                            isDisabled={disabled}
                            variant={'primary'}
                        >
                            {confirmText}
                        </Button>
                    </HStack>
                </HStack>
            </Stack>
        </CustomDialog>
    );
};

export default TwoFactorConfirmDialog;
