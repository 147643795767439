import {
    Button,
    HStack,
    Tab,
    TabIndicator,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    useColorModeValue,
} from '@chakra-ui/react';
import {useColorModeValueKey} from 'hooks/useColors';
import {findIndex} from 'lodash';
import React from 'react';

const CustomTab = (props) => {
    const {tabs = [], value, onChange, tabOnly, tabPanelSx={}, ...other} = props;
    const primaryColor = useColorModeValueKey('primary');
    const textColor = useColorModeValue('gray.50', 'gray.800');
    const index = findIndex(tabs, (item) => item.value === value);
    // return (
    //     <HStack
    //         alignItems={'center'}
    //         spacing={2}
    //         overflowX="scroll"
    //         sx={{
    //             scrollbarWidth: 'none',
    //             '::-webkit-scrollbar': {
    //                 display: 'none',
    //             },
    //         }}
    //         {...other}
    //     >
    //         {tabs.map((item) => (
    //             <Button
    //                 variant={'unstyled'}
    //                 color={item.value === value ? primaryColor : textColor}
    //                 key={item.value}
    //                 // minW={'100px'}
    //                 flexShrink={0}
    //                 onClick={() => onChange(item.value)}
    //                 fontWeight={'400'}
    //                 px={2}
    //                 py={1}
    //             >
    //                 {item.label}
    //             </Button>
    //         ))}
    //     </HStack>
    // );
    return (
        <Tabs
            index={index}
            onChange={(index) => onChange(tabs[index].value)}
            position="relative"
            variant="unstyled"
            size={{base: 'sm', md: 'sm'}}
            {...other}
        >
            <TabList
                overflowX="scroll"
                sx={{
                    scrollbarWidth: 'none',
                    '::-webkit-scrollbar': {
                        display: 'none',
                    },
                }}
            >
                {tabs.map((item) => (
                    <Tab
                        _selected={{color: primaryColor}}
                        key={item.value}
                        flexShrink={0}
                        // rounded={'md'}
                        mx={1}
                        fontWeight={'500'}
                        borderBottomWidth={2}
                        borderBottomColor={item.value === value ? primaryColor : 'transparent'}
                    >
                        {item.label}
                    </Tab>
                ))}
            </TabList>
            {/* <TabIndicator
                mt="-1.5px"
                height="2px"
                bg={primaryColor}
                borderRadius="1px"
            /> */}
            {!tabOnly && (
                <TabPanels>
                    {tabs.map((item) => (
                        <TabPanel key={item.value} sx={tabPanelSx}>{item.component}</TabPanel>
                    ))}
                </TabPanels>
            )}
        </Tabs>
    );
};

export default CustomTab;
