import {get, lowerCase, split, take, upperCase} from 'lodash';
import {processUserProfile} from './userProfile';

export const processStockAccountList = (rawData) => {
    const lastDayChange = Number(get(rawData, 'totalEquity')) - Number(get(rawData, 'yesterdayTotalEquity'));
    const accountInfo = JSON.parse(get(rawData, ':applyRecord.:request.params'));
    return {
        ...rawData,
        clientDetail: processUserProfile(get(rawData, ':user')),
        person: get(rawData, 'user'),
        id: get(rawData, 'id'),
        login: get(rawData, 'clientCode'),
        balance: Number(get(rawData, 'totalMarketPrice') || 0) +  Number(get(rawData, 'freeEquity') || 0),
        lastDayBalance: Number(get(rawData, 'yesterdayTotalEquity')) || 0,
        lockedEquity: Number(get(rawData, 'lockedEquity')) || 0,
        lastDayChange: get(rawData, 'lastDayChange.valueChange'),
        lastDayChangePercent: get(rawData, 'lastDayChange.percentChange'),
        availableBalance: get(rawData, 'freeEquity') || 0,
        totalEquity: get(rawData, 'totalEquity') || 0,
        status: get(rawData, 'status'),
        accountInfo: accountInfo,
        currency: get(accountInfo, 'currency'),
        country: get(rawData, ':baseCurrency.entity'),
        leverage: get(rawData, 'leverage'),
        cashBalanceBF: get(rawData, 'cashBalanceBF') || 0,
        cashBalanceCF: get(rawData, 'cashBalanceCF') || 0,
        positionAmount: get(rawData, 'totalMarketPrice') || 0,
        accountSource: 'stock',
        posting: get(rawData, 'posting') || 0,
        account: get(rawData, 'account'),
        code: get(rawData, 'code'),
        ledgerCurrency: get(rawData, 'ledgerCurrency'),
        baseCurrency: get(rawData, 'baseCurrency'),
        email: get(rawData, 'email'),
        hasDeposit: String(get(rawData,'hasDeposit')),
        cashBalance: get(rawData, 'cashBalance') || 0,
        runDate: get(rawData, 'runDate') || get(rawData, 'updatedAt'),
        depositWithdrawal: get(rawData, 'depositWithdrawal') || 0,
        totalChange: get(rawData, 'totalChange') || 0,
        desirable: get(rawData, 'desirable'),
        totalChangePercent: get(rawData, 'totalChangePercent') || 0,
        realisedPL: get(rawData, 'realisedPL') || 0,
        unrealisedPL: get(rawData, 'unrealisedPL') || 0,
        initialMargin: get(rawData, 'initialMargin') || 0 ,
        accountPortfolio: Number(get(rawData, 'totalMarketPrice') || 0) +  Number(get(rawData, 'freeEquity') || 0),
        label: `${get(rawData, 'clientCode')} Share ${get(
            rawData,
            'baseCurrency',
        )}`,
        value: get(rawData, 'id'),
        updatedAt: get(rawData, 'updatedAt') || get(rawData, 'createdAt'),
        rawData: rawData,
    };
};

