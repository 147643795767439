import {
    Avatar,
    Center,
    HStack,
    Spinner,
    Stack,
    Tag,
    TagLabel,
    TagLeftIcon,
    Text,
} from '@chakra-ui/react';
import {useAdminClientDetail} from 'hooks/datahook/adminClients';
import {useAdminPermissionSettings} from 'hooks/datahook/adminPermission';
import {useAuth} from 'hooks/useAuth';
import {useColorModeValueKey} from 'hooks/useColors';
import {capitalize, get} from 'lodash';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {
    HiCheckBadge,
    HiClock,
    HiExclamationCircle,
    HiExclamationTriangle,
    HiOutlineGlobeAlt,
    HiPauseCircle,
    HiShieldCheck,
    HiShieldExclamation,
} from 'react-icons/hi2';
import {useNavigate} from 'react-router-dom';
import {adminPaths} from 'routes/path';
import {formatDate} from 'utils/formator';

const UserDetailPopoverContent = (props) => {
    const {id, profile} = props;
    const {data, isLoading} = useAdminClientDetail({id});
    const {roles} = useAuth();
    const {getPermission} = useAdminPermissionSettings({roles});
    const grayColor = useColorModeValueKey('gray');
    const {t} = useTranslation('app');
    const navigate = useNavigate();
    const processedData = id ? data : profile;
    // console.log('render');
    const isUser = get(processedData, 'roles', []).includes('USER');
    const colorMode = 'light';
    const getSchema = useCallback(
        (status) => {
            switch (status) {
                case 'actived':
                case 'activated':
                case 'completed':
                case 'processed':
                case 'passed':
                case 'approved':
                case 'replyed':
                case 'verified':
                    return `${colorMode}.success`;

                case 'pending':
                case 'processing':
                case 'inactive':
                case 'unReplyed':
                case 'unregistered':
                    return `${colorMode}.pending`;

                case 'error':
                case 'failed':
                case 'declined':
                    return `${colorMode}.error`;

                case 'renew':
                case 'draft':
                case 'return':
                    return `${colorMode}.warning`;

                case 'suspended':
                    return 'gray';
            }
        },
        [colorMode],
    );

    const getStatusIcon = useCallback(
        (status) => {
            switch (status) {
                case 'actived':
                case 'activated':
                case 'completed':
                case 'processed':
                case 'passed':
                case 'approved':
                case 'replyed':
                case 'verified':
                    return HiCheckBadge;

                case 'pending':
                case 'processing':
                case 'inactive':
                case 'unReplyed':
                case 'unregistered':
                    return HiClock;

                case 'error':
                case 'failed':
                case 'declined':
                    return HiExclamationCircle;

                case 'renew':
                case 'draft':
                case 'return':
                    return HiExclamationTriangle;

                case 'suspended':
                    return HiPauseCircle;
            }
        },
        [colorMode],
    );
    const getKycStatusIcon = useCallback(
        (status) => {
            switch (status) {
                case 'actived':
                case 'activated':
                case 'completed':
                case 'processed':
                case 'passed':
                case 'approved':
                case 'replyed':
                case 'verified':
                    return HiShieldCheck;

                case 'pending':
                case 'processing':
                case 'inactive':
                case 'unReplyed':
                case 'unregistered':
                    return HiClock;

                case 'error':
                case 'failed':
                case 'declined':
                    return HiShieldExclamation;

                case 'renew':
                case 'draft':
                case 'return':
                    return HiShieldExclamation;

                case 'suspended':
                    return HiPauseCircle;
            }
        },
        [colorMode],
    );

    if (id && isLoading) {
        return (
            <Center h={32}>
                <Spinner />
            </Center>
        );
    }
    return (
        <Stack
            onClick={(e) => {
                e.stopPropagation();
                if (
                    isUser &&
                    getPermission('clientsHome.clients.clientDetail')
                ) {
                    // navigate(adminPaths.clientDetail(get(processedData, 'id')));
                    window.open(
                        adminPaths.clientDetail(get(processedData, 'id')),
                        '_blank',
                    );
                }
            }}
            cursor={'pointer'}
        >
            <HStack alignItems={'center'}>
                <Avatar
                    size="md"
                    src={get(processedData, 'avatar')}
                    name={get(processedData, 'name')}
                />
                <Stack spacing={0} justifyContent={'space-between'} flex={1}>
                    <Stack spacing={-0.5}>
                        <Text fontWeight={'500'} noOfLines={1} mt={-0.5}>
                            {capitalize(get(processedData, 'name'))}
                        </Text>
                        <Text
                            fontSize={'xs'}
                            fontWeight={'400'}
                            color={grayColor}
                        >
                            {get(processedData, 'email')}
                        </Text>
                        <Text
                            fontSize={'xs'}
                            fontWeight={'400'}
                            color={grayColor}
                        >
                            Ref: {get(processedData, 'reference')}
                        </Text>
                    </Stack>
                    {/* {get(processedData, 'createdAt') && ( */}
                        <Text fontSize={'2xs'} color={grayColor}>
                            {isUser
                                ? null
                                : get(processedData, 'roles', [])
                                      .map((item) => t(item))
                                      .join(',')}
                            {/* {'. '} */}
                            {/* {t('joinInAt')}:{' '}
                            {formatDate(get(processedData, 'createdAt'))} */}
                        </Text>
                    {/* )} */}
                </Stack>
            </HStack>
            <HStack wrap={'wrap'}>
                {get(processedData, 'status') && (
                    <Tag
                        size={'md'}
                        variant="outline"
                        colorScheme={getSchema(get(processedData, 'status'))}
                    >
                        <TagLeftIcon
                            boxSize="4"
                            as={getStatusIcon(get(processedData, 'status'))}
                        />
                        <TagLabel>{t(get(processedData, 'status'))}</TagLabel>
                    </Tag>
                )}
                {get(processedData, 'kycStatus') && isUser && (
                    <Tag
                        size={'md'}
                        variant="outline"
                        colorScheme={getSchema(get(processedData, 'kycStatus'))}
                    >
                        <TagLeftIcon
                            boxSize="4"
                            as={getKycStatusIcon(
                                get(processedData, 'kycStatus'),
                            )}
                        />
                        <TagLabel>
                            {t(get(processedData, 'kycStatus'))}
                        </TagLabel>
                    </Tag>
                )}
                {get(processedData, 'kycStatus') && isUser && (
                    <Tag
                        size={'md'}
                        variant="outline"
                    >
                        <TagLeftIcon
                            boxSize="4"
                            as={HiOutlineGlobeAlt}
                        />
                        <TagLabel>
                            {get(processedData, 'country')}
                        </TagLabel>
                    </Tag>
                )}
            </HStack>
        </Stack>
    );
};

export default UserDetailPopoverContent;
