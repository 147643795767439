import React from 'react';
import {get} from 'lodash';
import {useColorModeValueKey} from 'hooks/useColors';
import { useUserPreference } from './datahook/settings';

const stockColorMode = {
    greenUpRedDown: {
        up: 'stockGreen',
        down: 'stockRed',
    },
    greenDownRedUp: {
        up: 'stockRed',
        down: 'stockGreen',
    },
};

export const usePreference = (options={})=>{
    const {data:preference} = useUserPreference();
    const stockColorKey = get(preference,'stockColor','greenUpRedDown');
    const stockUpColor = useColorModeValueKey(stockColorMode[stockColorKey].up);
    const stockDownColor = useColorModeValueKey(
        stockColorMode[stockColorKey].down,
    );

    return {
        stockColor: stockColorKey,
        stockUpColor: stockUpColor,
        stockDownColor: stockDownColor,
        getStockColorValue: (value) =>
            value > 0
                ? stockUpColor
                : value < 0
                ? stockDownColor
                : useColorModeValueKey('gray'),
    };
}