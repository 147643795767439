import Excel from 'exceljs';
import numeral from 'numeral';
import {capitalize, get} from 'lodash';
import {
    currencyFormatorWithDecimal,
    formatDateTimes2,
} from 'utils/formator';

export const DownloadClosedOrderFile = async (data) => {
    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet('Closed order data');
    sheet.columns = [
        {header: 'Last update', key: 'lastUpdateOn'},
        {header: 'Account num', key: 'login'},
        {header: 'Symbol', key: 'symbol'},
        {header: 'Closed lots', key: 'closedLots'},
        {header: 'Open price', key: 'openPrice'},
        {header: 'Close price', key: 'closePrice'},
        {header: 'CMD', key: 'cmd'},
        {header: 'Profits', key: 'profit'},
    ];
    for (const grant of data) {
        const row = [];
        for (const col of sheet.columns) {
            switch (col.key) {
                case 'lastUpdateOn':
                    row.push(grant.lastUpdateOn);
                    break;
                case 'login':
                    row.push(grant.login);
                    break;
                case 'symbol':
                    row.push(grant.symbol);
                    break;
                case 'closedLots':
                    row.push(
                        numeral(get(grant, 'volume') / 100).format('0,0.00'),
                    );
                    break;
                case 'openPrice':
                    row.push(grant.openPrice);
                    break;
                case 'closePrice':
                    row.push(grant.closePrice);
                    break;
                case 'cmd':
                    row.push(grant.cmd);
                    break;
                case 'profit':
                    row.push(grant.profit);
                    break;
            }
        }
        sheet.addRow(row);
    }
    console.log(sheet);
    const buffer = await workbook.xlsx.writeBuffer();
    let a = window.document.createElement('a');
    a.href = URL.createObjectURL(
        new Blob([buffer], {type: 'application/vnd.openxmlformats'}),
    );
    a.download = 'Close orders.xlsx';
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
};

export const DownloadAccountTransactionFile = async (
    data,
    title = 'Account transactions',
) => {
    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet('Account transaction data');
    sheet.columns = [
        {header: 'Last update', key: 'lastUpdateOn'},
        {header: 'Ticket', key: 'mtTicket'},
        {header: 'Type', key: 'type'},
        {header: 'From account', key: 'fromAccount'},
        {header: 'From amount', key: 'fromAmount'},
        {header: 'To account', key: 'toAccount'},
        {header: 'To amount', key: 'toAmount'},
        {header: 'Comment', key: 'comment'},
    ];
    for (const grant of data) {
        const row = [];
        for (const col of sheet.columns) {
            switch (col.key) {
                case 'lastUpdateOn':
                    row.push(
                        get(grant, 'mtTicket')
                            ? get(grant, 'statusCompletedTimestamp')
                            : get(grant, 'updatedAt') ||
                            get(grant, 'createdAt'),
                    );
                    break;
                case 'mtTicket':
                    row.push(get(grant, 'ticket') ||
                        get(grant, 'mtTicket') ||
                        get(grant, 'login'));
                    break;
                case 'type':
                    row.push(get(grant, 'type'));
                    break;
                case 'fromAccount':
                    row.push(
                        get(grant, 'fromAccount'),
                    );
                    break;
                case 'fromAmount':
                    row.push(
                        get(grant, 'from_amount'),
                    );
                    break;
                case 'toAccount':
                    row.push(
                        get(grant, 'toAccount'),
                    );
                    break;
                case 'toAmount':
                    row.push(
                        get(grant, 'to_amount'),
                    );
                    break;
                case 'comment':
                    row.push(grant.comment);
                    break;
            }
        }
        sheet.addRow(row);
    }
    console.log(sheet);
    const buffer = await workbook.xlsx.writeBuffer();
    let a = window.document.createElement('a');
    a.href = URL.createObjectURL(
        new Blob([buffer], {type: 'application/vnd.openxmlformats'}),
    );
    a.download = `${title}.xlsx`;
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
};

export const DownloadClosedOrderSummaryFile = async (data) => {
    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet('Closed order summary data');
    sheet.columns = [
        {header: 'Date', key: 'date'},
        {header: 'Source', key: 'source'},
        {header: 'Account num', key: 'login'},
        {header: 'Closed lots', key: 'closedLots'},
        {header: 'Currency', key: 'currency'},
        {header: 'Profits', key: 'profits'},
    ];
    for (const grant of data) {
        const row = [];
        for (const col of sheet.columns) {
            switch (col.key) {
                case 'date':
                    row.push(grant.date);
                    break;
                case 'source':
                    row.push(grant.origin);
                    break;
                case 'login':
                    row.push(grant.login);
                    break;
                case 'closedLots':
                    row.push(numeral(get(grant, 'volume')).format('0,0.00'));
                    break;
                case 'currency':
                    row.push(grant.currency);
                    break;
                case 'profits':
                    row.push(numeral(grant?.profits).format('0,0.00'));
                    break;
            }
        }
        sheet.addRow(row);
    }
    console.log(sheet);
    const buffer = await workbook.xlsx.writeBuffer();
    let a = window.document.createElement('a');
    a.href = URL.createObjectURL(
        new Blob([buffer], {type: 'application/vnd.openxmlformats'}),
    );
    a.download = 'Close order summary.xlsx';
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
};

export const DownloadCommissionSummaryFile = async (data) => {
    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet('Commission summary data');
    sheet.columns = [
        {header: 'Date', key: 'date'},
        {header: 'Source', key: 'source'},
        {header: 'Account num', key: 'login'},
        {header: 'Currency', key: 'currency'},
        {header: 'Commissions', key: 'commission'},
    ];
    for (const grant of data) {
        const row = [];
        for (const col of sheet.columns) {
            switch (col.key) {
                case 'date':
                    row.push(grant.date);
                    break;
                case 'source':
                    row.push(grant.origin);
                    break;
                case 'login':
                    row.push(grant.login);
                    break;
                case 'currency':
                    row.push(grant.currency);
                    break;
                case 'commission':
                    row.push(currencyFormatorWithDecimal(
                        get(grant, 'commission'),
                        '',
                        4,
                    ));
                    break;
            }
        }
        sheet.addRow(row);
    }
    console.log(sheet);
    const buffer = await workbook.xlsx.writeBuffer();
    let a = window.document.createElement('a');
    a.href = URL.createObjectURL(
        new Blob([buffer], {type: 'application/vnd.openxmlformats'}),
    );
    a.download = 'Commission summary.xlsx';
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
};

export const DownloadAccountCommissionFile = async (
    data,
    title = 'Account commissions',
) => {
    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet('Account transaction data');
    sheet.columns = [
        {header: 'Last update', key: 'lastUpdateOn'},
        {header: 'Ticket', key: 'ticket'},
        {header: 'Account num', key: 'login'},
        {header: 'Type', key: 'type'},
        {header: 'Currency', key: 'currency'},
        {header: 'Amount', key: 'amount'},
        {header: 'Comment', key: 'comment'},
    ];
    for (const grant of data) {
        const row = [];
        for (const col of sheet.columns) {
            switch (col.key) {
                case 'lastUpdateOn':
                    row.push(get(grant, 'lastUpdateOn'));
                    break;
                case 'ticket':
                    row.push(grant?.ticket);
                    break;
                case 'login':
                    row.push(grant?.login);
                    break;
                case 'type':
                    row.push(get(grant, 'type'));
                    break;
                case 'currency':
                    row.push(grant?.currency);
                    break;
                case 'amount':
                    row.push(grant?.amount);
                    break;
                case 'comment':
                    row.push(grant?.comment);
                    break;
            }
        }
        sheet.addRow(row);
    }
    console.log(sheet);
    const buffer = await workbook.xlsx.writeBuffer();
    let a = window.document.createElement('a');
    a.href = URL.createObjectURL(
        new Blob([buffer], {type: 'application/vnd.openxmlformats'}),
    );
    a.download = `${title}.xlsx`;
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
};


export const DownloadFundingTransactionFile = async (
    data,
    title = '',
    type = '',
    networks= [],
) => {
    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet('Transaction data');
    sheet.columns = [
        {header: 'Last update', key: 'lastUpdateOn'},
        {header: 'CRM ticket', key: 'ticket'},
        ...(type !== 'Stock transaction' ? [{header: 'Ticket', key: 'mtTicket'}] : []),
        ...(type === 'Stock transaction' ? [{header: 'Type', key: 'type'}] : []),
        ...(type === 'transfer' || type === 'moneyMove' ? []
            : [{header: 'Method', key: 'method'}]),
        ...(type !== 'deposit'
            ? [
                {header: 'From account', key: 'fromAccount'},
                {header: 'From amount', key: 'fromAmount'},
            ]
            : []),
        {header: 'To account', key: 'toAccount'},
        {header: 'To amount', key: 'toAmount'},
        ...(type === 'withdrawal'
            ? [
                {header: 'Crypto network', key: 'chainType'},
                {header: 'Account number', key: 'accountNumber'},
                {header: 'Account holder name', key: 'accountHolderName'},
                {header: 'Withdrawal method', key: 'routeType'},
                {header: 'BIC/SWIFT', key: 'routeCode'},
            ]
            : []),
        {header: 'Status', key: 'status'},
    ];

    for (const grant of data) {
        const row = [];
        for (const col of sheet.columns) {
            switch (col.key) {
                case 'lastUpdateOn':
                    row.push(
                        get(grant, 'mtTicket')
                            ? get(grant, 'statusCompletedTimestamp')
                            : get(grant, 'updatedAt') ||
                            get(grant, 'createdAt'),
                    );
                    break;
                case 'ticket':
                    row.push(get(grant, 'ticket'));
                    break;
                case 'mtTicket':
                    row.push(get(grant, 'mtTicket') === 0
                        ? ''
                        : get(grant, 'mtTicket'));
                    break;
                case 'type':
                    row.push(get(grant, 'type'))
                    break;
                case 'method':
                    row.push(get(grant, 'method') || get(grant, ':paymentGateway.displayName'));
                    break;
                case 'fromAccount':
                    row.push(
                        get(grant, 'fromAccount')
                    );
                    break;
                case 'fromAmount':
                    row.push(
                        get(grant, 'from_amount')
                    );
                    break;
                case 'toAccount':
                    row.push(
                        get(grant, 'toAccount')
                    );
                    break;
                case 'toAmount':
                    row.push(
                        get(grant, 'to_amount')
                    );
                    break;

                case 'chainType':
                    const dic = networks.find((d)=>d?.chain === get(grant, ':withdrawalAccount.chainType'));
                    row.push(get(dic, ':chain.name'));
                    break;
                case 'accountNumber':
                    row.push(get(grant, ':withdrawalAccount.accountNumber'));
                    break;
                case 'accountHolderName':
                    row.push(get(grant, ':withdrawalAccount.accountHolderName'));
                    break;
                case 'routeType':
                    row.push(get(grant, ':withdrawalAccount.routeType'));
                    break;
                case 'routeCode':
                    row.push(get(grant, ':withdrawalAccount.routeCode'));
                    break;

                case 'status':
                    row.push(grant.status);
                    break;
            }
        }
        sheet.addRow(row);
    }
    const buffer = await workbook.xlsx.writeBuffer();
    let a = window.document.createElement('a');
    a.href = URL.createObjectURL(
        new Blob([buffer], {type: 'application/vnd.openxmlformats'}),
    );
    a.download = `${capitalize(type)}s-${formatDateTimes2(new Date())}.xlsx`;
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
};


export const DownloadSalesReportNetFile = async (data) => {
    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet('Sales report net data');
    sheet.columns = [
        {header: 'Date', key: 'date'},
        {header: 'Sale', key: 'sale'},
        {header: 'Deposit', key: 'deposit'},
        {header: 'Withdrawal', key: 'withdrawal'},
        {header: 'Net', key: 'net'},
    ];
    for (const grant of data) {
        const row = [];
        for (const col of sheet.columns) {
            switch (col.key) {
                case 'date':
                    row.push(grant.date);
                    break;
                case 'sale':
                    row.push(grant.saleShortName);
                    break;
                case 'deposit':
                    row.push(currencyFormatorWithDecimal(grant.positiveProfit, 'USD', 2, true));
                    break;
                case 'withdrawal':
                    row.push(currencyFormatorWithDecimal(grant.negativeProfit, 'USD', 2, true));
                    break;
                case 'net':
                    row.push(currencyFormatorWithDecimal(grant.netProfit, 'USD', 2, true));
                    break;
            }
        }
        sheet.addRow(row);
    }
    console.log(sheet);
    const buffer = await workbook.xlsx.writeBuffer();
    let a = window.document.createElement('a');
    a.href = URL.createObjectURL(
        new Blob([buffer], {type: 'application/vnd.openxmlformats'}),
    );
    a.download = 'sales-performance.xlsx';
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
};

export const DownTotalClientFile = async (data) => {
    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet('Total client data');
    sheet.columns = [
        {header: 'Country', key: 'country'},
        {header: 'Code', key: 'code'},
        {header: 'Count', key: 'count'},
    ];
    for (const grant of data) {
        const row = [];
        for (const col of sheet.columns) {
            switch (col.key) {
                case 'country':
                    row.push(grant.name);
                    break;
                case 'code':
                    row.push(grant.code);
                    break;
                case 'count':
                    row.push(grant.count);
                    break;
            }
        }
        sheet.addRow(row);
    }
    console.log(sheet);
    const buffer = await workbook.xlsx.writeBuffer();
    let a = window.document.createElement('a');
    a.href = URL.createObjectURL(
        new Blob([buffer], {type: 'application/vnd.openxmlformats'}),
    );
    a.download = 'Total clients.xlsx';
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
};
