import {useEffect, useState} from 'react';

export const useInIframe = () => {
    const [inIframe, setInIframe] = useState(false);
    useEffect(() => {
        if (window.location !== window.parent.location) {
            // The page is in an iframe
            setInIframe(true);
        } else {
            // The page is not in an iframe
            setInIframe(false);
        }
    }, [window.location]);
    return inIframe;
};
