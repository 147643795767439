export const cryptoNetwork = [
    {
        label: 'ETH',
        value: 'ETH',
    },
    {
        label: 'TRON',
        value: 'TRON',
    },
];

export const sourceList = [
    {
        label: 'MT4s1',
        value: 'MT4',
    },
    {
        label: 'MT4s2',
        value: 'MT4s2',
    },
    {
        label: 'MT4s3',
        value: 'MT4s3',
    },
    {
        label: 'MT5',
        value: 'MT5',
        disabled: false,
    },
];

export const DemoSourceList = [
    {
        label: 'MT4 Demo',
        value: 'MT4Demo',
    },
    {
        label: 'MT5 Demo',
        value: 'MT5Demo',
    },
]

export const mergeSourceList = [
    {
        label: 'MT4',
        value: 'MT4',
    },
    {
        label: 'MT5',
        value: 'MT5',
        disabled: false,
    },
];

export const adminSourceList = [
    {
        label: 'MT4s1',
        value: 'MT4',
    },
    {
        label: 'MT4s2',
        value: 'MT4s2',
    },
    {
        label: 'MT4s3',
        value: 'MT4s3',
    },
    {
        label: 'MT5',
        value: 'MT5',
    },
];

export const adminAllSourceList = (i18) => {
    return [
        {
            label: i18('MT4s1'),
            value: 'MT4',
        },
        {
            label: i18('MT4s2'),
            value: 'MT4s2',
        },
        {
            label: i18('MT4s3'),
            value: 'MT4s3',
        },
        {
            label: i18('MT5'),
            value: 'MT5',
        },
        {
            label: i18('stock'),
            value: 'Stock',
        },
    ];
};

export const accountTypeList = (i18) => {
    return [
        {
            label: i18('liveAccount'),
            value: 'Live',
        },
        // {
        //     label: i18('demoAccount'),
        //     value: 'Demo',
        // },
        {
            label: i18('rebateAccount'),
            value: 'Rebate',
        },
    ];
};

export const groupTypeList = [
    {
        label: 'STD',
        value: 'STD',
    },
    {
        label: 'PRO',
        value: 'PRO',
    },
];

export const dateTypeList = (i18) => {
    return [
        {
            label: i18('byMonth'),
            value: 'byMonth',
        },
        {
            label: i18('byWeek'),
            value: 'byWeek',
        },
        {
            label: i18('byDay'),
            value: 'byDay',
        },
    ];
};

export const accountList = (list) => {
    return list.map((item) => {
        return {
            label: item?.login,
            value: item?.login,
        };
    });
};

export const LeadStatusList = (t) => {
    return [
        {
            label: t('new'),
            value: 'new',
        },
        {
            label: t('contacted'),
            value: 'contacted',
        },
        {
            label: t('attempted'),
            value: 'attempted',
        },
        {
            label: t('interested'),
            value: 'interested',
        },
        {
            label: t('converted'),
            value: 'converted',
        },
        {
            label: t('noInterested'),
            value: 'noInterested',
        },
    ];
};

export const ADPositionList = (t) => {
    return [
        {
            label: t('banner'),
            value: 'Banner',
        },
        {
            label: t('me'),
            value: 'Me',
        },
        {
            label: t('side'),
            value: 'Side',
        },
        {
            label: t('introduction'),
            value: 'Introduction',
        },
        {
            label: t('home'),
            value: 'Home',
        },
    ];
};

export const ADStatusList = (t) => {
    return [
        {
            label: t('online'),
            value: 'online',
        },
        {
            label: t('offline'),
            value: 'offline',
        },
    ];
};

export const LanguageList = (t) => {
    return [
        {
            label: t('englishConsist'),
            value: 'en-US',
            county: 'US',
        },
        {
            label: t('chineseConsist'),
            value: 'zh-CN',
            county: 'CN',
        },
        {
            label: t('chineseTWConsist'),
            value: 'zh-TW',
            county: 'CN',
        },
        {
            label: t('japaneseConsist'),
            value: 'jp-JP',
            county: 'JP',
        },
        {
            label: t('koreanConsist'),
            value: 'ko-KR',
            county: 'kr',
        },
    ];
};

export const StockTypeList = (i18) => {
    return [
        {
            label: i18('liveAccount'),
            value: 'Live',
        },
        {
            label: i18('demoAccount'),
            value: 'Demo',
        },
    ];
};

export const StockHasDepositList = (i18) => {
    return [
        {
            label: i18('true'),
            value: '1',
        },
        {
            label: i18('false'),
            value: '0',
        },
    ];
};

export const MessageTypeList = (t) => {
    return [
        {
            label: t('email'),
            value: 'email',
        },
        {
            label: t('notification'),
            value: 'notification',
        },
    ];
};

export const AccountCategoryList = (t) => {
    return [
        {
            label: t('forex'),
            value: 'forex',
        },
        {
            label: t('stock'),
            value: 'stock',
        },
    ];
};

export const StockHasLeverage = (i18) => {
    return [
        {
            label: i18('hasLeverage'),
            value: '1',
        },
        {
            label: i18('noLeverage'),
            value: '0',
        },
    ];
};

export const convertSource = (source) => {
    switch (source) {
        case 'MT4':
            source = 'MT4s1';
            break;
        case 'MT4s2':
            source = 'MT4s2';
            break;
        case 'MT4s3':
            source = 'MT4s3';
            break;
        case 'MT5':
            source = 'MT5';
            break;
        case 'MT4Demo':
            source = 'MT4';
            break;
        case 'MT5Demo':
            source = 'MT5';
            break;
    }
    return source;
};

export const hasAllSourceList = [
    {
        label: 'All',
        value: 'All',
    },
    {
        label: 'MT4s1',
        value: 'MT4',
    },
    {
        label: 'MT4s2',
        value: 'MT4s2',
    },
    {
        label: 'MT4s3',
        value: 'MT4s3',
    },
    {
        label: 'MT5',
        value: 'MT5',
        disabled: false,
    },
]

export const allSourceStr = 'MT4,MT4s2,MT4s3,MT5'


export const sourceConvertList = ['MT4s1', 'MT4s2', 'MT5'];