import { split } from 'lodash';
import {find, get} from 'lodash';
import React from 'react';
import FormViewWrapper from './FormViewWrapper';
import { HStack, Text } from '@chakra-ui/react';
import { ArrowForwardIcon, ArrowRightIcon } from '@chakra-ui/icons';

const TransferNeedsViewer = (props) => {
    const {data, i18n, index, name, text, options, formater} = props;
    const v = get(data, name);
    const from = split(v,'/')?.[0]||''
    const to = split(v,'/')?.[1]||''
    return (
        <FormViewWrapper {...props}>
            <HStack alignItems={'center'} spacing={1}>
                <Text fontWeight={500}>{from || ' '}</Text>
                <ArrowForwardIcon />
                <Text fontWeight={500}>{to || ' '}</Text>
            </HStack>
        </FormViewWrapper>
    );
};

export default TransferNeedsViewer;
