import {Text, Tooltip} from '@chakra-ui/react';
import {get} from 'lodash';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Cell} from 'rsuite-table';
import {
    convertTZ,
    formatDateTimeWithTimezone,
    formatDateInTimezone,
    getLocalTime,
} from 'utils/formator';

const MT45TimeTableCell = ({rowData, dataKey, style, ...props}) => {
    const date =
        get(rowData, dataKey) ||
        (get(rowData, 'mtTicket')
            ? get(rowData, 'statusCompletedTimestamp')
            : get(rowData, 'updatedAt') || get(rowData, 'createdAt') || get(rowData, 'statusPendingTimestamp'));
    // const mt45LocalTime = new Date(date).toLocaleString('en-US', {
    //     timeZone: 'Europe/Moscow',
    // });
    const moscowTime = date ? formatDateInTimezone(date, 'Factory') : '';

    return (
        <Cell {...props} style={{...style}}>
            <Tooltip
                label={
                    date
                        ? formatDateInTimezone(
                              date,
                              'Factory',
                              'dd/MM/yyyy HH:mm:ss OOOO',
                          )
                        : null
                }
                openDelay={200}
                hasArrow
            >
                <Text noOfLines={1}>{moscowTime}</Text>
            </Tooltip>
        </Cell>
    );
};

export default MT45TimeTableCell;
