import {get} from 'lodash';
import { processAddress } from './common';

export const processPerson = (rawData) => {
    const phoneCode = get(rawData, 'phoneCode')||''
    const phoneNo = get(rawData, 'phoneNo')||''
    return {
        id: get(rawData, 'id', ''),
        title: get(rawData, 'title', ''),
        firstname: get(rawData, 'firstname', ''),
        lastname: get(rawData, 'lastname', ''),
        middlename: get(rawData, 'middlename', ''),
        legalName: get(rawData, 'legalName', ''),

        firstNameNative:get(rawData, 'nativeLanguageFirstname', ''),
        middleNameNative:get(rawData,'nativeLanguageMiddlename', ''),
        lastNameNative:get(rawData,'nativeLanguageLastname', ''),
        occupation: get(rawData, 'occupation', ''),
        // referral: get(rawData, 'referral', ''),
        dob: get(rawData, 'dob'),
        primaryDocumentFrontSide: get(rawData, 'frontPhotoOfPrimary', ''),
        primaryDocumentBackSide: get(rawData, 'backPhotoOfPrimary', ''),
        secondaryIdentificationDocument: get(rawData, 'photoOfSecondary', ''),
        additionalPhotoIdentification: get(rawData, 'photoOfAdditional', ''),
        countryOfResidence: get(rawData, ':address.country', ''),
        phoneCode,
        phoneNo,
        region: get(rawData, 'region', ''),
        phone: `${phoneCode}@${phoneNo}`,
        ...processAddress(get(rawData, ':address'), 'individual'),
        rawData,
    };
};
