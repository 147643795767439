import { FormControl, FormLabel } from '@chakra-ui/react';
import { useColorModeValueKey } from 'hooks/useColors';
import {isFunction} from 'lodash';
import React from 'react';

const FormViewWrapper = (props) => {
    const {
        data,
        i18n,
        index,
        name,
        label,
        title,
        required = false,
        disabled,
        readOnly,
        helper,
        children,
    } = props;
    const renderLabel = isFunction(label) ? label(data) : label;
    const darkColor = useColorModeValueKey('text');
    return (
        <FormControl
            isDisabled={disabled}
            isReadOnly={readOnly}
            // lineHeight={1}
            // isRequired={required}
        >
            <FormLabel fontSize="xs" fontWeight={'400'} color={darkColor}>
                {renderLabel || title}
            </FormLabel>
            {children}
        </FormControl>
    );
};

export default FormViewWrapper;
