// import {Ionicons} from '@expo/vector-icons';
// import { OptionsPickerModal} from 'components/Pickers';
// import ChevornDown from 'components/common/BaseIcon/ChevornDown';
import {find} from 'lodash';
import React, {useEffect, useState} from 'react';
import FormWrapper from './FormWrapper';
import Selector from 'components/Selector';

const OptionSelector = (props) => {
    const {
        formik,
        i18n,
        index,
        name,
        disabled,
        readOnly,
        options = [],
        inputProps,
        helper,
        label,
        allowClear,
    } = props;
    const value = formik.values[name];
    return (
        <FormWrapper {...props}>
            <Selector
                value={value}
                onChangeValue={(v) => {
                    // console.log(v);
                    formik.setFieldValue(name, v);
                }}
                isDisabled={disabled}
                options={options}
                allowClear={allowClear}
            />
        </FormWrapper>
    );
};

// export default OptionSelector;

export default React.memo(OptionSelector, (prev, next) => {
    const name = prev.name;
    return (
        prev.formik.values[name] === next.formik.values[name] &&
        prev.formik.errors[name] === next.formik.errors[name] &&
        prev.formik.touched[name] === next.formik.touched[name] &&
        prev.disabled === next.disabled &&
        prev.readOnly === next.readOnly &&
        prev.i18n === next.i18n &&
        prev.options === next.options
    );
});
