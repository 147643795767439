import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from 'translations';

const supportedLngs = ['en-US', 'zh-CN', 'zh-TW', 'jp-JP','ko-KR'];

i18n.use(initReactI18next).init({
    ns: 'app',
    fallbackLng: 'en-US',
    debug: false,
    supportedLngs,
    resources,
    load: 'currentOnly',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
