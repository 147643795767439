import {addMonths, addYears, endOfDay, endOfMonth, endOfYear, format, isValid, startOfDay, startOfMonth, startOfYear} from 'date-fns';
import { formatMonthDataAndYear } from './formator';

export const getMonthsBetween = (startDate, endDate) => {
    let start = startOfMonth(new Date(startDate));
    const end = endOfMonth(new Date(endDate));
    const months = [];
    while (start <= end) {
        months.push(new Date(start));
        start = addMonths(start, 1);
    }
    return months;
};
export const getYearsBetween = (startDate, endDate) => {
    let start = startOfYear(new Date(startDate));
    const end = endOfYear(new Date(endDate));
    const years = [];
    while (start <= end) {
        years.push(new Date(start));
        start = addYears(start, 1);
    }
    return years;
};

export const processStartEndDateLocale = ({startDate,endDate})=>{
    return {
        startDate: startDate ? startOfDay(startDate) : null,
        endDate: startDate?endOfDay(endDate):null,
    };
}
export const formatedStartEndDate = ({startDate,endDate})=>{
    const formatedStartDate =
        startDate && isValid(startDate)
            ? formatMonthDataAndYear(startDate)
            : null;
    const formatedendDate =
        endDate && isValid(endDate)
            ? formatMonthDataAndYear(endDate)
            : null;
    return {
        startDate: formatedStartDate,
        endDate: formatedendDate
    };
}

