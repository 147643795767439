import client, {adminClient} from 'helper/httpClient';
import {
    useSWRPaginationTemplate,
    useSWRTemplate,
} from 'hooks/dataHooksTemplate';
import {cleanObjectNilValue} from 'utils/clean';
import {get, random} from 'lodash';
import {
    formatMonthDataAndYear,
    formatDateInTimezone,
    currencyFormatorWithDecimal,
    formatDate
} from 'utils/formator';
import {api} from './common';
import {useAuth} from 'hooks/useAuth';
import {
    processTransaction,
    processFundingTransaction,
    processLedger,
} from 'data/transaction';
import Excel from 'exceljs';
import { convertSource } from 'page/app/ConstantGroup';
import { sleep_ } from '@genx/july/lib/commonjs/lang';

export const useTransaction = (options = {}) => {
    const {
        startDate,
        endDate,
        type,
        status,
        ticket,
        accountId,
        user,
        isHidden,
        accountType,
        paymentGateway,
        source,
        keyword,
        fromCurrency,
        subMethod,
        subMethodIsNull,
    } = options;
    const swr = useSWRPaginationTemplate({
        key: [
            'transaction',
            type,
            status,
            endDate,
            startDate,
            ticket,
            accountId,
            user,
            isHidden,
            accountType,
            paymentGateway,
            source,
            keyword,
            fromCurrency,
            subMethod,
            subMethodIsNull,
        ],
        request: async (_key, _pageIndex, _pageSize) => {
            const [
                _,
                _type,
                _status,
                _endDate,
                _startDate,
                _ticket,
                _accountId,
                _user,
                _isHidden,
                _accountType,
                _paymentGateway,
                _source,
                _keyword,
                _fromCurrency,
                _subMethod,
                _subMethodIsNull,
            ] = _key;
            const query = {
                type: _type,
                status: _status,
                endDate: _endDate
                    ? formatMonthDataAndYear(_endDate.toISOString())
                    : null,
                startDate: _startDate
                    ? formatMonthDataAndYear(_startDate.toISOString())
                    : null,
                ticket: _ticket,
                accountId: _accountId,
                user: _user,
                isHidden: _isHidden,
                accountType: _accountType,
                paymentGateway: _paymentGateway,
                source: _source||'All',
                keyword: _keyword,
                fromCurrency: _fromCurrency,
                subMethod: _subMethod,
                subMethodIsNull: _subMethodIsNull,
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
            };
            const {result} = await api().get(
                ['transaction'],
                cleanObjectNilValue(query),
            );
            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map(processFundingTransaction),
                },
            };
        },
        defaultValue: {
            items: [],
            totalItems: 0,
        },
    });
    return swr;
};

export const useLedge = (options = {}) => {
    const {
        startDate,
        endDate,
        type,
        status,
        ticket,
        accountId,
        user,
        isHidden,
        accountType,
    } = options;
    const swr = useSWRPaginationTemplate({
        key: [
            'ledge',
            type,
            status,
            endDate,
            startDate,
            ticket,
            accountId,
            user,
            isHidden,
            accountType,
        ],
        request: async (_key, _pageIndex, _pageSize) => {
            const [
                _,
                _type,
                _status,
                _endDate,
                _startDate,
                _ticket,
                _accountId,
                _user,
                _isHidden,
                _accountType,
            ] = _key;
            const query = {
                type: _type,
                status: _status,
                endDate: _endDate
                    ? formatMonthDataAndYear(_endDate.toISOString())
                    : null,
                startDate: _startDate
                    ? formatMonthDataAndYear(_startDate.toISOString())
                    : null,
                ticket: _ticket,
                accountId: _accountId,
                user: _user,
                isHidden: _isHidden,
                accountType: _accountType,
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
            };
            const {result} = await api().get(
                ['finance', 'ledger'],
                cleanObjectNilValue(query),
            );
            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map(processLedger),
                },
            };
        },
        defaultValue: {
            items: [],
            totalItems: 0,
        },
    });
    return swr;
};

export const useTransactionDetail = (options = {}) => {
    const {id} = options;
    const swr = useSWRTemplate({
        key: id ? ['transactionDetail', id] : null,
        request: async ([_, _id]) => {
            const {result} = await api().get(['transaction', _id]);
            return processTransaction(result);
        },
        defaultValue: {},
    });
    return swr;
};

export const useAccountTransaction = (options = {}) => {
    const {
        startDate,
        endDate,
        type,
        status,
        ticket,
        accountId,
        user,
        isHidden,
        accountType,
        pageSize = 20,
        request = true,
        source,
    } = options;
    const swr = useSWRPaginationTemplate({
        key: request
            ? [
                  'account-transaction',
                  type,
                  status,
                  endDate,
                  startDate,
                  ticket,
                  accountId,
                  user,
                  isHidden,
                  accountType,
                  source,
                  pageSize,
              ]
            : null,
        pageSize: pageSize,
        request: async (_key, _pageIndex, _pageSize) => {
            const [
                _,
                _type,
                _status,
                _endDate,
                _startDate,
                _ticket,
                _accountId,
                _user,
                _isHidden,
                _accountType,
                _source
            ] = _key;
            const query = {
                type: _type,
                status: _status,
                endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                startDate: _startDate
                    ? formatMonthDataAndYear(_startDate)
                    : null,
                ticket: _ticket,
                accountId: _accountId,
                userId: _user,
                isHidden: _isHidden,
                accountType: _accountType,
                source: _source,
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
            };
            const {result} = await api().get(
                ['fx', 'trades'],
                cleanObjectNilValue(query),
            );
            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items')
                        .map(processTransaction)
                        .filter(
                            (item) =>
                                item?.type != 'PAMM' && item?.type != 'MAM',
                        ),
                },
            };
        },
        defaultValue: {
            items: [],
            totalItems: 0,
        },
    });
    return swr;
};

// 获取commission明细
export const useAccountCommissions = (options = {}) => {
    const {
        startDate,
        endDate,
        accountId,
        pageSize = 20,
        request = true,
    } = options;
    const swr = useSWRPaginationTemplate({
        key:
            accountId && request
                ? [
                      'account-commissions',
                      endDate,
                      startDate,
                      accountId,
                      pageSize,
                  ]
                : null,
        pageSize: pageSize,
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _endDate, _startDate, _accountId] = _key;

            const query = {
                endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                startDate: _startDate
                    ? formatMonthDataAndYear(_startDate)
                    : null,
                accountId: _accountId,
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
            };
            const {result} = await api().get(
                ['fx', 'commissions'],
                cleanObjectNilValue(query),
            );
            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items')
                        .map(processTransaction)
                        .filter(
                            (item) =>
                                item?.type != 'PAMM' && item?.type != 'MAM',
                        ),
                },
            };
        },
        defaultValue: {
            items: [],
            totalItems: 0,
        },
    });
    return swr;
};

export const useCommissionSummary = ({type, accountId, startDate, endDate}) => {
    const swr = useSWRPaginationTemplate({
        key:
            type === 'commission'
                ? ['commissions-summary', startDate, endDate, accountId]
                : null,
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _startDate, _endDate, _accountId] = _key;
            const query = {
                endDate: _endDate
                    ? formatMonthDataAndYear(_endDate.toISOString())
                    : null,
                startDate: _startDate
                    ? formatMonthDataAndYear(_startDate.toISOString())
                    : null,
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
                accountId: _accountId,
            };
            const {result} = await api().get(
                ['summary', 'commission-daily'],
                cleanObjectNilValue(query),
            );
            return {
                result: {
                    items: get(result, 'items'),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
        defaultValue: {
            items: [],
            totalItems: 0,
        },
    });
    // console.log(swr);
    return swr;
};

export const useClosedOrderSummary = ({
    type,
    accountId,
    startDate,
    endDate,
}) => {
    const swr = useSWRPaginationTemplate({
        key: ['closed-order-summary', startDate, endDate, accountId],
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _startDate, _endDate, _accountId] = _key;
            const query = {
                endDate: _endDate
                    ? formatMonthDataAndYear(_endDate.toISOString())
                    : null,
                startDate: _startDate
                    ? formatMonthDataAndYear(_startDate.toISOString())
                    : null,
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
                accountId: _accountId,
            };
            const {result} = await api().get(
                ['summary', 'close-order-daily'],
                cleanObjectNilValue(query),
            );
            console.log(result);
            return {
                result: {
                    items: get(result, 'items'),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
        defaultValue: {
            items: [],
            totalItems: 0,
        },
    });
    console.log(swr);
    return swr;
};

export const useUserClosedOrderSummary = ({
    type,
    accountId,
    startDate,
    endDate,
    userId,
}) => {
    const swr = useSWRPaginationTemplate({
        key: accountId
            ? ['closed-order-summary', startDate, endDate, accountId, userId]
            : null,
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _startDate, _endDate, _accountId, _userId] = _key;
            const query = {
                endDate: _endDate
                    ? formatMonthDataAndYear(_endDate.toISOString())
                    : null,
                startDate: _startDate
                    ? formatMonthDataAndYear(_startDate.toISOString())
                    : null,
                userId: _userId,
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
                accountId: _accountId,
            };
            const {result} = await api().get(
                ['summary', 'close-order-daily'],
                cleanObjectNilValue(query),
            );
            console.log(result);
            return {
                result: {
                    items: get(result, 'items'),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
        defaultValue: {
            items: [],
            totalItems: 0,
        },
    });
    return swr;
};

export const downloadUserClosedOrderSummary = async (options = {}, isUser) => {
    const {accountId, startDate, endDate,userId} = options;
    const {result} = await api().get(
        ['summary', 'close-order-daily'],
        cleanObjectNilValue({
            ...(isUser?{}: { userId: userId }),
            accountId: accountId,
            startDate: startDate ? formatMonthDataAndYear(startDate) : null,
            endDate: endDate ? formatMonthDataAndYear(endDate) : null,
        }),
    );
    if(!result.length) {
        throw new Error('Can not export empty data')
    }

    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet('Closed orders daily');
    sheet.columns = [
        {header: 'Date', key: 'date'},
        {header: 'Source', key: 'source'},
        {header: 'Currency', key: 'currency'},
        {header: 'Closed lots', key: 'closedLots'},
    ];

    for (const grant of result) {
        const row = [];
        for (const col of sheet.columns) {
            switch (col.key) {
                case 'date':
                    row.push(formatDate(get(grant, 'date')));
                    break;
                case 'source':
                    row.push(grant.origin);
                    break;
                case 'currency':
                    row.push(grant.currency);
                    break;
                case 'closedLots':
                    row.push(currencyFormatorWithDecimal(grant.volume, '', 2, true));
                    break;
            }
        }
        sheet.addRow(row);
    }

    const buffer = await workbook.xlsx.writeBuffer();
    let a = window.document.createElement('a');
    a.href = URL.createObjectURL(
        new Blob([buffer], {type: 'application/vnd.openxmlformats'}),
    );
    a.download = `Closed orders daily.xlsx`;
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
};


export const useFinanceSummary = () => {
    const swr = useSWRPaginationTemplate({
        key: ['finance-summary'],
        request: async (_, _pageIndex, _pageSize) => {
            const query = {
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
            };
            const {result} = await api().get(['finance', 'summary'], query);
            return {
                result: {
                    items: get(result, 'items').map((item) => {
                        return {
                            ...item,
                            currency: get(item, 'currency') || 'USD',
                        };
                    }),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
        defaultValue: {
            items: [],
            totalItems: 0,
        },
    });
    return swr;
};
export const useExpense = () => {
    const swr = useSWRPaginationTemplate({
        key: ['finance-expense'],
        request: async (_, _pageIndex, _pageSize) => {
            const query = {
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
            };
            const {result} = await api().get(['finance', 'expense'], query);
            return {
                result: {
                    items: get(result, 'items'),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
        defaultValue: {
            items: [],
            totalItems: 0,
        },
    });
    return swr;
};

export const addExpense = async (data) => {
    const result = await api().post(['finance', 'expense'], data);
    return result;
};

export const fxAdjustment_ = async (data) => {
    const {result} = await api().post(
        ['fx', 'adjustment'],
        cleanObjectNilValue(data),
    );
    return result;
};

export const useStatements = () => {
    const swr = useSWRTemplate({
        key: ['client/statement'],
        request: async ([_, _id]) => {
            const {result} = await api().get(['statement']);
            return result;
        },
        defaultValue: [],
    });
    return swr;
};

export const useAdminStatements = (id) => {
    const swr = useSWRTemplate({
        key: ['admin/statement', id],
        request: async ([_, _id]) => {
            const {result} = await api().get(['user', _id, 'statement']);
            return result;
        },
        defaultValue: [],
    });
    return swr;
};

export const downloadStatements = async (month, userId) => {
    let startDate = new Date(month).setDate(1);
    let endDate = new Date(month).setMonth(new Date(month).getMonth() + 1);
    endDate = new Date(endDate).setDate(0);
    startDate = formatMonthDataAndYear(new Date(startDate).toISOString());
    endDate = formatMonthDataAndYear(new Date(endDate).toISOString());
    const {result} = await api().get(
        ['transaction'],
        cleanObjectNilValue({
            type: 'Deposit,Withdrawal,Commission',
            startDate,
            endDate,
            user: userId,
        }),
    );
    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet('Statements');
    sheet.columns = [
        {header: 'Last updated', key: 'date'},
        {header: 'Ticket', key: 'ticket'},
        {header: 'CRM Ticket', key: 'crmTicket'},
        {header: 'Type', key: 'type'},
        {header: 'From account', key: 'fromAccount'},
        {header: 'From amount', key: 'fromAmount'},
        {header: 'To account', key: 'toAccount'},
        {header: 'To amount', key: 'toAmount'},
        {header: 'Comment', key: 'comment'},
    ];
    for (const grant of result) {
        const row = [];
        for (const col of sheet.columns) {
            switch (col.key) {
                case 'date':
                    const date = get(grant, 'mtTicket')
                        ? get(grant, 'statusCompletedTimestamp')
                        : get(grant, 'updatedAt') ||
                          get(grant, 'createdAt') ||
                          get(grant, 'statusPendingTimestamp');
                    const moscowTime = date
                        ? formatDateInTimezone(date, 'Factory')
                        : '';
                    row.push(moscowTime);
                    break;
                case 'ticket':
                    row.push(grant.mtTicket);
                    break;
                case 'crmTicket':
                    row.push(grant.ticket);
                    break;
                case 'type':
                    row.push(grant.type);
                    break;
                case 'fromAccount':
                    row.push(get(grant, 'fromAccount')||`${convertSource(get(grant, ':fromFxAccount.source'))}-${get(grant, ':fromFxAccount.login')}`);
                    break;
                case 'fromAmount':
                    row.push(
                        currencyFormatorWithDecimal(
                            get(grant, 'fromAmount'),
                            get(grant, 'fromCurrency'),
                            2,
                            true,
                        ),
                    );
                    break;
                case 'toAccount':
                    row.push(
                        get(grant, 'type') === 'Withdrawal'
                            ? get(grant, ':withdrawalAccount.displayName')
                            : get(grant, 'toAccountName')
                            ? `${get(grant, 'toAccountSource')}-${get(
                                  grant,
                                  'toAccountName',
                              )}`
                            : '',
                    );
                    break;
                case 'toAmount':
                    row.push(
                        currencyFormatorWithDecimal(
                            get(grant, 'toAmount'),
                            get(grant, 'toCurrency'),
                            2,
                            true,
                        ),
                    );
                    break;
                case 'comment':
                    row.push(grant.comment);
                    break;
            }
        }
        sheet.addRow(row);
    }
    console.log(sheet);
    const buffer = await workbook.xlsx.writeBuffer();
    let a = window.document.createElement('a');
    a.href = URL.createObjectURL(
        new Blob([buffer], {type: 'application/vnd.openxmlformats'}),
    );
    a.download = month + '-Statements.xlsx';
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
};
export const useAccountOpenPosition = async (options = {}) => {
    const {startDate, endDate, refreshCount} = options;
    const swr = useSWRTemplate({
        key: ['account/open-position', startDate, endDate, refreshCount],
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _startDate, _endDate] = _key;
            const query = {
                startDate: _startDate,
                endDate: _endDate,
                // pageIndex: _pageIndex - 1,
                // pageSize: _pageSize
            };
            const {result} = await api().get(['fx', 'open-position'], query);
            return result.sort((a, b) => {
                return new Date(a.open_time) - new Date(b.open_time);
            });
            // return {
            //     result:  {
            //         items: get(result, 'items'),
            //         totalItems: get(result, 'totalItems'),
            //     }
            // }
        },
        defaultValue: [],
    });
    return swr;
};



export const use2CheckNpFirst =  (options={})=>{
    const {randomId} = options;
    const swr = useSWRTemplate({
        key: ['checkNpFirst', randomId],
        request: async (_key) => {
            const {result} = await api().get(['commons', 'mask']);
            return {
                SN: get(result, 'MaskedString'),
                hash: get(result, 'signatureData'),
            };
        },
        defaultValue: {},
    });
    return swr;
}

export const get2CheckNpFirst_ = async ()=>{
    const {result} = await api().get(['commons','mask']);
    return {
        SN: get(result, 'maskedString'),
        hash: get(result, 'hash'),
    };
}

export const check2CheckNpSecond_ = async ({
    transactionId,
    SN,
    hash,
    script,
    amount,
    account,
}) => {
    const {result} = await api().post(['commons','mask-verify'], {
        transactionId,
        SN,
        hash,
        script,
        amount,
        account,
    });
    return result;
    // await sleep_(4000);
    // return {
    //     success:true
    // }
};