import PropTypes from 'prop-types';
import {useState, useEffect} from 'react';

import {usePlatformBasicInfo, useProfile} from 'hooks/datahook/auth';
import {Box, Button, Center, Heading, Stack, Text} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import CardContainer from 'components/CardContainer';
import KYCCard from 'components/KYCCard';
import {kycStepConfig} from 'constant/status';
import {useColorModeValueKey} from 'hooks/useColors';
import {useNavigate} from 'react-router-dom';
import { paths } from 'routes/path';
import {get} from 'lodash';

// ----------------------------------------------------------------------

KYCRequireGuard.propTypes = {
    children: PropTypes.node,
};

export default function KYCRequireGuard({children}) {
    const {data: platformBasicInfo} = usePlatformBasicInfo();
    const {kycVerified, isCompany, data: profileData,kycPending} = useProfile();
    const {t} = useTranslation('app');
    const navigate = useNavigate();
    const stepConfig = kycStepConfig(isCompany)[profileData?.kycStatus] || {};
    if (!kycVerified) {
        return (
            <CardContainer>
                <Box textAlign="center" py={10} px={6}>
                    <Heading display="inline-block" as="h2" size="xl">
                        {t(
                            kycPending
                                ? 'reviewingApplication'
                                : isCompany
                                ? 'kybNotVerified'
                                : 'kycNotVerified',
                        )}
                    </Heading>
                    <Text color={useColorModeValueKey('gray')} mb={6}>
                        {stepConfig.descKey && t(stepConfig.descKey,{shortName: get(platformBasicInfo, 'companyShortName')})}
                    </Text>
                    {stepConfig.btnText && (
                        <Button
                            size="sm"
                            variant="primary"
                            onClick={() =>
                                navigate(isCompany ? paths.kyb : paths.kyc)
                            }
                        >
                            {t(stepConfig.btnText)}
                        </Button>
                    )}
                </Box>
            </CardContainer>
        );
    }

    return <>{children}</>;
}
