import {
    Box,
    Button,
    Center,
    Flex,
    HStack,
    Icon,
    IconButton,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Spinner,
    Stack,
    Text,
    useBreakpointValue,
    useToken,
} from '@chakra-ui/react';
import {useColorModeValueKey} from 'hooks/useColors';
import React, {memo, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Table, Column, Cell, HeaderCell} from 'rsuite-table';
import CardContainer from './CardContainer';
import '../style/css/rsuite-table.css';
import CustomPagination from './CustomPagination';
import {HiOutlineFolderOpen} from 'react-icons/hi2';
import {useSearchParams} from 'react-router-dom';
import {motion} from 'framer-motion';
// import 'rsuite-table/dist/css/rsuite-table.css';
import {FiMoreVertical} from 'react-icons/fi';
import Selector from './Selector';

const CustomTable = (props) => {
    const {
        data = [],
        column,
        title,
        description,
        height,
        onRowClick,
        loading,
        isLoading,
        page,
        totalPage,
        setPage,
        pageSize,
        totalItems,
        hidePagenation = false,
        actions,
        noCard,
        isTree,
        rowKey,
        fullPagination,
        withUrlQuery,
        size = '14px',
        cellBordered = true,
        selectIndex,
        indexField,
        autoHeight = true,
        sortable,
        fillHeight,
        virtualized,
        sort = {
            onSortColumn: (sortColumn, sortType) => {
                console.log(sortColumn, sortType);
            },
        },
    } = props;
    const {t} = useTranslation('app');
    const grayColor = useToken('colors', useColorModeValueKey('gray'));
    const [searchParams, setSearchParams] = useSearchParams();
    const [sortOpen, setSortOpen] = useState(false);
    const [dataKey, setDataKey] = useState(null);

    useEffect(() => {
        if (withUrlQuery) {
            setPage(Number(searchParams.get('page') || 1));
        }
    }, []);

    const Wrapper = noCard ? Box : CardContainer;
    return (
        <Wrapper
            title={title}
            noPaddingContent
            actions={actions}
            height={fillHeight ? height : undefined}
        >
            <Table
                data={data}
                onRowClick={(rowData) => {
                    if (onRowClick) onRowClick(rowData);
                }}
                rowClassName={(rowData) =>
                    rowData?.[indexField] === selectIndex ? 'tableSelected' : ''
                }
                height={height}
                autoHeight={autoHeight}
                fillHeight={fillHeight}
                bordered={false}
                loading={loading || isLoading}
                renderLoading={() => (
                    <Center flex={1} height="100%" width={'100%'}>
                        <HStack zIndex={20} alignItems="center">
                            <Spinner />
                            <Text fontSize="lg">{t('loading')}...</Text>
                        </HStack>
                    </Center>
                )}
                renderEmpty={() => (
                    <Center flex={1} height="100%">
                        <Stack alignItems={'center'} spacing={1}>
                            {/*<Icon*/}
                            {/*    as={HiOutlineFolderOpen}*/}
                            {/*    boxSize="4"*/}
                            {/*    color={grayColor}*/}
                            {/*/>*/}
                            <Text fontSize="md" color={grayColor}>
                                {t('noData')}
                            </Text>
                        </Stack>
                    </Center>
                )}
                isTree={isTree}
                rowKey={rowKey}
                cellBordered={cellBordered}
                // headerHeight={30}
                rowHeight={36}
                // rowHeight={(rowData) => {
                //     // if (rowData?.tags.length > 4) {
                //     //     return 80;
                //     // }
                //     return 32;
                // }}
                sortColumn={sort.sortColumn}
                sortType={sort.sortType}
                onSortColumn={sort.onSortColumn}
            >
                {column.map((col, index) => {
                    // console.log(col);
                    const UsedCell = col.CustomCell ? col.CustomCell : Cell;
                    return (
                        <Column
                            key={index}
                            width={100}
                            align="left"
                            fullText
                            sortable={col.sortable}
                            {...col.columProps}
                        >
                            <HeaderCell
                                style={{
                                    color: grayColor,
                                    fontWeight: '400',
                                    fontSize: size,
                                    fontFamily: 'Inter',
                                    overflow: 'visible',
                                }}
                            >
                                {col.label}
                            </HeaderCell>
                            <UsedCell
                                dataKey={col.dataKey}
                                style={{
                                    fontFamily: 'Inter',
                                    fontSize: size,
                                    // paddingTop: 14,
                                    // paddingBottom: 14,
                                }}
                            >
                                {col.renderCell ? col.renderCell : null}
                            </UsedCell>
                        </Column>
                    );
                })}
                {autoHeight == false && (
                    <Column width={12} align="left">
                        <HeaderCell></HeaderCell>
                        <Cell dataKey={'ept-blank'}></Cell>
                    </Column>
                )}
            </Table>
            {!hidePagenation && page && totalPage && setPage && (
                <Flex justifyContent={'flex-end'} p={2}>
                    <CustomPagination
                        totalPage={totalPage}
                        page={page}
                        onChange={(page) => {
                            setPage(page);
                            window.scrollTo({top: 0, behavior: 'smooth'});
                            if (withUrlQuery) {
                                setSearchParams({page});
                            }
                        }}
                        pageSize={pageSize}
                        totalItems={totalItems}
                        variant={fullPagination ? 'full' : 'mini'}
                        loading={loading}
                    />
                </Flex>
            )}
        </Wrapper>
    );
};

export default memo(CustomTable, (prev, next) => {
    return (
        prev.data === next.data &&
        prev.page === next.page &&
        prev.totalPage === next.totalPage &&
        prev.totalItems === next.totalItems &&
        prev.loading === next.loading &&
        prev.isLoading === next.isLoading &&
        prev.selectIndex === next.selectIndex &&
        prev.title === next.title&&
        prev.nodeId === next.nodeId && 
        prev.column === next.column &&
        prev.i18n === next.i18n
    );
})
