import React from 'react';
import {flatten, get, isFunction, isNil, trimEnd} from 'lodash';
import { Box, Stack, Text } from '@chakra-ui/react';

// const oneLine = (components, formik, i18n) => {
//     return (
//         <Row alignItems={'center'} space={2}>
//             {components.map((item, index) => (
//                 <View
//                     key={index}
//                     style={{
//                         marginLeft: index !== 0 ? 1 : 0,
//                         flex: 1,
//                     }}
//                 >
//                     {formikFormMapper({item, formik, i18n})}
//                 </View>
//             ))}
//         </Row>
//     );
// };

const UndefinedView = (props) => (
    <Box>
        <Text style={{textAlign: 'center'}}>
            Undefined views of type:{props.type}
        </Text>
    </Box>
);
const UndefinedForm = (props) => (
    <Box>
        <Text style={{textAlign: 'center'}}>
            Undefined forms of type:{props.type}
        </Text>
    </Box>
);

export default class FormFactory {
    constructor(
        viewComponents = {},
        formComponents = {},
        viewLayoutComponents = ['divider', 'title'],
    ) {
        this.viewComponents = {
            ...viewComponents,
            undefined: {
                component: UndefinedForm,
                placeholderValue: '',
            },
        };
        this.formComponents = {
            ...formComponents,
            undefined: {
                component: UndefinedView,
                defaultValue: '',
            },
        };
        this.viewLayoutComponents = viewLayoutComponents;
    }

    oneLine(components, formik, i18n, isForm) {
        return (
            <Stack direction={{base:'column',md:'row'}} flex={1} spacing={2}>
                {components.map((item, index) => (
                    <Box key={index} flex={1}>
                        {isForm
                            ? this.formikFormMapper({
                                  field: item,
                                  formik,
                                  i18n,
                              })
                            : this.formikViewMapper({
                                  field: item,
                                  data:formik,
                                  i18n,
                              })}
                    </Box>
                ))}
            </Stack>
        );
    }

    formikFormMapper({field, formik, i18n, index, ...other}) {
        if (Array.isArray(field)) {
            return this.oneLine(field, formik, i18n,true);
        } else {
            if (field?.hide && field.hide(formik)) {
                return null;
            }
            const {component: Component, ...otherProps} =
                this.formComponents[field.type] ||
                this.viewComponents['undefined'];
            return (
                <Component
                    formik={formik}
                    i18n={i18n}
                    index={index}
                    {...other}
                    {...otherProps}
                    {...field}
                />
            );
        }
    }
    formikViewMapper({field, i18n, index, data, trimStar, ...other}) {
        if (Array.isArray(field)) {
            return this.oneLine(field, data, i18n, false);
        }else{
            const {component: Component, ...otherProps} =
                this.viewComponents[field.type] || this.viewComponents['undefined'];
            return (
                <Component
                    i18n={i18n}
                    index={index}
                    data={data}
                    {...other}
                    {...otherProps}
                    {...field}
                />
            );
        }
    }

    buildForm({fields, formik, i18n}) {
        return fields.map((field, index) =>
            this.formikFormMapper({field, formik, i18n, index}),
        );
    }

    buildView({i18n, data, fields, keepFormat = false, trimStar = false}) {
        const filterField = keepFormat
            ? fields
            : flatten(fields).filter(
                  (field) => !this.viewLayoutComponents.includes(field.type),
              );
        return filterField
            .filter((field) =>{
                if(Array.isArray(field)){
                    return true
                }else{
                    const {name} = field
                    return keepFormat ? true : !isNil(data[name]) && data[name] !== ''
                }
            }
            )
            .map((field, index) =>
                this.formikViewMapper({field, i18n, trimStar, index, data}),
            );
    }
}
