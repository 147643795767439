import {Icon} from '@chakra-ui/react';
import React, {memo} from 'react';

const EUR = (props) => {
    return (
        <Icon size="md" {...props}>
            <svg
                viewBox="0 0 256 256"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M115.2 10.7C89.5 13.3 63.3 25.9 44.6 44.6C30.7 58.4 20 76.3 14.8 94.5C6.50003 123.2 9.00002 153.4 21.8 179.7C38.9 214.8 71.7 238.8 110.8 244.9C118.4 246.1 137.7 246.1 145.3 244.9C187.6 238.3 222.4 210.6 238 171.1C248.6 144.2 248.7 112.9 238.2 85.7C227.5 57.9 206.8 35.2 180 22.2C166.7 15.7 154 12 139.9 10.6C134 10 121.2 10.1 115.2 10.7ZM138.2 34.4C170.3 37.9 198.5 57.7 212.7 86.8C228.4 119 224.4 157.8 202.5 185.9C199 190.4 191.3 198.1 186.8 201.8C152.3 229.3 103.1 229.2 68.8 201.5C64.7 198.1 56.4 189.7 53.4 185.7C40.7 169 33.9 149 33.9 128.1C33.9 113.3 36.9 100.3 43.5 86.8C57.3 58.1 85.6 38 117.1 34.4C122.4 33.8 132.9 33.8 138.2 34.4Z"
                    fill="currentColor"
                />
                <path
                    d="M132.3 74.5C127.2 75.1 120.8 76.9 116.1 78.9C104.4 84 94.4 94.2 89.7 105.9L88.9 107.9H79.2C72.2 107.9 69.3 108 68.7 108.5C68 109 67.9 110 67.9 115.8C67.9 119.5 68 122.9 68.2 123.4C68.7 124.5 69.8 124.7 78.1 124.7H85.7V128.4V132.1H77C71.3 132.1 68.3 132.3 68 132.6C67.9 132.9 67.7 136.2 67.7 139.9C67.7 145.8 67.8 146.8 68.5 147.3C69.1 147.8 71.9 147.9 78.8 147.9H88.3L88.9 149.2C92.2 157.5 95 161.7 100.5 167.1C109.1 175.5 120.5 180.1 135 180.9C147 181.6 165.4 178.1 167.2 174.9C167.6 174.2 167.3 172.6 166.1 168.1C164.1 160.6 164 160.5 158.7 162.4C152.3 164.6 146.9 165.3 139.1 165.1C128.7 164.7 121.6 162.1 115.4 156.5C113 154.3 108.8 149.1 108.8 148.2C108.8 148 117.3 147.9 127.7 147.9C146 147.9 146.6 147.9 147.5 147C148.3 146.2 148.4 145.5 148.4 139.9C148.4 135.7 148.2 133.4 147.8 132.9C147.2 132.2 145.2 132.1 125.7 132L104.3 131.9L104.2 128.3L104.1 124.7H125C145.7 124.7 147.2 124.6 148.2 123.1C148.4 122.8 148.6 119.7 148.6 116.2C148.6 110.4 148.5 109.7 147.7 108.9C146.8 108 146.2 108 127.7 108H108.5L109.6 106.1C111.2 103.3 116.6 97.6 119.6 95.6C125.3 91.8 132.8 89.8 141.4 89.7C147.8 89.6 152.5 90.3 157.3 92C161.6 93.5 163.3 93.8 164 92.9C164.8 92 167.9 81.5 167.7 80.3C167.4 78.2 159.1 75.5 150 74.4C144.5 73.9 137.1 73.9 132.3 74.5Z"
                    fill="currentColor"
                />
            </svg>
        </Icon>
    );
};

export default memo(EUR);
