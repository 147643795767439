import {Box, Center, Heading, Image, Stack, Text, useBreakpointValue} from '@chakra-ui/react';
import React from 'react';
import bull from 'assets/images/whiteBull.png'
import darkbg from 'assets/images/OX Guest bg dark.png'
import bullTrans from 'assets/images/bullTrans.png';
import trust from 'assets/images/Trustpilot Micro.svg'
import trustWhite from 'assets/images/TrustpilotMicroWhite.svg'
import { useTranslation } from 'react-i18next';

const GuestBanner = () => {
    const {t} = useTranslation('app')
    return <Box
            bg="#003566"
            flex={1}
            h="100vh"
            backgroundImage={darkbg}
            backgroundPosition={'bottom right'}
            backgroundRepeat={'no-repeat'}
            backgroundSize={'contain'}
        >
            <Box
                flex={1}
                h="100vh"
                backgroundImage={bullTrans}
                backgroundPosition={'bottom right'}
                backgroundRepeat={'no-repeat'}
                backgroundSize={'70%'}
                py={24}
                px={16}
            >
                <Stack spacing={4}>
                    <Heading w={'70%'} color={'white'} fontSize={'100px'} fontWeight={'800'}>
                        {t('trustByOurClients')}
                    </Heading>
                    <Image src={trustWhite} width={'450px'}/>
                </Stack>
            </Box>
        </Box>
};

export default GuestBanner;
