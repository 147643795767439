import {Icon} from '@chakra-ui/react';
import React, {memo} from 'react';

const AUD = (props) => {
    return (
        <Icon size="md" {...props}>
            <svg
                viewBox="0 0 237 236"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M104.9 0.700024C65.4 5.50002 31.3 29.5 13.4 65.2C-1.49999 94.8 -2.99999 130.4 9.00001 161C25.1 201.8 61.9 230.1 105.4 235.2C137.2 238.9 169.9 229.2 194.5 208.7C230.7 178.4 245.4 130.2 232.2 84.4C226.4 64.3 213.9 44.3 198.3 30.4C179.5 13.6 157.3 3.50002 132.7 0.700024C125.5 -0.199976 111.9 -0.199976 104.9 0.700024ZM131 24.4C166.9 29.1 197.3 54.1 208.5 88.3C223.2 133.2 202.7 181.8 160.3 202.6C128.5 218.2 90.9 214.5 62.3 193.1C57.5 189.5 49.3 181.5 45.4 176.6C18 142.2 18 93.5 45.4 59.1C49 54.7 56.7 47 61.2 43.5C74 33.5 90.2 26.7 106.4 24.5C113.1 23.6 124.3 23.6 131 24.4Z"
                    fill="currentColor"
                />
                <path
                    d="M170.7 76C170 76.4 169.9 77.2 169.9 80.8V85.2L167.9 85.7C162.1 87.1 157.4 90.7 155 95.4C153.9 97.8 153.7 98.5 153.7 103.1C153.7 107.7 153.8 108.5 154.9 110.8C157.4 116 163 119.9 172.3 122.9C182 126 185.1 128.5 185.1 133.4C185.1 136 184.2 137.9 182.2 139.4C180 141 177.9 141.6 173.9 141.6C170.9 141.6 169.9 141.4 167.8 140.3C164.7 138.7 163.4 136.8 162.9 133.3C162.7 131.9 162.3 130.5 162.1 130.3C161.5 129.9 152.9 129.8 151.8 130.2C150.7 130.6 150.8 135.6 152 139.5C153.6 144.8 158.2 148.7 165.1 150.7L167.8 151.4V154.9C167.8 158 167.9 158.5 169 159.5C170 160.6 170.5 160.7 174 160.7C176.4 160.7 178.1 160.5 178.4 160.1C178.8 159.7 179 158 179 155.5C179 151 178.8 151.3 182.3 150.6C185.3 150 190.6 147.2 192.6 145.2C193.7 144.1 195.1 142.1 195.8 140.6C196.9 138.3 197 137.4 197 133.1C197 128.8 196.9 127.9 195.8 125.6C193.2 120.2 188.7 117.1 178.5 113.5C171.7 111.2 168.8 109.7 167.2 107.9C165.7 106.1 165.3 104.8 165.6 101.8C165.8 99.3 166.8 97.7 168.9 96.1C170.3 95.1 175 94.6 177.8 95.1C181.2 95.8 184 99.4 184.5 103.9C184.6 105.3 185 106.6 185.2 106.9C185.5 107.3 187.2 107.5 190.7 107.5C196.8 107.5 197 107.3 196.6 103.4C195.6 94.7 190.6 88.4 183 86.1L180.2 85.2L180 80.9C179.9 78.5 179.6 76.2 179.4 75.9C179 75.4 171.8 75.3 170.7 76Z"
                    fill="currentColor"
                />
                <path
                    d="M65.2 87.2C63.5 87.8 61.3 89.8 60.3 91.9C58.3 95.6 41.1 143 40.9 145.3C40.4 149.4 43 152.5 47.1 152.5C50.6 152.5 51.8 151 55 142.3L56.8 137.5L68.4 137.6L80.1 137.7L81.6 141.8C85 150.7 86.3 152.4 90 152.4C92.3 152.4 94.1 151.3 95.4 149.3C96.3 147.9 96.4 147.2 96.2 145.2C95.9 141.9 77.1 92.6 75.4 90.4C74.7 89.5 73.6 88.4 73 87.9C71.5 87 67.2 86.5 65.2 87.2ZM72.3 115.2C74.4 121.3 75.9 126.3 75.8 126.5C75.4 126.9 61 126.9 60.6 126.5C60.5 126.4 62 121.1 64.1 114.9C66.2 108.6 68 103.7 68.2 103.9C68.4 104 70.2 109.1 72.3 115.2Z"
                    fill="currentColor"
                />
                <path
                    d="M99.9 87.5001C99 87.9001 97.8 89.1001 97.2 90.2001L96.2 92.1001L96.3 112.6C96.5 134.8 96.5 135.4 99.2 141.1C101.8 146.6 107 150.3 114.1 151.8C118.4 152.7 126.6 152.7 130.7 151.8C136.5 150.5 141.5 146.7 144.1 141.6C146.3 137.2 146.6 134.8 146.6 112.2C146.6 92.2001 146.6 91.1001 145.7 89.7001C144.5 87.7001 142.3 86.6001 140 86.9001C137.5 87.2001 136 88.3001 134.9 90.6001C134.1 92.4001 134 93.9001 134 110.8C134 129.4 133.7 133 132.1 136.2C130.3 139.7 127.1 141.3 122.2 141.3C117.8 141.3 115.4 140.5 113.2 138.2C109.7 134.7 109.6 134.1 109.3 111C109 92.4001 108.9 90.6001 108.2 89.4001C106.5 86.8001 102.8 86.0001 99.9 87.5001Z"
                    fill="currentColor"
                />
            </svg>
        </Icon>
    );
};

export default memo(AUD);
