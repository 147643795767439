import {  usePersonTitle } from 'hooks/datahook/common';
import React from 'react';
import Selector from './Selector';

const TitleSelector = (props) => {
    const {value, onChangeValue, ...other} = props;
    const {localedTitle} = usePersonTitle();
    return (
        <Selector
            value={value}
            onChangeValue={onChangeValue}
            options={localedTitle}
            {...other}
        />
    );
};

export default TitleSelector;
