import {get} from 'lodash';
import {processUserProfile} from './userProfile';

export const processReferee = (rawData) => {
    const refereeName =
        get(rawData, 'type') === 'individual'
            ? `${get(rawData, 'firstname') || ''} ${
                  get(rawData, 'lastname') || ''
              }`
            : get(rawData, 'companyName');
    return {
        rawData,
        id: get(rawData, 'id'),
        status: get(rawData, 'status'),
        companyName: get(rawData, 'companyName'),
        lastname: get(rawData, 'lastname'),
        firstname: get(rawData, 'firstname'),
        refereeName: refereeName,
        mobile: get(rawData, 'phone'),
        email: get(rawData, 'email'),
        user: get(rawData, 'user'),
        createdAt: get(rawData, 'createdAt'),
        invitedAt: get(rawData, 'createdAt'),
        updatedAt: get(rawData, 'updatedAt'),
        type: get(rawData, 'type'),
        activatedAt:null,
        region: get(rawData, 'region'),
        website: get(rawData, 'website'),
        regulationName: get(rawData, ':regulation.name'),
        regulation: get(rawData, ':regulation.id'),
        inviter: get(rawData, ':inviter.id'),
        inviterName: get(rawData, ':inviter.name'),
        sale: get(rawData, ':salePerson.id'),
        saleName: get(rawData, ':salePerson.name'),
        saleDetail: get(rawData, ':salePerson'),
        upLevelIb: get(rawData, ':inviter')
    };
};
