import {
    Box,
    Stack,
} from '@chakra-ui/react';
import CustomTab from 'components/CustomTab';
import { useAdminPermissionSettings } from 'hooks/datahook/adminPermission';
import { useAuth } from 'hooks/useAuth';
import {find, get, last, split, trim} from 'lodash';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet, useMatches, useNavigate} from 'react-router-dom';
import {adminPaths, paths} from 'routes/path';

const SystemMonitorLayout = () => {
    const {t} = useTranslation('app');
    const matches = useMatches();
    const matchPath = get(last(matches), 'pathname');
    const mainPath = last(split(trim(matchPath, '/'), '/'));
    const navigate = useNavigate();
    const {roles} = useAuth();
    const {getPermission} = useAdminPermissionSettings({
        roles,
    });
    const tabs = [
        {
            label: t('systemScheduledTasks'),
            value: 'systemScheduledTasks',
            path: adminPaths.systemScheduledTasks,
            hasPermission: getPermission(
                'applicationManagement.systemMonitorHome.systemScheduledTasks',
            ),
        },
        // {
        //     label: t('systemStatus'),
        //     value: 'systemStatus',
        //     path: adminPaths.systemStatus,
        //     hasPermission: getPermission(
        //         'applicationManagement.systemMonitorHome.systemStatus',
        //     ),
        // },
        // {
        //     label: t('systemLog'),
        //     value: 'systemLog',
        //     path: adminPaths.systemLog,
        //     hasPermission: getPermission(
        //         'applicationManagement.systemMonitorHome.systemLog',
        //     ),
        // },
        // {
        //     label: t('systemAlert'),
        //     value: 'systemAlert',
        //     path: adminPaths.systemAlert,
        //     hasPermission: getPermission(
        //         'applicationManagement.systemMonitorHome.systemAlert',
        //     ),
        // },
        // {
        //     label: t('systemErrorTracking'),
        //     value: 'systemErrorTracking',
        //     path: adminPaths.systemErrorTracking,
        //     hasPermission: getPermission(
        //         'applicationManagement.systemMonitorHome.systemErrorTracking',
        //     ),
        // },
        
    ].filter((item) => item.hasPermission !== false);
    const value =
        find(tabs, (o) => o.path === matchPath)?.value || tabs[0]?.value;

    return (
        <Stack spacing={4}>
            <CustomTab
                tabs={tabs}
                tabOnly
                value={value}
                onChange={(v) => {
                    navigate(find(tabs, (o) => o.value === v)?.path);
                }}
            />
            <Box width={'100%'}>
                <Outlet />
            </Box>
        </Stack>
    );
};

export default SystemMonitorLayout;
