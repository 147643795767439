import client from 'helper/httpClient';
import {
    useSWRPaginationTemplate,
    useSWRTemplate,
} from 'hooks/dataHooksTemplate';
import {useAuth} from 'hooks/useAuth';
import {get} from 'lodash';
import {cleanObjectNilValue} from 'utils/clean';
import {api} from './common';
import {processReferee} from 'data/referee';

export const useReferees = (options = {}) => {
    const {status = null, name = null, source} = options;
    const {id} = useAuth();
    const swr = useSWRPaginationTemplate({
        key: id ? ['referee', id, status, name, source] : null,
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _id, _status, _name, _source] = _key;
            const {result} = await client.get(
                ['invite', 'user'],
                cleanObjectNilValue({
                    status: _status,
                    username: _name,
                    source: _source,
                    pageSize: _pageSize,
                    pageIndex: _pageIndex - 1,
                }),
            );
            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map(processReferee),
                },
            };
        },
        defaultValue: [],
    });
    return swr;
};

export const useAdminReferees = (options = {}) => {
    const {status, name, user} = options;
    const swr = useSWRPaginationTemplate({
        key: user ? ['admin/referee', user, status, name] : null,
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _id, _status, _name] = _key;
            const {result} = await api().get(
                ['referral', 'referee'],
                cleanObjectNilValue({
                    status: _status,
                    refereeName: _name,
                    pageSize: _pageSize,
                    pageIndex: _pageIndex - 1,
                    user: user,
                }),
            );
            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map(processReferee),
                },
            };
        },
        defaultValue: [],
    });
    return swr;
};

export const initeUser_ = async ({
    type,
    region,
    email,
    phone,
    firstname,
    lastname,
    companyName,
    website,
}) => {
    const {result} = await api().post(
        ['ib', 'invite'],
        cleanObjectNilValue({
            type,
            region,
            email,
            phone,
            firstname,
            lastname,
            companyName,
            website,
        }),
    );
    return result;
};

