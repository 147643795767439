import {switchAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';
import { colorByMode } from 'theme/themeHelper';

const {definePartsStyle, defineMultiStyleConfig} =
    createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle((props) => ({
    // define the part you're going to style
    container: {
        // ...
    },
    thumb: {
        bg: colorByMode('brighterBg', props.colorMode),
    },
    track: {
        bg: 'gray.200',
        _checked: {
            bg: colorByMode('primary', props.colorMode),
        },
    },
}));

export const switchTheme = defineMultiStyleConfig({baseStyle});
