import AUD from 'components/Icon/Currencies/AUD';
import CAD from 'components/Icon/Currencies/CAD';
import EUR from 'components/Icon/Currencies/EUR';
import GBP from 'components/Icon/Currencies/GBP';
import JPY from 'components/Icon/Currencies/JPY';
import NZD from 'components/Icon/Currencies/NZD';
import SGD from 'components/Icon/Currencies/SGD';
import USD from 'components/Icon/Currencies/USD';
import HKD from 'components/Icon/Currencies/HKD';

export const forexSupportCurrency = [
    'USD',
    'AUD',
    'JPY',
    'EUR',
    'GBP',
    'SGD',
    'CAD',
    'NZD',
    'HKD',
];

export const currencyIconMap = {
    USD: USD,
    AUD: AUD,
    JPY: JPY,
    EUR: EUR,
    GBP: GBP,
    SGD: SGD,
    CAD:CAD,
    NZD: NZD,
    HKD: HKD
};
