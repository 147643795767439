import {Box, Stack} from '@chakra-ui/react';
import CustomTab from 'components/CustomTab';
import Page from 'components/Page';
import { useAdminPermissionSettings } from 'hooks/datahook/adminPermission';
import { useAuth } from 'hooks/useAuth';
import {find, get, last, split, trim} from 'lodash';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet, useMatches, useNavigate} from 'react-router-dom';
import {adminPaths, paths} from 'routes/path';

const ApprovalsLayout = () => {
    const {t} = useTranslation('app');
    const matches = useMatches();
    const matchPath = get(last(matches), 'pathname');
    const mainPath = last(split(trim(matchPath, '/'), '/'));
    const navigate = useNavigate();
    const {roles} = useAuth();
    const {getPermission} = useAdminPermissionSettings({
        roles,
    });
    const tabs = [
        {
            label: t('kyc_request'),
            value: 'approvalsKyc',
            path: adminPaths.approvalsKyc,
            hasPermission: getPermission(
                'riskManagement.approvals.approvalsKyc',
            ),
        },
        {
            label: t('account_applications'),
            value: 'approvalsMt45Accounts',
            path: adminPaths.approvalsMt45Accounts,
            hasPermission: getPermission(
                'riskManagement.approvals.approvalsMt45Accounts',
            ),
        },
        {
            label: t('approvalsIbApplications'),
            value: 'approvalsIbApplications',
            path: adminPaths.approvalsIbApplications,
            hasPermission: getPermission(
                'riskManagement.approvals.approvalsIbApplications',
            ),
        },
        {
            label: t('approvalsDebitCards'),
            value: 'approvalsDebitCards',
            path: adminPaths.approvalsDebitCards,
            hasPermission: getPermission(
                'riskManagement.approvals.approvalsIbApplications',
            ),
        },
        {
            label: t('approvalsFxWithdrawals'),
            value: 'approvalsFxWithdrawals',
            path: adminPaths.approvalsFxWithdrawals,
            hasPermission: getPermission(
                'riskManagement.approvals.approvalsFxWithdrawals',
            ),
        },
        {
            label: t('withdrawalAccounts'),
            value: 'approvalsWithdrawalAccount',
            path: adminPaths.approvalsWithdrawalAccount,
            hasPermission: getPermission(
                'riskManagement.approvals.approvalsWithdrawalAccount',
            ),
        },
        {
            label: t('requestWithdrawalWallet'),
            value: 'approvalsWithdrawalWallet',
            path: adminPaths.approvalsWithdrawalWallet,
            hasPermission: getPermission(
                'riskManagement.approvals.approvalsWithdrawalWallet',
            ),
        },
        {
            label: t('exchangeWithdrawals'),
            value: 'approvalsExchangeWithdrawal',
            path: adminPaths.approvalsExchangeWithdrawal,
            hasPermission: getPermission(
                'riskManagement.approvals.approvalsWithdrawalWallet',
            ),
        },
        {
            label: t('requestLeverage'),
            value: 'approvalsLeverage',
            path: adminPaths.approvalsLeverage,
            hasPermission: getPermission(
                'riskManagement.approvals.approvalsWithdrawalWallet',
            ),
        },
    ].filter((item) => item.hasPermission !== false);
    const value =
        find(tabs, (o) => o.path === matchPath)?.value || tabs[0]?.value;

    return (
        <Stack spacing={4}>
            <CustomTab
                tabs={tabs}
                tabOnly
                value={value}
                onChange={(v) => {
                    navigate(find(tabs, (o) => o.value === v)?.path);
                }}
            />
            <Box width={'100%'}>
                <Outlet />
            </Box>
        </Stack>
    );
};

export default ApprovalsLayout;
