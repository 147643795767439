import { find, get, isFunction } from 'lodash';
import React from 'react'
import FormViewWrapper from './FormViewWrapper';
import { Text } from '@chakra-ui/react';

const TextViewer = (props) => {
    const {data, i18n, index, name, text, options, formater} = props;
    const v = get(data, name);
    const displayValue = formater
        ? formater(v)
        : options
        ? isFunction(options)
            ? find(options(data), {value: v})?.label || v
            : find(options, {value: v})?.label || v
        : text
        ? text
        : v;
    // console.log(name,v)
    return (
        <FormViewWrapper {...props}>
            <Text fontWeight={'400'}>{displayValue || ''}&nbsp;</Text>
        </FormViewWrapper>
    );
}


export default TextViewer

