// import CountryIcon from 'components/common/CountryIcon';
import {
    Box,
    Button,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack,
    useBreakpointValue,
} from '@chakra-ui/react';
import {find} from 'lodash';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import CountryIcon from './CountryIcon';
import {ChevronDownIcon} from '@chakra-ui/icons';

const OXLanguageBtn = (props) => {
    const {noShadow} = props;
    const {t, i18n} = useTranslation('app');

    const languageList = [
        {
            label: t('englishConsist'),
            value: 'en-US',
            county: 'US',
        },
        {
            label: t('chineseConsist'),
            value: 'zh-CN',
            county: 'CN',
        },
        {
            label: t('chineseTWConsist'),
            value: 'zh-TW',
            county: 'CN',
        },
        {
            label: t('japaneseConsist'),
            value: 'jp-JP',
            county: 'JP',
        },
    ];
    const matched = find(languageList, (o) => o.value === i18n.language);
    const isMobile = useBreakpointValue({
        base: true,
        md: false,
    });
    return (
        <Menu>
            <MenuButton
                size={isMobile ? 'sm' : 'md'}
                border={'1px'}
                borderColor={'gray.200'}
                as={Button}
                aria-label="Options"
                // icon={<CountryIcon country={matched.county} size={24} />}
                rightIcon={<ChevronDownIcon />}
                leftIcon={<CountryIcon country={matched.county} size={24} />}
                boxShadow={'none'}
                variant={'ghost'}
                bg={'white'}
                px={4}
            >
                {matched.label}
            </MenuButton>
            <MenuList>
                {languageList.map((item) => (
                    <MenuItem
                        onClick={() => i18n.changeLanguage(item.value)}
                        key={item.value}
                        icon={<CountryIcon country={item.county} size={24} />}
                    >
                        {item.label}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    );
};

export default OXLanguageBtn;
