import React from 'react';
import {Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Center, HStack, Heading, Stack, Text} from '@chakra-ui/react';
import {ErrorBoundary} from 'react-error-boundary';
import {useTranslation} from 'react-i18next';
import { useColorModeValueKey } from 'hooks/useColors';

export const ComponentError = (props) => {
    const {error, resetErrorBoundary} = props;
    const {t} = useTranslation('app');
    return (
        <Center minH={'400px'}>
            <Stack alignItems={'center'} spacing={4}>
                <Stack spacing={2} alignItems={'center'}>
                    <Heading
                        display="inline-block"
                        as="h2"
                        size="xl"
                        // bgGradient="linear(to-r, light.primary.400, light.primary.600)"
                        // backgroundClip="text"
                        color={useColorModeValueKey('primary')}
                    >
                        {t('Oops')}
                    </Heading>
                    <Text color={useColorModeValueKey('gray')} mb={6}>
                        {t('wrongHappenedDesc')}
                    </Text>
                </Stack>
                {error?.message && 
                <Alert status="error">
                    <AlertIcon />
                    <AlertDescription>
                        {t('error')}: {error?.message}
                    </AlertDescription>
                </Alert>
                }
                <Button
                    variant={'primary'}
                    onClick={() => {
                        location.reload();
                    }}
                    size="sm"
                >
                    {t('refresh')}
                </Button>
            </Stack>
        </Center>
    );
};

const SafeLoadingComponent = (props) => {
    const {children} = props;
    return (
        <ErrorBoundary FallbackComponent={ComponentError}>
            {children}
        </ErrorBoundary>
    );
};

export default SafeLoadingComponent;
