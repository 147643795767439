import {useEffect, useRef, useState} from 'react';
import {useCalendarState, useRangeCalendarState} from 'react-stately';
import {
    useButton,
    useCalendar,
    useCalendarCell,
    useRangeCalendar,
} from 'react-aria';
import {
    createCalendar,
    isSameMonth,
    getWeeksInMonth,
    endOfMonth,
    CalendarDate,
} from '@internationalized/date';
import {ChevronLeftIcon, ChevronRightIcon} from '@chakra-ui/icons';
import {
    Box,
    Button,
    ButtonGroup,
    Grid,
    GridItem,
    Heading,
    Stack,
    Text,
} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {useCalendarGrid} from 'react-aria';
import {useColorModeValueKey} from 'hooks/useColors';
import {addYears, format, setYear, subYears} from 'date-fns';

const CalendarButton = (props) => {
    const ref = useRef();
    const {buttonProps} = useButton(props, ref);
    return (
        <Button {...buttonProps} ref={ref} size="sm">
            {props.children}
        </Button>
    );
};

const CalendarCell = ({state, date, currentMonth}) => {
    const ref = useRef();
    const {cellProps, buttonProps, isSelected, isInvalid, formattedDate} =
        useCalendarCell({date}, state, ref);

    const isOutsideMonth = !isSameMonth(currentMonth, date);
    return (
        <Box as="td" {...cellProps} textAlign="center">
            <Button
                {...buttonProps}
                ref={ref}
                hidden={isOutsideMonth}
                size={{base: 'xs', md: 'sm'}}
                colorScheme={isInvalid ? 'red' : 'gray'}
                variant={isSelected ? 'primary' : 'ghost'}
                width="100%"
                fontWeight={'normal'}
                color={useColorModeValueKey('text')}
            >
                {formattedDate}
            </Button>
        </Box>
    );
};

export function CalendarGrid({state, offset = {}}) {
    const {i18n} = useTranslation('app');
    const locale = i18n.language;
    // console.log(state.visibleRange);
    const startDate = state.visibleRange.start.add(offset);
    const endDate = endOfMonth(startDate);
    const {gridProps, headerProps, weekDays} = useCalendarGrid(
        {
            startDate,
            endDate,
        },
        state,
    );

    // Get the number of weeks in the month so we can render the proper number of rows.
    const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);
    return (
        <table {...gridProps}>
            <thead {...headerProps}>
                <tr>
                    {weekDays.map((day, index) => (
                        <th key={index}>
                            <Text fontSize={{base: 'xs', md: 'sm'}}>{day}</Text>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
                    <tr key={weekIndex}>
                        {state
                            .getDatesInWeek(weekIndex, startDate)
                            .map((date, i) =>
                                date ? (
                                    <CalendarCell
                                        key={i}
                                        state={state}
                                        date={date}
                                        currentMonth={startDate}
                                    />
                                ) : (
                                    <td key={i} />
                                ),
                            )}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

function getYearsBetween() {
    const thisYear = new Date().getFullYear();
    const startYear = thisYear - 70;
    const endYear = thisYear + 9;
    const years = [];

    for (let i = endYear; i >= startYear; i--) {
        years.push(i);
    }

    return years;
}

export function CalenderYearGrid(props) {
    const {value, state, setYearMode, setMonthMode} = props;
    const years = getYearsBetween();
    const stackRef = useRef(null);
    // console.log(props, value);
    return (
        <Stack h={64} overflow={'scroll'} ref={stackRef}>
            <Grid templateColumns="repeat(4, 1fr)" gap={2}>
                {years.map((year, i) => {
                    const isSelected = value?.year === year;
                    return (
                        <GridItem w="100%" key={year}>
                            <Button
                                size={{base: 'xs', md: 'sm'}}
                                variant={isSelected ? 'primary' : 'ghost'}
                                width="100%"
                                fontWeight={'normal'}
                                color={useColorModeValueKey('text')}
                                onClick={() => {
                                    state?.selectDate(
                                        new CalendarDate(
                                            year,
                                            value?.month || 1,
                                            1,
                                        ),
                                    );
                                    setMonthMode(true);
                                    setYearMode(false);
                                }}
                            >
                                {year}
                            </Button>
                        </GridItem>
                    );
                })}
            </Grid>
        </Stack>
    );
}
export function CalenderMonthGrid(props) {
    const {value, state, setMonthMode, setYearMode} = props;
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    // console.log(state)
    return (
        <Stack h={'160px'} overflow={'scroll'}>
            <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                {months.map((month, i) => {
                    const isSelected = value?.month === month;
                    return (
                        <GridItem w="100%" key={month}>
                            <Button
                                size={{base: 'xs', md: 'sm'}}
                                variant={isSelected ? 'primary' : 'ghost'}
                                width="100%"
                                fontWeight={'normal'}
                                color={useColorModeValueKey('text')}
                                onClick={() => {
                                    state?.selectDate(
                                        new CalendarDate(value?.year, month, 1),
                                    );
                                    state?.setFocusedDate(
                                        new CalendarDate(value?.year, month, 1),
                                    );
                                    setMonthMode(false);
                                    setYearMode(false);
                                }}
                            >
                                {format(new Date(`1970-${month}-1`), 'MMMM')}
                            </Button>
                        </GridItem>
                    );
                })}
            </Grid>
        </Stack>
    );
}

const Calendar = (props) => {
    const {isOpen} = props;
    const {i18n} = useTranslation('app');
    const state = useCalendarState({
        ...props,
        locale: i18n.language,
        createCalendar,
    });

    const ref = useRef();
    const {calendarProps, prevButtonProps, nextButtonProps, title} =
        useCalendar(props, state, ref);
    const [yearMode, setYearMode] = useState(false);
    const [monthMode, setMonthMode] = useState(false);
    return (
        <div {...calendarProps} ref={ref}>
            {props.yearMode ? (
                <CalenderYearGrid
                    state={state}
                    {...props}
                    setYearMode={setYearMode}
                    setMonthMode={setMonthMode}
                />
            ) : monthMode ? (
                <CalenderMonthGrid
                    state={state}
                    {...props}
                    setYearMode={setYearMode}
                    setMonthMode={setMonthMode}
                />
            ) : (
                <>
                    <Box display="flex" alignItems="center" paddingBottom="4">
                        <CalendarButton {...prevButtonProps}>
                            <ChevronLeftIcon w={6} h={6} />
                        </CalendarButton>
                        <Button
                            size="sm"
                            flex={1}
                            onClick={() => setYearMode(true)}
                        >
                            <Heading size="sm" flex="1" textAlign="center">
                                {title}
                            </Heading>
                        </Button>
                        <CalendarButton {...nextButtonProps}>
                            <ChevronRightIcon w={6} h={6} />
                        </CalendarButton>
                    </Box>
                    <CalendarGrid state={state} />
                </>
            )}
        </div>
    );
};

export default Calendar;

export const RangeCalendar = (props) => {
    const {i18n} = useTranslation('app');
    const state = useRangeCalendarState({
        ...props,
        locale: i18n.language,
        createCalendar,
        visibleDuration: {months: 2},
    });

    const ref = useRef();
    const {calendarProps, prevButtonProps, nextButtonProps, title} =
        useRangeCalendar(props, state, ref);

    return (
        <div {...calendarProps} ref={ref}>
            <Box display="flex" alignItems="center" paddingBottom="4">
                <CalendarButton {...prevButtonProps}>
                    <ChevronLeftIcon w={6} h={6} />
                </CalendarButton>
                <Heading
                    size={{base: 'xs', md: 'sm'}}
                    flex="1"
                    textAlign="center"
                >
                    {title}
                </Heading>
                <CalendarButton {...nextButtonProps}>
                    <ChevronRightIcon w={6} h={6} />
                </CalendarButton>
            </Box>
            <Stack
                direction={{base: 'column', md: 'row'}}
                display="flex"
                // gap="2"
            >
                <CalendarGrid state={state} />
                <CalendarGrid state={state} offset={{months: 1}} />
            </Stack>
        </div>
    );
};
