import {Box, Stack} from '@chakra-ui/react';
import CustomTab from 'components/CustomTab';
import Page from 'components/Page';
import {useAdminPermissionSettings} from 'hooks/datahook/adminPermission';
import {useAuth} from 'hooks/useAuth';
import {find, get, last, split, trim} from 'lodash';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet, useMatches, useNavigate} from 'react-router-dom';
import {adminPaths, paths} from 'routes/path';

const TaskboardLayout = () => {
    const {t} = useTranslation('app');
    const matches = useMatches();
    const matchPath = get(last(matches), 'pathname');
    const mainPath = last(split(trim(matchPath, '/'), '/'));
    const navigate = useNavigate();
    const {roles} = useAuth();
    const {getPermission} = useAdminPermissionSettings({
        roles,
    });
    const tabs = [
        // {
        //     label: t('taskboardPendingTasks'),
        //     value: 'taskboardPendingTasks',
        //     path: adminPaths.taskboardPendingTasks,
        //     hasPermission: getPermission('tasks.taskboard.taskboardPendingTasks'),
        // },
        // {
        //     label: t('taskboardKyc'),
        //     value: 'taskboardKyc',
        //     path: adminPaths.taskboardKyc,
        //     hasPermission: getPermission('tasks.taskboard.taskboardKyc'),
        // },
        // {
        //     label: t('taskboardWithdrawalAccount'),
        //     value: 'taskboardWithdrawalAccount',
        //     path: adminPaths.taskboardWithdrawalAccount,
        //     hasPermission: getPermission(
        //         'tasks.taskboard.taskboardWithdrawalAccount',
        //     ),
        // },
        // {
        //     label: t('taskboardWithdrawalAccount'),
        //     value: 'taskboardWithdrawalAccount',
        //     path: adminPaths.taskboardWithdrawalAccount,
        //     hasPermission: getPermission(
        //         'tasks.taskboard.taskboardWithdrawalAccount',
        //     ),
        // },
        {
            label: t('taskboardDeposits'),
            value: 'taskboardDeposits',
            path: adminPaths.taskboardDeposits,
            hasPermission: getPermission('tasks.taskboard.taskboardDeposits'),
        },
        {
            label: t('taskboardWithdrawals'),
            value: 'taskboardWithdrawals',
            path: adminPaths.taskboardWithdrawals,
            hasPermission: getPermission(
                'tasks.taskboard.taskboardWithdrawals',
            ),
        },
        {
            label: t('taskboardTransfer'),
            value: 'taskboardTransfer',
            path: adminPaths.taskboardTransfer,
            hasPermission: getPermission(
                'tasks.taskboard.taskboardTransfer',
            ),
        },
        // {
        //     label: t('requestLeverage'),
        //     value: 'taskboardLeverage',
        //     path: adminPaths.taskboardLeverage,
        //     hasPermission: getPermission(
        //         'tasks.taskboard.taskboardLeverage',
        //     ),
        // },
        {
            label: t('requestPassword'),
            value: 'taskboardPassword',
            path: adminPaths.taskboardPassword,
            hasPermission: getPermission(
                'tasks.taskboard.taskboardPassword',
            ),
        },
        {
            label: t('taskboardGeneral'),
            value: 'taskboardGeneral',
            path: adminPaths.taskboardGeneral,
            hasPermission: getPermission('tasks.taskboard.taskboardGeneral'),
        },
    ].filter((item) => item.hasPermission !== false);
    const value =
        find(tabs, (o) => o.path === matchPath)?.value || tabs[0]?.value;

    return (
        <Stack spacing={4}>
            <CustomTab
                tabs={tabs}
                tabOnly
                value={value}
                onChange={(v) => {
                    navigate(find(tabs, (o) => o.value === v)?.path);
                }}
            />
            <Box width={'100%'}>
                <Outlet />
            </Box>
        </Stack>
    );
};

export default TaskboardLayout;
