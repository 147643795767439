import React, {useState} from 'react';
import Brochure, {printDocument} from 'components/Brochure';
import {useTranslation} from 'react-i18next';
import {useCopyToClipboard} from 'react-use';
import {useAuth} from 'hooks/useAuth';
import {get} from 'lodash';
import useCustomToast from 'hooks/useCustomToast';
import CustomDialog from './CustomDialog';
import {
    Button,
    Center,
    FormControl,
    FormHelperText,
    FormLabel,
    HStack,
    Spinner,
    Stack,
    Text,
    useToast,
} from '@chakra-ui/react';
import {
    addUserHDWallet_,
    useClientHDWallet,
} from 'hooks/datahook/hdwallet';
import {useColorModeValueKey} from 'hooks/useColors';
import {getErrorMessage} from 'utils/error';
import ClientHDWallets from 'components/ClientHDWallets';
import StatusTag from 'components/StatusTag';
import {useNavigate} from 'react-router-dom';
import {paths} from 'routes/path';

const ClientHDWalletDialog = (props) => {
    const {open, onClose, cryptoOnly} = props;
    const {t} = useTranslation('app');
    const navigate = useNavigate();
    const {
        data: clientWallet,
        isLoading: isLoadingClientWallet,
        mutate,
    } = useClientHDWallet({request: open});

    return (
        <CustomDialog
            open={open}
            onClose={onClose}
            title={t('cryptoWallet')}
            actions={<HStack spacing={2}></HStack>}
            size={{base: 'sm', md: '2xl'}}
        >
            <Stack spacing={4}>
                <ClientHDWallets cryptoOnly={cryptoOnly} />
                {clientWallet.result && (
                    <>
                        <FormControl>
                            <FormLabel>{t('cryptoDepositTo')}</FormLabel>
                            <HStack>
                                <Text>
                                    {get(clientWallet, 'data.fxAccount')
                                        ? `FX ${get(
                                              clientWallet,
                                              'data.:fxAccount.login',
                                          )}`
                                        : t('noneYet')}
                                </Text>
                                {get(clientWallet, 'data.fxAccount') && (
                                    <StatusTag
                                        status={get(
                                            clientWallet,
                                            'data.:fxAccount.status',
                                        )}
                                    />
                                )}
                                {/* {(!get(clientWallet, 'data.fxAccount') ||
                                    get(
                                        clientWallet,
                                        'data.:fxAccount.status',
                                    ) !== 'activated') && ( */}
                                <Button
                                    size={'xs'}
                                    variant={'primary'}
                                    onClick={() => {
                                        navigate(paths.profileCryptoWallet);
                                        onClose();
                                    }}
                                >
                                    {t('set')}
                                </Button>
                                {/* )} */}
                            </HStack>
                            {/* <FormHelperText
                                color={useColorModeValueKey('gray')}
                                fontSize={'xs'}
                            >
                                {t('associatedAccountHelper')}
                            </FormHelperText> */}
                        </FormControl>
                        <Text
                            color={useColorModeValueKey('gray')}
                            fontSize={'xs'}
                        >
                            {t('minimalDepositHelper')}
                        </Text>
                    </>
                )}
            </Stack>
        </CustomDialog>
    );
};

export default ClientHDWalletDialog;
