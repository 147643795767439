import {Icon} from '@chakra-ui/react';
import React, {memo} from 'react';

const JPY = (props) => {
    return (
        <Icon size="md" {...props}>
            <svg
                viewBox="0 0 256 256"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M113.9 10.6999C78.5 14.9999 46.7 35.1999 27.8 65.3999C8.19998 96.6999 4.49998 136.8 18 171C34.1 211.8 70.9 240.1 114.4 245.2C146.2 248.9 178.9 239.2 203.6 218.7C239.8 188.5 254.5 140.1 241.3 94.3999C235.5 74.2999 223 54.2999 207.4 40.3999C188.6 23.5999 166.4 13.4999 141.7 10.6999C134.5 9.7999 120.9 9.7999 113.9 10.6999ZM140 34.3999C171.9 38.4999 199.3 58.4999 213.3 87.6999C223.5 109.2 225.1 134.5 217.6 157.4C209.8 181.3 192.2 201.4 169.3 212.6C136.3 228.7 96.7 224.1 68.2 200.8C63.7 197.1 56.2 189.2 52.8 184.8C26.9 150 27.5 102.8 54.3 69.1999C58 64.5999 65.7 56.9999 70.2 53.4999C83 43.4999 99.2 36.6999 115.4 34.4999C122 33.5999 133.3 33.5999 140 34.3999Z"
                    fill="currentColor"
                />
                <path
                    d="M81.6 79.1999C81.2 79.8999 80.9 80.5999 81.1 80.8999C81.2 81.1999 89.2 90.4999 98.9 101.6L116.4 121.6V124.1V126.6H106.3C96.5 126.6 94.8 126.8 93.9 128.2C93.7 128.5 93.5 131.5 93.5 134.8C93.5 143.2 92.6 142.6 105.9 142.8L116.3 142.9V146.9V150.8H105.8C99.5 150.8 94.9 151 94.4 151.3C93.6 151.8 93.5 152.4 93.5 158.3C93.5 167.2 92.8 166.7 106.1 166.7H116.3V177.4V188.2L117.5 189.3C118.6 190.5 118.8 190.5 126.7 190.5C132.3 190.5 135 190.3 135.3 189.9C135.7 189.5 135.9 186.2 135.9 178V166.7H147.3C153.5 166.7 159 166.6 159.4 166.4C160.1 166.2 160.1 165 160.1 158.5V150.9L148.1 150.8L136.1 150.7L136 146.8L135.9 143H147.3C153.5 143 159 142.9 159.4 142.7C160.1 142.5 160.1 141.3 160.1 134.8C160.1 128.3 160 127.2 159.4 126.9C159 126.8 153.5 126.6 147.3 126.6H135.9V124.1V121.7L155.2 101.3C166 89.8999 174.5 80.5999 174.5 80.0999C174.5 78.2999 173.7 78.0999 163.9 78.0999H154.5L141 91.6999L127.6 105.3L114.6 91.6999L101.5 78.0999H92H82.3L81.6 79.1999Z"
                    fill="currentColor"
                />
            </svg>
        </Icon>
    );
};

export default memo(JPY);
