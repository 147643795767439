import {inputAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';
import {colorByMode} from 'theme/themeHelper';
const {definePartsStyle, defineMultiStyleConfig} =
    createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
    // define the part you're going to style
    field: {
        fontFamily: 'mono', // change the font family
        color: 'teal.500', // change the input text color
    },
});
const Input = defineMultiStyleConfig({
    // The styles all button have in common
    baseStyle: {},
    // sizes: {},
    variants: {
        outline: (props) => {
            return {
                field: {
                    borderColor: colorByMode('border', props.colorMode),
                    rounded: 'md',
                    _hover: {
                        borderColor: 'gray.400',
                    },
                    _dark: {
                        borderColor: colorByMode('border', props.colorMode),
                        _hover: {
                            borderColor: 'gray.300',
                        },
                        _focus: {
                            boxShadow: `0 0 0 1px ${colorByMode(
                                'primary',
                                props.colorMode,
                            )}`,
                            borderColor: colorByMode(
                                'primary',
                                props.colorMode,
                            ),
                        },
                    },
                    _focus: {
                        boxShadow: `0 0 0 1px ${colorByMode(
                            'primary',
                            props.colorMode,
                        )}`,
                        borderColor: colorByMode('primary', props.colorMode),
                    },
                },
            };
        },
        filled: (props) => {
            return {
                field: {
                    rounded: 'md',
                    backgroundColor: colorByMode('brighterBg', props.colorMode),
                    _hover: {
                        backgroundColor: props.colorMode==='light'?'gray.100':"gray.700",
                    },
                    _focus: {
                        backgroundColor: colorByMode(
                            'brighterBg',
                            props.colorMode,
                        ),
                    },
                },
            };
        },
    },
    // // The default size and variant values
    defaultProps: {},
});

export default Input;
