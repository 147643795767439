import {
    Box,
    Stack,
} from '@chakra-ui/react';
import CustomTab from 'components/CustomTab';
import { useAdminPermissionSettings } from 'hooks/datahook/adminPermission';
import { useAuth } from 'hooks/useAuth';
import {find, get, last, split, trim} from 'lodash';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet, useMatches, useNavigate} from 'react-router-dom';
import {adminPaths, paths} from 'routes/path';

const FinanceLayout = () => {
    const {t} = useTranslation('app');
    const matches = useMatches();
    const matchPath = get(last(matches), 'pathname');
    const mainPath = last(split(trim(matchPath, '/'), '/'));
    const navigate = useNavigate();
    const {roles} = useAuth();
    const {getPermission} = useAdminPermissionSettings({
        roles,
    });
    const tabs = [
        {
            label: t('financeSummary'),
            value: 'financeSummary',
            path: adminPaths.financeSummary,
            hasPermission: getPermission('finance.financeSummary'),
        },
        {
            label: t('financeTransaction'),
            value: 'financeTransaction',
            path: adminPaths.financeTransaction,
            hasPermission: getPermission('finance.financeTransaction'),
        },
        {
            label: t('financeLedge'),
            value: 'financeLedge',
            path: adminPaths.financeLedge,
            hasPermission: getPermission('finance.financeLedge'),
        },
        {
            label: t('financeExpenses'),
            value: 'financeExpenses',
            path: adminPaths.financeExpenses,
            hasPermission: getPermission('finance.financeExpenses'),
        },
        {
            label: t('financeRequests'),
            value: 'financeRequests',
            path: adminPaths.financeRequests,
            hasPermission: getPermission('finance.financeRequests'),
        }
    ].filter((item) => item.hasPermission !== false);
    const value =
        find(tabs, (o) => o.path === matchPath)?.value ||  tabs[0]?.value;

    return (
        <Stack spacing={4}>
            <CustomTab
                tabs={tabs}
                tabOnly
                value={value}
                onChange={(v) => {
                    navigate(find(tabs, (o) => o.value === v)?.path);
                }}
            />
            <Box width={'100%'}>
                <Outlet />
            </Box>
        </Stack>
    );
};

export default FinanceLayout;
