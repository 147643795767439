import {defineStyle, defineStyleConfig} from '@chakra-ui/react';

const baseStyle = defineStyle((props) => {
    return {
        mb: 1,
        fontSize:'sm'
    };
});

export const FormLabel = defineStyleConfig({
    baseStyle,
});
