import React from 'react';
import {Box, Avatar} from '@chakra-ui/react';
import {hasFlag} from 'country-flag-icons';
import * as CountryIcons from 'country-flag-icons/react/1x1';
import {iconInfo} from 'utils/icon';
// import { ReactComponent as ReactLogo } from 'cryptocurrency-icons/svg/color/btc.svg'

const defaultParams = {
    // type: 'country',
    size: 14,
};

function RenderIconLayout(props) {
    const {size, children} = props;
    return (
        <Box
            borderRadius={'50%'}
            overflow={'hidden'}
            height={size}
            width={size}
            sx={{display: 'flex', border: '1px solid rgba(204, 204, 204, .5)'}}
        >
            {children}
        </Box>
    );
}

function RenderInitialAvatar(code, iconSize, children) {
    if (children) {
        return <Avatar icon={children} size={iconSize} overflow='hidden' />;
    }
    return <Avatar size={iconSize} name={code}>{code.slice(0, 1)}</Avatar>;
}

const Icon = (props) => {
    const {code, size, initials = true, type} = props;

    const iconSize = size || 'sm';
    if (!code) return null;
    if (!type) initials && RenderInitialAvatar(code, iconSize);
    if (!['country', 'cryptocurrency', 'url'].includes(type))
        initials && RenderInitialAvatar(code, iconSize);

    if (type === 'url') {
        const children = <img src={code} alt={code} />;
        return initials && RenderInitialAvatar(code, iconSize, children);
    }

    if (type === 'country') {
        let currentCode = code === 'EU_EZ' ? 'EU' : code;
        const check = hasFlag(currentCode.toUpperCase());
        if (check) {
            const IconComponent = CountryIcons[currentCode];
            const children = <IconComponent />;
            return initials && RenderInitialAvatar(code, iconSize, children);
        }
    }

    // if (type === 'cryptocurrency') {
    //     try {
    //         const source =
    //             code === 'DECODE'
    //                 ? require('assets/DECODE-icon.png')
    //                 : require('cryptocurrency-icons/svg/color/' + code.toLowerCase() + '.svg');
    //         const children = (
    //             <RenderIconLayout size={iconSize}>
    //                 {/*<ReactLogo />*/}
    //                 <img src={source} alt={code} />
    //             </RenderIconLayout>
    //         );
    //         return initials && RenderInitialAvatar(code, iconSize, children);
    //     } catch (error) {
    //         return initials && RenderInitialAvatar(code, iconSize);
    //     }
    // }

    return initials && RenderInitialAvatar(code, iconSize);
};

const IconByCodeString = (props) => {
    const {codeStr, ...other} = props;
    const {code, type} = iconInfo(codeStr);
    return <Icon {...other} code={code} type={type} />;
};

export default Icon;

export const IconByCodeStr = IconByCodeString;
