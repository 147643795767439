import PropTypes from 'prop-types';
import {useState, useEffect} from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';

import {useAuth} from 'hooks/useAuth';
import {useAuthStore} from 'stores/authStore';
import {adminPaths, authPaths, paths} from 'routes/path';
import {useAdminPermissionSettings} from 'hooks/datahook/adminPermission';
import {Center, Spinner} from '@chakra-ui/react';
import {get, last, split, trim} from 'lodash';
import {useNavigate, useMatches} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Stack, Box, Text, HStack, Button} from '@chakra-ui/react';
import CustomTab from 'components/CustomTab';
import {find} from 'lodash';
import DateSelector from 'components/DateSelector';
import Selector from 'components/Selector';
import {hasAllSourceList} from 'page/app/ConstantGroup';
import {addMonths} from 'date-fns';
import {cleanObjectNilValue} from 'utils/clean';
import {
    formatMonthDataAndYear,
    currencyFormatorWithDecimal,
} from 'utils/formator';
import {useTotalUnrealisedPnl} from 'hooks/datahook/managementReport';
import { format, parseISO } from 'date-fns';
// ----------------------------------------------------------------------

export default function ManagementLayout() {
    const {t} = useTranslation('app');
    const [year, setYear] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date(format(parseISO(new Date().toISOString()), 'yyyy-MM-01')));
    const [endDate, setEndDate] = useState(new Date());
    const [source, setSource] = useState('All');
    const [book, setBook] = useState(null);
    const [currentPage, setCurrentPage] = useState('closed');
    const [isDetail, setIsDetail] = useState(false)
    const [yearClosedPnl, setYearClosedPnl] = useState(0)
    const [currentMonthClosedPnl, setCurrentMonthClosedPnl] = useState(0)
    const [selectClosedPnl, setSelectClosedPnl] = useState(0)
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const {data, isLoading} = useTotalUnrealisedPnl({
        startDate,
        endDate,
        year: year.getFullYear(),
        source: source === 'All' ? null : source,
    });

    useEffect(() => {
        navigate(pathname, {
            state: cleanObjectNilValue({
                startDate: startDate
                    ? formatMonthDataAndYear(startDate.toISOString())
                    : null,
                endDate: endDate
                    ? formatMonthDataAndYear(endDate.toISOString())
                    : null,
                year: year?.getFullYear(),
                source: source === 'All' ? null : source,
                bookType: book,
            }),
        });
    }, [startDate, endDate, year, source, book, pathname]);

    useEffect(()=>{
        setYearClosedPnl(get(data, 'yearClosedPnl', 0)||yearClosedPnl)
        setCurrentMonthClosedPnl(get(data, 'currentMonthClosedPnl', 0)||currentMonthClosedPnl)
        setSelectClosedPnl(get(data, 'selectClosedPnl', 0)||selectClosedPnl)
    }, [isLoading])

    useEffect(() => {
        const paths = pathname.split('/');
        setCurrentPage(paths[paths.length - 1]);
        if(paths.includes('pnl')){
            setIsDetail(true)
        }
    }, []);

    return (
        <Stack spacing={4}>
            <HStack spacing={4} alignItems="center">
                <HStack spacing={4} w={700} alignItems="center">
                    <Stack textAlign={'center'} spacing={0} flex={1} mb={-2}>
                        <Text fontSize={'sm'}>
                            {t('closedPnlSelectedDates')}
                        </Text>
                        {(isLoading||selectClosedPnl === null) ? (
                            <Center>
                                <Spinner />
                            </Center>
                        ) : (
                            <Text
                                fontSize={21}
                                color={
                                    get(data, 'selectClosedPnl') > 1
                                        ? '#10B02B'
                                        : '#FF0505'
                                }
                            >
                                {currencyFormatorWithDecimal(
                                    (get(data, 'selectClosedPnl')||selectClosedPnl) / 1000,
                                    '',
                                ) +
                                    `${
                                        get(data, 'selectClosedPnl') ? ' K' : ''
                                    }`}
                            </Text>
                        )}
                    </Stack>
                    <Stack textAlign={'center'} spacing={0} flex={1} mb={-2}>
                        <Text fontSize={'sm'}>
                            {t('closedPnlCurrentMonth')}
                        </Text>
                        {(isLoading||currentMonthClosedPnl === null) ? (
                            <Center>
                                <Spinner />
                            </Center>
                        ) : (
                            <Text
                                fontSize={21}
                                color={
                                    get(data, 'currentMonthClosedPnl') > 1
                                        ? '#10B02B'
                                        : '#FF0505'
                                }
                            >
                                {currencyFormatorWithDecimal(
                                    (get(data, 'currentMonthClosedPnl')||currentMonthClosedPnl) / 1000,
                                    '',
                                ) +
                                    `${
                                        get(data, 'currentMonthClosedPnl')
                                            ? ' K'
                                            : ''
                                    }`}
                            </Text>
                        )}
                    </Stack>
                    <Stack textAlign={'center'} spacing={0} flex={1} mb={-2}>
                        <Text fontSize={'sm'}>{t('closedPnlYearToDate')}</Text>
                        {(isLoading||yearClosedPnl === null) ? (
                            <Center>
                                <Spinner />
                            </Center>
                        ) : (
                            <Text
                                fontSize={21}
                                color={
                                    get(data, 'yearClosedPnl') > 1
                                        ? '#10B02B'
                                        : '#FF0505'
                                }
                            >
                                {currencyFormatorWithDecimal(
                                    (get(data, 'yearClosedPnl')||yearClosedPnl) / 1000,
                                    '',
                                ) + `${get(data, 'yearClosedPnl') ? ' K' : ''}`}
                            </Text>
                        )}
                    </Stack>
                </HStack>
                <Stack>
                    <HStack spacing={4}>
                        <HStack flex={1}>
                            <Text fontSize={'xs'}>{t('year')}: </Text>
                            <DateSelector
                                value={year}
                                onChangeValue={setYear}
                                granularity={'year'}
                                yearMode
                                variant="filled"
                            />
                        </HStack>
                        <HStack flex={2}>
                            <Text fontSize={'xs'} w={69}>
                                {t('dateRange')}:
                            </Text>
                            <DateSelector
                                value={startDate}
                                onChangeValue={setStartDate}
                                granularity={'day'}
                                maxDate={new Date()}
                                placeholder={t('startDate')}
                                variant="filled"
                            />
                            <DateSelector
                                value={endDate}
                                onChangeValue={setEndDate}
                                granularity={'day'}
                                maxDate={new Date()}
                                placeholder={t('endDate')}
                                variant="filled"
                            />
                        </HStack>
                        <HStack flex={1}>
                            <Text fontSize={'xs'}>{t('source')}:</Text>
                            <Selector
                                options={hasAllSourceList}
                                value={source}
                                onChangeValue={setSource}
                                allowClear
                                variant="filled"
                                allowClear={false}
                            />
                        </HStack>
                        <HStack flex={1}>
                            <Text fontSize={'xs'}>{t('bookType')}:</Text>
                            <Selector
                                options={[
                                    {
                                        label: t('A-Book'),
                                        value: 'A-Book',
                                        label: t('B-Book'),
                                        value: 'B-Book',
                                    },
                                ]}
                                value={book}
                                onChangeValue={setBook}
                                variant="filled"
                            />
                        </HStack>
                    </HStack>
                    <HStack
                        w={'100%'}
                        spacing={4}
                        justifyContent="space-between"
                    >
                        {isLoading ? (
                            <Center>
                                <Spinner />
                            </Center>
                        ) : (
                            <HStack>
                                <Box display={'flex'} flexDirection={'row'}>
                                    <Text fontSize={'xs'} mr={1}>
                                        {t('totalClosedLots')}:{' '}
                                    </Text>
                                    {isLoading ? (
                                        <Center>
                                            <Spinner />
                                        </Center>
                                    ) : (
                                        <Text
                                            fontSize={'xs'}
                                            fontWeight={'bold'}
                                            color={'#10B02B'}
                                        >
                                            {currencyFormatorWithDecimal(
                                                get(
                                                    data,
                                                    'lotsData.Total',
                                                    0,
                                                ) || 0,
                                                '',
                                            )}
                                        </Text>
                                    )}
                                </Box>
                                <Box display={'flex'} flexDirection={'row'}>
                                    <Text fontSize={'xs'} mr={1}>
                                        {t('MT4s1')}:{' '}
                                    </Text>
                                    {isLoading ? (
                                        <Center>
                                            <Spinner />
                                        </Center>
                                    ) : (
                                        <Text
                                            fontSize={'xs'}
                                            fontWeight={'bold'}
                                            color={'#10B02B'}
                                        >
                                            {currencyFormatorWithDecimal(
                                                get(
                                                    data,
                                                    'lotsData.MT4s1',
                                                    0,
                                                ) || 0,
                                                '',
                                            )}
                                        </Text>
                                    )}
                                </Box>
                                <Box display={'flex'} flexDirection={'row'}>
                                    <Text fontSize={'xs'} mr={1}>
                                        {t('MT4s2')}:{' '}
                                    </Text>
                                    {isLoading ? (
                                        <Center>
                                            <Spinner />
                                        </Center>
                                    ) : (
                                        <Text
                                            fontSize={'xs'}
                                            fontWeight={'bold'}
                                            color={'#10B02B'}
                                        >
                                            {currencyFormatorWithDecimal(
                                                get(
                                                    data,
                                                    'lotsData.MT4s2',
                                                    0,
                                                ) || 0,
                                                '',
                                            )}
                                        </Text>
                                    )}
                                </Box>{' '}
                                <Box display={'flex'} flexDirection={'row'}>
                                    <Text fontSize={'xs'} mr={1}>
                                        {t('MT4s3')}:{' '}
                                    </Text>
                                    {isLoading ? (
                                        <Center>
                                            <Spinner />
                                        </Center>
                                    ) : (
                                        <Text
                                            fontSize={'xs'}
                                            fontWeight={'bold'}
                                            color={'#10B02B'}
                                        >
                                            {currencyFormatorWithDecimal(
                                                get(
                                                    data,
                                                    'lotsData.MT4s3',
                                                    0,
                                                ) || 0,
                                                '',
                                            )}
                                        </Text>
                                    )}
                                </Box>
                                <Box display={'flex'} flexDirection={'row'}>
                                    <Text fontSize={'xs'} mr={1}>
                                        {t('MT5')}:{' '}
                                    </Text>
                                    {isLoading ? (
                                        <Center>
                                            <Spinner />
                                        </Center>
                                    ) : (
                                        <Text
                                            fontSize={'xs'}
                                            fontWeight={'bold'}
                                            color={'#10B02B'}
                                        >
                                            {currencyFormatorWithDecimal(
                                                get(data, 'lotsData.MT5', 0) ||
                                                    0,
                                                '',
                                            )}
                                        </Text>
                                    )}
                                </Box>
                            </HStack>
                        )}
                        <HStack width={300}>
                            <Selector
                                options={[
                                    {
                                        label: t('pnlAnalysis'),
                                        value: 'closed-pnl',
                                    },
                                    {
                                        label: t('volAnalysis'),
                                        value: 'lots',
                                    },
                                    {
                                        label: t('tradesAnalysis'),
                                        value: 'trades',
                                    },
                                ]}
                                value={currentPage}
                                variant="filled"
                                allowClear={false}
                                onChangeValue={(newValue) => {
                                    setCurrentPage(newValue);
                                    setIsDetail(false)
                                    navigate(
                                        adminPaths.managementReport[newValue],
                                        {
                                            state: cleanObjectNilValue({
                                                startDate: startDate
                                                    ? formatMonthDataAndYear(
                                                          startDate.toISOString(),
                                                      )
                                                    : null,
                                                endDate: endDate
                                                    ? formatMonthDataAndYear(
                                                          endDate.toISOString(),
                                                      )
                                                    : null,
                                                year: year?.getFullYear(),
                                                source:
                                                    source === 'All'
                                                        ? null
                                                        : source,
                                                bookType: book,
                                            }),
                                        },
                                    );
                                }}
                            />
                            <Button
                                size="sm"
                                variant={'primary'}
                                onClick={() => {
                                    if(!isDetail){
                                        setCurrentPage(null)
                                        setIsDetail(true)
                                        navigate(
                                            adminPaths.managementReport.bySymbol,
                                            {
                                                state: cleanObjectNilValue({
                                                    startDate: startDate
                                                        ? formatMonthDataAndYear(
                                                            startDate.toISOString(),
                                                        )
                                                        : null,
                                                    endDate: endDate
                                                        ? formatMonthDataAndYear(
                                                            endDate.toISOString(),
                                                        )
                                                        : null,
                                                    year: year?.getFullYear(),
                                                    source:
                                                        source === 'All'
                                                            ? null
                                                            : source,
                                                    bookType: book,
                                                }),
                                            },
                                        );
                                    }else{
                                        setIsDetail(false)
                                        setCurrentPage('closed-pnl');
                                        navigate(
                                            adminPaths.managementReport.pnl,
                                            {
                                                state: cleanObjectNilValue({
                                                    startDate: startDate
                                                        ? formatMonthDataAndYear(
                                                            startDate.toISOString(),
                                                        )
                                                        : null,
                                                    endDate: endDate
                                                        ? formatMonthDataAndYear(
                                                            endDate.toISOString(),
                                                        )
                                                        : null,
                                                    year: year?.getFullYear(),
                                                    source:
                                                        source === 'All'
                                                            ? null
                                                            : source,
                                                    bookType: book,
                                                }),
                                            },
                                        );
                                    }
                                }}
                            >
                                {isDetail?t('pnlDashboard'):t('detailReports')}
                            </Button>
                        </HStack>
                    </HStack>
                </Stack>
            </HStack>
            <Box width={'100%'}>
                <Outlet />
            </Box>
        </Stack>
    );
}
