import { find } from 'lodash';
import React from 'react';
import FormWrapper from './FormWrapper';
import { Text, useColorModeValue } from '@chakra-ui/react';

const DisplayText = (props) => {
    const {
        formik,
        i18n,
        index,
        name,
        label,
        inputProps,
        textArea = false,
        disabled,
        formater,
        options,
        text,
    } = props;
    const value = formik.values[name];
    const displayValue = formater
        ? formater(value)
        : options
        ? find(options, {value: value})?.label || value
        : text
        ? text
        : value;
    const grayColor = useColorModeValue('gray')
    return (
        <FormWrapper {...props}>
            <Text color={disabled?grayColor:undefined}>{displayValue}</Text>
        </FormWrapper>
    );
};
export default DisplayText;
