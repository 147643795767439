import {
    Avatar,
    Badge,
    Box,
    Flex,
    HStack,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Portal,
    Text,
} from '@chakra-ui/react';
import UserDetailPopoverContent from 'components/UserDetailPopoverContent';

import {get} from 'lodash';
import React, {memo, useCallback, useMemo, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {adminPaths} from 'routes/path';

import {Cell} from 'rsuite-table';
import {formatDate} from 'utils/formator';

const ClientDetailCell = ({rowData, dataKey, style, ...props}) => {
    // console.log(props?.dataKey)
    const obj = dataKey ? get(rowData, dataKey) : rowData;
    const isUser = get(obj, 'roles', []).includes('USER');
    const renderItem = useMemo(() => {
        if (get(obj, 'name')) {
            return (
                <Popover isLazy trigger="hover" openDelay={200}>
                    <PopoverTrigger>
                        <HStack alignItems={'center'}>
                            <Avatar
                                size="xs"
                                height={'20px'}
                                width={'20px'}
                                src={get(obj, 'avatar')}
                                name={get(obj, 'name')}
                            />
                            <Text
                                noOfLines={1}
                                onClick={
                                    isUser
                                        ? (e) => {
                                              e.stopPropagation();
                                            //   navigate(
                                            //       adminPaths.clientDetail(
                                            //           obj.id,
                                            //       ),
                                            //   );
                                            window.open(
                                                adminPaths.clientDetail(
                                                      obj.id,
                                                  ),
                                                '_blank'
                                            )
                                          }
                                        : undefined
                                }
                            >
                                {get(obj, 'name')}
                            </Text>
                        </HStack>
                    </PopoverTrigger>
                    <Portal>
                        <PopoverContent>
                            <PopoverArrow />
                            <PopoverBody>
                                <UserDetailPopoverContent
                                    // profile={obj}
                                    id={obj?.id}
                                />
                            </PopoverBody>
                        </PopoverContent>
                    </Portal>
                </Popover>
            );
        }
    }, [get(obj, 'id'), get(obj, 'name')]);
    return (
        <Cell {...props} style={{...style}}>
            {renderItem}
        </Cell>
    );
};
export default ClientDetailCell;

// export default memo(ClientDetailCell, (prev, next) => {
//     const prevObj = prev.dataKey ? get(prev.rowData, prev.dataKey) : prev.rowData;
//     const nextObj = next.dataKey
//         ? get(next.rowData, next.dataKey)
//         : next.rowData;
//     return prevObj.id === prevObj.id && prevObj.name === nextObj.name && prev.style === next.style;
// });
