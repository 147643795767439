import {defineStyle, defineStyleConfig} from '@chakra-ui/react';
import {colorByMode} from 'theme/themeHelper';

const outline = defineStyle((props) => {
    const {colorScheme: c, colorMode} = props;
    return {
        borderColor: 'gray.300',
        _hover: {
            borderColor: 'gray.400',
        },
        _dark: {
            borderColor: 'gray.500',
            _hover: {
                borderColor: 'gray.400',
            },
            _focusVisible: {
                boxShadow: `0 0 0 1px ${colorByMode('primary', colorMode)}`,
                borderColor: colorByMode('primary', colorMode),
            },
        },
        _focusVisible: {
            boxShadow: `0 0 0 1px ${colorByMode('primary', colorMode)}`,
            borderColor: colorByMode('primary', colorMode),
        },
    };
});

const PinInputTheme = defineStyleConfig({
    variants: {outline},
    defaultProps: {},
});
export default PinInputTheme;
