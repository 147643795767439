import { usePlatformBasicInfo } from 'hooks/datahook/auth';
import { get, last } from 'lodash';
import PropTypes from 'prop-types';
import {Navigate, useMatches} from 'react-router-dom';
import {paths} from 'routes/path';

// ----------------------------------------------------------------------

UnMaintenanceGuard.propTypes = {
    children: PropTypes.node,
};

export default function UnMaintenanceGuard({children}) {
    const {data: platformBasicInfo} = usePlatformBasicInfo();
    if (!platformBasicInfo?.mts){
        return <Navigate to={paths.redirector} />;
    }
    return <>{children}</>;
}
