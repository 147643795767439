import {get} from 'lodash';
import {
    currencyFormator,
    currencyFormatorOfficial,
    formatDateTimeWithTimezone,
    formatDate, currencyFormatorWithDecimal,
} from 'utils/formator';
import {t} from 'i18next';

export const transferReceiptList = ({i18n, data,isClientSide=false}) => {
    return [
        ...(!get(data, 'mtTicket')
            ? []
            : [
                {
                    label: i18n('ticket'),
                    value: get(data, 'mtTicket'),
                },
            ]),
        ...(!get(data, 'ticket')
            ? []
            : [
                {
                    label: i18n('crmTicket'),
                    value: get(data, 'ticket'),
                },
            ]),
        {
            label: i18n('transactionType'),
            value: i18n('transfer'),
        },
        {
            label: i18n('date'),
            value: get(data, 'createdAt')
                ? formatDate(get(data, 'createdAt'))
                : null,
        },
        {
            label: t('fromAccount'),
            value: get(data, 'fromAccount'),
        },
        {
            label: t('fromAmount'),
            value: get(data, 'fromAmount') ? currencyFormatorWithDecimal(
                Math.abs(get(data, 'fromAmount')),
                get(data, 'fromCurrency'),2,true) : '',
        },
        {
            label: t('toAccount'),
            value: get(data, 'toAccount'),
        },
        {
            label: t('toAmount'),
            value: get(data, 'to_amount'),
        },
        {
            label: i18n('status'),
            value: i18n(get(data, 'status')),
        },
        {
            label: i18n('comment'),
            value: get(data, 'comment'),
        },
    ];
};
