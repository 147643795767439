import { Stack, Text, VStack } from '@chakra-ui/react';
import {useColorModeValueKey} from 'hooks/useColors';
import {isFunction} from 'lodash';
import React from 'react';

const SectionHeading = (props) => {
    const {formik, i18n, index, name, label, title, subTitle,...other} = props;
    const renderSubTitle = isFunction(subTitle)
        ? subTitle(formik.values)
        : subTitle;
    const renderTitle = isFunction(title) ? title(formik.values) : title;
    const grayColor = useColorModeValueKey('gray');
    return (
        <Stack {...other} spacing={0} lineHeight={'short'}>
            <Text fontSize="lg">{renderTitle}</Text>
            {renderSubTitle && (
                <Text fontSize={'xs'} color={grayColor} >
                    {renderSubTitle}
                </Text>
            )}
        </Stack>
    );
};

// export default SectionHeading;

export default React.memo(
    SectionHeading,
    (prev, next) =>{
        return (
            prev.subTitle === next.subTitle &&
            prev.title === next.title &&
            prev.i18n === next.i18n
        );
    }
);
