import {get} from 'lodash';
import {
    currencyFormatorOfficial,
    percentageFormator,
    formatDate
} from 'utils/formator';

export const stockProductReceiptList = ({i18n, data,isClientSide}) => {

    return [
        {
            label: i18n('ISIN'),
            value: get(data, 'ISIN'),
        },
        {
            label: i18n('exchangeName'),
            value: get(data, 'exchangeCode'),
        },
        // {
        //     label: i18n('contractFee'),
        //     value: currencyFormatorOfficial(get(data, 'contractFee'),'',2,false),
        // },
        {
            label: i18n('marginPercent'),
            value: percentageFormator(get(data, 'marginPercent')),
        },
        {
            label: i18n('lastDonePrice'),
            value: currencyFormatorOfficial(get(data, 'lastDonePrice'),'',2,false),
        },
        {
            label: i18n('lotSize'),
            value: currencyFormatorOfficial(get(data, 'lotSize')),
        },
        {
            label: i18n('availableQuantity'),
            value: currencyFormatorOfficial(get(data, 'availableQuantity')),
        },
        {
            label: i18n('fee'),
            value: currencyFormatorOfficial(get(data, 'fee'),'',2,false),
        },
        {
            label: i18n('shortSellable'),
            value: get(data, 'shortSellable') ? 'true' : 'false',
        },
        ...(
            !isClientSide ? [
                {
                    label: i18n('boExchangeName'),
                    value: get(data, 'boExchangeCode'),
                },
                {
                    label: i18n('updatedAt'),
                    value: formatDate(get(data, 'statusActivatedTimestamp') || get(data, 'createdAt')),
                },
            ] : []
        ),
        {
            label: i18n('desc'),
            value: get(data, 'securityDescription'),
        },
    ];
};
