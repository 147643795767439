import {get} from 'lodash'
import { formatDateInTimezone } from 'utils/formator';

export const commissionReceiptList = ({i18n, data}) => {
    const date = get(data, 'mtTicket')
        ? get(data, 'statusCompletedTimestamp')
        : get(data, 'updatedAt') || get(data, 'createdAt');
    return [
        ...(!get(data, 'mtTicket')
            ? []
            : [
                  {
                      label: i18n('ticket'),
                      value: get(data, 'mtTicket'),
                  },
              ]),
        ...(!get(data, 'ticket')
            ? []
            : [
                  {
                      label: i18n('crmTicket'),
                      value: get(data, 'ticket'),
                  },
              ]),
        {
            label: i18n('transactionType'),
            value: i18n(get(data, 'type')),
        },
        {
            label: i18n('date'),
            value: date
                ? formatDateInTimezone(date, 'Factory', 'dd/MM/yyyy')
                : null,
        },
        {
            label: i18n('toAccount'),
            value: get(data, 'toAccount'),
        },
        {
            label: i18n('toAmount'),
            value: get(data, 'to_amount'),
        },
        {
            label: i18n('status'),
            value: i18n(get(data, 'status')),
        },
        {
            label: i18n('comment'),
            value: get(data, 'comment'),
        },
    ];
};
