import {processTreeView} from 'data/treeview';
import client, {adminClient} from 'helper/httpClient';
import {useState} from 'react';
import {
    useSWRPaginationTemplate,
    useSWRTemplate,
} from 'hooks/dataHooksTemplate';
import {get, orderBy, result} from 'lodash';
import {cleanObjectNilValue} from 'utils/clean';
import {
    formatMonthYear,
    currencyFormatorDetail,
    currencyFormaterOfficialWithSymbol,
    formatMonthDataAndYear,
    formatMonth,
    currencyFormatorWithDecimal,
    formatDateTimes
} from 'utils/formator';
import {api} from './common';
import {useAuth} from '../useAuth';
import Excel from 'exceljs';
import {addMonths} from 'date-fns';
import {convertSource, adminSourceList} from 'page/app/ConstantGroup';

export const useIbInfo = () => {
    const swr = useSWRTemplate({
        key: ['ib-info'],
        request: async (_) => {
            // const { result } = await adminClient.get(['ib','info'])
            const {result} = {
                result: {
                    ':user': {
                        name: 'Mary Anna',
                    },
                    reference: '1000001818',
                    referralCode: 'HSJKCYS',
                },
            };

            return result;
        },
    });
    return swr;
};

export const useTotalCommission = (options = {}) => {
    const {isUser, id} = useAuth();
    const {date} = options;
    const swr = useSWRTemplate({
        key: isUser && id ? ['total-commission', date, id] : null,
        request: async ([_, _date, _id]) => {
            const {result} = await api().get(
                ['summary', 'commission-user-month'],
                {yearMonth: _date},
            );
            return result;
        },
        defaultValue: {},
    });
    return swr;
};

export const useUserTotalCommission = (options = {}) => {
    const {userId, date} = options;
    const swr = useSWRTemplate({
        key: userId ? ['user-total-commission', date, userId] : null,
        request: async ([_, _date, _userId]) => {
            const {result} = await adminClient.get(
                ['summary', 'commission-user-month'],
                {
                    yearMonth: _date,
                    user: _userId,
                },
            );
            return result;
        },
        defaultValue: {},
    });
    return swr;
};

export const useSubLevel = () => {
    const {isUser, id} = useAuth();
    const swr = useSWRTemplate({
        key: isUser && id ? ['sub-level', id] : null,
        request: async ([_, _id]) => {
            const {result} = await api().get(['ib', 'invitation-count']);
            return result;
        },
        defaultValue: {},
    });
    return swr;
};

export const useUpperIb = () => {
    const {isUser, id} = useAuth();
    const swr = useSWRTemplate({
        key: isUser && id ? ['client/upper-ib', id] : null,
        request: async ([_, _id]) => {
            const {result} = await api().get(['ib', 'upper-ib', _id]);
            return result;
        },
        defaultValue: {},
    });
    return swr;
};

export const useUserSubLevel = (options = {}) => {
    const {userId} = options;
    const swr = useSWRTemplate({
        key: userId ? ['user-user-subLevel', userId] : null,
        request: async ([_, _userId]) => {
            const {result} = await adminClient.get([
                'user',
                _userId,
                'invitation-count',
            ]);
            return result;
        },
        defaultValue: {},
    });
    return swr;
};

export const useSubIbDetail = (options = {}) => {
    const {id} = options;
    const swr = useSWRTemplate({
        key: id ? ['sub-ib-detail', id] : null,
        request: async ([_, _id]) => {
            const {result} = await api().get(
                ['ib', 'detail'],
                cleanObjectNilValue({
                    nodeId: _id,
                }),
            );
            return result;
        },
    });
    return swr;
};

export const useTreeView = (data) => {
    const swr = useSWRTemplate({
        key: data?.id ? ['tree-view'] : null,
        request: async (_) => {
            const {result} = await api().get(['ib', 'tree']);
            return processTreeView(result);
        },
        defaultValue: [],
    });
    return swr;
};

export const useAdminTreeView = (nodeId, renderKey) => {
    const [index, setIndex] = useState(0);
    const swr = useSWRTemplate({
        key: nodeId ? ['tree-view', nodeId, index, renderKey] : null,
        request: async ([_, _nodeId]) => {
            const {result} = await adminClient.get(
                ['ib', 'tree'],
                cleanObjectNilValue({
                    nodeId: _nodeId,
                }),
            );
            return processTreeView(result);
        },
        defaultValue: [],
    });
    return {
        ...swr,
        mutate: async () => {
            setIndex(index + 1);
        },
    };
};

export const useAdminUserTreeView = (data) => {
    const swr = useSWRTemplate({
        key: data?.id ? ['admin-user-tree-view'] : null,
        request: async (_) => {
            const {result} = await api().get(['user', data.id, 'ib-tree']);
            return processTreeView(result);
        },
        defaultValue: [],
    });
    return swr;
};

export const useRebateAccountList = (options = {}) => {
    const {isUser, id} = useAuth();
    const {date} = options;
    const swr = useSWRTemplate({
        key: isUser && id ? ['rebate-account-list', date, id] : null,
        request: async ([_, _date, _id]) => {
            const {result} = await api().get(['summary', 'commission-month'], {
                yearMonth: _date,
            });
            const arr = orderBy(
                result,
                (o) => Number(get(o, 'total') || 0),
                'desc',
            );

            return [
                ...arr.filter((d) => d?.isDefault),
                ...arr.filter((d) => !d?.isDefault),
            ];
        },
        defaultValue: [],
    });
    return swr;
};

export const useMonthlyCommission = (options = {}) => {
    const {isUser} = useAuth();
    const {ib} = options;
    const swr = useSWRPaginationTemplate({
        key: ib ? ['monthly-commissions', ib] : null,
        request: async ([_, _ib], _pageIndex, _pageSize) => {
            const {result} = await api().get(['ib', 'monthly-commissions'], {
                ...(isUser ? {} : {ib: _ib}),
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
            });
            return {
                result: {
                    items: get(result, 'items'),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
        defaultValue: {
            items: [],
            totalItems: 0,
        },
    });
    return swr;
};

export const useUserRebateAccountList = (options = {}) => {
    const {userId, date} = options;
    const swr = useSWRTemplate({
        key: userId ? ['user-rebate-account-list', date, userId] : null,
        request: async ([_, _date, _userId]) => {
            const {result} = await adminClient.get(
                ['summary', 'commission-month'],
                {
                    yearMonth: _date,
                    user: _userId,
                },
            );
            const arr = orderBy(
                result,
                (o) => Number(get(o, 'total') || 0),
                'desc',
            );

            return [
                ...arr.filter((d) => d?.isDefault),
                ...arr.filter((d) => !d?.isDefault),
            ];
        },
        defaultValue: [],
    });
    return swr;
};

export const useClientFxAccounts = () => {
    const swr = useSWRPaginationTemplate({
        key: ['client-forex-accounts'],
        request: async (_, _pageIndex, _pageSize) => {
            // const { result } = await adminClient.get(['ib','info'])
            const {result} = {
                result: {
                    items: new Array(10).fill(0).map((item, index) => {
                        return {
                            accountType: 'MT4 STD',
                            login: '20001100' + index + 1,
                            currency: 'USD',
                            closedOrders: 84612,
                            commissions: 18612,
                        };
                    }),
                    totalItems: 10,
                },
            };
            return {
                result: {
                    items: get(result, 'items'),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
    });
    return swr;
};

export const useUserClosedOrders = ({accountId}) => {
    const swr = useSWRPaginationTemplate({
        key: accountId ? ['client-forex-accounts', accountId] : null,
        request: async (_, _pageIndex, _pageSize) => {
            const [_key, _accountId] = _;
            const query = {
                accountId: _accountId,
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
            };
            const {result} = await adminClient.get(
                ['fx', 'close-order'],
                cleanObjectNilValue(query),
            );
            return {
                result: {
                    items: get(result, 'items'),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
    });
    return swr;
};

export const useSubPendingClients = () => {
    const swr = useSWRPaginationTemplate({
        key: ['sub-pending-clients'],
        request: async (_, _pageIndex, _pageSize) => {
            // const { result } = await adminClient.get(['ib','info'])
            const {result} = {
                result: {
                    items: new Array(6).fill(0).map((item, index) => {
                        return {
                            ':user': {
                                id: 10045,
                                name: 'test ib' + index + 1,
                                email: 'testib' + index + 1 + '@gmail.com',
                            },
                            profitRate: 0.3,
                            agentId: 100001918,
                            status: 'pending',
                        };
                    }),
                    totalItems: 6,
                },
            };
            return {
                result,
            };
        },
    });
    return swr;
};

export const useSelectMonth = () => {
    const swr = useSWRTemplate({
        key: ['select-month'],
        request: async (_) => {
            // const { result } = await adminClient.get(['ib','info'])
            const result = [
                {
                    label: formatMonthYear(new Date().toISOString()),
                    value: new Date().toISOString(),
                },
            ];
            return result;
        },
        defaultValue: [],
    });
    return swr;
};

export const setDefaultRebate_ = async (id, data) => {
    const res = await api().put(['fx', 'account', id], data);
    return res;
};

export const applyAsIb_ = async (acceptTerms, currency) => {
    const res = await client.post(['ib', 'apply'], {
        acceptTerms: acceptTerms,
        currency: currency,
    });
    return res;
};

const checkLength = (str) => {
    if (str) {
        if (str.length >= 3) return str;
        if (str.length < 3) return null;
    }
    return null;
};

export const useAdminIBList = (options = {}) => {
    const {
        reference,
        name,
        fxAccount,
        includeClient,
        parent,
        ibCode,
        email,
        referralCode,
    } = options;
    const swr = useSWRPaginationTemplate({
        key:
            includeClient !== undefined
                ? [
                      'admin/ib-list',
                      checkLength(reference),
                      checkLength(name),
                      checkLength(fxAccount),
                      includeClient,
                      parent,
                      ibCode,
                      checkLength(email),
                      checkLength(referralCode),
                  ]
                : null,
        request: async (
            [
                _,
                _reference,
                _name,
                _fxAccount,
                _includeClient,
                _parent,
                _ibCode,
                _email,
                _referralCode,
            ],
            _pageIndex,
            _pageSize,
        ) => {
            const query = {
                reference: _reference,
                username: _name,
                login: _fxAccount,
                includeClient: _includeClient,
                parent: _parent,
                ibCode: _ibCode,
                email: _email,
                referralCode: _referralCode,
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
            };
            const {result} = await api().get(['ib', 'list'], {
                ...cleanObjectNilValue(query),
            });
            return {
                result: {
                    items: get(result, 'items').map((item) => {
                        return {
                            ...item,
                            reference: get(item, ':user.ref'),
                            ibLevel: get(item, ':user.ibLevel'),
                            name: get(item, ':user.name'),
                            regulation: get(item, ':user.regulation'),
                        };
                    }),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
        defaultValue: {items: [], totalItems: 0},
    });
    return swr;
};

export const useIbDetail = (options = {}) => {
    const {nodeId} = options;
    const swr = useSWRTemplate({
        key: nodeId ? ['admin/ib-detail', nodeId] : null,
        request: async ([_, _nodeId]) => {
            const {result} = await api().get(
                ['ib', 'detail'],
                cleanObjectNilValue({
                    nodeId: _nodeId,
                }),
            );
            return result;
        },
        defaultValue: {},
    });
    return {
        ...swr,
        detail: get(swr, 'data.nodeInfo'),
        accounts: get(swr, 'data.fxAccounts'),
    };
};
export const useUserIbDetail = (options = {}) => {
    const {nodeId} = options;
    const swr = useSWRTemplate({
        key: nodeId ? ['client/ib-detail', nodeId] : null,
        request: async ([_, _nodeId]) => {
            const {result} = await api().get(
                ['ib', 'detail'],
                cleanObjectNilValue({
                    nodeId: _nodeId,
                }),
            );
            const childrenName = get(result, 'name').split(' ');
            return {
                ...result,
                childrenName: `${get(childrenName, '[0][0]') || ''}.${
                    get(childrenName, '[1][0]') || ''
                }`,
                commissionRate: get(result, 'commissionRate') || 0.2,
            };
        },
        defaultValue: {},
    });
    return {
        ...swr,
        detail: get(swr, 'data.nodeInfo'),
        accounts: get(swr, 'data.fxAccounts'),
    };
};

export const useAdminAllIBList = (options = {}) => {
    const {search, includeClient} = options;
    const swr = useSWRTemplate({
        key: ['admin/ib-all-list', search, includeClient],
        request: async ([_key, _search, _includeClient]) => {
            const query = {
                username: _search,
                includeClient: _includeClient,
            };
            const {result} = await api().get(
                ['ib', 'list'],
                cleanObjectNilValue(query),
            );
            return result.map((item) => {
                return {
                    ...item,
                    reference: get(item, ':user.ref'),
                    ibLevel: get(item, ':user.ibLevel'),
                    name: get(item, ':user.name'),
                    regulation: get(item, ':user.regulation'),
                    value: get(item, 'id'),
                    label: get(item, ':user.name'),
                };
            });
        },
        defaultValue: [],
    });
    return swr;
};

export const editIBParent = async (data) => {
    const {result} = await api().post(['ib', 'rebuild'], data);
    return result;
};

export const editIBDetail = async (data) => {
    const {result} = await api().post(['ib', 'info'], data);
};
export const editIBCodeDetail_ = async (data) => {
    const {result} = await api().post(['ib', 'code'], data);
};

export const editRewireTo = async (data) => {
    const {result} = await api().post(['fx', 'rewire-to'], data);
};

export const removeRewireTo = async (account) => {
    const {result} = await api().del(['fx', 'rewire-to', account]);
};

export const useIBCodeUpdateAffectCounter = (options = {}) => {
    const {sourceNodeId} = options;
    const swr = useSWRTemplate({
        key: sourceNodeId
            ? ['admin/ib-update-affect-number', sourceNodeId]
            : null,
        request: async ([_key, _sourceNodeId]) => {
            const {result} = await await api().get(['ib', 'code'], {
                sourceNodeId: _sourceNodeId,
            });
            return result;
        },
        defaultValue: {},
    });
    return swr;
};

export const setSubLevelGroups = async (nodeId, data) => {
    const result = await api().post(['ib', 'info'], {
        sourceNodeId: Number(nodeId),
        ...data,
    });
    return result;
};

export const getSublevelTrades = async (query) => {
    const {result} = await api().get(
        ['ib', 'trades'],
        cleanObjectNilValue(query),
    );
    return result;
};

export const downloadSublevelTrades = async (
    mt4 = [],
    mt5 = [],
    mt4s2 = [],
    detail = {},
    startDate,
    endDate,
) => {
    const workbook = new Excel.Workbook();
    const columns = [
        {header: 'IB name', key: 'ibName'},
        {header: 'IB email', key: 'ibEmail'},
        {header: 'Trader account', key: 'traderAccount'},
        {header: 'Group', key: 'group'},
        {header: 'Leverage', key: 'leverage'},
        {header: 'Trader email', key: 'traderEmail'},
        {header: 'Trader status code', key: 'traderStatusCode'},
        {header: 'Rewire to account', key: 'rewireTo'},
        {header: 'Symbol', key: 'symbol'},
        {header: 'Type', key: 'cmd'},
        {header: 'Lots', key: 'lots'},
        {header: 'Profits', key: 'profits'},
        {header: 'Commissions', key: 'commissions'},
        {header: 'Agent of trading account', key: 'agentAccount'},
    ];
    let sheet, sheet2, sheet3;

    if (mt4.length) {
        sheet = workbook.addWorksheet('MT4s1');
        sheet.columns = columns;
        for (const grant of mt4) {
            const row = [];
            for (const col of sheet.columns) {
                switch (col.key) {
                    case 'ibName':
                        row.push(grant.ibName);
                        break;
                    case 'ibEmail':
                        row.push(grant.ibEmail);
                        break;
                    case 'traderAccount':
                        row.push(grant.login);
                        break;
                    case 'group':
                        row.push(grant.group);
                        break;
                    case 'leverage':
                        row.push(get(grant, 'leverage'));
                        break;
                    case 'traderEmail':
                        row.push(grant.email);
                        break;
                    case 'traderStatusCode':
                        row.push(grant.status);
                        break;
                    case 'rewireTo':
                        row.push(grant.rewireTo);
                        break;
                    case 'symbol':
                        row.push(grant.symbol);
                        break;
                    case 'cmd':
                        row.push(grant.cmd ? 'Sell' : 'Buy');
                        break;
                    case 'lots':
                        row.push(
                            currencyFormaterOfficialWithSymbol(grant.volume),
                        );
                        break;
                    case 'profits':
                        row.push(
                            currencyFormaterOfficialWithSymbol(grant.profit),
                        );
                        break;
                    case 'commissions':
                        row.push(
                            currencyFormaterOfficialWithSymbol(
                                grant.commission,
                            ),
                        );
                        break;
                    case 'agentAccount':
                        row.push(grant.agentAccount);
                        break;
                }
            }
            sheet.addRow(row);
        }
        sheet.insertRow(1, {
            ibName: `From date: ${formatMonthDataAndYear(startDate || '')}`,
            ibEmail: `To date: ${formatMonthDataAndYear(endDate)}`,
        });
        sheet.insertRow(2, {});
    }
    if (mt5.length) {
        sheet2 = workbook.addWorksheet('MT5');
        sheet2.columns = columns;
        for (const grant of mt5) {
            const row = [];
            for (const col of sheet2.columns) {
                switch (col.key) {
                    case 'ibName':
                        row.push(grant.ibName);
                        break;
                    case 'ibEmail':
                        row.push(grant.ibEmail);
                        break;
                    case 'traderAccount':
                        row.push(grant.login);
                        break;
                    case 'group':
                        row.push(grant.group);
                        break;
                    case 'leverage':
                        row.push(get(grant, 'leverage'));
                        break;
                    case 'traderEmail':
                        row.push(grant.email);
                        break;
                    case 'traderStatusCode':
                        row.push(grant.status);
                        break;
                    case 'rewireTo':
                        row.push(grant.rewireTo);
                        break;
                    case 'symbol':
                        row.push(grant.symbol);
                        break;
                    case 'cmd':
                        row.push(grant.cmd ? 'Sell' : 'Buy');
                        break;
                    case 'lots':
                        row.push(
                            currencyFormaterOfficialWithSymbol(grant.volume),
                        );
                        break;
                    case 'profits':
                        row.push(
                            currencyFormaterOfficialWithSymbol(grant.profit),
                        );
                        break;
                    case 'commissions':
                        row.push(
                            currencyFormaterOfficialWithSymbol(
                                grant.commission,
                            ),
                        );
                        break;
                }
            }
            sheet2.addRow(row);
        }
        sheet2.insertRow(1, {
            ibName: `From date: ${formatMonthDataAndYear(startDate || '')}`,
            ibEmail: `To date: ${formatMonthDataAndYear(endDate)}`,
        });
        sheet2.insertRow(2, {});
    }
    if (mt4s2.length) {
        sheet3 = workbook.addWorksheet('MT4s2');
        sheet3.columns = columns;
        for (const grant of mt4s2) {
            const row = [];
            for (const col of sheet3.columns) {
                switch (col.key) {
                    case 'ibName':
                        row.push(grant.ibName);
                        break;
                    case 'ibEmail':
                        row.push(grant.ibEmail);
                        break;
                    case 'traderAccount':
                        row.push(grant.login);
                        break;
                    case 'group':
                        row.push(grant.group);
                        break;
                    case 'leverage':
                        row.push(get(grant, 'leverage'));
                        break;
                    case 'traderEmail':
                        row.push(grant.email);
                        break;
                    case 'traderStatusCode':
                        row.push(grant.status);
                        break;
                    case 'rewireTo':
                        row.push(grant.rewireTo);
                        break;
                    case 'symbol':
                        row.push(grant.symbol);
                        break;
                    case 'cmd':
                        row.push(grant.cmd ? 'Sell' : 'Buy');
                        break;
                    case 'lots':
                        row.push(
                            currencyFormaterOfficialWithSymbol(grant.volume),
                        );
                        break;
                    case 'profits':
                        row.push(
                            currencyFormaterOfficialWithSymbol(grant.profit),
                        );
                        break;
                    case 'commissions':
                        row.push(
                            currencyFormaterOfficialWithSymbol(
                                grant.commission,
                            ),
                        );
                        break;
                }
            }
            sheet3.addRow(row);
        }
        sheet3.insertRow(1, {
            ibName: `From date: ${formatMonthDataAndYear(startDate || '')}`,
            ibEmail: `To date: ${formatMonthDataAndYear(endDate)}`,
        });
        sheet3.insertRow(2, {});
    }

    const buffer = await workbook.xlsx.writeBuffer();
    let a = window.document.createElement('a');
    a.href = URL.createObjectURL(
        new Blob([buffer], {type: 'application/vnd.openxmlformats'}),
    );
    a.download = `${get(detail, 'name')}(${get(detail, 'ibCode')}) ${
        mt5.length ? 'MT5' : 'MT4'
    } sublevel trades.xlsx`;
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
};

export const useIBReportsDailyCommissions = (options = {}) => {
    const {ib, source, client, account, startDate, endDate} = options;
    const {isUser} = useAuth();
    const swr = useSWRPaginationTemplate({
        key: ib
            ? [
                  'admin/ib-report-daily-commissions',
                  ib,
                  source,
                  client,
                  account,
                  startDate,
                  endDate,
              ]
            : null,
        request: async (
            [_key, _ib, _source, _client, _account, _startDate, _endDate],
            _pageIndex,
            _pageSize,
        ) => {
            const {result} = await await api().get(
                ['ib', 'daily-commissions'],
                cleanObjectNilValue({
                    ...(isUser ? {} : {ib: _ib}),
                    source: _source,
                    client: _client,
                    account: _account,
                    startDate: _startDate
                        ? formatMonthDataAndYear(_startDate)
                        : null,
                    endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                    pageIndex: _pageIndex - 1,
                    pageSize: _pageSize,
                }),
            );
            return {
                result: {
                    items: get(result, 'items'),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
        defaultValue: {items: [], totalItems: 0},
    });
    return swr;
};

export const useIBReportsSublevelFunds = (options = {}) => {
    const {ib, source, client, account, startDate, endDate, sort} = options;
    const {isUser} = useAuth();
    const swr = useSWRPaginationTemplate({
        key: ib
            ? [
                  'admin/ib-report-sublevel-funds',
                  ib,
                  source,
                  client,
                  account,
                  startDate,
                  endDate,
                  sort,
              ]
            : null,
        request: async (
            [
                _key,
                _ib,
                _source,
                _client,
                _account,
                _startDate,
                _endDate,
                _sort,
            ],
            _pageIndex,
            _pageSize,
        ) => {
            const {result} = await await api().get(
                ['ib', 'sublevel-fund-history'],
                cleanObjectNilValue({
                    ...(isUser ? {} : {ib: _ib}),
                    source: _source,
                    client: _client,
                    account: _account,
                    startDate: _startDate
                        ? formatMonthDataAndYear(_startDate)
                        : null,
                    endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                    sort:
                        _sort === 'withdrawals/desc'
                            ? 'withdrawals/asc'
                            : _sort === 'withdrawals/asc'
                            ? 'withdrawals/desc'
                            : _sort,
                    pageIndex: _pageIndex - 1,
                    pageSize: _pageSize,
                }),
            );
            return {
                result: {
                    items: get(result, 'items').map((item) => {
                        return {
                            ...item,
                            withdrawals: 0 - get(item, 'withdrawals'),
                            clientDetail: {
                                name: get(item, 'name'),
                                id: get(item, 'user'),
                            },
                        };
                    }),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
        defaultValue: {items: [], totalItems: 0},
    });
    return swr;
};

export const useIBReportsSublevelFundsDetails = (options = {}) => {
    const {ib, source, client, account, startDate, endDate, sort, userId} =
        options;
    const {isUser} = useAuth();
    const swr = useSWRPaginationTemplate({
        key:
            ib && userId
                ? [
                      'admin/ib-report-sublevel-funds-details',
                      ib,
                      source,
                      client,
                      account,
                      startDate,
                      endDate,
                      sort,
                      userId,
                  ]
                : null,
        request: async (
            [
                _key,
                _ib,
                _source,
                _client,
                _account,
                _startDate,
                _endDate,
                _sort,
                _userId,
            ],
            _pageIndex,
            _pageSize,
        ) => {
            const {result} = await await api().get(
                ['ib', 'sublevel-fund-history', _userId],
                cleanObjectNilValue({
                    ...(isUser ? {} : {ib: _ib}),
                    source: _source,
                    client: _client,
                    pageIndex: _pageIndex - 1,
                    pageSize: _pageSize,
                }),
            );
            return {
                result: {
                    items: get(result, 'items').map((item) => {
                        return {
                            ...item,
                            clientDetail: {
                                name: get(item, 'name'),
                                id: get(item, 'user'),
                            },
                            toAmount:
                                get(item, 'type') === 'Deposit'
                                    ? get(item, 'toAmount')
                                    : 0 - get(item, 'toAmount'),
                        };
                    }),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
        defaultValue: {items: [], totalItems: 0},
    });
    return swr;
};

export const useIBReportsSublevelAccounts = (options = {}) => {
    const {ib, source, client, account, startDate, endDate, funded} = options;
    const {isUser} = useAuth();
    const swr = useSWRPaginationTemplate({
        key: ib
            ? [
                  'admin/ib-report-sublevel-accounts',
                  ib,
                  source,
                  client,
                  account,
                  startDate,
                  endDate,
                  funded,
              ]
            : null,
        request: async (
            [
                _key,
                _ib,
                _source,
                _client,
                _account,
                _startDate,
                _endDate,
                _funded,
            ],
            _pageIndex,
            _pageSize,
        ) => {
            const {result} = await await api().get(
                ['ib', 'sublevel-accounts'],
                cleanObjectNilValue({
                    ...(isUser ? {} : {ib: _ib}),
                    source: _source,
                    client: _client,
                    account: _account,
                    startDate: _startDate
                        ? formatMonthDataAndYear(_startDate)
                        : null,
                    endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                    funded: _funded === 'yes' ? 1 : _funded === 'no' ? 0 : null,
                    pageIndex: _pageIndex - 1,
                    pageSize: _pageSize,
                }),
            );
            return {
                result: {
                    items: get(result, 'items').map((item) => {
                        return {
                            ...item,
                            clientDetail: {
                                name: get(item, 'name'),
                                id: get(item, 'user'),
                            },
                        };
                    }),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
        defaultValue: {items: [], totalItems: 0},
    });
    return swr;
};

export const useIBReportsSublevelAccountTrades = (options = {}) => {
    const {ib, source, client, account, startDate, endDate, sort, login} =
        options;
    const {isUser} = useAuth();
    const swr = useSWRPaginationTemplate({
        key:
            ib && login
                ? [
                      'admin/ib-report-sublevel-account-trades',
                      ib,
                      source,
                      client,
                      account,
                      startDate,
                      endDate,
                      sort,
                      login,
                  ]
                : null,
        request: async (
            [
                _key,
                _ib,
                _source,
                _client,
                _account,
                _startDate,
                _endDate,
                _sort,
                _login,
            ],
            _pageIndex,
            _pageSize,
        ) => {
            const {result} = await await api().get(
                ['ib', 'closed-trades'],
                cleanObjectNilValue({
                    ...(isUser ? {} : {ib: _ib}),
                    account: _login,
                    source: _source,
                    client: _client,
                    pageIndex: _pageIndex - 1,
                    pageSize: _pageSize,
                }),
            );
            return {
                result: {
                    items: get(result, 'items').map((item) => {
                        return {
                            ...item,
                            clientDetail: {
                                name: get(item, 'name'),
                                id: get(item, 'user'),
                            },
                        };
                    }),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
        defaultValue: {items: [], totalItems: 0},
    });
    return swr;
};

export const useIBReportsAllTrades = (options = {}) => {
    const {ib, source, client, account, startDate, endDate, funded} = options;
    const {isUser} = useAuth();
    const swr = useSWRPaginationTemplate({
        key: ib
            ? [
                  'admin/ib-report-all-trades',
                  ib,
                  source,
                  client,
                  account,
                  startDate,
                  endDate,
                  funded,
              ]
            : null,
        request: async (
            [
                _key,
                _ib,
                _source,
                _client,
                _account,
                _startDate,
                _endDate,
                _funded,
            ],
            _pageIndex,
            _pageSize,
        ) => {
            const {result} = await await api().get(
                ['ib', 'sublevel-trades'],
                cleanObjectNilValue({
                    ...(isUser ? {} : {ib: _ib}),
                    pageIndex: _pageIndex - 1,
                    pageSize: _pageSize,
                }),
            );
            return {
                result: {
                    items: get(result, 'items'),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
        defaultValue: {items: [], totalItems: 0},
    });
    return swr;
};

export const useIBReportsDetailTrades = (options = {}) => {
    const {ib, source, client, account, startDate, endDate, funded, symbol} = options;
    const {isUser} = useAuth();
    const swr = useSWRPaginationTemplate({
        key: ib && symbol
            ? [
                  'admin/ib-report-all-detail-trades',
                  ib,
                  source,
                  client,
                  account,
                  startDate,
                  endDate,
                  funded,
                  symbol
              ]
            : null,
        request: async (
            [
                _key,
                _ib,
                _source,
                _client,
                _account,
                _startDate,
                _endDate,
                _funded,
                _symbol
            ],
            _pageIndex,
            _pageSize,
        ) => {
            const {result} = await await api().get(
                ['ib', 'sublevel-trades', _symbol],
                cleanObjectNilValue({
                    ...(isUser ? {} : {ib: _ib}),
                    login: _account,
                    source: _source,
                    startDate: _startDate
                        ? formatMonthDataAndYear(_startDate)
                        : null,
                    endDate: _endDate ? formatMonthDataAndYear(_endDate) : null,
                    pageIndex: _pageIndex - 1,
                    pageSize: _pageSize,
                }),
            );
            return {
                result: {
                    items: get(result, 'items').map(item=>{
                        return {
                            ...item,
                            clientDetail: {
                                id: get(item, 'user'),
                                name: get(item, 'username')
                            }
                        }
                    }),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
        defaultValue: {items: [], totalItems: 0},
    });
    return swr;
};

export const useIBReportsSublevelAccountDailyLots = (options = {}) => {
    const {ib, source, client, account, startDate, endDate, sort, login} =
        options;
    const {isUser} = useAuth();
    const swr = useSWRPaginationTemplate({
        key:
            ib && login
                ? [
                      'admin/ib-report-sublevel-account-daily-lots',
                      ib,
                      source,
                      client,
                      account,
                      startDate,
                      endDate,
                      sort,
                      login,
                  ]
                : null,
        request: async (
            [
                _key,
                _ib,
                _source,
                _client,
                _account,
                _startDate,
                _endDate,
                _sort,
                _login,
            ],
            _pageIndex,
            _pageSize,
        ) => {
            const {result} = await await api().get(
                ['ib', 'sublevel-account-daily-lots'],
                cleanObjectNilValue({
                    ...(isUser ? {} : {ib: _ib}),
                    account: _login,
                    source: _source,
                    client: _client,
                    pageIndex: _pageIndex - 1,
                    pageSize: _pageSize,
                }),
            );
            return {
                result: {
                    items: get(result, 'items').map((item) => {
                        return {
                            ...item,
                            clientDetail: {
                                name: get(item, 'name'),
                                id: get(item, 'user'),
                            },
                        };
                    }),
                    totalItems: get(result, 'totalItems'),
                },
            };
        },
        defaultValue: {items: [], totalItems: 0},
    });
    return swr;
};

export const useFxCommissions = (options = {}) => {
    const {month} = options;
    const swr = useSWRTemplate({
        key: ['fx-commissions', month],
        request: async ([_, _month]) => {
            const data = [
                {
                    value: Math.random() * 1000,
                    date: addMonths(new Date(), 0).toISOString(),
                },
                {
                    value: Math.random() * 1000,
                    date: addMonths(new Date(), 1).toISOString(),
                },
                {
                    value: Math.random() * 1000,
                    date: addMonths(new Date(), 2).toISOString(),
                },
                {
                    value: Math.random() * 1000,
                    date: addMonths(new Date(), 3).toISOString(),
                },
                {
                    value: Math.random() * 1000,
                    date: addMonths(new Date(), 4).toISOString(),
                },
                {
                    value: Math.random() * 1000,
                    date: addMonths(new Date(), 6).toISOString(),
                },
            ];
            const categories = [];
            const series = [];
            data.forEach((item) => {
                categories.push(formatMonth(item.date));
                series.push(item.value);
            });
            return {
                categories,
                series,
            };
        },
        defaultValue: {},
    });
    return {
        ...swr,
    };
};

export const useTotalCommissions = (options = {}) => {
    const {ib} = options;
    const {isUser} = useAuth();
    const swr = useSWRTemplate({
        key: ib ? ['client-detail/ib/total-commissions', ib] : null,
        request: async ([_, _ib]) => {
            const {result} = await api().get(
                ['ib', 'summary', 'total-commissions'],
                cleanObjectNilValue({
                    ...(isUser ? {} : {ib: _ib}),
                }),
            );
            const categories = [];
            const series = [];
            let data = result.data;
            data = adminSourceList.map((item) => {
                const res = data.find((i) => i.source === item.value);
                if (res) {
                    return res;
                } else {
                    return {
                        source: item.value,
                        amount: 0,
                        currency: 'USD',
                    };
                }
            });
            data.forEach((item) => {
                categories.push(convertSource(item.source));
                series.push(item.amount);
            });
            return {
                categories,
                series,
                total: result.totalAmount,
            };
        },
        defaultValue: {
            categories: [],
            series: [],
            total: 0,
        },
    });
    return {
        ...swr,
    };
};

export const useLiveAccount = (options = {}) => {
    const {ib} = options;
    const {isUser} = useAuth();
    const swr = useSWRTemplate({
        key: ib ? ['live-account', ib] : null,
        request: async ([_, _ib]) => {
            const {result} = await api().get(
                ['ib', 'summary', 'sublevel-clients'],
                cleanObjectNilValue({
                    ...(isUser ? {} : {ib: _ib}),
                }),
            );
            const categories = [];
            const series = [];
            let data = result.data;
            data = adminSourceList.map((item) => {
                const res = data.find((i) => i.source === item.value);
                if (res) {
                    return res;
                } else {
                    return {
                        source: item.value,
                        accounts: 0,
                    };
                }
            });
            data.forEach((item) => {
                categories.push(convertSource(item.source));
                series.push(item.accounts);
            });
            return {
                categories,
                series,
                clients: result.clients || 0,
                accounts: result.accounts || 0,
            };
        },
        defaultValue: {
            categories: [],
            series: [],
            clients: 0,
            accounts: 0,
        },
    });
    return {
        ...swr,
    };
};

export const useSublevelFunds = (options = {}) => {
    const {ib, type = 'net'} = options;
    const {isUser} = useAuth();
    const swr = useSWRTemplate({
        key: ib ? ['sublevel-funds', ib] : null,
        request: async ([_, _ib]) => {
            const {result} = await api().get(
                ['ib', 'summary', 'sublevel-funds'],
                cleanObjectNilValue({
                    ...(isUser ? {} : {ib: _ib}),
                }),
            );
            const withdrawals = [];
            const deposits = [];
            const net = [];
            result.data.forEach((item) => {
                withdrawals.push(0 - item.withdrawals);
                deposits.push(item.deposits);
                net.push(item.net);
            });
            return {
                categories: result.months,
                withdrawals: withdrawals.reverse(),
                deposits: deposits.reverse(),
                net: net.reverse(),
            };
        },
        defaultValue: {
            categories: [],
            withdrawals: [],
            deposits: [],
            net: [],
        },
    });
    return {
        ...swr,
    };
};

export const useSublevelVols = (options = {}) => {
    const {ib} = options;
    const {isUser} = useAuth();
    const swr = useSWRTemplate({
        key: ib ? ['sublevel-vols', ib] : null,
        request: async ([_, _ib]) => {
            const {result} = await api().get(
                ['ib', 'summary', 'sublevel-trade-vols'],
                cleanObjectNilValue({
                    ...(isUser ? {} : {ib: _ib}),
                }),
            );

            const series = [];
            const series2 = [];
            const series3 = [];
            const series4 = [];
            const series5 = [];
            result.months.forEach((item) => {
                const res = result.data.find(
                    (i) => `${i.year}-${i.month}` === item,
                );
                if (res) {
                    const mt4s1 = result.data.find(
                        (i) =>
                            `${i.year}-${i.month}` === item &&
                            i.source === 'MT4',
                    );
                    const mt4s2 = result.data.find(
                        (i) =>
                            `${i.year}-${i.month}` === item &&
                            i.source === 'MT4s2',
                    );
                    const mt4s3 = result.data.find(
                        (i) =>
                            `${i.year}-${i.month}` === item &&
                            i.source === 'MT4s3',
                    );
                    const mt5 = result.data.find(
                        (i) =>
                            `${i.year}-${i.month}` === item &&
                            i.source === 'MT5',
                    );
                    series.push(Number(mt4s1?.volume || 0));
                    series2.push(Number(mt4s2?.volume || 0));
                    series3.push(Number(mt4s3?.volume || 0));
                    series4.push(Number(mt5?.volume || 0));
                    series5.push(
                        (
                            Number(get(mt4s1, 'volume', 0)) +
                            Number(get(mt4s2, 'volume', 0)) +
                            Number(get(mt4s3, 'volume', 0)) +
                            Number(get(mt5, 'volume', 0))
                        ).toFixed(2),
                    );
                } else {
                    series.push(0);
                    series2.push(0);
                    series3.push(0);
                    series4.push(0);
                    series5.push(0);
                }
            });
            return {
                categories: result.months,
                series,
                series2,
                series3,
                series4,
                series5,
            };
        },
        defaultValue: {
            categories: [],
            series: [],
            series2: [],
            series3: [],
            series4: [],
            series5: [],
        },
    });
    return {
        ...swr,
    };
};

export const useCommissions = (options = {}) => {
    const {month, source, ib} = options;
    const {isUser} = useAuth();
    const swr = useSWRTemplate({
        key: ib ? ['commissions', month, source, ib] : null,
        request: async ([_, _month, _source, _ib]) => {
            const query = {
                month: _month,
                source: _source,
                ...(isUser ? {} : {ib: _ib}),
            };
            const {result} = await api().get(
                ['ib', 'summary', 'commissions-trends'],
                cleanObjectNilValue(query),
            );
            if(_month){
                return {
                    data: result?.data?.map((item) => {
                        return {
                            ...item,
                            yearMonth: get(item, 'date'),
                            MT4: get(item, 'MT4', 0),
                            MT4s2: get(item, 'MT4s2', 0),
                            MT4s3: get(item, 'MT4s3', 0),
                            MT5: get(item, 'MT5', 0),
                            amount: get(item, 'Total', 0),
                        };
                    }),
                    month: result?.months,
                };
            }else{
                return {
                    data: result?.data?.map((item) => {
                        return {
                            ...item,
                            yearMonth: get(item, 'date'),
                            MT4: get(item, 'MT4', 0),
                            MT4s2: get(item, 'MT4s2', 0),
                            MT4s3: get(item, 'MT4s3', 0),
                            MT5: get(item, 'MT5', 0),
                            date: formatMonth(new Date(get(item, 'date')).toISOString()),
                            amount: get(item, 'Total', 0),
                        };
                    }).reverse(),
                    month: result?.months,
                };
            }
            // return {
            //     data: result?.months?.map((item) => {
            //         const d = item.split('-')
            //         const resd = d[0] + '-' + (Number(d[1]) >=10 ? d[1] : '0'+d[1])
            //         const res = result.data.find(
            //             (i) => i.date === resd,
            //         );
            //         if(res) {
            //             const mt4s1 = result.data.find(
            //                 (i) => i.date === resd && i.source === 'MT4',
            //             );
            //             const mt4s2 = result.data.find(
            //                 (i) => i.date === resd && i.source === 'MT4s2',
            //             );
            //             const mt4s3 = result.data.find(
            //                 (i) => i.date === resd && i.source === 'MT4s3',
            //             );
            //             const mt5 = result.data.find(
            //                 (i) => i.date === resd && i.source === 'MT5',
            //             );
            //             return {
            //                 yearMonth: resd,
            //                 MT4: get(mt4s1, 'amountUSD', 0),
            //                 MT4s2: get(mt4s2, 'amountUSD', 0),
            //                 MT4s3: get(mt4s3, 'amountUSD', 0),
            //                 MT5: get(mt5, 'amountUSD', 0),
            //                 amount:
            //                     Number(get(mt4s1, 'MT4', 0)) +
            //                     Number(get(mt4s2, 'MT4s2', 0)) +
            //                     Number(get(mt4s3, 'MT4s3', 0)) +
            //                     Number(get(mt5, 'MT5', 0)),
            //             }
            //         }else{
            //             return {
            //                 yearMonth: resd,
            //                 MT4: 0,
            //                 MT4s2: 0,
            //                 MT4s3: 0,
            //                 MT5:0,
            //                 amount: 0,
            //             };
            //         }
            //     }),
            //     month: result?.months,
            // };
        },
        defaultValue: {
            month: [],
            data: [],
        },
    });
    return {
        ...swr,
        monthList: swr?.data?.month?.map((item) => {
            return {
                label: item,
                value: item,
            };
        }),
    };
};

export const downloadDailyCommissions = async (options = {}) => {
    const {source, client, account, startDate, endDate, isUser, ib} = options;
    const {result} = await api().get(
        ['ib', 'daily-commissions'],
        cleanObjectNilValue({
            ...(isUser ? {} : {ib: ib}),
            source: source,
            client: client,
            account: account,
            startDate: startDate ? formatMonthDataAndYear(startDate) : null,
            endDate: endDate ? formatMonthDataAndYear(endDate) : null,
        }),
    );

    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet('Daily commissions');
    sheet.columns = [
        {header: 'Distribution date', key: 'date'},
        {header: 'Ticket', key: 'ticker'},
        {header: 'CRM ticket', key: 'crmTicket'},
        {header: 'Sublevel IB/client', key: 'sublevel'},
        {header: 'Email', key: 'email'},
        {header: 'Trader account', key: 'tradeAccount'},
        {header: 'Trader account group', key: 'tradeAccountGroup'},
        {header: 'Rebate', key: 'rebate'},
        {header: 'Status', key: 'status'},
        {header: 'Commission', key: 'commission'},
    ];

    for (const grant of result) {
        const row = [];
        for (const col of sheet.columns) {
            switch (col.key) {
                case 'date':
                    row.push(formatMonthDataAndYear(get(grant, 'date')));
                    break;
                case 'ticker':
                    row.push(grant.mtTicket);
                    break;
                case 'crmTicket':
                    row.push(grant.ticket);
                    break;
                case 'sublevel':
                    row.push(grant.client);
                    break;
                case 'email':
                    row.push(get(grant, 'email'));
                    break;
                case 'tradeAccount':
                    row.push(
                        `${convertSource(get(grant, 'source', '') || '')}-${
                            get(grant, 'tradeAccount', '') || ''
                        }`,
                    );
                    break;
                case 'tradeAccountGroup':
                    row.push(grant.tradeGroup);
                    break;
                case 'rebate':
                    row.push(
                        `${convertSource(get(grant, 'source', '') || '')}-${
                            get(grant, 'rebateAccount', '') || ''
                        }`,
                    );
                    break;
                case 'status':
                    row.push(grant.status);
                    break;
                case 'commission':
                    row.push(
                        currencyFormatorWithDecimal(
                            grant.toAmount,
                            grant.toCurrency,
                        ),
                    );
                    break;
            }
        }
        sheet.addRow(row);
    }

    const buffer = await workbook.xlsx.writeBuffer();
    let a = window.document.createElement('a');
    a.href = URL.createObjectURL(
        new Blob([buffer], {type: 'application/vnd.openxmlformats'}),
    );
    a.download = `Daily commissions.xlsx`;
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
};

export const downloadSublevelFundHistory = async (options = {}) => {
    const {sort, isUser, ib, client} = options;
    const {result} = await api().get(
        ['ib', 'sublevel-fund-history'],
        cleanObjectNilValue({
            ...(isUser ? {} : {ib: ib}),
            client,
            sort:
                sort === 'withdrawals/desc'
                    ? 'withdrawals/asc'
                    : sort === 'withdrawals/asc'
                    ? 'withdrawals/desc'
                    : sort,
        }),
    );

    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet('Daily commissions');
    sheet.columns = [
        {header: 'Sublevel IB/client', key: 'sublevel'},
        {header: 'Total deposit in USD', key: 'deposit'},
        {header: 'Total withdrawal in USD', key: 'withdrawal'},
        {header: 'Net in USD', key: 'net'},
    ];

    for (const grant of result) {
        const row = [];
        for (const col of sheet.columns) {
            switch (col.key) {
                case 'sublevel':
                    row.push(get(grant, 'name'));
                    break;
                case 'deposit':
                    row.push(
                        currencyFormatorWithDecimal(
                            grant.deposits,
                            '',
                            2,
                            true,
                        ),
                    );
                    break;
                case 'withdrawal':
                    row.push(
                        currencyFormatorWithDecimal(
                            0-grant.withdrawals,
                            '',
                            2,
                            true,
                        ),
                    );
                    break;
                case 'net':
                    row.push(
                        currencyFormatorWithDecimal(grant.net, '', 2, true),
                    );
                    break;
            }
        }
        sheet.addRow(row);
    }

    const buffer = await workbook.xlsx.writeBuffer();
    let a = window.document.createElement('a');
    a.href = URL.createObjectURL(
        new Blob([buffer], {type: 'application/vnd.openxmlformats'}),
    );
    a.download = `Sublevel fund history.xlsx`;
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
};

export const downloadSublevelAccountAndTrades = async (options = {}) => {
    const {source, client, account, startDate, endDate, isUser, ib, funded} = options;
    const {result} = await api().get(
        ['ib', 'sublevel-accounts'],
        cleanObjectNilValue({
            ...(isUser ? {} : {ib: ib}),
            source: source,
            client: client,
            account: account,
            funded: funded === 'yes' ? 1 : funded === 'no' ? 0 : null,
            startDate: startDate ? formatMonthDataAndYear(startDate) : null,
            endDate: endDate ? formatMonthDataAndYear(endDate) : null,
        }),
    );

    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet('Daily commissions');
    sheet.columns = [
        {header: 'Sublevel Client/IB', key: 'sublevel'},
        {header: 'Account num', key: 'account'},
        {header: 'Group', key: 'group'},
        {header: 'Currency', key: 'currency'},
        {header: 'Funded', key: 'funded'},
        {header: 'Balance', key: 'balance'},
        {header: 'Equity', key: 'equity'},
        {header: 'Free margin', key: 'freeMargin'},
        {header: 'Total traded lots', key: 'totalTradedLots'},
        {header: 'Account open date', key: 'accountOpenDate'},
    ];

    for (const grant of result) {
        const row = [];
        for (const col of sheet.columns) {
            switch (col.key) {
                case 'sublevel':
                    row.push(get(grant, 'name'));
                    break;
                case 'account':
                    row.push(`${convertSource(grant.source)}-${grant.login}`);
                    break;
                case 'group':
                    row.push(grant.group);
                    break;
                case 'currency':
                    row.push(grant.currency);
                    break;
                case 'funded':
                    row.push(grant.funded ? 'Yes' : 'No');
                    break;
                case 'balance':
                    row.push(
                        currencyFormatorWithDecimal(
                            get(grant, 'balance'),
                            '',
                            2,
                            true,
                        ),
                    );
                    break;
                case 'equity':
                    row.push(
                        currencyFormatorWithDecimal(
                            get(grant, 'equity'),
                            '',
                            2,
                            true,
                        ),
                    );
                    break;
                case 'freeMargin':
                    row.push(
                        currencyFormatorWithDecimal(
                            grant.marginFree,
                            '',
                            2,
                            true,
                        ),
                    );
                    break;
                case 'totalTradedLots':
                    row.push(
                        currencyFormatorWithDecimal(
                            get(grant, 'lots'),
                            '',
                            2,
                            true,
                        ),
                    );
                    break;
                case 'accountOpenDate':
                    row.push(formatDateTimes(get(grant, 'createdAt')));
                    break;
            }
        }
        sheet.addRow(row);
    }

    const buffer = await workbook.xlsx.writeBuffer();
    let a = window.document.createElement('a');
    a.href = URL.createObjectURL(
        new Blob([buffer], {type: 'application/vnd.openxmlformats'}),
    );
    a.download = `Sublevel accounts and trades.xlsx`;
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
};


export const useClientMapTrend = (options={}, countriesT=()=>{}) => {
    const { ib } = options
    const {isUser} = useAuth()
    return useSWRTemplate({
        key: ib?['clientDetail/ib/total-user', ib]:null,
        request: async ([_, _ib]) => {
            const {result} = await api().get(['ib', 'summary', 'sublevel-clients-region'], cleanObjectNilValue({ 
                ...(isUser ? {} : {ib: _ib}),
             }));
            return {
                totalClients: {
                    count: get(result, 'totalClients.count'),
                    typeData: get(result, 'totalClients.typeData').map(item=>{
                        return {
                            ...item,
                            name: countriesT(get(item, 'region'))
                        }
                    }),
                }
            };
        },
        defaultValue: {},
    });
}

