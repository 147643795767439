import {Box, Tag, TagLabel, useColorMode, useToken} from '@chakra-ui/react';
import StatusTag from 'components/StatusTag';
import {get} from 'lodash';
import React, {useCallback, useMemo} from 'react';
import {Cell} from 'rsuite-table';

const StatusTableCell = ({rowData, dataKey, style, ...props}) => {
    return (
        <Cell {...props} style={{...style}}>
            <StatusTag status={get(rowData, dataKey)} />
        </Cell>
    );
};

export default StatusTableCell;
