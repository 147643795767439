import React from 'react';
import { Outlet } from 'react-router-dom';

const HelpCenterLayout = () => {
    return <div>
        <Outlet/>
    </div>;
};

export default HelpCenterLayout;
