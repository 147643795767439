import React from 'react';
import FormWrapper from './FormWrapper';
import {isEmpty} from 'lodash';
import BankSearchInput from 'components/BankSearchInput';

const FormBankSearchInput = (props) => {
    const {
        formik,
        i18n,
        index,
        name,
        inputProps,
        disabled,
        readOnly,
        placeholder,

        bankSearchType,
        stateName,
        cityName,
        countryName,
        postcodeName,
        line1Name,
        branchName,
        bankName,
        CustomInput
    } = props;
    const value = formik.values[name];
    const onSelectBankInfo = (v) => {
        if (!isEmpty(v)) {
            if (stateName && v.stateISO) {
                formik.setFieldValue(stateName, v.stateISO);
            }
            if (cityName && v.bankCity) {
                formik.setFieldValue(cityName, v.bankCity);
            }
            if (countryName && v.country) {
                formik.setFieldValue(countryName, v.country);
            }
            if (postcodeName && v.bankPostCode) {
                formik.setFieldValue(postcodeName, v.bankPostCode);
            }
            if (line1Name && v.bankAddress) {
                formik.setFieldValue(line1Name, v.bankAddress);
            }
            if (branchName && v.bankBranch) {
                formik.setFieldValue(branchName, v.bankBranch);
            }
            if (bankName && v.bankName) {
                formik.setFieldValue(bankName, v.bankName);
            }
        }
    };
    return (
        <FormWrapper {...props}>
            <BankSearchInput
                isDisabled={disabled}
                isReadOnly={readOnly}
                onChangeText={(value) => formik.setFieldValue(name, value)}
                value={value}
                autoCapitalize="none"
                placeholder={placeholder || i18n('enter')}
                onSelectBankInfo={onSelectBankInfo}
                bankSearchType={bankSearchType}
                CustomInput={CustomInput}
                {...inputProps}
            />
        </FormWrapper>
    );
};

export default FormBankSearchInput;
