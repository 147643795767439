import {adminClient} from 'helper/httpClient';
import {
    useSWRPaginationTemplate,
    useSWRTemplate,
} from 'hooks/dataHooksTemplate';
import {cleanObjectNilValue} from 'utils/clean';
import {get, isNil, keyBy, keys, some, split} from 'lodash';
import {sleep_} from '@genx/july/lib/commonjs/lang';
import {useCallback, useMemo} from 'react';
import {allPermissions, permissionSettings} from 'constant/permissions';
import {processUserProfile} from 'data/userProfile';

export const useAllPermissions = () => {
    const swr = useSWRTemplate({
        key: ['allSystemPermissions'],
        request: async ([_]) => {
            await sleep_(1000);
            return allPermissions;
        },
        defaultValue: [],
    });
    return swr;
};

const processSub = (item) => {
    if (item?.sub) {
        return {
            ...item,
            sub: keyBy(item.sub.map(processSub), 'key'),
        };
    } else {
        return item;
    }
};

export const useAdminPermissionSettings = (options = {}) => {
    const {roles = []} = options;
    // const swr = useSWRTemplate({
    //     key: ['permissionSettings'],
    //     request: async ([_]) => {
    //         // await sleep_(1000);
    //         return permissionSettings;
    //     },
    //     defaultValue: {},
    //     config: {
    //         revalidateOnFocus: false,
    //         revalidateOnReconnect: false,
    //         refreshInterval:0
    //     },
    // });
    const swr = {
        data: permissionSettings,
        isLoading: false,
        isValidating:false,
        error:null
    };

    const searchOptPermission = useMemo(
        () =>
            keys(get(swr, 'data', {})).reduce((prev, curr) => {
                return {
                    ...prev,
                    [curr]: keyBy(
                        get(swr, `data.${curr}`, []).map(processSub),
                        'key',
                    ),
                };
            }, {}),
        [swr.data],
    );
    return {
        ...swr,
        getPermission: useCallback(
            (key, type = 'read') => {
                return some(
                    roles.map((role) => {
                        const setting = get(searchOptPermission, role);
                        if (isNil(setting)) {
                            return false;
                        }
                        const splitKeys = split(key, '.');
                        const permission = get(
                            setting,
                            `${splitKeys.join('.sub.')}.${type}`,
                            false,
                        );
                        return permission;
                    }),
                );
            },
            [searchOptPermission],
        ),
    };
};

export const useSystemRoles = () => {
    const swr = useSWRTemplate({
        key: ['adminSystemRoles'],
        request: async ([_]) => {
            const {result} = await adminClient.get(['role-type']);
            return result.filter((item) => !['DEVELOPER', 'IT_ADMIN'].includes(item.code));
        },
        defaultValue: [],
    });
    return swr;
};

export const useSystemRolesAccess = () => {
    const swr = useSWRTemplate({
        key: ['adminSystemRolesAccess'],
        request: async ([_]) => {
            await sleep_(1000);
            return permissionSettings;
            // return keys(permissionSettings).map((key) => ({
            //     role: key,
            //     value: permissionSettings[key],
            // }));
        },
        defaultValue: {},
    });
    return swr;
};

export const useAdminSystemUsers = (options = {}) => {
    const {username} = options;
    const swr = useSWRPaginationTemplate({
        key: ['adminSysmteUsers', username],
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _username] = _key;
            const {result} = await adminClient.get(['staff'], {
                username: _username,
                pageIndex: _pageIndex - 1,
                pageSize: _pageSize,
            });
            return {
                result: {
                    totalItems: get(result, 'totalItems', 0),
                    items: get(result, 'items', []).map(processUserProfile),
                },
            };
        },
        defaultValue: [],
    });
    return swr;
};
