import { get } from "lodash"

export const processPlatformAccount = (rawData) => {
    return {
        id: get(rawData, 'id'),
        // status: get(rawData, 'status'),
        enable: Boolean(get(rawData, 'enable')),
        routeType: get(rawData, 'routeType'),
        method: get(rawData, 'routeType'),
        currency: get(rawData, 'currency'),
        routeCode: get(rawData, 'routeCode'),
        accountNumber: get(rawData, 'accountNumber'),
        bankAddress: get(rawData, 'bankAddress'),
        bankName: get(rawData, 'bankName'),
        companyAddress: get(rawData, 'companyAddress'),
        companyName: get(rawData, 'companyName'),
        contactEmail: get(rawData, 'contactEmail'),
        isLocal: get(rawData, 'type')==='L',
        isInternational: get(rawData, 'type')==='I',
        createdAt: get(rawData, 'createdAt'),
        rawData,
    };
}