import { Text, VStack } from '@chakra-ui/react';
import {useColorModeValueKey} from 'hooks/useColors';
import {isFunction} from 'lodash';
import React from 'react';

const ViewerSectionHeading = (props) => {
    const {data, i18n, index, name, label, title, subTitle, ...other} = props;
    const renderSubTitle = isFunction(subTitle) ? subTitle(data) : subTitle;
    const renderTitle = isFunction(title) ? title(data) : title;
    return (
        <VStack {...other}>
            <Text fontSize="lg">{renderTitle}</Text>
            {renderSubTitle && (
                <Text fontSize={'xs'} color={grayColor}>
                    {renderSubTitle}
                </Text>
            )}
        </VStack>
    );
};

export default ViewerSectionHeading;
