import {
    Button,
    Center,
    FormControl,
    FormLabel,
    HStack,
    Icon,
    IconButton,
    Spinner,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react';
import {
    addUserHDWallet_,
    useClientHDWallet,
    useClientPlatformHDWalletStatus,
} from 'hooks/datahook/hdwallet';
import {useColorModeValueKey} from 'hooks/useColors';
import useCustomToast from 'hooks/useCustomToast';
import {get} from 'lodash';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IoCopy} from 'react-icons/io5';
import QRCode from 'react-qr-code';
import {useCopyToClipboard} from 'react-use';
import {getErrorMessage} from 'utils/error';
import CryptoIcon from './CryptoIcon';
import {HiStop, HiStopCircle} from 'react-icons/hi2';

const ClientHDWallets = (props) => {
    const {cryptoOnly} = props;
    const {t} = useTranslation('app');
    const [loading, setLoading] = useState(false);
    const toast = useCustomToast();
    const {
        data: clientWallet,
        isLoading: isLoadingClientWallet,
        mutate,
    } = useClientHDWallet();

    const {data: status, isLoading: isLoadingPlatformStatus} =
        useClientPlatformHDWalletStatus();
    const [state, copyToClipboard] = useCopyToClipboard();
    const onCopyLink = (value) => {
        copyToClipboard(value);
        toast.show({title: t('copied')});
    };

    const onCreateWallet_ = async () => {
        try {
            setLoading(true);
            await addUserHDWallet_();
            await mutate();
        } catch (error) {
            toast.show({
                title: t('createFailed'),
                status: 'error',
                description: getErrorMessage(error),
            });
        } finally {
            setLoading(false);
        }
    };

    const wallets = [
        {
            network: 'ETH',
            address: get(clientWallet, `data.ETH`) || '',
            minimal: get(status, `ETHMinimal`) || 0,
            enable: get(status, `ETH`) || false,
            currencyIcon: 'ETH',
        },
        {
            network: 'TRON',
            address: get(clientWallet, `data.TRON`) || '',
            minimal: get(status, `TRONMinimal`) || 0,
            enable: get(status, `TRON`) || false,
            currencyIcon: 'TRX',
        },
    ].filter(
        (item) =>
            item.enable && (cryptoOnly ? item.network === cryptoOnly : true),
    );
    const errorColor = useColorModeValueKey('error')
    return (
        <Center>
            <VStack alignItems={'center'} justifyContent={'center'}>
                <Icon as={HiStopCircle} color={errorColor} boxSize={'8'} />
                <Text textAlign={'center'}>
                    {
                        'Under maintenance. Please do not deposit in your wallet. Please use USDT@NACE Pay instead.'
                    }
                </Text>
            </VStack>
        </Center>
    );

    return (
        <Stack spacing={4} flex={1}>
            {isLoadingClientWallet || isLoadingPlatformStatus ? (
                <Center>
                    <Spinner />
                </Center>
            ) : status.enable ? (
                clientWallet.result ? (
                    <Stack>
                        <Stack
                            direction={{base: 'column', md: 'row'}}
                            alignItems={{base: 'stretch', md: 'center'}}
                        >
                            {wallets.map((item, index) => (
                                <FormControl key={index}>
                                    <FormLabel>
                                        <HStack justifyContent={'center'}>
                                            <Text>{item.network}</Text>
                                            <CryptoIcon
                                                crypto={item.currencyIcon}
                                                size={18}
                                            />
                                        </HStack>
                                    </FormLabel>
                                    <Stack alignItems={'center'} spacing={0}>
                                        <QRCode
                                            value={item.address}
                                            size={200}
                                        />
                                        <HStack alignItems={'center'}>
                                            <Text
                                                pt={1}
                                                color={useColorModeValueKey(
                                                    'gray',
                                                )}
                                                fontSize={'xs'}
                                            >
                                                {item.address}
                                            </Text>
                                            <IconButton
                                                icon={<Icon as={IoCopy} />}
                                                onClick={() =>
                                                    onCopyLink(item.address)
                                                }
                                                size="2xs"
                                            />
                                        </HStack>
                                        <Text>
                                            {t('minimalDeposit')}:{' '}
                                            <Text as="span" fontWeight={'600'}>
                                                USDT {item.minimal}
                                            </Text>
                                        </Text>
                                    </Stack>
                                </FormControl>
                            ))}
                        </Stack>
                    </Stack>
                ) : (
                    <Stack alignItems={'center'}>
                        <Text textAlign={'center'} fontSize={'lg'}>
                            {t('HDWalletTitle')}
                        </Text>
                        <Text
                            textAlign={'center'}
                            color={useColorModeValueKey('gray')}
                            fontSize={'xs'}
                        >
                            {t('HDWalletDescription', {
                                ETHMinimal: get(status, `ETHMinimal`) || 0,
                                TronMinimal: get(status, `TRONMinimal`) || 0,
                            })}
                        </Text>

                        <Button
                            size="sm"
                            variant={'primary'}
                            onClick={onCreateWallet_}
                            loading={loading}
                        >
                            {t('enable')}
                        </Button>
                    </Stack>
                )
            ) : (
                <Center>
                    <Text textAlign={'center'}>
                        {t('PlatformNotEnableHDWalletYet')}
                    </Text>
                </Center>
            )}
        </Stack>
    );
};

export default ClientHDWallets;
