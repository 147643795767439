import create from 'zustand';
import {persist} from 'zustand/middleware';
import appConfig from '../config/app';

export const userHdAlertStore = create(
    persist(
        (set, get) => ({
            showHDAlertDialog: false,
            hasShowHDAlertDialog: false,
            setShowHDAlertDialog: (value) =>
                set(() => ({showHDAlertDialog: value})),
            setHasShowHDAlertDialog: (value) =>
                set(() => ({hasShowHDAlertDialog: value})),
        }),
        {name: `${appConfig.storePrefix}-hdAlertStore`},
    ),
);
