import {useAuth} from 'hooks/useAuth';
import PropTypes from 'prop-types';
import {Navigate} from 'react-router-dom';
import {paths} from 'routes/path';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
    children: PropTypes.node,
};

export default function GuestGuard({children}) {
    const {isLoggedIn} = useAuth();

    if (isLoggedIn) {
        return <Navigate to={paths.redirector} />;
    }

    return <>{children}</>;
}
