import {ChevronLeftIcon, ChevronRightIcon} from '@chakra-ui/icons';
import {
    HStack,
    IconButton,
    Input,
    NumberInput,
    NumberInputField,
    Text,
    useBreakpointValue,
} from '@chakra-ui/react';
import {useColorModeValueKey} from 'hooks/useColors';
import {isNumber, max, range} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

const SIBLING_COUNT = 2;

const CustomPagination = (props) => {
    const {
        totalPage,
        page,
        onChange,
        pageSize,
        totalItems,
        loading,
        variant = 'mini',
    } = props;
    const {t} = useTranslation('app');
    const [jumpPage, setJumpPage] = useState(page);
    const primaryColor = useColorModeValueKey('primary');
    // console.log(page, totalPage, pageSize, totalItems);
    const leftList = range(Math.max(1, page - SIBLING_COUNT), page);
    const rightList = range(
        page + 1,
        Math.min(totalPage + 1, page + 1 + SIBLING_COUNT),
    );
    const list = [...leftList, page, ...(totalPage < page ? [] : rightList)];
    const isMobile = useBreakpointValue({
        base: true,
        md: false,
    });

    useEffect(() => {
        if (jumpPage != page) {
            setJumpPage(page);
        }
    }, [page]);

    useEffect(() => {
        if (page > totalPage) {
            onChange(1);
        }
    }, [page, totalPage]);

    // console.log(
    //     page + 1,
    //     Math.min(totalPage + 1, page + 1 + SIBLING_COUNT),
    //     rightList,
    // );
    return (
        <HStack alignItems={'center'}>
            <Text fontSize={'sm'} color={useColorModeValueKey('gray')}>
                {t('paginFooter', {
                    from: Math.min((page - 1) * pageSize + 1, totalItems),
                    to: Math.min(page * pageSize, totalItems),
                    totalItems,
                })}
            </Text>
            {variant === 'full' && !isMobile && (
                <HStack>
                    <NumberInput
                        value={jumpPage}
                        onChange={(v) => setJumpPage(Number(v))}
                        onBlur={(e) => {
                            const v = Number(e.target.value);
                            if (v <= 1) {
                                onChange(1);
                            } else if (v >= totalPage) {
                                onChange(totalPage);
                            } else {
                                onChange(v);
                            }
                        }}
                        variant={'outline'}
                        size={'sm'}
                        min={1}
                        max={totalPage}
                        step={1}
                        rounded={'md'}
                    >
                        <NumberInputField
                            width={'60px'}
                            paddingInlineEnd={3}
                            rounded={'md'}
                        />
                    </NumberInput>
                    {list.map((i) => (
                        <IconButton
                            key={i}
                            size="sm"
                            onClick={() => onChange(i)}
                            icon={
                                <Text fontSize={'sm'} fontWeight={'400'} px={1}>
                                    {i}
                                </Text>
                            }
                            // isActive={i == page}
                            variant={i == page ? 'primary' : 'ghost'}
                        />
                    ))}
                </HStack>
            )}
            <IconButton
                size="sm"
                isDisabled={page == 1}
                onClick={() => onChange(page - 1)}
                icon={<ChevronLeftIcon boxSize={'5'} />}
            />
            <IconButton
                size="sm"
                isDisabled={page == totalPage}
                onClick={() => {
                    onChange(page + 1);
                }}
                icon={<ChevronRightIcon boxSize={'5'} />}
            />
        </HStack>
    );
};

export default CustomPagination;
