export const jsonViewerTheme = {
    base00: "#1B2B34",
    base01: "#343D46",
    base02: "#4F5B66",
    base03: "#65737E",
    base04: "#A7ADBA",
    base05: "#C0C5CE",
    base06: "#CDD3DE",
    base07: "#D8DEE9",
    base08: "#EC5f67",
    base09: "#F99157",
    base0A: "#FAC863",
    base0B: "#99C794",
    base0C: "#5FB3B3",
    base0D: "#6699CC",
    base0E: "#C594C5",
    base0F: "#AB7967"
};
