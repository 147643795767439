import {Tree} from 'react-arborist';
import {GrAddCircle} from 'react-icons/gr';
import {
    AiOutlineMinusCircle,
    AiOutlineUser,
    AiOutlinePlayCircle,
} from 'react-icons/ai';
import {HiOutlineUserCircle} from 'react-icons/hi';
import {Text, Tooltip, Box, Stack} from '@chakra-ui/react';
import {Button, Icon} from '@chakra-ui/react';
import {IoAddCircleOutline} from 'react-icons/io5';
import {useState, useEffect} from 'react';
import {get} from 'lodash';
// hooks
import {useColorModeValueKey} from 'hooks/useColors';
import {t} from 'i18next';
import numeral from 'numeral';
import ClientDetailPopover from './ClientDetailPopover';
import {percentageFormator} from '../utils/formator';

export default ({
    data = [],
    setSubIbDetail = () => {},
    setNodeId = () => {},
    h,
    isClient = false,
}) => {
    const [selectIb, setSelectIb] = useState(null);
    const onSelect = (nodes) => {
        if (isClient) {
            if (nodes[0]?.data?.disabled) {
                return;
            }
        }
        if (nodes[0]) {
            setSelectIb(nodes[0]?.data);
            setSubIbDetail(nodes[0]?.data);
            setNodeId(nodes[0]?.data?.id);
        }
        return;
    };
    useEffect(() => {
        setSelectIb(data?.[0]);
        setSubIbDetail(data?.[0]);
        setNodeId(data?.[0]?.id);
    }, [data?.[0]?.id]);
    return (
        <Tree
            data={data}
            onSelect={onSelect}
            width={'100%'}
            rowHeight={30}
            selection={get(selectIb, 'id')}
            height={h || document.body.clientHeight - 120}
        >
            {isClient ? NodeClient : Node}
        </Tree>
    );
};
function NodeClient({node, style, dragHandle, tree}) {
    const count = style.paddingLeft / 24;
    /* This node instance can do many things. See the API reference. */
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                position: 'relative',
                ...style,
            }}
            ref={dragHandle}
        >
            {new Array(count).fill(0).map((item, index) => {
                return (
                    <Box
                        sx={{
                            position: 'absolute',
                            left: index * 24 + 15 + 'px',
                            top: 0,
                            height: '100%',
                            width: '1px',
                            borderLeft: '1px dashed',
                            borderColor: useColorModeValueKey('border'),
                        }}
                        key={index}
                    ></Box>
                );
            })}

            <Stack>
                <Text
                    display={'flex'}
                    alignItems="center"
                    height="100%"
                    _hover={{
                        backgroundColor: node.data.disabled
                            ? ''
                            : useColorModeValueKey('bg'),
                    }}
                    cursor={node.data.disabled ? 'no-drop' : ''}
                    bg={
                        !node.data.disabled
                            ? node.isSelected
                                ? useColorModeValueKey('bg')
                                : ''
                            : ''
                    }
                >
                    <Text
                        mx={3}
                        color={
                            node.data.disabled
                                ? useColorModeValueKey('gray')
                                : ''
                        }
                        onClick={() => node.toggle()}
                    >
                        {node.isLeaf ? (
                            <AiOutlinePlayCircle fontSize={13} />
                        ) : node.isOpen ? (
                            <AiOutlineMinusCircle fontSize={13} />
                        ) : (
                            <IoAddCircleOutline fontSize={13} />
                        )}
                    </Text>
                    <Text
                        display={'flex'}
                        flex={1}
                        alignItems="center"
                        color={
                            node.data.disabled
                                ? useColorModeValueKey('gray')
                                : ''
                        }
                    >
                        <AiOutlineUser fontSize={16} />
                        <Box
                            flex={1}
                            display="flex"
                            justifyContent={'space-between'}
                            overflow="hidden"
                            textOverflow={'ellipsis'}
                            whiteSpace="nowrap"
                        >
                            <Text ml={2} fontSize="sm">
                                {node.data.ibLevel === 999
                                    ? node.data.name
                                    : node.data.ibLevel
                                    ? `${node.data.childrenName} ${node.data.isTop?'(IB-'+node.data.ibCode+')': '(IB)'}`
                                    : `${node.data.childrenName} (Client)`}
                            </Text>
                        </Box>
                        <Text fontSize={14} pr={1}>
                            {!node.data.disabled
                                ? node.data.commissionRate
                                    ? percentageFormator(
                                          node.data.commissionRate,
                                      )
                                    : ''
                                : ''}
                        </Text>
                    </Text>
                </Text>
            </Stack>
        </div>
    );
}
function Node({node, style, dragHandle, tree}) {
    const count = style.paddingLeft / 24;
    /* This node instance can do many things. See the API reference. */
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                position: 'relative',
                ...style,
            }}
            ref={dragHandle}
            onClick={() => {
                return;
            }}
        >
            {new Array(count).fill(0).map((item, index) => {
                return (
                    <Box
                        sx={{
                            position: 'absolute',
                            left: index * 24 + 15 + 'px',
                            top: 0,
                            height: '100%',
                            width: '1px',
                            borderLeft: '1px dashed',
                            borderColor: useColorModeValueKey('border'),
                        }}
                        key={index}
                    ></Box>
                );
            })}
            {/* <Tooltip
                label={
                    node.data.disabled
                        ? ''
                        : `${node.data.name} (${node.data.ref})`
                }
            > */}
            <ClientDetailPopover id={node.data.userId}>
                <Text
                    display={'flex'}
                    alignItems="center"
                    height="100%"
                    cursor={node.data.disabled ? 'no-drop' : ''}
                    bg={node.isSelected ? useColorModeValueKey('bg') : ''}
                    // onClick={() => {
                    //     setSelectIb({...node.data});
                    // }}
                >
                    <Text mx={3} onClick={() => node.toggle()}>
                        {node.isLeaf ? (
                            <AiOutlinePlayCircle fontSize={13} />
                        ) : node.isOpen ? (
                            <AiOutlineMinusCircle fontSize={13} />
                        ) : (
                            <IoAddCircleOutline fontSize={13} />
                        )}
                    </Text>
                    <Text display={'flex'} flex={1} alignItems="center">
                        <AiOutlineUser fontSize={16} />
                        <Box
                            flex={1}
                            display="flex"
                            justifyContent={'space-between'}
                            overflow="hidden"
                            textOverflow={'ellipsis'}
                            whiteSpace="nowrap"
                        >
                            <Text ml={2} fontSize="sm">
                                {node.data.ibLevel === 999
                                    ? node.data.name
                                    : `${node.data.name} (${
                                          node.data.ibLevel ? 'IB-'+node.data.ibCode : 'Client'
                                      })`}
                            </Text>
                        </Box>
                        <Text fontSize={14} pr={1}>
                            {node.data.adminCommissionRate
                                ? numeral(
                                      node.data.adminCommissionRate * 100,
                                  ).format('0.00') + '%'
                                : ''}
                        </Text>
                    </Text>
                </Text>
            </ClientDetailPopover>
            {/* </Tooltip> */}
        </div>
    );
}
