import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {find, isNil, max, min} from 'lodash';
import {useColorModeValueKey} from 'hooks/useColors';
import {
    HStack,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    InputLeftElement as ChakraInputLeftElement,
    Spinner,
    Stack,
    Popover,
    PopoverContent,
    PopoverBody,
    PopoverCloseButton,
    PopoverAnchor,
    Box,
    Center,
    Text,
    Button,
    useColorModeValue,
} from '@chakra-ui/react';
import {ChevronDownIcon, CloseIcon} from '@chakra-ui/icons';
import {useDebounce, useMeasure} from 'react-use';
import VirtualList from 'react-tiny-virtual-list';

const Selector = (props) => {
    const {
        value,
        onChangeValue,
        placeholder,
        options,
        isLoading,
        flex = 1,
        customRenderItem,
        customRenderInputRightElement,
        customRenderInputLeftElement,
        isDisabled,
        InputLeftElement,
        allowNoMatch = false,
        allowClear = true,
        maxH = 300,
        itemHeight = 40,
        useSearchHook,
        searchHookParams = {},
        isIB,
        size = 'sm',
        debounceSearchChar = 3,
        minW = null,
        ...other
    } = props;
    const {t} = useTranslation('app');
    const grayColor = useColorModeValueKey('gray');
    const textColor = useColorModeValueKey('text');
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [debounceSearch, setDebounceSearch] = useState('');
    const [selectedResult, setSelectedResult] = useState(null);
    const inputRef = useRef(false);
    const [inputLayoutRef, {width}] = useMeasure();
    const menuHoverColor = useColorModeValueKey('menuHover');
    const menuActiveColor = useColorModeValueKey('menuActive');
    useDebounce(
        () => {
            if (search == '') {
                setDebounceSearch('');
            } else {
                setDebounceSearch(search);
            }
        },
        500,
        [search],
    );
    // console.log(searchHookParams)
    const searchSwr = useSearchHook?.({
        search:
            debounceSearchChar && debounceSearch.length >= debounceSearchChar
                ? debounceSearch
                : !debounceSearchChar?debounceSearch:undefined,
        isIB,
        ...searchHookParams,
    });


    useEffect(() => {
        if (
            useSearchHook &&
            value &&
            selectedResult === null &&
            !searchSwr.isLoading &&
            searchSwr?.data?.length > 0
        ) {
            const matchedItem = find(searchSwr.data, {value});
            if (matchedItem) {
                setSelectedResult(matchedItem);
            }
        }
    }, [value, useSearchHook, searchSwr?.isLoading]);
    const seachedOptions = useSearchHook
        ? debounceSearchChar && debounceSearch.length >= debounceSearchChar
            ? searchSwr.data
            : !debounceSearchChar
            ? searchSwr.data
            : []
        : useMemo(() => {
              if (search) {
                  return options.filter((option) =>
                      option.label.toLowerCase().includes(search.toLowerCase()),
                  );
              }
              return options;
          }, [search, isOpen, options]);

    const matchedItem = find(options, {value});

    const showLeftElement =
        InputLeftElement ||
        (customRenderInputLeftElement && (matchedItem || selectedResult));

    return (
        <Stack flex={flex}>
            <Popover
                placement="bottom-start"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                isLazy
                closeOnBlur={false}
                autoFocus={false}
            >
                <PopoverAnchor>
                    <InputGroup size={size} ref={inputLayoutRef}>
                        {showLeftElement && (
                            <ChakraInputLeftElement>
                                <HStack spacing={1} alignItems={'center'}>
                                    {InputLeftElement}
                                    {customRenderInputLeftElement
                                        ? customRenderInputLeftElement(
                                              matchedItem || selectedResult,
                                          )
                                        : null}
                                </HStack>
                            </ChakraInputLeftElement>
                        )}
                        <Input
                            minW={minW ? minW : null}
                            value={search}
                            ref={inputRef}
                            onChange={(e) => {
                                setSearch(e.target.value);
                                if (e.target.value?.length >= 3) {
                                    setIsOpen(true);
                                }
                            }}
                            variant={'outline'}
                            placeholder={
                                matchedItem?.label ||
                                selectedResult?.label ||
                                placeholder ||
                                t('search')
                            }
                            _placeholder={{
                                color:
                                    matchedItem || selectedResult
                                        ? textColor
                                        : grayColor,
                            }}
                            flex={1}
                            isDisabled={isDisabled}
                            onFocus={() => {
                                if (search?.length >= 2) {
                                    setIsOpen(true);
                                }
                            }}
                            onBlur={() => {
                                setTimeout(() => {
                                    // if ((search?.length || 0) === 0) {
                                    //     onChangeValue(null);
                                    //     console.log(1);
                                    //     setSearch('');
                                    // } else
                                    if (seachedOptions.length == 1) {
                                        setSearch('');
                                        onChangeValue(seachedOptions[0].value);
                                        if (useSearchHook) {
                                            setSelectedResult(
                                                seachedOptions[0],
                                            );
                                        }
                                    } else if (
                                        allowNoMatch &&
                                        seachedOptions.length == 0
                                    ) {
                                        onChangeValue(search);
                                    } else if (seachedOptions.length == 0) {
                                        setTimeout(() => {
                                            setSearch('');
                                        }, 100);
                                    }
                                    setIsOpen(false);
                                }, 50);
                            }}
                            {...other}
                        />
                        <InputRightElement
                            width={'auto'}
                            height="100%"
                            size={'sm'}
                            alignItems="center"
                            mr={1}
                        >
                            <HStack
                                alignItems={'center'}
                                justifyContent={'center'}
                                spacing={1}
                            >
                                {customRenderInputRightElement
                                    ? customRenderInputRightElement(matchedItem)
                                    : null}
                                {(matchedItem || selectedResult) &&
                                    allowClear && (
                                        <IconButton
                                            variant={'ghost'}
                                            isDisabled={isDisabled}
                                            onClick={() => {
                                                onChangeValue(null);
                                                setSearch('');
                                                setSelectedResult(null);
                                                setDebounceSearch('');
                                            }}
                                            size="xs"
                                            icon={
                                                <CloseIcon
                                                    boxSize={'10px'}
                                                    color={grayColor}
                                                />
                                            }
                                        />
                                    )}
                                {isLoading ||
                                (useSearchHook && searchSwr?.isLoading) ? (
                                    <Spinner
                                        size="sm"
                                        color={useColorModeValueKey('primary')}
                                    />
                                ) : (
                                    <IconButton
                                        isDisabled={ isDisabled}
                                        onClick={() => setIsOpen((c) => !c)}
                                        size="xs"
                                        variant={'ghost'}
                                        icon={
                                            <ChevronDownIcon
                                                fontSize={'20px'}
                                            />
                                        }
                                    />
                                )}
                            </HStack>
                        </InputRightElement>
                    </InputGroup>
                </PopoverAnchor>
                <PopoverContent
                    width={width}
                    overflow={'hidden'}
                    boxShadow={'lg'}
                    borderColor={useColorModeValueKey('border')}
                >
                    <PopoverBody
                        px={0}
                        py={0}
                        bg={useColorModeValueKey('brighterBg')}
                        transition={'all 200ms linear'}
                    >
                        {
                        seachedOptions.length > 0 ? (
                            <VirtualList
                                width="100%"
                                height={Math.min(
                                    maxH,
                                    seachedOptions.length * itemHeight,
                                )}
                                itemCount={seachedOptions.length}
                                itemSize={() => itemHeight} // Also supports variable heights (array or function getter)
                                renderItem={({index, style}) => {
                                    const option = seachedOptions[index];
                                    return (
                                        <Stack
                                            cursor={'pointer'}
                                            onClick={() => {
                                                if (!option.disabled) {
                                                    onChangeValue(option.value);
                                                    if (useSearchHook) {
                                                        setSelectedResult(
                                                            option,
                                                        );
                                                    }
                                                }
                                                setSearch('');
                                                setIsOpen(false);
                                            }}
                                            key={option.value}
                                            px={3}
                                            py={2}
                                            _hover={{
                                                bg: !option.disabled
                                                    ? menuHoverColor
                                                    : null,
                                                _active: !option.disabled
                                                    ? {
                                                          bg: menuActiveColor,
                                                      }
                                                    : null,
                                            }}
                                            disabled={option.disabled}
                                            style={style}
                                        >
                                            <Stack key={option.value}>
                                                {customRenderItem ? (
                                                    customRenderItem(option)
                                                ) : (
                                                    <Text
                                                        fontSize={'sm'}
                                                        color={
                                                            option.disabled
                                                                ? grayColor
                                                                : undefined
                                                        }
                                                    >
                                                        {option.label}
                                                    </Text>
                                                )}
                                            </Stack>
                                        </Stack>
                                    );
                                }}
                            />
                        ) : (
                            <Center px={3} py={2}>
                                <Text color={grayColor}>{t('noResult')}</Text>
                            </Center>
                        )}
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </Stack>
    );
};

export default Selector;
