import React from 'react';
import FormWrapper from './FormWrapper';
import { Checkbox, Input, Textarea } from '@chakra-ui/react';
import {isFunction, upperFirst} from 'lodash';

const CheckBox = (props) => {
    const {
        formik,
        i18n,
        index,
        name,
        label,
        title,
        inputProps,
        disabled,
        readOnly,
    } = props;
    const value = formik.values[name];
    const _disabled = isFunction(disabled) ? disabled(formik.values) : disabled;
    return (
        <FormWrapper {...props}>
            <Checkbox
                isChecked={value}
                onChange={(e) => formik.setFieldValue(name, e.target.checked)}
                isDisabled={_disabled}
                
            >
                {i18n('yes')}
            </Checkbox>
        </FormWrapper>
    );
};
// export default TextInput;
export default React.memo(CheckBox, (prev, next) => {
    const name = prev.name;
    return (
        prev.formik.values[name] === next.formik.values[name] &&
        prev.formik.errors[name] === next.formik.errors[name] &&
        prev.formik.touched[name] === next.formik.touched[name] &&
        prev.disabled === next.disabled &&
        prev.readOnly === next.readOnly &&
        prev.i18n === next.i18n
    );
});
