import {Icon} from '@chakra-ui/react';
import React, {memo} from 'react';

const CAD = (props) => {
    return (
        <Icon size="md" {...props}>
            <svg
                viewBox="0 0 256 256"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M113.9 10.6999C74.4 15.4999 40.3 39.4999 22.4 75.1999C7.50001 104.8 6.00001 140.4 18 171C34.1 211.8 70.9 240.1 114.4 245.2C146.2 248.9 178.9 239.2 203.5 218.7C239.7 188.4 254.4 140.2 241.2 94.3999C235.4 74.2999 222.9 54.2999 207.3 40.3999C188.5 23.5999 166.3 13.4999 141.7 10.6999C134.5 9.7999 120.9 9.7999 113.9 10.6999ZM140 34.3999C175.9 39.0999 206.3 64.0999 217.5 98.2999C232.2 143.2 211.7 191.8 169.3 212.6C137.5 228.2 99.9 224.5 71.3 203.1C66.5 199.5 58.3 191.5 54.4 186.6C27 152.2 27 103.5 54.4 69.0999C58 64.6999 65.7 56.9999 70.2 53.4999C83 43.4999 99.2 36.6999 115.4 34.4999C122.1 33.5999 133.3 33.5999 140 34.3999Z"
                    fill="currentColor"
                />
                <path
                    d="M160.2 75.9999C159 76.4999 158.8 77.1999 158.8 82.3999V87.4999L156.4 88.0999C152.6 88.8999 147.4 91.6999 144.9 94.1999C140.2 98.7999 138.5 103.7 138.8 111.4C139 115.7 139.2 116.5 140.7 119.7C143.9 126.1 150.3 130.6 161.5 134.2C168.2 136.4 171.2 137.7 173.8 139.6C177.3 142.1 178.8 146.1 177.8 149.9C176.5 154.7 172.5 157.3 165.8 157.7C156.5 158.2 151.2 154.4 150.5 146.7C150.4 145.3 150 143.8 149.8 143.4C149.5 143 147.5 142.8 142.9 142.8C136.8 142.8 136.4 142.8 135.9 143.8C135.2 145.1 136.1 152.7 137.3 155.9C139.8 162.4 145.8 166.9 155.4 169.4L157.4 169.9V174.5C157.4 180.5 157.5 180.6 164.4 180.6C171.1 180.6 171.1 180.6 171.4 174.5L171.6 169.7L175 168.8C187.5 165.6 193.9 157.5 193.1 145.5C192.4 134.2 186.5 128.5 169.2 122.6C161.5 120 159.5 119.1 157.1 117C151.8 112.3 152.8 103.9 158.9 100.7C161.3 99.4999 165.9 99.0999 169.1 99.7999C173.8 100.9 177.4 106.1 177.4 111.6C177.4 112.7 177.7 114 178.1 114.6C178.8 115.6 179.1 115.6 184.8 115.6C192.6 115.6 192.5 115.6 192.2 110.2C191.5 98.8999 184.8 90.7999 174.1 87.9999L171.9 87.3999V81.9999C171.9 78.3999 171.7 76.3999 171.3 75.9999C170.7 75.6999 161.6 75.4999 160.2 75.9999Z"
                    fill="currentColor"
                />
                <path
                    d="M92.2 89.6998C84.9 90.9998 79.3 93.9998 73.9 99.3998C69.1 104.2 66.2 109.5 64.2 116.6C63.1 120.7 63 121.9 63 130.2C63 138.6 63.1 139.7 64.3 143.9C68.1 157.8 76.1 166.7 87.9 169.9C92.7 171.2 103.1 171.3 107.5 170.1C118.6 167.1 127.3 157.9 128.9 147.4C129.4 144.3 129 141.5 128 140.1C125.2 136.4 119.8 135.9 117.2 139.1C116.5 139.8 115.4 142.2 114.5 144.4C112.8 149.1 110.4 152.3 107.3 154.4C104.3 156.5 101.9 157.1 97.5 157C88.5 157 83 151.9 80.2 141.5C78.9 136.9 79.1 124.4 80.4 119.4C81.8 114.3 83.9 110.6 87.1 107.7C90.6 104.5 93.3 103.5 98.7 103.5C102.1 103.5 103 103.7 105.3 104.8C108.6 106.4 111.4 109.5 114 114.4C115.2 116.5 116.8 118.8 117.6 119.6C119 120.8 119.5 121 122 121C124.7 121 124.9 120.9 126.6 119.1C129.6 116 129.6 111.5 126.7 105.6C123.3 98.6998 115.4 92.4999 107.2 90.3999C103.8 89.4999 95.5 89.0998 92.2 89.6998Z"
                    fill="currentColor"
                />
            </svg>
        </Icon>
    );
};

export default memo(CAD);
