import {sleep_} from '@genx/july/lib/commonjs/lang';
import {adminClient} from 'helper/httpClient';
import {
    useSWRPaginationTemplate,
    useSWRTemplate,
} from 'hooks/dataHooksTemplate';
import {get} from 'lodash';
import {api} from './common';
import {processAnnouncement} from 'data/announcement';

export const useAnnouncements = (options = {}) => {
    const {} = options;
    return useSWRTemplate({
        key: ['client/announcements'],
        request: async (_key, _pageIndex, _pageSize) => {
            const {result} = await api().get(['announcement']);
            return result.map(processAnnouncement)
            // await sleep_(2000);
            // return {
            //     result: {
            //         totalItems: 0,
            //         items: [
            //             // {
            //             //     id: 1,
            //             //     createdAt: '2021-09-01T00:00:00.000Z',
            //             //     title: 'Test announcement',
            //             //     message: 'Lorem ipsum dolor sit amet',
            //             // },
            //         ],
            //     },
            // };
        },
        defaultValue: [],
    });
};

export const useAdminAnnouncements = (options = {}) => {
    const {} = options;
    return useSWRTemplate({
        key: ['admin/information/announcements'],
        request: async (_key) => {
            const {result} = await api().get(['information', 'announcement']);
            return result.map(processAnnouncement);
        },
        defaultValue: [],
    });
};

export const useAdminAnnouncementDetail = (options = {}) => {
    const {id} = options;
    return useSWRTemplate({
        key: id ? ['admin/information/announcements', id] : null,
        request: async (_key) => {
            const [_, id] = _key;
            const {result} = await api().get([
                'information',
                'announcement',
                id,
            ]);
            return processAnnouncement(result);
        },
        defaultValue: null,
    });
};

export const createAnnouncement_ = async (data) => {
    const {result} = await api().post(['information', 'announcement'], data);
    return result;
};
export const updateAnnouncement_ = async (id, data) => {
    const {result} = await api().put(['information', 'announcement', id], data);
    return result;
};
