export function iconInfo(icoStr) {
    if (!icoStr) return {};

    if (icoStr.startsWith('country-flag:')) {
        return {
            code: icoStr.replace('country-flag:', ''),
            type: 'country',
        };
    }

    if (icoStr.startsWith('cryptocurrency:')) {
        return {
            code: icoStr.replace('cryptocurrency:', ''),
            type: 'cryptocurrency',
        };
    }

    if (icoStr.startsWith('http')) {
        return {
            code: icoStr,
            type: 'url',
        };
    }

    return {};
}

export const getCurrencySymbol = (locale, currency) =>
    (0)
        .toLocaleString(locale, {
            style: 'currency',
            currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })
        .replace(/\d/g, '')
        .trim();
