import appConfig from 'config/app';
import { usePlatformBasicInfo } from 'hooks/datahook/auth';
import { get, last } from 'lodash';
import PropTypes from 'prop-types';
import {Navigate, useMatches} from 'react-router-dom';
import {paths} from 'routes/path';

// ----------------------------------------------------------------------

MaintenanceGuard.propTypes = {
    children: PropTypes.node,
};

export default function MaintenanceGuard({children}) {
    const {data: platformBasicInfo} = usePlatformBasicInfo();
    const matches = useMatches();
    if (platformBasicInfo?.mts && appConfig.appPermissionControl.includes('USER')) {
        const matchPath = get(last(matches), 'pathname');
        if (matchPath !== paths.maintenance) {
            return <Navigate to={paths.maintenance} />;
        }
    }
    return <>{children}</>;
}
