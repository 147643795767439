import {Box, Spinner, Stack} from '@chakra-ui/react';
import CustomTab from 'components/CustomTab';
import {useAdminPermissionSettings} from 'hooks/datahook/adminPermission';
import {useAuth} from 'hooks/useAuth';
import {find, get, last, split, trim} from 'lodash';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Navigate, Outlet, useMatches, useNavigate} from 'react-router-dom';
import {adminPaths, paths} from 'routes/path';

const TradesLayout = () => {
    const {t} = useTranslation('app');
    const matches = useMatches();
    const matchPath = get(last(matches), 'pathname');
    const mainPath = last(split(trim(matchPath, '/'), '/'));
    const navigate = useNavigate();
    const {roles} = useAuth();
    const {getPermission,isLoading} = useAdminPermissionSettings({
        roles,
    });
    const tabs = [
        {
            label: t('transactionSummary'),
            value: 'transactionSummary',
            path: adminPaths.transactionSummary,
            hasPermission: getPermission('transactions.transactionSummary'),
        },
        {
            label: t('transactionDeposits'),
            value: 'transactionDeposits',
            path: adminPaths.transactionDeposits,
            hasPermission: getPermission('transactions.transactionDeposits'),
        },
        {
            label: t('transactionWithdrawals'),
            value: 'transactionWithdrawals',
            path: adminPaths.transactionWithdrawals,
            hasPermission: getPermission('transactions.transactionWithdrawals'),
        },
        {
            label: t('transactionTransfers'),
            value: 'transactionTransfers',
            path: adminPaths.transactionTransfers,
            hasPermission: getPermission('transactions.transactionTransfers'),
        },
        {
            label: t('transactionCommissions'),
            value: 'transactionCommissions',
            path: adminPaths.transactionCommissions,
            hasPermission: getPermission('transactions.transactionCommissions'),
        },
        {
            label: t('transactionAdjustments'),
            value: 'transactionAdjustments',
            path: adminPaths.transactionAdjustments,
            hasPermission: getPermission('transactions.transactionAdjustments'),
        },
        {
            label: t('transactionMoneyMoves'),
            value: 'transactionMoneyMoves',
            path: adminPaths.transactionMoneyMoves,
            hasPermission: getPermission('transactions.transactionMoneyMoves'),
        },
        {
            label: t('transactionHDWalletOperations'),
            value: 'transactionHDWalletOperations',
            path: adminPaths.transactionHDWalletOperations,
            hasPermission: getPermission(
                'transactions.transactionHDWalletOperations',
            ),
        },
    ].filter((item) => item.hasPermission !== false);
    const value =
        find(tabs, (o) => o.path === matchPath)?.value || tabs[0]?.value;
    if(matchPath===adminPaths.transactions){
        return <Navigate to={tabs[0].path} />;
    }
    return (
        <Stack spacing={4} width="100%">
            <CustomTab
                tabs={tabs}
                tabOnly
                value={value}
                onChange={(v) => {
                    navigate(find(tabs, (o) => o.value === v)?.path);
                }}
            />
            <Box width={'100%'}>
                {isLoading?
                <Spinner/>
                :
                <Outlet />
                }
            </Box>
        </Stack>
    );
};

export default TradesLayout;
