import {Center, Spinner} from '@chakra-ui/react';
// import ProgressBar from './ProgressBar';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

LoadingScreen.propTypes = {};

export default function LoadingScreen({...other}) {
    return (
        <>
            {/* <ProgressBar /> */}
            <Center h={'100%'} flex={1}>
                <Spinner />
            </Center>
        </>
    );
}
