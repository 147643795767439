import {kycStepConfig} from 'constant/status';
import {useColorModeValueKey} from 'hooks/useColors';
import {get} from 'lodash';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {paths} from 'routes/path';
import {useLatestKycResult, usePlatformBasicInfo, useProfile} from 'hooks/datahook/auth';
import CardContainer from './CardContainer';
import {Button, Progress, Stack, Text} from '@chakra-ui/react';

const KYCCard = (props) => {
    const {t} = useTranslation('app');
    const {data: platformBasicInfo} = usePlatformBasicInfo();
    const {
        isCompany,
        data: profileData,
        isLoading,
        remainStepNumber,
        stepNum,
        currentStep,
    } = useProfile();
    // const isCompany = false;
    const {data} = useLatestKycResult()
    const navigate = useNavigate();
    const stepConfig = kycStepConfig(isCompany)[profileData?.kycStatus] || {};
    return (
        <CardContainer
            title={t(isCompany ? 'KYBStatus' : 'KYCStatus')}
            actions={
                <Text fontSize={'xs'} color={useColorModeValueKey('gray')}>
                    {remainStepNumber
                        ? t('numStepToComplete', {
                              count: remainStepNumber,
                          })
                        : ''}
                </Text>
            }
            isLoading={isLoading}
        >
            <Progress
                value={(currentStep / stepNum) * 100}
                size="xs"
                colorScheme={stepConfig.colorScheme}
                pos={'absolute'}
                top={0}
                left={0}
                right={0}
                borderTopLeftRadius={'md'}
                borderTopRightRadius={'md'}
            />
            <Stack
                direction={{md: 'row', sm: 'column'}}
                alignItems={{md: 'center', sm: 'flex-start'}}
                justifyContent="space-between"
                spacing={2}
            >
                <Stack spacing={1}>
                    <Text>{stepConfig.titleKey && t(stepConfig.titleKey)}</Text>
                    <Text fontSize={'xs'} color={useColorModeValueKey('gray')}>
                        {stepConfig.showReason
                            ? get(data, 'declineReason')
                            : stepConfig.descKey && t(stepConfig.descKey,{shortName: get(platformBasicInfo, 'companyShortName')})}
                    </Text>
                </Stack>
                {stepConfig.btnText && (
                    <Button
                        variant={'primary'}
                        size="sm"
                        onClick={() =>
                            navigate(isCompany ? paths.kyb : paths.kyc)
                        }
                    >
                        {t(stepConfig.btnText)}
                    </Button>
                )}
            </Stack>
        </CardContainer>
    );
};

export default KYCCard;
