import {checkboxAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';

const {definePartsStyle, defineMultiStyleConfig} =
    createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle((props) => ({
    // define the part you're going to style
    label: {},
    control: {
        // borderRadius: 0, // change the border radius of the control
        mt: 1,
    },
    container: {
        alignItems: 'flex-start',
    },
}));

export const checkboxTheme = defineMultiStyleConfig({
    baseStyle,
    defaultProps: {
        colorScheme: `light.primary`,
    },
});
