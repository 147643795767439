import React, {useState, useEffect} from 'react';
import {
    Button,
    Box,
    Stack,
    HStack,
    Text,
    Checkbox,
    Link, FormLabel, FormControl,
} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import useCustomToast from 'hooks/useCustomToast';
import {getErrorMessage} from 'utils/error';
import {changeLeverage_, useIBDefaultGroupSettings} from 'hooks/datahook/forex';
import CustomDialog from 'components/Dialog/CustomDialog';
import {useColorModeValueKey} from '../../hooks/useColors';
import {applyAsIb_} from 'hooks/datahook/useIbAgent';
import CSelector from 'components/CurrencySelector';
import {useCurrency} from '../../hooks/datahook/common';
import {isNil} from 'lodash';
import Selector from '../../components/Selector';
import {sourceList} from './ConstantGroup';

const ApplyIB = (props) => {
    const {isOpen, onClose} = props;
    const toast = useCustomToast();
    const {t} = useTranslation('app');
    const {localedCurrency} = useCurrency();
    const {mt4GroupList,mt5GroupList, isLoading: isLoadingGroupSettings} =
        useIBDefaultGroupSettings({
            type: 'MT4,MT5',
            request: isOpen,
        });
    const primaryColor = useColorModeValueKey('primary');
    const [loading, setLoading] = useState(false);
    const [check, setCheck] = useState(false);
    const [currency, setCurrency] = useState(null);
    const [mt5Currency, setMt5Currency] = useState(null);

    const viewContract = () => {
        window.open('', '_blank');
    };

    const onSubmit = async () => {
        try {
            setLoading(true);
            await applyAsIb_(check,currency);
            toast.show({
                title: t('IB application initiated success'),
                status: 'success',
            });
            onClose();
        } catch (error) {
            toast.show({
                title: t('IB application initiated failed'),
                desc: getErrorMessage(error),
                status: 'error',
            });
        } finally {
            setLoading(false);
        }
    };
    const mt4HasGroupCurrencies = mt4GroupList
        .filter((item) => item.rebateGroup)
        .map((item) => item.currency);
    const mt4CurrencyOptions = localedCurrency.filter((item) =>
        mt4HasGroupCurrencies.includes(item.code),
    );

    const mt5HasGroupCurrencies = mt5GroupList
        .filter((item) => item.rebateGroup)
        .map((item) => item.currency);
    const mt5CurrencyOptions = localedCurrency.filter((item) =>
        mt5HasGroupCurrencies.includes(item.code),
    );

    const loadingGroupSettings = isLoadingGroupSettings;

    return (
        <CustomDialog
            open={isOpen}
            onClose={onClose}
            title={t('applyIB')}
            actions={
                <HStack spacing={2}>
                    <Button
                        size={'sm'}
                        isDisabled={!check || !currency}
                        isLoading={loading}
                        onClick={() => onSubmit()}
                        variant={'primary'}
                    >
                        {t('confirm')}
                    </Button>
                </HStack>
            }
        >
            <Stack spacing={2}>
                <Text fontSize={'md'} fontWeight={'400'}>
                    {t('ApplyIBDesc')}
                </Text>
                {/*<FormControl>*/}
                {/*    <FormLabel>{t('sourceType')}</FormLabel>*/}
                {/*    <Selector*/}
                {/*        options={sourceList}*/}
                {/*        value={'MT4'}*/}
                {/*        isDisabled*/}
                {/*    />*/}
                {/*</FormControl>*/}
                <FormControl isDisabled={loadingGroupSettings}>
                    <FormLabel>{t('defaultCurrencyForApplyIb')}</FormLabel>
                    <CSelector
                        value={currency}
                        onValueChange={(value) => {
                            setCurrency(value);
                        }}
                        platformCurrencyOnly
                        options={mt4CurrencyOptions}
                    />
                </FormControl>

                {/*<FormControl>*/}
                {/*    <FormLabel>{t('sourceType')}</FormLabel>*/}
                {/*    <Selector*/}
                {/*        options={sourceList}*/}
                {/*        value={'MT5'}*/}
                {/*        isDisabled*/}
                {/*    />*/}
                {/*</FormControl>*/}
                {/*<FormControl isDisabled={loadingGroupSettings}>*/}
                {/*    <FormLabel>{t('currency')}</FormLabel>*/}
                {/*    <CSelector*/}
                {/*        value={mt5Currency}*/}
                {/*        onValueChange={(value) => {*/}
                {/*            setMt5Currency(value);*/}
                {/*        }}*/}
                {/*        platformCurrencyOnly*/}
                {/*        options={mt5CurrencyOptions}*/}
                {/*    />*/}
                {/*</FormControl>*/}
                <HStack alignItems={'center'}>
                    <Checkbox
                        mt={'-1'}
                        size="md"
                        colorScheme="light.primary"
                        onChange={(e) => setCheck(e.target.checked)}
                    />
                    <Text fontSize={'sm'}>
                        {t('readAndAgreeDes')}{' '}
                        <Link
                            color={primaryColor}
                            href={
                                'https://oxsecurities.com/disclosure-documents/'
                            }
                            isExternal
                        >
                            {t('contract')}
                        </Link>
                    </Text>
                </HStack>
            </Stack>
        </CustomDialog>
    );
};

export default ApplyIB;
