// import { useColorModeValueKey } from "hooks/useColors";
import {Center, Text} from '@chakra-ui/react';
import {useColorModeValueKey} from 'hooks/useColors';
import {first, upperCase} from 'lodash';
import React, {memo} from 'react';

export const TextIcon = memo(({name, size = 24, height, width}) => {
    const bg = useColorModeValueKey('bg');
    const dark = useColorModeValueKey('text');
    return (
        <Center
            rounded={'sm'}
            overflow="hidden"
            backgroundColor={bg}
            width={`${width || size}px`}
            height={`${height || size}px`}
        >
            <Text fontSize={'2xs'} color={dark} fontWeight={600}>
                {upperCase(first(name))}
            </Text>
        </Center>
    );
});
