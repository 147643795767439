import {split} from 'lodash';
import React from 'react';
import FormWrapper from './FormWrapper';
import CurrencySelector from 'components/CurrencySelector';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { HStack } from '@chakra-ui/react';

const TransferNeedsInput = (props) => {
    const {
        formik,
        i18n,
        index,
        name,
        label,
        title,
        serverValidator,
        inputProps,
        textArea = false,
        disabled,
        readOnly,
    } = props;
    const value = formik.values[name];
    const fromValue = formik.values[`${name}From`] || '';
    const toValue = formik.values[`${name}To`] || '';
    const from = split(value, '/')?.[0] || fromValue;
    const to = split(value, '/')?.[1] || toValue;
    // console.log(from, to);
    return (
        <FormWrapper {...props}>
            <HStack spacing={1} alignItems="center">
                <CurrencySelector
                    isDisabled={disabled}
                    value={from}
                    onChangeValue={(value) => {
                        formik.setFieldValue(`${name}From`, value);
                        formik.setFieldValue(name, `${value}/${to}`);
                    }}
                    excluedCurrencies={['AUD.L']}
                />
                <ArrowForwardIcon />
                <CurrencySelector
                    isDisabled={disabled}
                    onChangeValue={(value) => {
                        formik.setFieldValue(`${name}To`, value);
                        formik.setFieldValue(name, `${from}/${value}`);
                    }}
                    value={to}
                    excluedCurrencies={['AUD.L']}
                />
            </HStack>
        </FormWrapper>
    );
};
export default TransferNeedsInput;
// export default React.memo(TransferNeedsInput, (prev, next) => {
//     const name = prev.name;
//     return (
//         prev.formik.values[name] === next.formik.values[name] &&
//         prev.formik.errors[name] === next.formik.errors[name] &&
//         prev.formik.touched[name] === next.formik.touched[name] &&
//         prev.disabled === next.disabled &&
//         prev.readOnly === next.readOnly
//     );
// });
