import {Icon} from '@chakra-ui/react';
import React, {memo} from 'react';

const SGD = (props) => {
    return (
        <Icon size="md" {...props}>
            <svg
                viewBox="0 0 256 256"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M113.9 10.7C74.4 15.5 40.3 39.5 22.4 75.2C7.50001 104.8 6.00001 140.4 18 171C34.1 211.8 70.9 240.1 114.4 245.2C146.2 248.9 178.9 239.2 203.5 218.7C239.7 188.4 254.4 140.2 241.2 94.4C235.4 74.3 222.9 54.3 207.3 40.4C188.5 23.6 166.3 13.5 141.7 10.7C134.5 9.80002 120.9 9.80002 113.9 10.7ZM140 34.4C175.9 39.1 206.3 64.1 217.5 98.3C232.2 143.2 211.7 191.8 169.3 212.6C137.5 228.2 99.9 224.5 71.3 203.1C66.5 199.5 58.3 191.5 54.4 186.6C27 152.2 27 103.5 54.4 69.1C58 64.7 65.7 57 70.2 53.5C83 43.5 99.2 36.7 115.4 34.5C122.1 33.6 133.3 33.6 140 34.4Z"
                    fill="currentColor"
                />
                <path
                    d="M157.3 76.3C156.6 76.8 156.5 77.8 156.4 82.2L156.3 87.5L153.5 88.3C148.8 89.6 145.4 91.5 142.3 94.5C138.9 97.9 137.6 100.4 136.6 105.5C134.9 114.3 138 122.6 145.1 127.6C149.1 130.4 152 131.8 159.2 134.2C171.9 138.4 175.6 141.4 175.6 147.3C175.6 150.4 174.8 152.3 172.5 154.4C169.8 156.8 167.8 157.4 161.9 157.4C157.2 157.4 156.5 157.3 154.4 156.1C150.3 153.9 148.1 150.6 148.1 146.3C148.1 142.8 148 142.7 140.7 142.7C136.3 142.7 134.1 142.9 133.7 143.3C132.4 144.6 133.5 153.4 135.6 157.6C137.9 162.4 145 167.5 151.6 169L154.4 169.7L154.6 174.2C154.9 180.3 155.2 180.5 162.1 180.5C168.5 180.5 168.5 180.5 168.7 174.3L168.8 169.7L172.2 168.9C176.9 167.8 182.3 165 185 162.3C192.6 154.7 192.7 140.2 185.2 132.6C181.1 128.5 176.7 126.1 167 122.8C153.6 118.2 151 115.9 151.4 109C151.7 102.5 155.2 99.7 162.7 99.7C166.5 99.7 167.1 99.8 169.1 101C170.3 101.7 171.9 103.1 172.6 104.2C173.9 106.1 175.1 110.3 175.1 112.9C175.1 115.6 175.8 115.8 182.5 115.8C190.1 115.8 190.3 115.6 189.8 110.5C189.2 104.1 187.3 99.5 183.7 95.4C180.9 92.3 177.7 90.3 173 88.7L169.2 87.4L169 81.7L168.8 75.9L163.4 75.8C159.6 75.7 157.9 75.8 157.3 76.3Z"
                    fill="currentColor"
                />
                <path
                    d="M88.2 89.7001C81.3 90.8001 75.6 93.6001 71.7 97.8001C65 105.1 64.8 118 71.4 125.5C75.2 129.8 81 132.6 93.2 135.8C104.9 138.9 108 141.1 108.3 146.7C108.6 151.1 106.9 154.1 102.5 156.3C98.6 158.3 92 158.7 88.1 157C84.9 155.6 83.2 153.5 80.3 147.6C77 140.7 75.9 139.8 71.7 140.1C69.4 140.3 68.9 140.5 67.3 142.2C64.3 145.5 64.4 150.3 67.7 156.8C70.9 163.1 76.9 167.9 84.5 169.9C89.4 171.3 99.7 171.3 104.5 170.1C115.7 167.2 122.9 159.7 124.4 149.4C125.9 138.5 121.6 130.1 112.2 125.6C108.3 123.8 104.4 122.4 93.7 119.4C84.6 116.8 81.6 114.4 82 109.7C82.1 107.6 82.4 107 84 105.4C86.5 102.9 89.2 101.9 93.8 101.9C100.6 101.9 103.1 103.6 107.1 111C109.4 115.3 110.6 116.4 113.2 116.8C115.7 117.2 118.6 115.8 120.2 113.4C121.2 112 121.4 111.2 121.4 108.4C121.4 105.6 121.2 104.6 119.9 102.1C117.1 96.6001 111.4 92.1001 104.3 90.2001C101 89.5001 91.8 89.1001 88.2 89.7001Z"
                    fill="currentColor"
                />
            </svg>
        </Icon>
    );
};

export default memo(SGD);
