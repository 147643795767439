import {get} from 'lodash';

export const processCurrency = (rawData) => {
    return {
        // id: get(rawData, 'id'),
        code: get(rawData, 'code'),
        decimal: get(rawData, 'decimalPlaces'),
        symbol: get(rawData, 'symbol'),
        isPlatformSupport: Boolean(get(rawData, 'isPlatformSupport')),
        freelyConvertible: Boolean(get(rawData, 'freelyConvertible')),
        isMT4Support: Boolean(get(rawData, 'isMT4Support')),
        desc: get(rawData, 'desc'),
        country:
            get(rawData, 'entity') === 'EU_EZ' ? 'EU' : get(rawData, 'entity'),
    };
};
