import {EthereumClient, w3mConnectors, w3mProvider} from '@web3modal/ethereum';
import {configureChains, createConfig, WagmiConfig} from 'wagmi';
import {infuraProvider} from 'wagmi/providers/infura';
import {arbitrum, mainnet, polygon, sepolia, bsc,bscTestnet,goerli} from 'wagmi/chains';

import {TronLinkAdapter} from '@tronweb3/tronwallet-adapter-tronlink';
import {BitKeepAdapter} from '@tronweb3/tronwallet-adapter-bitkeep';
import {OkxWalletAdapter} from '@tronweb3/tronwallet-adapter-okxwallet';
import { isDev } from 'config/app';

const chains = isDev ? [mainnet, goerli] : [mainnet];
// const chains = [mainnet, sepolia, bsc, bscTestnet];
export const projectId = 'd63d0ee28867404e3d48ff0a7b72f0a0';

const {publicClient} = configureChains(chains, [
    // infuraProvider({apiKey: '154c975bfd5f485e81f5151e6ca2c084'}),
    w3mProvider({projectId}),
]);
export const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({projectId, chains}),
    publicClient,
});

export const ethereumClient = new EthereumClient(wagmiConfig, chains);

// tron

export const tronAdapters = [
    new TronLinkAdapter(),
    new BitKeepAdapter(),
    new OkxWalletAdapter(),
];