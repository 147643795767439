import {useSWRTemplate} from 'hooks/dataHooksTemplate';
import {cleanObjectNilValue} from 'utils/clean';
import {api} from './common';
import {
    processWithdrawalAccount,
    processBankWithdrawalMethods,
} from 'data/withdrawalAccount';
import {useAuth} from 'hooks/useAuth';
import {get} from 'lodash'

export const useWithdrawalAccountList = (options = {}) => {
    const {status = null} = options;
    const swr = useSWRTemplate({
        key: ['funding/withdrawal-account-list',status],
        request: async ([_,_status]) => {
            const {result} = await api().get(['funding', 'withdrawal-account'],
                cleanObjectNilValue({
                    // status: _status
                }));
            return result.map(processWithdrawalAccount)
                  .filter((item) => (_status ? item.status == 'activated' : true));
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useWithdrawalAccounDetail = (options = {}) => {
    const {id} = options;
    const swr = useSWRTemplate({
        key: id ? ['funding/withdrawal-account-detail', id] : null,
        request: async ([_, _id]) => {
            const {result} = await api().get([
                'funding',
                'withdrawal-account',
                _id,
            ]);
            return processWithdrawalAccount(result);
        },
        defaultValue: {},
    });
    return {
        ...swr,
    };
};

export const editDisplayName_ = async (accountId, data) => {
    const res = await api().put(
        ['funding', 'withdrawal-account', accountId],
        data,
    );
    return res;
};

export const useWithdrawalSupportMethod = (options = {}) => {
    const {currency} = options;
    const swr = useSWRTemplate({
        key: currency ? ['withdrawalSupportMethod', currency] : null,
        request: async ([_, _currency]) => {
            const {result} = await api().get(
                ['platform', 'withdrawal-support-method'],
                {
                    currency: _currency,
                },
            );

            return result;
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useBankWithdrawalMethods = (options = {}) => {
    const {currency} = options;
    const {isAdmin} = useAuth();
    const swr = useSWRTemplate({
        key: currency ? ['bank-withdrawal-methods', currency] : null,
        request: async ([_, _currency]) => {
            const {result} = await api().get(
                [isAdmin ? 'commons' : 'platform', 'withdrawal-support-method'],
                {
                    currency: _currency,
                },
            );
            return result.map(processBankWithdrawalMethods);

            // return [
            //     {
            //         id: 1,
            //         name: 'BSB',
            //         rule: {
            //             bsbCode: '/d+/',
            //             accountNumber: '/d+/',
            //             accountHolderName: '/d+/',
            //         },
            //     },
            //     {
            //         id: 2,
            //         name: 'SWIFT',
            //         rule: {
            //             bicSwift: '/d+/',
            //             accountNumber: '/d+/',
            //             accountHolderName: '/d+/',
            //         },
            //     },
            // ];
        },
        defaultValue: [],
    });
    return swr;
};

export const useSearchBankDetail = (options = {}) => {
    const {type, value} = options;
    const swr = useSWRTemplate({
        key: type && value ? ['search-bank-detail', type, value] : null,
        request: async ([_, _type, _value]) => {
            const {result} = await api().get(['bank-detail'], {
                type: _type,
                value: _value,
            });
            return result;
            // return {
            //     bankName: 'Bank name',
            //     bankAddress: 'Bank address', //line1
            //     country: 'US',
            //     bankPostCode: '12345678910',
            //     stateISO: 'New York State',
            //     bankCity: 'New York',
            // };
        },
        defaultValue: {},
        confirg: {
            errorRetryCount: 0,
        },
    });
    return {
        ...swr,
    };
};

export const useWithdrawalFee = (options = {}) => {
    const {amount, fromFxAccountId, toWithdrawalAccountId,type} = options;
    return useSWRTemplate({
        key:
            amount && fromFxAccountId && toWithdrawalAccountId
                ? [
                      'useWithdrawalFee',
                      amount,
                      fromFxAccountId,
                      toWithdrawalAccountId,
                      type
                  ]
                : null,
        request: async ([
            _,
            _amount,
            _fromFxAccountId,
            _toWithdrawalAccountId,
            _type
        ]) => {
            const {result} = await api().post(
                ['withdrawal', 'fee'],
                cleanObjectNilValue({
                    amount: _amount,
                    accountId: _fromFxAccountId,
                    withdrawalAccountId: _toWithdrawalAccountId,
                    type: _type,
                }),
            );
            return result;
        },
        defaultValue: {},
    });
};

export const useLatestWithdrawalAccount = (login, source) => {
    return useSWRTemplate({
        key:
            login && source
                ? ['useLatestWithdrawalAccount', login, source]
                : null,
        request: async ([_, _login, _source]) => {
            const {result} = await api().get(['funding', 'account', _login], {
                source: _source,
            });
            return result?.[0];
        },
        defaultValue: {},
        config: {
            refreshInterval: 1000 * 30,
        },
    });
};

export const useClientWithdrawalAccount = (options = {}) => {
    const {status = null,user} = options;
    const swr = useSWRTemplate({
        key: user ? ['ClientWithdrawalAccount',status,user] : null,
        request: async ([_,_status,_user]) => {
            const {result} = await api().get(['withdrawal-account'],
                cleanObjectNilValue({
                    // status: _status
                    user: _user,
                }));
            return result.map(processWithdrawalAccount)
                .filter((item) => (_status ? item.status == 'activated' : true));
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const useAdminWithdrawalFee = (options = {}) => {
    const {amount, fromFxAccountId, toWithdrawalAccountId,userId} = options;
    return useSWRTemplate({
        key:
            amount && fromFxAccountId && toWithdrawalAccountId && userId
                ? [
                    'AdminWithdrawalFee',
                    amount,
                    fromFxAccountId,
                    toWithdrawalAccountId,
                    userId
                ]
                : null,
        request: async ([
                            _,
                            _amount,
                            _fromFxAccountId,
                            _toWithdrawalAccountId,
                            _userId
                        ]) => {
            const {result} = await api().post(
                ['support', 'withdrawal-fee'],
                cleanObjectNilValue({
                    amount: _amount,
                    accountId: _fromFxAccountId,
                    withdrawalAccountId: _toWithdrawalAccountId,
                    userId: _userId,
                }),
            );
            return result;
        },
        defaultValue: {},
    });
};


export const addWithdrawalAccount_ = async (data) => {
    const res = await api().post(
        ['funding', 'withdrawal-currency-account'],
        cleanObjectNilValue(data),
    );
    return res;
};

export const createWithdrawal_ = async (data) => {
    const res = await api().post(['fx', 'withdraw'], cleanObjectNilValue(data));
    return res;
};

export const addWithdrawalCrypto_ = async (data) => {
    const res = await api().post(
        ['funding', 'withdrawal-crypto-account'],
        cleanObjectNilValue(data),
    );
    return res;
};

export const withdrawalCryptoAccountCheck_ = async (id,{lastFourDigital,secondTime}) => {
    const {result} = await api().put(
        ['funding', 'withdrawal-crypto-account-check', id],
        cleanObjectNilValue({
            lastFourDigital,
            secondTime,
        }),
    );
    return result;
};

export const useFxAccountRefund = (accountId) => {
    const swr = useSWRTemplate({
        key: accountId? ['fx-account-refund', accountId]: null,
        request: async ([_, _accountId]) => {
            const {result} = await api().get(['withdraw', 'worldpay-refundable'], cleanObjectNilValue({ accountId: _accountId }))
            return result
        }
    })
    return swr
}

export const useAdminFxAccountRefund = (accountId) => {
    const swr = useSWRTemplate({
        key: accountId? ['fx-account-refund', accountId]: null,
        request: async ([_, _accountId]) => {
            const {result} = await api().get(['workflow', 'worldpay-refundable'], cleanObjectNilValue({ accountId: _accountId }))
            return {
                deposits: Number(get(result, 'deposits')).toFixed(4),
                refund: Number(get(result, 'refund')).toFixed(4),
                refundable: Number(get(result, 'refundable')).toFixed(4),
            }
        }
    })
    return swr
}