// import CountryIcon from 'components/common/CountryIcon';
import {
    Box,
    Icon,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    Tooltip,
} from '@chakra-ui/react';
import {find, isNil} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CountryIcon from './CountryIcon';
import getSymbolFromCurrency from 'currency-symbol-map';
import {getCurrencySymbol} from 'utils/icon';
import {currencyIconMap} from 'constant/forexSupportCurrency';
import AUD from './Icon/Currencies/AUD';
import Bell from './Icon/Bell';
import {useCurrency} from 'hooks/datahook/common';
import {useUserPreference} from 'hooks/datahook/settings';
import {useExchangeRateStore} from '../stores/exchangeRateStore';

const PlatformCurrencyBtn = (props) => {
    const {noShadow} = props;
    const {t, i18n} = useTranslation('app');
    const exchangeRateState = useExchangeRateStore((state) => state);
    const {t: currenciesT} = useTranslation('currencies');
    const {data: preference, updateProfile, isLoading} = useUserPreference();
    const [platformCurrency, setPlatformCurrency] = useState(
        preference?.preferredPlatformCurrency,
    );
    const {platformCurrency: options, isLoading: isLoadingCurrency} =
        useCurrency();

    useEffect(() => {
        // if (isNil(platformCurrency) && !isLoading && preference) {
        //     setPlatformCurrency(preference?.preferredPlatformCurrency);
        // }
        setPlatformCurrency(exchangeRateState?.currency);
    }, [exchangeRateState?.currency]);

    const CurrentIcon = currencyIconMap[platformCurrency];
    return (
        <Menu>
            <Tooltip label={t('preferredCurrency')} hasArrow>
                <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={
                        CurrentIcon ? (
                            <CurrentIcon boxSize={5} />
                        ) : (
                            platformCurrency && (
                                <Text>
                                    {getCurrencySymbol(
                                        'en-US',
                                        platformCurrency,
                                    )}
                                </Text>
                            )
                        )
                    }
                    boxShadow={'none'}
                    variant={'ghost'}
                />
            </Tooltip>
            {!isLoadingCurrency && (
                <MenuList>
                    {options.map((item) => {
                        const CIcon = currencyIconMap[item.value];
                        return (
                            <MenuItem
                                onClick={() => {
                                    setPlatformCurrency(item.value);
                                    updateProfile({
                                        preferredPlatformCurrency: item.value,
                                    });
                                }}
                                key={item.value}
                                icon={
                                    CIcon ? (
                                        <CIcon boxSize={5} />
                                    ) : (
                                        <Text
                                            width={'36px'}
                                            fontWeight={'500'}
                                            fontSize={'md'}
                                        >
                                            {getCurrencySymbol(
                                                'en-US',
                                                item.value,
                                            )}
                                        </Text>
                                    )
                                }
                            >
                                {currenciesT(item.code)}
                            </MenuItem>
                        );
                    })}
                </MenuList>
            )}
        </Menu>
    );
};

export default PlatformCurrencyBtn;
