import {find} from 'lodash';
import {get} from 'lodash';
import {processAddress} from './common';
import {porcessLegalRepresentative} from './leaglRepresentative';

export const processCompany = (rawData) => {
    const processedPerson = (get(rawData, ':legalRepresents') || []).map(
        porcessLegalRepresentative,
    );
    const ap = find(processedPerson, (item) => Boolean(item.isAP)) || {};
    return {
        id: get(rawData, 'id'),
        name: get(rawData, 'name'),
        industryName: get(rawData, 'industryName'),
        businessURL: get(rawData, 'officialWebsite'),
        businessNo: get(rawData, 'businessNo'),
        region: get(rawData, 'region'),
        legalRepresentative: processedPerson.filter((item) =>
            Boolean(item.isLR),
        ),
        authorisedPerson: ap,
        ...processAddress(get(rawData, ':address'), 'company'),
        companyDocuments: (get(rawData, ':documents') || []).map(
            (item) => item.url,
        ),
        rawData,
    };
};
