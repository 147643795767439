import {find, get, isFunction, keyBy} from 'lodash';
import React from 'react';
import FormViewWrapper from './FormViewWrapper';
import {Text} from '@chakra-ui/react';
import {useSystemRoles} from 'hooks/datahook/adminPermission';
import {useTranslation} from 'react-i18next';

const RolesSelectorViewer = (props) => {
    const {data, i18n, index, name, text, formater} = props;
    const {t} = useTranslation('app');
    const value = get(data, name);
    const {data: roles} = useSystemRoles();
    const options = roles.map((item) => ({
        label: t(get(item, 'name')),
        value: get(item, 'code'),
    }));

    const kvPair = keyBy(options, 'value');

    const combinedString = (value || [])
        .map((item) => kvPair[item]?.label)
        .join(', ');

    return (
        <FormViewWrapper {...props}>
            <Text fontWeight={'400'}>
                {combinedString}
            </Text>
        </FormViewWrapper>
    );
};

export default RolesSelectorViewer;
