import PropTypes from 'prop-types';
import {useState, useEffect} from 'react';
import {Navigate, useLocation} from 'react-router-dom';

import {useAuth} from 'hooks/useAuth';
import {adminPaths, authPaths, paths} from 'routes/path';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
    children: PropTypes.node,
};

export default function AuthGuard({children}) {
    const {isLoggedIn, isAdmin, isSupport, isSale} = useAuth();
    const {pathname} = useLocation();
    const [requestedLocation, setRequestedLocation] = useState(null);
    if (!isLoggedIn) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return <Navigate to={authPaths.login} />;
    }
    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation} />;
    }
    if (isAdmin || isSupport || isSale) {
        return <Navigate to={adminPaths.dashboard} replace />;
    }

    return <>{children}</>;
}
