import {useCallback, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useMarketingRecordStore} from 'stores/marketingRecordStore';
import {
    createMarkingAccessRecord_,
    updateMarkingAccessRecord_,
} from './datahook/marketing';
import appConfig from 'config/app';
import {debounce} from 'lodash';
import {useCookie} from 'react-use';

const isValidUrl = (urlString) => {
    try {
        return Boolean(new URL(urlString));
    } catch (e) {
        return false;
    }
};

export const useSourceRecorder = () => {
    const [searchParams] = useSearchParams();
    const [value, updateV, deleteV] = useCookie('__gtm_campaign_url');
    const validCookieUrl = value && isValidUrl(value);
    const cookieUrl = validCookieUrl ? new URL(value) : null;
    const utmSource =
        searchParams.get('utm_source') ||
        cookieUrl?.searchParams?.get('utm_source');
    const utmMedium =
        searchParams.get('utm_medium') ||
        cookieUrl?.searchParams?.get('utm_medium');
    const utmCampaign =
        searchParams.get('utm_campaign') ||
        cookieUrl?.searchParams?.get('utm_campaign');
    const utmContent =
        searchParams.get('utm_content') ||
        cookieUrl?.searchParams?.get('utm_content');
    const utmTerm =
        searchParams.get('utm_term') ||
        cookieUrl?.searchParams?.get('utm_term');
    const [recordId, setRecordId] = useMarketingRecordStore((state) => [
        state.recordId,
        state.setRecordId,
    ]);
    const addAccessRecord_ = useCallback(
        debounce(async () => {
            const {result} = await createMarkingAccessRecord_({
                utmSource,
                utmMedium,
                utmCampaign,
                utmContent,
                utmTerm,
                query: validCookieUrl
                    ? cookieUrl?.search
                    : searchParams.toString(),
                agent: navigator.userAgent,
            });
            const {id} = result;
            setRecordId(id);
        }, 500),
        [searchParams, value],
    );

    useEffect(() => {
        if (
            !recordId &&
            (utmSource || utmMedium || utmCampaign || utmContent || utmTerm) &&
            appConfig.appPermissionControl.includes('USER')
        ) {
            addAccessRecord_();
        }
    }, [utmSource, utmMedium, utmCampaign, utmContent, utmTerm, recordId]);

    return {
        utmSource,
        utmMedium,
        utmCampaign,
        utmContent,
        utmTerm,
        addAccessRecord_,
    };
};
