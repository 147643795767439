import {Box, Stack} from '@chakra-ui/react';
import CustomTab from 'components/CustomTab';
import Page from 'components/Page';
import { useAdminPermissionSettings } from 'hooks/datahook/adminPermission';
import { useAuth } from 'hooks/useAuth';
import {find, get, last, split, trim} from 'lodash';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet, useMatches, useNavigate} from 'react-router-dom';
import {adminPaths, paths} from 'routes/path';

const BulkDataLayout = () => {
    const {t} = useTranslation('app');
    const matches = useMatches();
    const matchPath = get(last(matches), 'pathname');
    const mainPath = last(split(trim(matchPath, '/'), '/'));
    const navigate = useNavigate();
    const {roles} = useAuth();
    const {getPermission} = useAdminPermissionSettings({
        roles,
    });
    const tabs = [
        {
            label: t('clientsBulkData'),
            value: 'clientsBulkData',
            path: adminPaths.clientsBulkData,
            hasPermission: getPermission(
                'applicationManagement.bulkData.clientsBulkData',
            ),
        },
        {
            label: t('banksBulkData'),
            value: 'banksBulkData',
            path: adminPaths.banksBulkData,
            hasPermission: getPermission(
                'applicationManagement.bulkData.banksBulkData',
            ),
        },
        {
            label: t('tradesBulkData'),
            value: 'tradesBulkData',
            path: adminPaths.tradesBulkData,
            hasPermission: getPermission(
                'applicationManagement.bulkData.tradesBulkData',
            ),
        },
    ].filter((item) => item.hasPermission !== false);
    const value =
        find(tabs, (o) => o.path === matchPath)?.value || tabs[0]?.value;

    return (
        <Stack spacing={4}>
            <CustomTab
                tabs={tabs}
                tabOnly
                value={value}
                onChange={(v) => {
                    navigate(find(tabs, (o) => o.value === v)?.path);
                }}
            />
            <Box width={'100%'}>
                <Outlet />
            </Box>
        </Stack>
    );
};

export default BulkDataLayout;
