import {isNil} from 'lodash';
import create from 'zustand';
import {persist} from 'zustand/middleware';

export const useUIStore = create((set, get) => ({
    headerTitle: null,
    headerDesc: null,
    unauthLogout: false,
    isOffline: false,
    showMobileDrawer: false,
    firstLanguageCounter: 0,
    sideBarOpen: true,
    setSideBarOpen: (value) => {
        set(() => ({sideBarOpen: value}));
    },
    setFirstLanguageCounter: (value) => {
        set(() => ({firstLanguageCounter: value}));
    },
    setHeaderTitle: (value) => {
        set(() => ({headerTitle: value}));
    },
    clearHeaderTitle: (value) => {
        if (!isNil(get().headerTitle)) {
            set(() => ({headerTitle: null}));
        }
    },
    setHeaderDesc: (value) => {
        set(() => ({headerDesc: value}));
    },
    clearHeaderDesc: (value) => {
        if (!isNil(get().headerDesc)) {
            set(() => ({headerDesc: null}));
        }
    },
    setUnauthLogout: (value) => set(() => ({unauthLogout: value})),
    setIsOffline: (value) => set(() => ({isOffline: value})),
    setShowMobileDrawer: (value) => set(() => ({showMobileDrawer: value})),
    withdrawalAlertHasOpened: false,
    setWithdrawalAlertHasOpened: (value) => {
        set(() => ({withdrawalAlertHasOpened: value}));
    },
}));
