import {split} from 'lodash';
import {currencyFormator} from 'utils/formator';
import isMobilePhone from 'validator/lib/isMobilePhone';
import * as yup from 'yup';
import {addressBlock, addressBlockRestricted, formWithDisabled} from './commons';
import { CurrencyInput, EnglishInput } from 'components/MaskInput';
import { customizeIsMobilePhone } from 'utils/validator';



export const vaildPhoneInputNumber = (value) => {
    const code = split(value, '@')?.[0];
    const number = split(value, '@')?.[1];
    if (code && number) {
        if (code === '+599') {
            const reg = /^\d{7,8}$/;
            return reg.test(number)
        }
        return customizeIsMobilePhone(`${code}${number}`);
    } else if (code === '' && number === '') {
        return true;
    } else {
        return false;
    }
};

const vaildTransferNeed = (value) => {
    const from = split(value, '/')?.[0];
    const to = split(value, '/')?.[1];
    if (from && to) {
        return true;
    } else if (from === '' && to === '') {
        return true;
    } else {
        return false;
    }
};

export const personalInformationForm = ({i18n, disabled}) => {
    return formWithDisabled(
        [
            [
                {
                    type: 'personTitleSelector',
                    name: 'title',
                    label: i18n('title'),
                    required: true,
                    yup: yup
                        .string()
                        .required(
                            i18n('requiredField', {fieldName: i18n('title')}),
                        ),
                    helper: null,
                },
                {
                    type: 'textInput',
                    name: 'firstname',
                    label: i18n('firstName(noAbbr)'),
                    viewLabel: i18n('firstName'),
                    required: true,
                    yup: yup.string().required(
                        i18n('requiredField', {
                            fieldName: i18n('firstName'),
                        }),
                    ),
                    isUpperFirst: true,
                    helper: null,
                    CustomInput: EnglishInput,
                },
            ],
            [
                {
                    type: 'textInput',
                    name: 'middlename',
                    label: i18n('middleName(ifHave)'),
                    viewLabel: i18n('middleName'),
                    required: false,
                    yup: yup.string().nullable(),
                    helper: null,
                    isUpperFirst: true,
                    CustomInput: EnglishInput,
                },
                {
                    type: 'textInput',
                    name: 'lastname',
                    label: i18n('lastName(noAbbr)'),
                    viewLabel: i18n('lastName'),
                    required: true,
                    yup: yup.string().required(
                        i18n('requiredField', {
                            fieldName: i18n('lastName'),
                        }),
                    ),
                    helper: null,
                    isUpperFirst: true,
                    CustomInput: EnglishInput,
                },
            ],
            [
                {
                    type: 'textInput',
                    name: 'firstNameNative',
                    label: i18n('firstNameNative'),
                    yup: yup.string().nullable(),
                    helper: i18n('IDdisplayasNativeLanguage'),
                },
                {
                    type: 'textInput',
                    name: 'middleNameNative',
                    label: i18n('middleNameNative'),
                    viewLabel: i18n('middleName'),
                    yup: yup.string().nullable(),
                    helper: null,
                    // CustomInput: EnglishInput,
                },
            ],
            [
                {
                    type: 'textInput',
                    name: 'lastNameNative',
                    label: i18n('lastNameNative'),
                    viewLabel: i18n('lastName'),
                    yup: yup.string().nullable(),
                    helper: null,
                    // CustomInput: EnglishInput,
                },
                {
                    type: 'dateSelector',
                    name: 'dob',
                    label: i18n('dob'),
                    viewLabel: i18n('dob'),
                    required: true,
                    yup: yup
                        .string()
                        .required(
                            i18n('requiredField', {fieldName: i18n('dob')}),
                        ),
                    // notNullAble: true,
                    helper: null,
                },
            ],
            {
                type: 'displayText',
                name: 'email',
                label: i18n('email'),
                viewLabel: i18n('email'),
                required: false,
                yup: yup.string(),
                helper: null,
            },
            {
                type: 'phoneNumberInput',
                name: 'phone',
                label: i18n('mobilePhone'),
                viewLabel: i18n('mobilePhone'),
                required: true,
                yup: yup
                    .string()
                    .test(
                        'mobile test',
                        i18n('mobileNumberfailText'),
                        vaildPhoneInputNumber,
                    )
                    .required(
                        i18n('requiredField', {fieldName: i18n('mobilePhone')}),
                    ),
                helper: null,
            },
            // [
            // {
            //     type: 'countryPicker',
            //     name: 'countryOfResidence',
            //     label: i18n('countryOfResidence'),
            //     viewLabel: i18n('countryOfResidence'),
            //     required: true,
            //     yup: yup.string().required(
            //         i18n('requiredField', {
            //             fieldName: i18n('countryOfResidence'),
            //         }),
            //     ),
            //     helper: null,
            // },
            // ],
            {
                type: 'textInput',
                name: 'occupation',
                label: i18n('occupation'),
                viewLabel: i18n('occupation'),
                required: true,
                yup: yup.string().required(
                    i18n('requiredField', {
                        fieldName: i18n('occupation'),
                    }),
                ),
                helper: null,
            },
            {
                type: 'countrySelector',
                name: 'region',
                label: i18n('region'),
                viewLabel: i18n('region'),
                required: true,
                yup: yup.string().required(
                    i18n('requiredField', {
                        fieldName: i18n('region'),
                    }),
                ),
                helper: i18n('kycRegionHelper'),
            },
            // ],
            // {
            //     type: 'textInput',
            //     name: 'referrer',
            //     label: i18n('whoReferredYou'),
            //     viewLabel: i18n('referral'),
            //     required: false,
            //     yup: yup.string(),
            //     helper: null,
            // },
            ...addressBlock({i18n, prefix: 'individual'}),
        ],
        disabled,
    );
};

export const transferNeedForm = ({i18n, disabled}) => {
    return formWithDisabled(
        [
            {
                type: 'transferNeedsInput',
                name: 'transferNeeds',
                label: i18n('transferNeeds'),
                viewLabel: i18n('transferNeeds'),
                required: true,
                yup: yup
                    .string()
                    .test(
                        'mobile test',
                        i18n('transferNeedsTestFailed'),
                        vaildTransferNeed,
                    )
                    .required(
                        i18n('requiredField', {
                            fieldName: i18n('transferNeeds'),
                        }),
                    ),
                helper: null,
            },
            {
                type: 'textInput',
                name: 'transferAmount',
                label: i18n('transferAmountDesc'),
                viewLabel: i18n('transferAmount'),
                required: true,
                yup: yup.string().required(
                    i18n('requiredField', {
                        fieldName: i18n('transferAmount'),
                    }),
                ),
                helper: null,
                CustomInput: CurrencyInput,
                formater: (value) => currencyFormator(value),
            },
        ],
        disabled,
    );
};
