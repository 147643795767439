import { keyBy, toLower } from 'lodash';
import { cryptos } from './cryptos';
import * as cryptoIconSvgs from 'assets/cryptoIcons'


export const cryptoIcons = cryptos.map((item) => ({
    ...item,
    code: item.symbol,
    xml: cryptoIconSvgs[toLower(item.symbol)] || null,
}));

export const cryptoIconsMap = keyBy(cryptoIcons,'code')