import {get} from 'lodash';
import {processUserProfile} from './userProfile';
import {formatDateTimes} from 'utils/formator';
import { convertSource } from 'page/app/ConstantGroup';

export const processForexAccountList = (rawData) => {
    return {
        type: get(rawData, 'type'),
        id: get(rawData, 'id'),
        balance: Number(get(rawData, 'balance')),
        availableBalance: Number(get(rawData, 'balance')) > Number(get(rawData, 'equity')) ?
            Number(get(rawData, 'equity')) - Number(get(rawData, 'locked')) > 0 ?
                Number(get(rawData, 'equity')) - Number(get(rawData, 'locked')) : 0 :
            Number(get(rawData, 'balance')) - Number(get(rawData, 'locked')) > 0 ?
                Number(get(rawData, 'balance')) - Number(get(rawData, 'locked')) : 0,
        equity: Number(get(rawData, 'equity')),
        status: get(rawData, 'status'),
        currency: get(rawData, 'currency'),
        country:
            get(rawData, ':currency.entity') === 'EU_EZ'
                ? 'EU'
                : get(rawData, ':currency.entity'),
        leverage: get(rawData, 'leverage'),
        login: get(rawData, 'login'),
        group: get(rawData, 'group'),
        groupType: get(rawData, 'accountType'),
        isHidden: Boolean(get(rawData, 'isHidden')),
        hasPosition: get(rawData, 'hasPosition'),
        hasPassword: Boolean(get(rawData, 'hasPassword')),
        password: get(rawData, 'password') || null,
        isDefault: get(rawData, 'isDefault'),
        isLiquidation: Number(get(rawData, 'balance')) < 0,
        subType: get(rawData, 'subType'),
        liveAccountStatus: get(rawData, 'liveAccountStatus'),
        isUse: Boolean(get(rawData, ':requestInfo.isUse')),
        accountType: get(rawData, 'accountType'),
        label: `${get(rawData, 'login')} ${convertSource(get(rawData, 'source'))} ${get(
            rawData,
            'currency',
        )}`,
        value: get(rawData, 'id'),
        accountSource: get(rawData, 'source'),
        margin: get(rawData, 'margin'),
        marginLevel: get(rawData, 'marginLevel'),
        availableMargin: get(rawData, 'availableMargin') || get(rawData, 'marginFree'),
        profits: get(rawData, 'profits'),
        rawData: rawData || {},
        closedOrders: 0,
        enable: Boolean(get(rawData, 'enable')),
        enableChangePassword: Boolean(get(rawData, 'enableChangePass')),
        enableReadonly: Boolean(get(rawData, 'enableReadonly')),
        createdAt: get(rawData, 'createdAt'),
        updatedAt: get(rawData, 'updatedAt'),
        clientDetail: processUserProfile(get(rawData, ':user')),
        groupName: get(rawData, 'groupName'),
        enableStatus: get(rawData, 'enable') ? 'enable' : 'disable',
        hasDeposit: get(rawData, 'flag') ? 'true' : 'false',
        accountStatus: get(rawData, 'accountStatus'),
        MQID: get(rawData, 'MQID'),
        rewireTo: get(rawData, ':rewireTo')
    };
};

export const processClosedOrders = (rawData) => {
    return {
        ...rawData,
        cmd: get(rawData, 'cmd') || get(rawData, 'Action'),
        lastUpdateOn: get(rawData, 'closeTime'),
        profit: Number(get(rawData, 'profit') || 0) +
            Number(get(rawData, 'charges') || 0) +
            Number(get(rawData, 'swaps') || 0),
    };
};
