import {capitalize, toLower, split} from 'lodash';
import * as yup from 'yup';
import {EnglishInput} from '../components/MaskInput';
export const formWithDisabled = (from, disabled = false) => {
    return from.map((item) => {
        const isArray = Array.isArray(item);
        if (isArray) {
            return formWithDisabled(item, disabled);
        }
        return {
            ...item,
            disabled,
        };
    });
};

export const addressBlock = ({
    i18n,
    prefix,
    hide,
    yupHideKey,
    prefixLabel = '',
    displayMode = false,
    required = true,
}) => {
    return [
        {
            type: displayMode ? 'displayText' : 'textInput',
            name: prefix + 'line1',
            label:
                prefixLabel +
                (prefixLabel
                    ? toLower(i18n('streetNumberAndName'))
                    : i18n('streetNumberAndName')),
            yup:
                displayMode || !required
                    ? yup.string().nullable()
                    : yupHideKey
                    ? yup.string().when(yupHideKey, {
                          is: true,
                          then: yup.string(),
                          otherwise: yup.string().required(
                              i18n('requiredField', {
                                  fieldName: i18n('streetNumberAndName'),
                              }),
                          ),
                      })
                    : yup.string().required(
                          i18n('requiredField', {
                              fieldName: i18n('streetNumberAndName'),
                          }),
                      ),
            required: displayMode ? false : required,
            helper: null,
            hide,
            CustomInput: EnglishInput,
        },
        [
            {
                type: displayMode ? 'displayText' : 'textInput',
                name: prefix + 'Suburb',
                label:
                    prefixLabel +
                    (prefixLabel ? toLower(i18n('suburb')) : i18n('suburb')),
                required: displayMode ? false : required,
                yup:
                    displayMode || !required
                        ? yup.string().nullable()
                        : yupHideKey
                        ? yup.string().when(yupHideKey, {
                              is: true,
                              then: yup.string(),
                              otherwise: yup.string().required(
                                  i18n('requiredField', {
                                      fieldName: i18n('suburb'),
                                  }),
                              ),
                          })
                        : yup.string().required(
                              i18n('requiredField', {
                                  fieldName: i18n('suburb'),
                              }),
                          ),
                hide,
                CustomInput: EnglishInput,
            },
            {
                type: displayMode ? 'displayText' : 'textInput',
                name: prefix + 'State',
                label:
                    prefixLabel +
                    (prefixLabel ? toLower(i18n('state')) : i18n('state')),
                required: displayMode ? false : required,
                yup:
                    displayMode || !required
                        ? yup.string().nullable()
                        : yupHideKey
                        ? yup.string().when(yupHideKey, {
                              is: true,
                              then: yup.string(),
                              otherwise: yup.string().required(
                                  i18n('requiredField', {
                                      fieldName: i18n('state'),
                                  }),
                              ),
                          })
                        : yup.string().required(
                              i18n('requiredField', {
                                  fieldName: i18n('state'),
                              }),
                          ),
                hide,
                CustomInput: EnglishInput,
            },
        ],
        [
            {
                type: displayMode ? 'displayText' : 'textInput',
                name: prefix + 'Postcode',
                label:
                    prefixLabel +
                    (prefixLabel
                        ? toLower(i18n('postcode'))
                        : i18n('postcode')),
                required: displayMode ? false : required,
                yup:
                    displayMode || !required
                        ? yup.string().nullable()
                        : yupHideKey
                        ? yup.string().when(yupHideKey, {
                              is: true,
                              then: yup.string(),
                              otherwise: yup.string().required(
                                  i18n('requiredField', {
                                      fieldName: i18n('postcode'),
                                  }),
                              ),
                          })
                        : yup.string().required(
                              i18n('requiredField', {
                                  fieldName: i18n('postcode'),
                              }),
                          ),
                hide,
            },
            {
                type: displayMode ? 'displayText' : 'countrySelector',
                name: prefix + 'Country',
                label:
                    prefixLabel +
                    (prefixLabel ? toLower(i18n('country')) : i18n('country')),
                required: displayMode ? false : required,
                yup:
                    displayMode || !required
                        ? yup.string().nullable()
                        : yupHideKey
                        ? yup.string().when(yupHideKey, {
                              is: true,
                              then: yup.string(),
                              otherwise: yup.string().required(
                                  i18n('requiredField', {
                                      fieldName: i18n('country'),
                                  }),
                              ),
                          })
                        : yup.string().required(
                              i18n('requiredField', {
                                  fieldName: i18n('country'),
                              }),
                          ),
                helper: null,
                hide,
            },
        ],
    ];
};

export const addressBlockRestricted = ({
    i18n,
    prefix,
    hide,
    yupHideKey,
    prefixLabel = '',
}) => {
    return [
        {
            type: 'textInput',
            name: prefix + 'line1',
            label: prefixLabel + i18n('streetNumberAndName'),
            yup: yupHideKey
                ? yup.string().when(yupHideKey, {
                      is: true,
                      then: yup.string(),
                      otherwise: yup.string().required(
                          i18n('requiredField', {
                              fieldName: i18n('streetNumberAndName'),
                          }),
                      ),
                  })
                : yup.string().required(
                      i18n('requiredField', {
                          fieldName: i18n('streetNumberAndName'),
                      }),
                  ),
            required: true,
            helper: null,
            hide,
        },
        [
            {
                type: 'countrySelector',
                name: prefix + 'Country',
                label: prefixLabel + i18n('country'),
                required: true,
                yup: yupHideKey
                    ? yup.string().when(yupHideKey, {
                          is: true,
                          then: yup.string(),
                          otherwise: yup.string().required(
                              i18n('requiredField', {
                                  fieldName: i18n('country'),
                              }),
                          ),
                      })
                    : yup.string().required(
                          i18n('requiredField', {
                              fieldName: i18n('country'),
                          }),
                      ),
                helper: null,
                hide,
            },
            {
                type: 'stateSelector',
                name: prefix + 'State',
                label: prefixLabel + i18n('state'),
                countryName: prefix + 'Country',
                required: true,
                yup: yupHideKey
                    ? yup.string().when(yupHideKey, {
                          is: true,
                          then: yup.string(),
                          otherwise: yup.string().required(
                              i18n('requiredField', {
                                  fieldName: i18n('state'),
                              }),
                          ),
                      })
                    : yup.string().required(
                          i18n('requiredField', {
                              fieldName: i18n('state'),
                          }),
                      ),
                hide,
            },
        ],
        [
            {
                type: 'citySelector',
                name: prefix + 'Suburb',
                label: prefixLabel + i18n('suburb'),
                stateName: prefix + 'State',
                countryName: prefix + 'Country',
                required: true,
                yup: yupHideKey
                    ? yup.string().when(yupHideKey, {
                          is: true,
                          then: yup.string(),
                          otherwise: yup.string().required(
                              i18n('requiredField', {
                                  fieldName: i18n('suburb'),
                              }),
                          ),
                      })
                    : yup.string().required(
                          i18n('requiredField', {
                              fieldName: i18n('suburb'),
                          }),
                      ),
                hide,
            },
            {
                type: 'textInput',
                name: prefix + 'Postcode',
                label: prefixLabel + i18n('postcode'),
                required: true,
                yup: yupHideKey
                    ? yup.string().when(yupHideKey, {
                          is: true,
                          then: yup.string(),
                          otherwise: yup.string().required(
                              i18n('requiredField', {
                                  fieldName: i18n('postcode'),
                              }),
                          ),
                      })
                    : yup.string().required(
                          i18n('requiredField', {
                              fieldName: i18n('postcode'),
                          }),
                      ),
                hide,
            },
        ],
    ];
};

export const capitalizeLabel = (from) => {
    return from.map((item) => {
        const isArray = Array.isArray(item);
        if (isArray) {
            return formWithDisabled(item);
        }
        return {
            ...item,
            label: split(item.label, ' ').map(capitalize).join(' '),
        };
    });
};
