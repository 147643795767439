import {Box, Stack} from '@chakra-ui/react';
import CustomTab from 'components/CustomTab';
import Page from 'components/Page';
import {useAdminPermissionSettings} from 'hooks/datahook/adminPermission';
import {useAuth} from 'hooks/useAuth';
import {find, get, last, set, split, trim} from 'lodash';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet, useLocation, useMatches, useNavigate} from 'react-router-dom';
import {adminPaths, paths} from 'routes/path';
import {StyledButton} from 'components/StyledBtn';

const DebitCardSettingLayout = () => {
    const {t} = useTranslation('app');
    const matches = useMatches();
    const matchPath = get(last(matches), 'pathname');
    const mainPath = last(split(trim(matchPath, '/'), '/'));
    const navigate = useNavigate();
    const {state} = useLocation();
    const {roles} = useAuth();
    const {getPermission} = useAdminPermissionSettings({
        roles,
    });
    const [currentTab, setCurrentTab] = useState('bySymbol');
    const [livePositionPath, setLivePositionPath] = useState(
        adminPaths.riskMtTradeMonitor,
    );
    const tabs = [
        {
            label: t('debitCardType'),
            value: 'debitCardType',
            path: adminPaths.debitCards.setting.type,
            // hasPermission: getPermission(
            //     'riskManagement.approvals.approvalsKyc',
            // ),
        },
        // {
        //     label: t('debitAutoApproval'),
        //     value: 'debitAutoApproval',
        //     path: adminPaths.debitCards.setting.autoApproval,
        //     // hasPermission: getPermission(
        //     //     'riskManagement.approvals.approvalsMt45Accounts',
        //     // ),
        // },
        {
            label: t('debitExcludeRegions'),
            value: 'debitExcludeRegions',
            path: adminPaths.debitCards.setting.excludeRegions,
        }
    ].filter((item) => item.hasPermission !== false);
    const value =
        find(tabs, (o) => o.path === matchPath)?.value || tabs[0]?.value;

    return (
        <Stack spacing={4}>
            <CustomTab
                tabs={tabs}
                tabOnly
                value={value}
                onChange={(v) => {
                    setCurrentTab(v);
                    navigate(find(tabs, (o) => o.value === v)?.path);
                }}
            />
            <Box width={'100%'}>
                <Outlet />
            </Box>
        </Stack>
    );
};

export default DebitCardSettingLayout;
