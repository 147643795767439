import React, {useState, useEffect} from 'react';
import {
    FormLabel,
    Button,
    Skeleton,
    Stack,
    HStack,
    Text,
    FormControl,
    Checkbox,
    Link,
} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {find, isNil, trim} from 'lodash';
import useCustomToast from 'hooks/useCustomToast';
import {getErrorMessage} from 'utils/error';
import {
    useLeverages,
    addApplyAccount_,
    useDefaultGroupSettings,
} from 'hooks/datahook/forex';
import Selector from 'components/Selector';
import CSelector from 'components/CurrencySelector';
import CustomDialog from 'components/Dialog/CustomDialog';
import {sourceList, accountTypeList, mergeSourceList, groupTypeList} from '../ConstantGroup';
import {useIbTerms, useProfile} from 'hooks/datahook/auth';
import {useColorModeValueKey} from 'hooks/useColors';
import {useCurrency} from 'hooks/datahook/common';
import CommonFactorConfirmDialog from 'components/Dialog/TwoFactorDialog/CommonFactorConfirmDialog';
import {useUserPreference} from '../../../hooks/datahook/settings';

const ApplyForexAccount = (props) => {
    const {isOpen, onClose, mutate} = props;
    const toast = useCustomToast();
    const {t, i18n} = useTranslation('app');
    const {profile} = useProfile();
    const typeList = accountTypeList(t);
    const {data, isLoading} = useLeverages();
    const {twoFactorEnabled: userEneableTwoFact} = useUserPreference();
    const [loading, setLoading] = useState(false);
    const [leverage, setLeverage] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [source, setSource] = useState(null);
    // const [type, setType] = useState('');
    const [groupType, setGroupType] = useState(null);
    const [agreement, setAgreement] = useState(false);
    const [openTwoFactorDialog, setOpenTwofactorDialog] = useState(false);
    const {data: terms} = useIbTerms();
    const primaryColor = useColorModeValueKey('primary');
    const {localedCurrency} = useCurrency();
    const {data: groupSettings, isLoading: isLoadingGroupSettings} =
        useDefaultGroupSettings({
            type: source,
        });

    const reset = () => {
        setLeverage(null);
        setCurrency(null);
        setSource(null);
        // setType('');
        setGroupType(null);
        setAgreement(false);
    };

    const onsSubmit = async () => {
        try {
            setLoading(true);
            await addApplyAccount_({
                leverage: Number(leverage),
                type: 'Live',
                groupType: groupType,
                currency: currency,
                source: source,
            });
            mutate && (await mutate());
            onClose();
            reset();
            toast.show({
                title: t('applyAccountSuccess'),
                status: 'success',
            });
        } catch (error) {
            toast.show({
                title: t('applyAccountFailed'),
                desc: getErrorMessage(error),
                status: 'error',
            });
        } finally {
            setLoading(false);
        }
    };
    const hasGroupCurrencies = groupSettings
        .filter((item) => item.liveSTDGroup || item.livePROGroup)
        .map((item) => item.currency);
    const currencyOptions = localedCurrency.filter((item) =>
        hasGroupCurrencies.includes(item.code),
    );

    const getGroupTypeList = (currency) => {
        let list = [];
        if (currency) {
            const found = find(groupSettings, {currency: currency});
            if (found?.liveSTDGroup) {
                list.push({label: 'STD', value: 'STD'});
            }
            if (found?.livePROGroup) {
                list.push({label: 'PRO', value: 'PRO'});
            }
        }
        return list;
    };

    const disabledBtn =
        !currency || !source || !groupType || !leverage || !agreement;
    const loadingGroupSettings = isNil(source) || isLoadingGroupSettings;
    return (
        <CustomDialog
            open={isOpen}
            onClose={() => {
                onClose();
                reset();
            }}
            title={t('applyLiveAccount')}
            actions={
                <HStack spacing={2}>
                    <Button
                        size={'sm'}
                        isDisabled={disabledBtn}
                        isLoading={loading}
                        onClick={() => userEneableTwoFact ?
                            setOpenTwofactorDialog(true) : onsSubmit()}
                        variant={'primary'}
                    >
                        {t('confirm')}
                    </Button>
                </HStack>
            }
        >
            <Stack>
                <HStack justifyContent="space-between">
                    <HStack>
                        <Text fontSize={'sm'} fontWeight={'500'}>
                            {t('reRegion')}:
                        </Text>
                        <Text fontSize={'sm'} fontWeight={'500'}>
                            {profile?.regulation}
                        </Text>
                    </HStack>
                    {/*<Tooltip label={t('accountRules')} hasArrow openDelay={200}>*/}
                    {/*    <Box>*/}
                    {/*        <Icon*/}
                    {/*            as={HiOutlineQuestionMarkCircle}*/}
                    {/*            boxSize={5}*/}
                    {/*        />*/}
                    {/*    </Box>*/}
                    {/*</Tooltip>*/}
                </HStack>
                {/*<Box>*/}
                {/*    <FormLabel>{t('type')}</FormLabel>*/}
                {/*    <Selector*/}
                {/*        options={typeList}*/}
                {/*        value={type}*/}
                {/*        onChangeValue={(v) => {*/}
                {/*            setType(v);*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</Box>*/}

                <FormControl>
                    <FormLabel>{t('sourceType')}</FormLabel>
                    <Selector
                        options={mergeSourceList}
                        value={source}
                        onChangeValue={(v) => {
                            setSource(v);
                        }}
                    />
                </FormControl>

                <FormControl isDisabled={loadingGroupSettings}>
                    <FormLabel>{t('currency')}</FormLabel>
                    <CSelector
                        value={currency}
                        onValueChange={(value) => {
                            setCurrency(value);
                        }}
                        platformCurrencyOnly
                        options={currencyOptions}
                        isDisabled={loadingGroupSettings}
                    />
                </FormControl>

                <FormControl
                    isDisabled={loadingGroupSettings || isNil(currency)}
                >
                    <FormLabel>{t('type')}</FormLabel>
                    <Selector
                        options={getGroupTypeList(currency)}
                        value={groupType}
                        onChangeValue={(v) => {
                            setGroupType(v);
                        }}
                        isDisabled={loadingGroupSettings || isNil(currency)}
                    />
                </FormControl>

                <Skeleton isLoaded={!isLoading} height={'60px'} rounded={'md'}>
                    <FormControl
                        isDisabled={loadingGroupSettings || isNil(currency)}
                    >
                        <FormLabel>{t('leverage')}</FormLabel>
                        <Selector
                            options={data}
                            value={leverage}
                            onChangeValue={(v) => {
                                setLeverage(v);
                            }}
                            isDisabled={loadingGroupSettings || isNil(currency)}
                        />
                    </FormControl>
                </Skeleton>
                <Checkbox
                    isChecked={agreement}
                    onChange={(e) => {
                        setAgreement(e.target.checked);
                    }}
                    key={`agree-${i18n.language}`}
                >
                    <Text>
                        {t('iAgree')}
                        <Link
                            color={primaryColor}
                            cursor="pinter"
                            href={terms}
                            isExternal
                        >
                            {t('Terms & Conditions')}
                        </Link>
                    </Text>
                </Checkbox>
            </Stack>
            <CommonFactorConfirmDialog
                title={'confirmSubmitApplication'}
                open={openTwoFactorDialog}
                onClose={() => setOpenTwofactorDialog(false)}
                onFinalSumit={onsSubmit}
            />
        </CustomDialog>
    );
};

export default ApplyForexAccount;
