import {Box, Stack} from '@chakra-ui/react';
import CustomTab from 'components/CustomTab';
import Page from 'components/Page';
import {useAdminPermissionSettings} from 'hooks/datahook/adminPermission';
import {useAuth} from 'hooks/useAuth';
import {find, get, last, split, trim} from 'lodash';
import React, {useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet, useMatches, useNavigate} from 'react-router-dom';
import {adminPaths, paths} from 'routes/path';

const RequestLayout = () => {
    const {t,i18n} = useTranslation('app');
    const matches = useMatches();
    const matchPath = useMemo(() => get(last(matches), 'pathname'), [matches]);
    const navigate = useNavigate();
    const {roles} = useAuth();
    const {getPermission} = useAdminPermissionSettings({
        roles,
    });
    const tabs = useMemo(
        () =>
            [
                {
                    label: t('requestSummary'),
                    value: 'requestSummary',
                    path: adminPaths.requestSummary,
                    hasPermission: getPermission(
                        'tasks.requests.requestSummary',
                    ),
                },
                {
                    label: t('account_applications'),
                    value: 'requestAccount',
                    path: adminPaths.requestAccount,
                    hasPermission: getPermission(
                        'tasks.requests.requestAccount',
                    ),
                },
                {
                    label: t('requestKyc'),
                    value: 'requestKyc',
                    path: adminPaths.requestKyc,
                    hasPermission: getPermission('tasks.requests.requestKyc'),
                },
                {
                    label: t('requestIB'),
                    value: 'requestIB',
                    path: adminPaths.requestIB,
                    hasPermission: getPermission('tasks.requests.requestIB'),
                },
                {
                    label: t('debitCards'),
                    value: 'debitCards',
                    path: adminPaths.requestDebitCards,
                    hasPermission: getPermission('tasks.requests.requestIB'),
                },
                {
                    label: t('requestDeposits'),
                    value: 'requestDeposits',
                    path: adminPaths.requestDeposits,
                    hasPermission: getPermission(
                        'tasks.requests.requestDeposits',
                    ),
                },
                {
                    label: t('requestWithdrawals'),
                    value: 'requestWithdrawals',
                    path: adminPaths.requestWithdrawals,
                    hasPermission: getPermission(
                        'tasks.requests.requestWithdrawals',
                    ),
                },
                {
                    label: t('requestTransfer'),
                    value: 'requestTransfer',
                    path: adminPaths.requestTransfer,
                    hasPermission: getPermission(
                        'tasks.requests.requestTransfer',
                    ),
                },
                {
                    label: t('requestLeverage'),
                    value: 'requestLeverage',
                    path: adminPaths.requestLeverage,
                    hasPermission: getPermission(
                        'tasks.requests.requestLeverage',
                    ),
                },
                {
                    label: t('requestPassword'),
                    value: 'requestPassword',
                    path: adminPaths.requestPassword,
                    hasPermission: getPermission(
                        'tasks.requests.requestPassword',
                    ),
                },

                {
                    label: t('requestWithdrawalAccount'),
                    value: 'requestWithdrawalAccount',
                    path: adminPaths.requestWithdrawalAccount,
                    hasPermission: getPermission(
                        'tasks.requests.requestWithdrawalAccount',
                    ),
                },
                {
                    label: t('requestWithdrawalWallet'),
                    value: 'requestWithdrawalWallet',
                    path: adminPaths.requestWithdrawalWallet,
                    hasPermission: getPermission(
                        'tasks.requests.requestWithdrawalWallet',
                    ),
                },
                {
                    label: t('exchangeWithdrawals'),
                    value: 'exchangeWithdrawal',
                    path: adminPaths.requestExchangeWithdrawal,
                    hasPermission: getPermission(
                        'tasks.requests.requestWithdrawalWallet',
                    ),
                },
                {
                    label: t('requestGeneral'),
                    value: 'requestGeneral',
                    path: adminPaths.requestGeneral,
                    hasPermission: getPermission(
                        'tasks.requests.requestGeneral',
                    ),
                },
            ].filter((item) => item.hasPermission !== false),
        [roles,i18n?.language],
    );
    const value = useMemo(
        () => find(tabs, (o) => o.path === matchPath)?.value || tabs[0]?.value,
        [matchPath, tabs[0]?.value],
    );
    return (
        <Page title={get(value, 'label') || t('requests')} style={{ width: '100%', overflow: 'hidden' }}>
            <Stack spacing={4} width='100%' >
                <Box maxWidth='calc(100vw - 275px)'>
                    <CustomTab
                        tabs={tabs}
                        tabOnly
                        value={value}
                        onChange={(v) => {
                            navigate(find(tabs, (o) => o.value === v)?.path);
                        }}
                    />
                </Box>

                <Box width={'100%'}>
                    <Outlet />
                </Box>
            </Stack>
        </Page>
    );
};

export default RequestLayout;
