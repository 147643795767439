import {get} from 'lodash';
import {processUserProfile} from './userProfile';
import {
    formatDate,
    currencyFormatorOfficial,
    currencyFormatorWithDecimal,
} from 'utils/formator';
import { convertSource } from 'page/app/ConstantGroup';

export const processTransaction = (rawData) => {
    let type = get(rawData, 'type');
    let amount = get(rawData, 'amount');
    let commentType = (get(rawData, 'comment') || '').split(' ')[0];
    if (commentType === 'Move') {
        type = 'Money Move';
    } else if (commentType === 'Transfer') {
        type = 'transfer';
    } else if (commentType === 'PAMM') {
        type = 'PAMM';
    } else if (commentType === 'MAM') {
        type = 'MAM';
    } else if (commentType === 'agent') {
        type = 'commission';
    } else {
        if (amount > 0) {
            type = 'deposit';
        } else {
            type = 'withdrawal';
        }
    };
    const stockAccount = get(rawData, ':toStockAccount');
    return {
        ...rawData,
        rawData,
        paymentGateway: get(rawData, ':paymentGateway.displayName'),
        requestOn: get(rawData, 'openTime')
            ? formatDate(get(rawData, 'openTime'))
            : null,
        lastUpdateOn: get(rawData, 'closeTime')
            ? formatDate(get(rawData, 'closeTime'))
            : null,
        updatedAt: get(rawData, 'closeTime') || get(rawData, 'openTime') || get(rawData, 'updatedAt'),
        clientDetail: processUserProfile(get(rawData, ':user')),
        status: get(rawData, 'status'),
        type: get(rawData, 'type'),
        amount: get(rawData, 'amount'),
        fromAccount: get(rawData, 'fromAccountName') ?
            `${convertSource(get(rawData, 'fromAccountSource') || get(rawData, 'source'))}-${get(rawData, 'fromAccountName',
            )}` : '',
        from_amount: get(rawData, 'fromAmount') ? currencyFormatorWithDecimal(
            -Math.abs(get(rawData, 'fromAmount')),
            get(rawData, 'fromCurrency') || get(rawData, 'fromCryptoCurrency'),
            get(rawData, 'fromCryptoCurrency') ? 8 : 2,
            true,
        ) : '',
        toAccount: get(rawData, 'type') === 'Withdrawal' ?
            get(rawData, ':withdrawalAccount.displayName') : get(rawData, 'toAccountName')
                ? `${convertSource(get(rawData, 'toAccountSource') || get(rawData, 'source'))}-${get(rawData, 'toAccountName')}`
                : '',
        to_amount: get(rawData, 'toAmount') ? currencyFormatorWithDecimal(
            get(rawData, 'toAmount'),
            get(rawData, ':withdrawalAccount.cryptoCurrency') || get(rawData, 'toCurrency'),
            get(rawData, ':withdrawalAccount.cryptoCurrency') ? 8 : 2,
            true,
        ) : '',
        toCurrency: get(rawData, ':withdrawalAccount.cryptoCurrency') || get(rawData, 'toCurrency'),
        fromAmount: get(rawData, 'fromAmount'),
        toAmount: get(rawData, 'toAmount'),
    };
};

export const processFundingTransaction = (rawData) => {
    return {
        ...rawData,
        clientDetail: processUserProfile(get(rawData, ':user')),
        lastUpdateOn: formatDate(get(rawData, 'updatedAt')),
        requestOn: formatDate(get(rawData, 'createdAt')),
        crmTicket: get(rawData, 'crmTicket'),
        ticket: get(rawData, 'ticket'),
        method: get(rawData, 'method'),
        subMethod: get(rawData, 'subMethod'),
        paymentGateway: get(rawData, ':paymentGateway.displayName'),
        attachment: get(rawData, 'attachment'),
        fromAccount: get(rawData, 'fromAccountName') ?
            `${convertSource(get(rawData, 'fromAccountSource') || convertSource(get(rawData, 'source')))}-${get(rawData, 'fromAccountName',
            )}` : '',
        from_amount: get(rawData, 'fromAmount') ? currencyFormatorWithDecimal(
            -Math.abs(get(rawData, 'fromAmount')),
            get(rawData, 'fromCurrency') || get(rawData, 'fromCryptoCurrency'),
            get(rawData, 'fromCryptoCurrency') ? 2 : 2,
            true,
        ) : '',
        toAccount: get(rawData, 'type') === 'Withdrawal' ?
            get(rawData, ':withdrawalAccount.displayName') : get(rawData, 'toAccountName')
                ? `${convertSource(get(rawData, 'toAccountSource') || convertSource(get(rawData, 'source')))}-${get(rawData, 'toAccountName')}`
                : '',
        to_amount: get(rawData, 'toAmount') ? currencyFormatorWithDecimal(
            get(rawData, 'toAmount'),
            get(rawData, ':withdrawalAccount.cryptoCurrency') || get(rawData, 'toCurrency'),
            get(rawData, ':withdrawalAccount.cryptoCurrency') ? 2 : 2,
            true,
        ) : '',
        toCurrency: get(rawData, ':withdrawalAccount.cryptoCurrency') || get(rawData, 'toCurrency'),
        fromAmount: get(rawData, 'fromAmount'),
        toAmount: get(rawData, 'toAmount'),
        clientDetail: get(rawData, ':user'),
        paymentReference: get(JSON.parse(get(rawData, 'request_params') || '{}'),'paymentResult.short_reference')||get(JSON.parse(get(rawData, 'request_params') || '{}'), 'paymentReference'),
        params: JSON.parse(get(rawData, 'request_params') || '{}'),
        rawData: rawData,
    };
};

export const processLedger = (rowData) => {
    return {
        ...rowData,
        clientDetail: processUserProfile(get(rowData, ':user')),
        refTicket: get(rowData, ':transaction.ticket'),
    };
};