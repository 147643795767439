import {get} from 'lodash';
import {processAddress} from './common';
import {processUserProfile} from './userProfile';

export const processWithdrawalAccount = (rawData) => {
    return {
        id: get(rawData, 'id'),
        mobile: get(rawData, 'phone'),
        type:
            get(rawData, 'type') === 'currency'
                ? 'bankAccount'
                : 'cryptoWallet',
        displayName: get(rawData, 'displayName'),
        currency:
            get(rawData, 'currency') === 'EU_EZ'
                ? 'EU'
                : get(rawData, 'currency') || get(rawData, 'cryptoCurrency'),
        cryptoCurrency: get(rawData, 'cryptoCurrency'),
        status: get(rawData, 'status'),

        //bankAccount
        method: get(rawData, 'routeType'),
        routeType: get(rawData, 'routeType'),
        routeCode: get(rawData, 'routeCode'),
        accountNumber: get(rawData, 'accountNumber'),
        accountHolderName: get(rawData, 'accountHolderName'),
        chainType: get(rawData, ':chainType.name'),
        ...processAddress(get(rawData, ':address'), 'bank'),
        bankAccountDetail:
            get(rawData, 'bankDetails') &&
            typeof get(rawData, 'bankDetails') === 'string'
                ? JSON.parse(get(rawData, 'bankDetails'))
                : {},
        //crypto
        walletAddress: get(rawData, 'walletAddress'),
        network: get(rawData, 'network'),

        userDetail: get(rawData, ':user')
            ? processUserProfile(get(rawData, ':user'))
            : null,
        createdAt: get(rawData, 'createdAt'),
        updatedAt: get(rawData, 'updatedAt'),
        label: `${get(rawData, 'displayName')} ${get(rawData, 'currency') || get(rawData, 'cryptoCurrency')}`,
        value: get(rawData, 'id'),
        country: get(rawData, ':address.country') === 'EU_EZ'
            ? 'EU'
            : get(rawData, ':address.country'),
        rawData: rawData,
    };
};

export const processBankWithdrawalMethods = (rawData) => {
    return {
        id: get(rawData, 'routeType'),
        name: get(rawData, 'routeType'),
        routeType: get(rawData, 'routeType'),
        rule: get(rawData, 'requireRule')
            ? get(rawData, 'requireRule') !== '{}' &&
              typeof get(rawData, 'requireRule') === 'string'
                ? JSON.parse(get(rawData, 'requireRule'))
                : get(rawData, 'requireRule')
            : null,
        rawData: rawData || {},
    };
};
