import { Spinner } from '@chakra-ui/react';
import { useUserPreference } from 'hooks/datahook/settings';
import { get, last } from 'lodash';
import PropTypes from 'prop-types';
import {Navigate} from 'react-router-dom';
import {authPaths} from 'routes/path';
import {useAuthStore} from 'stores/authStore';

// ----------------------------------------------------------------------

PassOtpResetRequiredGuard.propTypes = {
    children: PropTypes.node,
};

export default function PassOtpResetRequiredGuard({children}) {
    const {data: preference,isLoading} = useUserPreference();
    const [last2faDate, setLast2faDate] = useAuthStore((state) => [state.last2faDate, state.setLast2faDate]);
    if(isLoading){
        return <Spinner/>
    }
    console.log(get(preference, 'requiredUpdatePassword'))
    if(get(preference, 'requiredUpdatePassword')){
        return <Navigate to={authPaths.requiredUpdatePassOtp} />;
    }
    if(last2faDate){
        if(last2faDate > get(preference, 'last2faDate')) {
            if (
                (!get(preference, 'enableSmsFact') && !get(preference, 'enableOtpFact'))
            ) {
                return <Navigate to={authPaths.requiredUpdatePassOtp} />;
            }
        }
    }else{
        if (
            (!get(preference, 'enableSmsFact') && !get(preference, 'enableOtpFact'))
        ) {
            return <Navigate to={authPaths.requiredUpdatePassOtp} />;
        }
    }
    return <>{children}</>;
}
