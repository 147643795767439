import React, {useState, useEffect} from 'react';
import {
    Button,
    Icon,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    HStack, Stack, Text, CloseButton,
} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {useCloseOrders} from 'hooks/datahook/forex';
import ClosedOrdersTable from '../../../components/Tables/ClosedOrdersTable';
import {DownloadClosedOrderFile} from '../../../hooks/datahook/useExports';
import {get} from 'lodash';
import {HiDownload} from 'react-icons/hi';
import useCustomToast from 'hooks/useCustomToast';
import {
    currencyFormatorOfficial,
    formatDateInTimezone,
    formatDayMonthYear,
} from '../../../utils/formator';
import {usePreference} from '../../../hooks/usePreference';
import numeral from 'numeral';
import { getErrorMessage } from 'utils/error';
import {api} from '../../../hooks/datahook/common';
import {cleanObjectNilValue} from '../../../utils/clean';
import {processClosedOrders} from '../../../data/forex';
import {ExportButton} from 'components/StyledBtn';

const ClosedOrderDetails = (props) => {
    const {isOpen, onClose, account, userId} = props;
    const toast = useCustomToast();
    const {t} = useTranslation('app');
    const {getStockColorValue} = usePreference();
    // console.log(account);
    const swr = useCloseOrders({
        accountId: account?.accountId,
        startDate: get(account, 'date'),
        endDate: get(account, 'date'),
        userId: userId,
        request: isOpen,
    });

    const onDownload = async () => {
        try {
            const {result} = await api().get(
                ['fx', 'close-order'],
                cleanObjectNilValue({
                    accountId: account?.accountId,
                    userId: userId,
                    startDate: get(account, 'date')
                        ? formatDateInTimezone(get(account, 'date'), 'Factory', 'yyyy-MM-dd')
                        : null,
                    endDate: get(account, 'date')
                        ? formatDateInTimezone(get(account, 'date'), 'Factory', 'yyyy-MM-dd')
                        : null,
                }),
            );

            if (result.length) {
                await DownloadClosedOrderFile((result || []).map(processClosedOrders));
            } else {
                throw new Error('Can not export empty data');
            }
        } catch (error) {
            toast.show({
                title: t('exportFailed'),
                status: 'error',
                desc: getErrorMessage(error),
            });
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            scrollBehavior="outside"
            size={'auto'}
            spacing={3}
        >
            <ModalOverlay />
            <ModalContent maxW={'950px'}>
                <HStack w={'100%'} px={6} pt={4}>
                    <Stack w={'100%'} spacing={1}>
                        <Text fontWeight={'600'} fontSize={'xl'}>{t('closedOrdersDetail')}</Text>
                        <Stack
                            spacing={4}
                            direction={{base: 'column', md: 'row'}}
                            alignItems={{base: 'stretch', md: 'center'}}
                            fontSize={'xs'}>
                            <HStack spacing={4}>
                                <Text>{t('account')}: {get(account, 'origin')} {get(account, 'login')}</Text>
                                <Text>{t('date')}: {formatDayMonthYear(get(account, 'date'))}</Text>
                            </HStack>
                            <HStack spacing={4}>
                                <Text>{t('totalClosedLots')}: {numeral(get(account, 'volume')).format('0,0.00')}</Text>
                                <HStack spacing={1}>
                                    <Text>{t('profits')}:</Text>
                                    <Text
                                        color={getStockColorValue(get(account, 'profits'))}>{currencyFormatorOfficial(get(account, 'profits'), get(account, 'currency'), true)}</Text>
                                </HStack>
                            </HStack>
                        </Stack>
                    </Stack>
                    <CloseButton
                        alignSelf='flex-start'
                        position='relative'
                        right={-1}
                        top={-1}
                        onClick={onClose}
                    />
                </HStack>
                <ModalBody
                    maxHeight={{base: '100%', md: '70vh'}}
                    overflow='auto'>
                    <HStack justifyContent={'flex-end'} mb={2}>
                        <ExportButton
                            size="sm"
                            onClick={onDownload}
                        >
                            {t('export')}
                        </ExportButton>
                    </HStack>
                    <ClosedOrdersTable {...swr}/>
                </ModalBody>
            </ModalContent>
        </Modal>
    );

};

export default ClosedOrderDetails;