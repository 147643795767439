import client, {adminClient, authClient} from 'helper/httpClient';
import {
    useSWRPaginationTemplate,
    useSWRTemplate,
} from 'hooks/dataHooksTemplate';
import {api} from './common';
import {cleanObjectNilValue} from 'utils/clean';
import {processUserProfile} from 'data/userProfile';
import Excel from 'exceljs';
import {get, capitalize, uniq} from 'lodash';
import {
    currencyFormatorWithDecimal,
    formatDateTimes2,
    formatDate,
} from 'utils/formator';

export const createMarkingAccessRecord_ = async (data) => {
    return await authClient.post(
        ['marketing', 'record'],
        cleanObjectNilValue(data),
    );
};

export const useMarkingAccessRecord = (options = {}) => {
    const {
        type,
        utmTerm,
        utmSource,
        utmMedium,
        utmCampaign,
        utmContent,
        search,
    } = options;

    const swr = useSWRPaginationTemplate({
        key: [
            'marketing/accessRecord',
            type,
            utmTerm,
            utmSource,
            utmMedium,
            utmCampaign,
            utmContent,
            search,
        ],
        request: async (_key, _pageIndex, _pageSize) => {
            const [
                _,
                _type,
                _utmTerm,
                _utmSource,
                _utmMedium,
                _utmCampaign,
                _utmContent,
                _search,
            ] = _key;
            const {result} = await adminClient.get(
                ['access-source-record'],
                cleanObjectNilValue({
                    pageSize: _pageSize,
                    pageIndex: _pageIndex - 1,
                    utmTerm: _utmTerm,
                    utmSource: _utmSource,
                    type: _type,
                    utmMedium: _utmMedium,
                    utmCampaign: _utmCampaign,
                    utmContent: _utmContent,
                    search: _search,
                }),
            );

            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map((item) => ({
                        ...item,
                        userDetail: processUserProfile(get(item, ':user')),
                    })),
                },
            };
        },
        defaultValue: [],
    });
    return swr;
};

export const downloadMarkingAccessRecord = async (query) => {
    const {result} = await adminClient.get(
        ['access-source-record'],
        cleanObjectNilValue(query),
    );
    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet('Records');
    sheet.columns = [
        {header: 'Date', key: 'date'},
        {header: 'Client', key: 'client'},
        {header: 'Type', key: 'type'},
        {header: 'Utm Term', key: 'utmTerm'},
        {header: 'Utm Source', key: 'utmSource'},
        {header: 'Utm Medium', key: 'utmMedium'},
        {header: 'Utm Campaign', key: 'utmCampaign'},
        {header: 'Utm Content', key: 'utmContent'},
        {header: 'User agent', key: 'agent'},
    ];
    for (const grant of result) {
        const row = [];
        for (const col of sheet.columns) {
            switch (col.key) {
                case 'date':
                    row.push(get(grant, 'createdAt'));
                    break;
                case 'client':
                    row.push(get(grant, 'userDetail.name'));
                    break;
                case 'type':
                    row.push(grant.type);
                    break;
                case 'utmTerm':
                    row.push(grant.utmTerm);
                    break;
                case 'utmSource':
                    row.push(get(grant, 'utmSource'));
                    break;
                case 'utmMedium':
                    row.push(get(grant, 'utmMedium'));
                    break;
                case 'utmCampaign':
                    row.push(get(grant, 'utmCampaign'));
                    break;
                case 'utmContent':
                    row.push(grant.utmContent);
                    break;
                case 'agent':
                    row.push(get(grant, 'agent'));
                    break;
            }
        }
        sheet.addRow(row);
    }
    const buffer = await workbook.xlsx.writeBuffer();
    let a = window.document.createElement('a');
    a.href = URL.createObjectURL(
        new Blob([buffer], {type: 'application/vnd.openxmlformats'}),
    );
    a.download = `Access-Records-${formatDateTimes2(new Date())}.xlsx`;
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
};

export const useMarkingOverview = (options = {}) => {
    const {year, month} = options;

    const swr = useSWRTemplate({
        key: ['marketing/campaignOverview', year, month],
        request: async (_key) => {
            const [_, _year, _month] = _key;
            const {result} = await adminClient.get(
                ['campaigns', 'overview'],
                cleanObjectNilValue({
                    year: _year,
                    month: _month,
                }),
            );
            return result;
            // return {
            //     totalTraffic: 100000,
            //     registerRate: 0.05,
            //     registerTotal: 5000,
            //     conversionRate: 0.01,
            //     conversionTotal: 1000,
            //     topSources: [
            //         {
            //             source: 'adroll',
            //             total: 2000,
            //         },
            //         {
            //             source: 'google',
            //             total: 100,
            //         },
            //         {
            //             source: 'facebook',
            //             total: 300,
            //         },
            //         {
            //             source: 'linkedin',
            //             total: 400,
            //         },
            //     ],
            //     topConversions: [
            //         {
            //             source: 'linkedin',
            //             converted: 300,
            //             rate: 0.75,
            //             total: 400,
            //         },
            //         {
            //             source: 'google',
            //             converted: 10,
            //             rate: 0.1,
            //             total: 100,
            //         },
            //         {
            //             source: 'facebook',
            //             converted: 30,
            //             rate: 0.1,
            //             total: 300,
            //         },
            //         {
            //             source: 'adroll',
            //             converted: 100,
            //             rate: 0.05,
            //             total: 2000,
            //         },
            //     ],
            // };
        },
        defaultValue: {},
    });
    return swr;
};

export const useMarkingTrafficTrends = (options = {}) => {
    const {year, month} = options;

    const swr = useSWRTemplate({
        key: ['marketing/trafficTrends', year, month],
        request: async (_key) => {
            const [_, _year, _month] = _key;
            const {result} = await adminClient.get(
                ['campaigns', 'trends'],
                cleanObjectNilValue({
                    year: _year,
                    month: _month,
                }),
            );
            return result
            // return [
            //     {
            //         date: '2021-01-01',
            //         total: 1000,
            //     },
            //     {
            //         date: '2021-01-02',
            //         total: 2000,
            //     },
            //     {
            //         date: '2021-01-03',
            //         total: 3000,
            //     },
            //     {
            //         date: '2021-01-04',
            //         total: 4000,
            //     },
            //     {
            //         date: '2021-01-05',
            //         total: 5000,
            //     },
            //     {
            //         date: '2021-01-06',
            //         total: 6000,
            //     },
            //     {
            //         date: '2021-01-07',
            //         total: 7000,
            //     },
            //     {
            //         date: '2021-01-08',
            //         total: 8000,
            //     },
            //     {
            //         date: '2021-01-09',
            //         total: 9000,
            //     },
            //     {
            //         date: '2021-01-10',
            //         total: 10000,
            //     },
            //     {
            //         date: '2021-01-11',
            //         total: 11000,
            //     },
            //     {
            //         date: '2021-01-12',
            //         total: 12000,
            //     },
            //     {
            //         date: '2021-01-13',
            //         total: 13000,
            //     },
            //     {
            //         date: '2021-01-14',
            //         total: 10000,
            //     },
            //     {
            //         date: '2021-01-15',
            //         total: 15000,
            //     },
            //     {
            //         date: '2021-01-16',
            //         total: 12000,
            //     },
            //     {
            //         date: '2021-01-17',
            //         total: 17000,
            //     },
            //     {
            //         date: '2021-01-18',
            //         total: 18000,
            //     },
            //     {
            //         date: '2021-01-19',
            //         total: 19000,
            //     },
            //     {
            //         date: '2021-01-20',
            //         total: 20000,
            //     },
            //     {
            //         date: '2021-01-21',
            //         total: 21000,
            //     },
            //     {
            //         date: '2021-01-22',
            //         total: 22000,
            //     },
            //     {
            //         date: '2021-01-23',
            //         total: 23000,
            //     },
            // ];
        },
        defaultValue: [],
    });
    return swr;
};
