import PropTypes from 'prop-types';
import {useState, useEffect} from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';

import {useAuth} from 'hooks/useAuth';
import {adminPaths, authPaths, paths} from 'routes/path';
import {useAdminPermissionSettings} from 'hooks/datahook/adminPermission';
import { Center, Spinner } from '@chakra-ui/react';

// ----------------------------------------------------------------------

AdminPermissionGuard.propTypes = {
    children: PropTypes.node,
};

export default function AdminPermissionGuard({children, permissionKey}) {
    const {isUser, roles} = useAuth();
    const {data,getPermission,isLoading,isValidating} = useAdminPermissionSettings({
        roles,
    });
    if (isLoading) {
        return (
            <Center>
                <Spinner />
            </Center>
        );
    }
    if (isUser) {
        return <Navigate to={paths.dashboard} replace />;
    }
    const hasPermission = getPermission(permissionKey);
    // console.log('permission:', roles, permissionKey, hasPermission);
    if (!hasPermission) {
        return <Navigate to={adminPaths[403]} />;
    }

    return <>{children?children:<Outlet/>}</>;
}
