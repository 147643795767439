import {defineStyle, defineStyleConfig} from '@chakra-ui/react';
import {colorByMode} from 'theme/themeHelper';

const outline = defineStyle((props) => ({
    borderColor: colorByMode('border', props.colorMode),
    rounded: 'md',
    _hover: {
        borderColor: 'gray.400',
    },
    _dark: {
        borderColor: colorByMode('border', props.colorMode),
        _hover: {
            borderColor: 'gray.300',
        },
        _focus: {
            boxShadow: `0 0 0 1px ${colorByMode('primary', props.colorMode)}`,
            borderColor: colorByMode('primary', props.colorMode),
        },
    },
    _focus: {
        boxShadow: `0 0 0 1px ${colorByMode('primary', props.colorMode)}`,
        borderColor: colorByMode('primary', props.colorMode),
    },
}));

export const TextareaTheme = defineStyleConfig({
    variants: {outline},
});
