import React from 'react';
import FormWrapper from './FormWrapper';
import { Input, Textarea } from '@chakra-ui/react';
import {isFunction, upperFirst} from 'lodash';

const TextInput = (props) => {
    const {
        formik,
        i18n,
        index,
        name,
        label,
        title,
        serverValidator,
        inputProps,
        textArea = false,
        disabled,
        readOnly,
        CustomInput,
        CondationInput,
        placeholder,
        isUpperFirst = false,
    } = props;
    const value = formik.values[name];
    const InputComponentArea = CondationInput
        ? CondationInput(formik.values)
        : CustomInput
        ? CustomInput
        : Textarea;
    const InputComponent = CondationInput
        ? CondationInput(formik.values)
        : CustomInput
        ? CustomInput
        : Input;
    const _disabled = isFunction(disabled) ? disabled(formik.values) : disabled;
    return (
        <FormWrapper {...props}>
            {textArea ? (
                <InputComponentArea
                    h={32}
                    isDisabled={_disabled}
                    isReadOnly={readOnly}
                    onChange={(e) => formik.setFieldValue(name, e.target.value)}
                    value={value}
                    autoCapitalize="none"
                    size="sm"
                    variant="outline"
                    placeholder={placeholder || i18n('enter')}
                />
            ) : (
                <InputComponent
                    isDisabled={_disabled}
                    isReadOnly={readOnly}
                    onChange={(e) =>
                        formik.setFieldValue(
                            name,
                            isUpperFirst
                                ? upperFirst(e.target.value)
                                : e.target.value,
                        )
                    }
                    value={value}
                    autoCapitalize="none"
                    size="sm"
                    variant="outline"
                    placeholder={placeholder || i18n('enter')}
                    // onBlur={() => {
                    //     formik.setFieldTouched(name, true);
                    // }}
                    {...inputProps}
                />
            )}
        </FormWrapper>
    );
};
// export default TextInput;
export default React.memo(TextInput, (prev, next) => {
    const name = prev.name;
    return (
        prev.formik.values[name] === next.formik.values[name] &&
        prev.formik.errors[name] === next.formik.errors[name] &&
        prev.formik.touched[name] === next.formik.touched[name] &&
        prev.disabled === next.disabled &&
        prev.readOnly === next.readOnly && 
        prev.i18n === next.i18n 
    );
});
