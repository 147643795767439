// import CountryIcon from 'components/common/CountryIcon';
import {
    Box,
    Button,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack,
    useBreakpointValue,
} from '@chakra-ui/react';
import {find} from 'lodash';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { authPaths } from 'routes/path';

const OXSignupBtn = (props) => {
    const {t, i18n} = useTranslation('app');
    const isMobile = useBreakpointValue({
        base: true,
        md: false,
    });
    const navigate = useNavigate()
    return (
        <Button
            size={isMobile ? 'sm' : 'md'}
            border={'1px'}
            borderColor={'gray.200'}
            variant={'ghost'}
            bg={'white'}
            px={4}
            boxShadow={'none'}
            onClick={() => {
                navigate(authPaths.signUp);
            }}
        >
            {t('openAccount')}
        </Button>
    );
};

export default OXSignupBtn;
