import {Icon} from '@chakra-ui/react';
import React, {memo} from 'react';

const HKD = (props) => {
    return (
        <Icon size="md" {...props}>
            <svg
                viewBox="0 0 256 256"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M120.3 10.2C97.1 11.7 75.6 19.7 56.6 33.9C46.4 41.5 34.9 54.2 27.5 65.7C19 79 12.3 98.7 10.5 115.4C9.90005 121 9.90005 135 10.5 140.7C13.4 167.5 25.4 192.5 44.5 211.6C62.8 229.8 85.2 241 111.8 245.1C119.4 246.3 136.9 246.2 144.7 245.1C171.2 241.1 194.4 229.2 212.8 210.2C231.8 190.6 243.2 165.9 245.7 138.7C246.4 131.4 246 117.9 245 110.9C238.2 66.1 207.2 29.4 164.2 15.6C150.2 11.1 135 9.2 120.3 10.2ZM141.1 34.7C151.7 36.2 160.6 39 169.8 43.6C188 52.7 202.8 67.3 211.9 85.1C218.4 97.7 221.6 110.2 222.2 124.7C223.3 153.6 210.9 181.6 188.8 200.1C182.7 205.2 177.1 208.8 169.5 212.6C147.3 223.6 122.2 225.3 98.4 217.5C78.4 211 61.2 197.9 49.5 180.2C39.1 164.6 33.8 147 33.8 127.8C33.8 115.2 35.9 104.2 40.5 92.8C53.1 61.7 81.4 39.5 114.5 34.7C120.8 33.8 134.9 33.8 141.1 34.7Z"
                    fill="currentColor"
                />
                <path
                    d="M177.5 93.7V97.9L174.4 98.9C170.7 100.2 167.9 102.2 166 105.2C163.9 108.2 163.3 110.5 163.3 115.3C163.3 118.7 163.5 119.7 164.7 122.2C166.8 127 172 130.9 179.4 133.3C185.8 135.4 189.8 138.4 190.4 141.7C190.8 143.9 189.9 146.9 188.3 148.4C184.1 152.3 174.6 151.1 172.4 146.3C171.9 145.3 171.4 143.4 171.2 142.1L170.9 139.9H166H161L161.3 143.6C161.6 148.1 163 151.6 165.8 154.4C167.9 156.5 172.3 158.8 175.2 159.3L177 159.6V163.3V167H181.2H185.4L185.5 163.4L185.6 159.8L188.2 159.1C196.2 156.7 200.1 152 200.7 144.2C200.9 141.3 200.7 139.9 200 137.2C198.2 131.5 194.1 128.1 184 124.4C178.8 122.4 175.8 120.7 174.8 118.9C172 114.3 174.1 108.5 179 107.3C185 105.9 189 108.9 190 115.7L190.4 118.3L194.8 118.4C200.3 118.6 200.7 118.3 200.3 114.8C199.3 106.4 194.9 100.7 188 98.7L185.5 98V93.8V89.6H181.5H177.5V93.7Z"
                    fill="currentColor"
                />
                <path
                    d="M58.5 98.2C57.6 98.7 56.5 99.8 55.9 100.7C55 102.3 55 103 55 129.6C55 154.8 55 157 55.8 158.4C57.3 161.3 61.8 162.3 64.2 160.4C66.9 158.3 67 157.5 67.2 144.7L67.4 132.9H79.3H91.2V144C91.2 155.6 91.5 157.8 93.2 159.8C94.6 161.3 97.9 161.9 99.9 160.8C102.9 159.2 102.8 159.7 102.8 129.4C102.8 102.7 102.8 101.9 101.9 100.4C99.3 96.2 93.4 97.1 91.7 102.1C91.4 103.1 91.2 107.4 91.2 113.4V123.2H79.3H67.5L67.3 113C67.2 105 67 102.5 66.4 101.2C65 98.1 61.4 96.7 58.5 98.2Z"
                    fill="currentColor"
                />
                <path
                    d="M113.7 97.8C112.2 98.1 110.4 100 109.9 102C109.2 104.7 109.2 155.5 110 157.7C111.1 160.9 115.3 162.4 118.3 160.6C120.7 159.2 121.1 157.7 121.1 149V141.2L125.6 136.6C128.1 134 130.3 132 130.5 132C130.7 132 134.6 138.2 139.1 145.8C148.1 160.7 148.6 161.4 152.3 161.4C156.1 161.4 158.3 159.3 158.1 155.8C158 153 157.2 151.6 147.7 136.9L138.8 123.1L146.4 115.2C150.6 110.8 154.2 106.8 154.6 106.2C156 103.7 154.9 99.6 152.5 98.3C150.9 97.4 148.1 97.5 146.7 98.5C146 99 140.2 105.2 133.7 112.5C127.3 119.7 121.8 125.7 121.6 125.8C121.4 125.9 121.2 120.9 121.2 114.3C121.2 101.7 121 100.1 118.8 98.7C117.4 97.9 115.2 97.5 113.7 97.8Z"
                    fill="currentColor"
                />
            </svg>
        </Icon>
    );
};

export default memo(HKD);
