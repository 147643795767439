import {Box, Stack} from '@chakra-ui/react';
import CustomTab from 'components/CustomTab';
import {useAdminPermissionSettings} from 'hooks/datahook/adminPermission';
import {useProfile} from 'hooks/datahook/auth';
import {useAuth} from 'hooks/useAuth';
import {find, get, last, split, trim, trimEnd, trimStart} from 'lodash';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Navigate, Outlet, useMatches, useNavigate} from 'react-router-dom';
import {adminPaths, paths} from 'routes/path';

const FundingLayout = () => {
    const {t} = useTranslation('app');
    const matches = useMatches();
    const {isIB} = useProfile();
    const matchPath = get(last(matches), 'pathname');
    const mainPath = last(split(trim(matchPath, '/'), '/'));
    const navigate = useNavigate();
    const tabs = [
        {
            label: t('fundingSummary'),
            value: 'fundingSummary',
            path: paths.fundingSummary,
        },
        {
            label: t('deposits'),
            value: 'deposits',
            path: paths.deposits,
        },
        {
            label: t('withdrawals'),
            value: 'withdrawals',
            path: paths.withdrawals,
        },
        {
            label: t('transfers'),
            value: 'transfers',
            path: paths.transfers,
        },
        ...(isIB
            ? [
                  {
                      label: t('commissions'),
                      value: 'commissions',
                      path: paths.commissions,
                  },
              ]
            : []),
        {
            label: t('moneyMove'),
            value: 'moneyMove',
            path: paths.moneyMove,
        },
        {
            label: t('withdrawalAccounts'),
            value: 'withdrawalAccounts',
            path: paths.withdrawalAccounts,
        },
        {
            label: t('depositFund'),
            value: 'depositFund',
            path: paths.newDeposit,
        },
        {
            label: t('statements'),
            value: 'statements',
            path: paths.statements,
        },
    ];
    const value =
        find(
            tabs,
            (o) =>
                trimEnd(matchPath, '/') === o.path ||
                trimEnd(matchPath, '/').includes(o.path),
        )?.value || tabs[0]?.value;

    return (
        <Stack spacing={4} width="100%">
            <CustomTab
                tabs={tabs}
                tabOnly
                value={value}
                onChange={(v) => {
                    navigate(find(tabs, (o) => o.value === v)?.path);
                }}
            />
            <Box width={'100%'}>
                <Outlet />
            </Box>
        </Stack>
    );
};

export default FundingLayout;
