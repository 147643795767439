import React, {useState} from 'react';
import {isImage, isImageExtension, isPDFExtension} from 'utils/mime';
import {flatten, get, isArray, isNil, split, takeRight} from 'lodash';
import FormViewWrapper from './FormViewWrapper';
// import File from 'components/common/BaseIcon/File';
// import Download from 'components/common/BaseIcon/Download';
import {Box, HStack, Link, Stack, Text, VStack} from '@chakra-ui/react';
import ImageViewer from 'components/ImageViewer';
import File from 'components/Icon/File';

const Viewer = (props) => {
    const {url, downloadable, openInNewTab} = props;
    const isImageUrl = isImageExtension(url);
    const [downloading, setDownloading] = useState(false);
    const [openView, setOpenView] = useState(false);
    const height = 300;
    return isImageUrl ? (
        <>
            <Box
                rounded={'md'}
                overflow={'hidden'}
                onClick={() =>
                    openInNewTab
                        ? window.open(url, '_blank')
                        : setOpenView(true)
                }
            >
                <img src={url} style={{height: height, objectFit: 'contain'}} />
            </Box>
            <ImageViewer
                images={[url]}
                open={openView}
                onClose={() => setOpenView(false)}
            />
        </>
    ) : (
        <HStack py={3} alignItems={'center'} justifyContent="space-between">
            <HStack alignItems={'center'} spacing={2}>
                <File />
                <Link target="_blank" href={url}>
                    {takeRight(split(url, '/'), 1)}
                </Link>
            </HStack>
        </HStack>
    );
};

const FileViewer = (props) => {
    const {data, i18n, index, downloadable, openInNewTab, name} = props;
    const url = get(data, name);
    const urls = isArray(url) ? url : [url];
    return (
        <FormViewWrapper {...props}>
            {isNil(url) ? (
                <Text fontSize="xs">{i18n('noFile')}</Text>
            ) : (
                <VStack>
                    {urls.map((url) => (
                        <Viewer
                            url={url}
                            key={url}
                            downloadable={downloadable}
                            openInNewTab={openInNewTab}
                        />
                    ))}
                </VStack>
            )}
        </FormViewWrapper>
    );
};

export default FileViewer;
