import {EnglishInput} from 'components/MaskInput';
import * as yup from 'yup';
import {vaildPhoneInputNumber} from './personalInformationForm';
export const individualRefereeForm = ({i18n}) => {
    return [
        {
            type: 'countrySelector',
            name: 'region',
            label: i18n('country'),
            required: true,
            yup: yup.string().required(
                i18n('requiredField', {
                    fieldName: i18n('country'),
                }),
            ),
        },
        [
            {
                type: 'textInput',
                name: 'firstname',
                label: i18n('firstName(noAbbr)'),
                viewLabel: i18n('firstName'),
                required: true,
                yup: yup.string().required(
                    i18n('requiredField', {
                        fieldName: i18n('firstName'),
                    }),
                ),
                helper: null,
                CustomInput: EnglishInput,
                isUpperFirst: true,
            },
            {
                type: 'textInput',
                name: 'lastname',
                label: i18n('lastName(noAbbr)'),
                viewLabel: i18n('lastName'),
                required: true,
                yup: yup.string().required(
                    i18n('requiredField', {
                        fieldName: i18n('lastName'),
                    }),
                ),
                helper: null,
                CustomInput: EnglishInput,
                isUpperFirst: true,
            },
        ],
        {
            type: 'textInput',
            name: 'email',
            label: i18n('email'),
            viewLabel: i18n('email'),
            required: true,
            yup: yup
                .string()
                .required(
                    i18n('requiredField', {
                        fieldName: i18n('email'),
                    }),
                )
                .email(i18n('invalidEmail')),
            helper: null,
        },
        {
            type: 'phoneNumberInput',
            name: 'phone',
            label: i18n('mobilePhone'),
            viewLabel: i18n('mobilePhone'),
            required: true,
            yup: yup
                .string()
                .test(
                    'mobile test',
                    i18n('mobileNumberfailText'),
                    vaildPhoneInputNumber,
                )
                .required(
                    i18n('requiredField', {
                        fieldName: i18n('email'),
                    }),
                ),
            helper: null,
        },
        {
            type: 'textInput',
            name: 'website',
            label: i18n('website'),
            viewLabel: i18n('website'),
            required: true,
            yup: yup
                .string()
                .required(
                    i18n('requiredField', {
                        fieldName: i18n('email'),
                    }),
                ),
            helper: null,
        },
    ];
};
export const companyRefereeForm = ({i18n}) => {
    return [
        {
            type: 'countrySelector',
            name: 'region',
            label: i18n('country'),
            required: true,
            yup: yup.string().required(
                i18n('requiredField', {
                    fieldName: i18n('country'),
                }),
            ),
        },
        {
            type: 'textInput',
            name: 'companyName',
            label: i18n('corperateName'),
            required: true,
            yup: yup.string().required(
                i18n('requiredField', {
                    fieldName: i18n('corperateName'),
                }),
            ),
            helper: null,
            CustomInput: EnglishInput,
            placeholder: i18n('enterCompanyNameOrNumber'),
            isUpperFirst: true,
        },
        [
            {
                type: 'textInput',
                name: 'contactFirstname',
                label: i18n('contactFirstname'),
                viewLabel: i18n('contactFirstname'),
                required: true,
                yup: yup.string().required(
                    i18n('requiredField', {
                        fieldName: i18n('contactFirstname'),
                    }),
                ),
                helper: null,
                CustomInput: EnglishInput,
                isUpperFirst: true,
            },
            {
                type: 'textInput',
                name: 'contactLastname',
                label: i18n('contactLastname'),
                viewLabel: i18n('contactLastname'),
                required: true,
                yup: yup.string().required(
                    i18n('requiredField', {
                        fieldName: i18n('contactLastname'),
                    }),
                ),
                helper: null,
                CustomInput: EnglishInput,
                isUpperFirst: true,
            },
        ],
        {
            type: 'textInput',
            name: 'email',
            label: i18n('companyEmail'),
            viewLabel: i18n('companyEmail'),
            required: true,
            yup: yup
                .string()
                .required(
                    i18n('requiredField', {
                        fieldName: i18n('companyEmail'),
                    }),
                )
                .email(i18n('invalidEmail')),
            helper: null,
        },
        {
            type: 'phoneNumberInput',
            name: 'phone',
            label: i18n('mobilePhone'),
            viewLabel: i18n('mobilePhone'),
            required: true,
            yup: yup
                .string()
                .test(
                    'mobile test',
                    i18n('mobileNumberfailText'),
                    vaildPhoneInputNumber,
                )
                .required(
                    i18n('requiredField', {
                        fieldName: i18n('email'),
                    }),
                ),
            helper: null,
        },
        {
            type: 'textInput',
            name: 'website',
            label: i18n('website'),
            viewLabel: i18n('website'),
            required: true,
            yup: yup
                .string()
                .required(
                    i18n('requiredField', {
                        fieldName: i18n('email'),
                    }),
                ),
            helper: null,
        },
    ];
};
