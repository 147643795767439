import {Button, Icon} from '@chakra-ui/react';
import {useColorModeValueKey} from 'hooks/useColors';
import {BiExport, BiImport} from 'react-icons/bi';
import {IoIosAddCircleOutline} from 'react-icons/io';
import {HiDownload, HiPlus, HiSearch} from 'react-icons/hi';
import {AddIcon} from '@chakra-ui/icons';
import {AiOutlineEdit} from 'react-icons/ai'

export const StyledButton = (props) => {
    const {variant = 'primary', size = 'sm', fontSize = 'sm', ...other} = props;
    return (
        <Button variant={variant} size={size} fontSize={fontSize} {...other} />
    );
};

export const ExportButton = ({iconSize = 4, ...other}) => {
    return (
        <StyledButton leftIcon={<Icon as={BiExport} boxSize={iconSize} />} {...other} />
    );
};
export const ImportButton = ({iconSize = 4, ...other}) => {
    return (
        <StyledButton leftIcon={<Icon as={BiImport} boxSize={iconSize} />} {...other} />
    );
};
export const SearchButton = ({iconSize = 4, ...other}) => {
    return (
        <StyledButton leftIcon={<Icon as={HiSearch} boxSize={iconSize} />} {...other} />
    );
};
export const AddButton = ({iconSize = 4, ...other}) => {
    return (
        <StyledButton
            leftIcon={<Icon as={HiPlus} boxSize={iconSize} />}
            {...other}
        />
    );
};
export const EditButton = ({iconSize = 4, ...other}) => {
    return (
        <StyledButton
            leftIcon={<Icon as={AiOutlineEdit} boxSize={iconSize} />}
            {...other}
        />
    );
};

export const StyledTableButton = (props) => {
    const {variant = 'solid'} = props;
    if (variant !== 'solid') {
        return <Button {...props} />;
    }
    return (
        <Button
            bg={useColorModeValueKey('primary')}
            color={'#fff'}
            _hover={{
                bg: 'light.primary.600',
            }}
            _active={{bg: 'light.primary.400'}}
            fontWeight="400"
            h={'auto'}
            p={1}
            display={'flex'}
            fontSize={'xs'}
            w={'auto'}
            minWidth={0}
            {...props}
        />
    );
};
