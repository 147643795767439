import {get} from 'lodash';

const noReapet = (arr) => {
    let name = 'name';
    let map = new Map();
    for (let item of arr) {
        if (!map.has(item.nodeId)) {
            map.set(item.nodeId, item);
        }
    }
    return [...map.values()];
};

export const processTreeView = (child, index = 0) => {
    if (child.length === 0) {
        return;
    }
    child = noReapet(child);

    child = child.map((item) => {
        let subChild;
        if (item['children']) {
            subChild = processTreeView(item['children'] || [], index + 1);
        }
        let childrenName = get(item, 'name').replace(/\(.*?\)/, '');
        childrenName = childrenName.split(' ');
        return {
            id: (get(item, 'nodeId') || get(item, 'id')) + '',
            ref: get(item, 'ref'),
            ibLevel: get(item, 'parent') === 0 ? 999 : get(item, 'ibLevel'),
            adminCommissionRate:
                index === 0
                    ? 0
                    : get(item, 'ibLevel')
                    ? get(item, 'commissionRate') || 0.2
                    : '',
            commissionRate:
                index === 0
                    ? 0
                    : get(item, 'ibLevel')
                    ? get(item, 'commissionRate') || 0.2
                    : '',
            name: get(item, 'name').replace(/\(.*?\)/, ''),
            childrenName:
                index <= 1
                    ? get(item, 'name')
                    : `${get(childrenName, '[0][0]')?.toUpperCase() || ''}.${
                          get(childrenName, '[1][0]')?.toUpperCase() || ''
                      }.`,
            email: get(item, 'email'),
            mobile: get(item, 'mobile'),
            subLevels: 20,
            referralType: get(item, 'ibLevel') ? 'IB' : 'Client',
            userId: get(item, 'userId'),
            disabled: index === 0 || index > 1,
            isTop: index === 0,
            children: subChild,
            ibCode: get(item, 'ibCode')
        };
    });
    return child;
};
