// import CountryIcon from 'components/CountryIcon';
import { find, get } from 'lodash';
import React from 'react'
import FormViewWrapper from './FormViewWrapper';
import { Text } from '@chakra-ui/react';

const BusinessIndustrySelectorViewer = (props) => {
    const {data, i18n, index, name, text, options, formater} = props;
    // const v = get(data, name);
    const vN = get(data, `${name}Name`);
    // const {localedOccupations} = useCompanyIndustry();
    // const displayValue = find(localedOccupations, {value: v})?.label || v;
    return (
        <FormViewWrapper {...props}>
            <Text fontWeight={400}>{ vN || ' '}&nbsp;</Text>
        </FormViewWrapper>
    );
};


export default BusinessIndustrySelectorViewer;

