import { get } from "lodash"

export const processSalePerformance = (rawData)=>{
    return {
        id:get(rawData,'id'),
        date:get(rawData,'date'),
        positiveProfit:get(rawData,'positiveProfit'),
        negativeProfit:get(rawData,'negativeProfit'),
        netProfit: get(rawData,'netProfit'),
        currency:get(rawData,'currency'),
        saleShortName:get(rawData,'saleShortName'),
        clients:get(rawData,'clients', ''),
        rawData,
    }
}