import {Box, Stack} from '@chakra-ui/react';
import CustomTab from 'components/CustomTab';
import Page from 'components/Page';
import {useAdminPermissionSettings} from 'hooks/datahook/adminPermission';
import {useAuth} from 'hooks/useAuth';
import {find, get, last, split, trim} from 'lodash';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet, useMatches, useNavigate} from 'react-router-dom';
import {adminPaths, paths} from 'routes/path';

const StockRequestLayout = () => {
    const {t} = useTranslation('app');
    const matches = useMatches();
    const matchPath = get(last(matches), 'pathname');
    const mainPath = last(split(trim(matchPath, '/'), '/'));
    const navigate = useNavigate();
    const {roles} = useAuth();
    const {getPermission} = useAdminPermissionSettings({
        roles,
    });
    const tabs = [
        {
            label: t('requestSummary'),
            value: 'requestSummary',
            path: adminPaths.stockRequestSummary,
            hasPermission: getPermission('stocksHome.stockRequests.requestSummary'),
        },
        {
            label: t('account_applications'),
            value: 'requestAccount',
            path: adminPaths.stockRequestAccount,
            hasPermission: getPermission('stocksHome.stockRequests.requestAccount'),
        },
        {
            label: t('requestDeposits'),
            value: 'requestDeposits',
            path: adminPaths.stockRequestDeposits,
            hasPermission: getPermission('stocksHome.stockRequests.requestDeposits'),
        },
        {
            label: t('requestWithdrawals'),
            value: 'requestWithdrawals',
            path: adminPaths.stockRequestWithdrawals,
            hasPermission: getPermission('stocksHome.stockRequests.requestWithdrawals'),
        },
        {
            label: t('requestTransfer'),
            value: 'requestTransfer',
            path: adminPaths.stockRequestTransfer,
            hasPermission: getPermission('stocksHome.stockRequests.requestTransfer'),
        },
    ].filter((item) => item.hasPermission !== false);
    const value =
        find(tabs, (o) => o.path === matchPath)?.value || tabs[0]?.value;

    return (
        <Page title={get(value, 'label') || t('requests')} style={{ width: '100%', overflow: 'hidden' }}>
            <Stack spacing={4} width='100%' >
                <Box maxWidth='calc(100vw - 275px)'>
                    <CustomTab
                        tabs={tabs}
                        tabOnly
                        value={value}
                        onChange={(v) => {
                            navigate(find(tabs, (o) => o.value === v)?.path);
                        }}
                    />
                </Box>

                <Box width={'100%'}>
                    <Outlet />
                </Box>
            </Stack>
        </Page>
    );
};

export default StockRequestLayout;
