import {Icon} from '@chakra-ui/react';
import React, {memo} from 'react';

const GBP = (props) => {
    return (
        <Icon size="md" {...props}>
            <svg
                viewBox="0 0 237 236"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M104.9 0.700024C65.4 5.50002 31.3 29.5 13.4 65.2C-1.49999 94.8 -2.99999 130.4 9.00001 161C25.1 201.8 61.9 230.1 105.4 235.2C137.2 238.9 169.9 229.2 194.5 208.7C230.7 178.4 245.4 130.2 232.2 84.4C226.4 64.3 213.9 44.3 198.3 30.4C179.5 13.6 157.3 3.50002 132.7 0.700024C125.5 -0.199976 111.9 -0.199976 104.9 0.700024ZM131 24.4C166.9 29.1 197.3 54.1 208.5 88.3C223.2 133.2 202.7 181.8 160.3 202.6C128.5 218.2 90.9 214.5 62.3 193.1C57.5 189.5 49.3 181.5 45.4 176.6C18 142.2 18 93.5 45.4 59.1C49 54.7 56.7 47 61.2 43.5C74 33.5 90.2 26.7 106.4 24.5C113.1 23.6 124.3 23.6 131 24.4Z"
                    fill="currentColor"
                />
                <path
                    d="M110.7 56.4C106.5 57 101.7 58.5 97.8 60.5C93 62.9 87.5 68.6 85.3 73.2C82.6 78.9 82 81.9 82 89.3C82 96.8 83.2 102.1 86.1 108.4L87.8 111.9H79.2C68 111.9 68.6 111.3 68.8 120.3C68.9 127 68.9 127.1 70.1 127.6C70.9 128 74.6 128.2 83.8 128.2H96.3L96.1 132C95.8 136.4 94.5 140.1 91.7 144.4C89.6 147.7 84.2 153.4 80.6 156.2C77.2 158.9 76 161.4 76.3 164.8C76.5 167.8 78 170.2 80.4 171.5C83.2 173.1 84.5 173.1 90.3 171.6C102.2 168.5 114.9 168 124.5 170.2C133.5 172.2 145.4 171.9 153.9 169.5C163.4 166.7 166.3 164.1 166.3 158.5C166.3 155.6 165.3 153.7 162.8 151.8C160.2 149.8 157.2 149.7 152.5 151.6C145 154.6 136.9 155.1 126.9 153C124.1 152.4 119.5 151.9 115.4 151.7C111.6 151.6 108.5 151.3 108.5 151.1C108.5 150.9 109.2 149.2 110.1 147.2C112.3 142.5 113.6 136.8 113.6 132V128.2H123.5C131.7 128.2 133.6 128.1 134.5 127.5C135.5 126.8 135.5 126.5 135.5 120.1C135.5 114.6 135.4 113.3 134.8 112.7C134.2 112 132.3 112 121.4 112H108.7L105.6 107.2C102 101.6 100.4 97.8 99.7 92.5C98.4 83.7 102.1 76.6 108.9 74.7C118 72.1 128.6 73.7 133.2 78.4C136 81.2 137 84.1 137.3 90.2L137.6 94.9L145.9 96.6L154.2 98.3L154.5 96.7C154.7 95.9 154.7 92.3 154.6 88.8C154.4 83.6 154.1 81.7 153.2 78.7C149.3 67.3 141 60 128.8 57.1C125.1 56.2 114.7 55.8 110.7 56.4Z"
                    fill="currentColor"
                />
            </svg>
        </Icon>
    );
};

export default memo(GBP);
