import { HStack, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import UserDetailPopoverContent from './UserDetailPopoverContent';

const ClientDetailPopover = (props) => {
    const {id, children} = props;
    return (
        <Popover isLazy trigger="hover" openDelay={3000}>
            <PopoverTrigger>{children}</PopoverTrigger>
            <Portal>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>
                        <UserDetailPopoverContent
                            id={id}
                        />
                    </PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    );
};

export default ClientDetailPopover;
