import { takeRight } from "lodash";
import {isMobilePhone, isNumeric} from 'validator';

export const validExpiryDate = (expiryDate) => {
    const expiryMonth = expiryDate?.split('/')[0] || null;
    const expiryYear = expiryDate?.split('/')[1] || null;

    const today = new Date();
    const toYear = Number(
        takeRight(today.getFullYear().toString(), 2).join(''),
    );

    const isValidExpiryDate =
        expiryDate &&
        (Number(expiryMonth) <= 12 || Number(expiryMonth) > 0) &&
        (Number(expiryYear) === toYear
            ? Number(expiryMonth) >= today.getMonth() + 1
            : true) &&
        Number(expiryYear) >= toYear;
    return isValidExpiryDate;
};

export const validACN = (acn) => {
    return (acn?.length || 0) === 9 && isNumeric(acn, {no_symbols: true});
};

export const validABN = (abn) => {
    return (abn?.length || 0) === 11 && isNumeric(abn, {no_symbols: true});
};

export const validRate = (rate) =>{
    const reg = /^\+?(\d*\.\d{2})$/;
    return reg.test(rate)
}

export const validMostTwoDecimalPlaces = (rate)  =>{
    const reg = /^([0]|([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
    return reg.test(rate)
}

export const verifyIfItIsANumber = (str) =>{
    const reg = /^[0-9]+.?[0-9]*$/;
    return reg.test(str)
}

export const verifyIfItIsInteger = (str) =>{
    const reg = /^[0-9]*$/;
    return reg.test(str)
};

export const verifyIfItIsLetter = (str) =>{
    const reg = /^[A-Za-z]+$/;
    return reg.test(str)
}

export const customizeIsMobilePhone = (value) => {
    return (
        isMobilePhone(value, 'any', {strictMode: true}) ||
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(value)
    );
}