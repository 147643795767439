import {Icon} from '@chakra-ui/react';
import React, {memo} from 'react';

const NZD = (props) => {
    return (
        <Icon size="md" {...props}>
            <svg
                viewBox="0 0 256 256"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M113.9 10.7C78.5 15 46.7 35.2 27.8 65.4C8.19998 96.7 4.49998 136.8 18 171C34.1 211.8 70.9 240.1 114.4 245.2C146.2 248.9 178.9 239.2 203.6 218.7C239.8 188.5 254.5 140.1 241.3 94.4C235.5 74.3 223 54.3 207.4 40.4C188.6 23.6 166.4 13.5 141.7 10.7C134.5 9.80002 120.9 9.80002 113.9 10.7ZM138.2 34.2C170.4 37.7 198.4 57.5 212.7 86.6C231.8 125.6 221.7 172.4 188.1 200.4C154.7 228.3 106.4 229.4 71.2 203.1C66.4 199.5 58.2 191.5 54.3 186.6C26.9 152.2 26.9 103.5 54.3 69.1C58 64.6 65.7 57 70.2 53.5C83.3 43.2 100.7 36.2 117.3 34.3C122.4 33.7 133 33.6 138.2 34.2Z"
                    fill="currentColor"
                />
                <path
                    d="M121.3 56.5001C120.6 57.2001 120.5 58.5 120.5 64.6V71.9001L117.2 72.6C101.2 76.2 91.6 88.6001 92.6 104.4C93.1 111.9 95.4 117.4 100.1 122.3C105.5 127.9 112 131.4 124.4 135.4C134 138.5 139 140.9 142.1 144C149 150.8 146.6 161.8 137.4 165.2C128.2 168.7 117.1 166.9 112 161.2C109.9 158.8 108.9 156 108.5 151.3C108.3 149.6 108 147.9 107.7 147.6C107.4 147.2 104.9 147 98.5 147H89.7L89 148.2C88.4 149.1 88.3 150.1 88.6 153.2C89.2 160.6 90.9 165.9 94.2 170.4C97.9 175.4 106.1 180.4 113.5 182.1C115.7 182.6 117.8 183.1 118.2 183.2C118.6 183.3 118.8 185 118.8 189.6C118.8 195.4 118.9 195.9 119.7 196.4C121.1 197.1 134.8 197 135.5 196.3C135.9 195.9 136.1 193.7 136.1 189.4V183.1L138.6 182.8C142.3 182.3 149 179.9 152.7 177.8C159.2 174 164.1 167.4 165.8 160.6C166.7 156.7 166.7 148.7 165.8 144.8C163.1 133.2 154.5 126.3 134.9 119.8C121.5 115.4 116.4 112.3 114.3 107.4C113.4 105.3 113.3 104.4 113.5 101.2C113.6 98 114 97.0001 115.1 94.9001C116.8 91.9001 119.9 89.6001 123.2 88.9001C126.6 88.2001 132.4 88.2 135.2 89.1C141 90.8 145.6 98.1 145.6 105.7C145.6 108.9 145.9 109 155.8 109C162 109 164.7 108.8 165.1 108.4C166 107.5 165.8 101.3 164.7 96.8001C161.5 83.7001 152.8 75.5001 138.5 72.4001L137.2 72.1L137.1 64.0001L137 56.0001L129.7 55.9001C122.8 55.7001 122 55.7001 121.3 56.5001Z"
                    fill="currentColor"
                />
            </svg>
        </Icon>
    );
};

export default memo(NZD);
