// import PersonOccupationSelector from 'components/common/PersonOccupationSelector';
import React from 'react';
import FormWrapper from './FormWrapper';
import {useSystemRoles} from 'hooks/datahook/adminPermission';
import {useTranslation} from 'react-i18next';
import {get} from 'lodash';
import MutiSelector from 'components/MutiSelector';

const RolesSelector = (props) => {
    const {
        formik,
        i18n,
        index,
        name,
        label,
        title,
        required = false,
        disabled,
        readOnly,
        helper,
        disabledOptions,
    } = props;
    const {t} = useTranslation('app');
    const value = formik.values[name];
    const {data: roles} = useSystemRoles();
    const options = roles.map((item) => ({
        label: t(get(item, 'name')),
        value: get(item, 'code'),
        disabled: disabledOptions?.includes(get(item, 'code')),
    })).filter(item=>item.value!=='USER')
    return (
        <FormWrapper {...props}>
            <MutiSelector
                options={options}
                value={value}
                onChangeValue={(v) => formik.setFieldValue(name, v)}
                allowClear={false}
            />
        </FormWrapper>
    );
};

// export default RolesSelector;

export default React.memo(RolesSelector, (prev, next) => {
    const name = prev.name;
    return (
        prev.formik.values[name] === next.formik.values[name] &&
        prev.formik.errors[name] === next.formik.errors[name] &&
        prev.formik.touched[name] === next.formik.touched[name] &&
        prev.disabled === next.disabled &&
        prev.i18n === next.i18n
    );
});
