import appConfig from 'config/app';
import {create} from 'zustand';
import {persist} from 'zustand/middleware';

export const useExchangeRateStore = create(
    persist(
        (set, get) => ({
            exchangeRate: 1,
            currency: 'USD',
            setExchangeRate: (value) => set(() => ({exchangeRate: value})),
            setCurrency: (value) => set(() => ({currency: value})),
        }),
        {name: `${appConfig.storePrefix}-exchangeRateStore`},
    ),
);
