import {get} from 'lodash';
import {
    formatDate,
    formatDateInTimezone,
    currencyFormatorWithDecimal
} from 'utils/formator';
import {t} from 'i18next';

export const moneyMoveReceiptList = ({i18n, data,}) => {

    const date = (get(data, 'mtTicket')
            ? get(data, 'statusCompletedTimestamp')
            : get(data, 'updatedAt') || get(data, 'createdAt'));

    return [
        ...(!get(data, 'mtTicket')
            ? []
            : [
                {
                    label: i18n('ticket'),
                    value: get(data, 'mtTicket'),
                },
            ]),
        ...(!get(data, 'ticket')
            ? []
            : [
                {
                    label: i18n('crmTicket'),
                    value: get(data, 'ticket'),
                },
            ]),
        {
            label: i18n('transactionType'),
            value: i18n(get(data, 'type')),
        },
        {
            label: i18n('date'),
            value: date
                ? formatDateInTimezone(
                    date,
                    'Factory',
                    'dd/MM/yyyy',
                )
                : null,
        },
        {
            label: t('fromAccount'),
            value: get(data, 'fromAccount'),
        },
        {
            label: t('fromAmount'),
            value: get(data, 'fromAmount') ? currencyFormatorWithDecimal(
                Math.abs(get(data, 'fromAmount')),
                get(data, 'fromCurrency') || get(data, 'fromCryptoCurrency'),
                get(data, 'fromCryptoCurrency') ? 8 : 2,true) : '',
        },
        {
            label: t('toAccount'),
            value: get(data, 'toAccount'),
        },
        {
            label: t('toAmount'),
            value: get(data, 'to_amount'),
        },
        {
            label: i18n('status'),
            value: i18n(get(data, 'status')),
        },
        {
            label: i18n('comment'),
            value: get(data, 'comment'),
        },
    ];
};
