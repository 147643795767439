import { HiCheckBadge, HiClock, HiExclamationCircle, HiExclamationTriangle, HiPauseCircle, HiShieldCheck, HiShieldExclamation } from "react-icons/hi2";

//client kyc status
export const clientStatus = {
    suspended: {
        color: 'gray',
        availableActions: ['unsuspend'],
        icon: HiPauseCircle,
    },
    pending: {
        color: 'pending',
        availableActions: ['approve', 'return', 'decline'],
        icon: HiClock,
    },
    draft: {
        color: 'warning',
        availableActions: ['directApprove'],
        icon: HiExclamationTriangle,
    },
    activated: {
        color: 'success',
        availableActions: ['suspend', 'renew'],
        icon: HiCheckBadge,
    },
    renew: {
        color: 'renew',
        availableActions: ['suspend'],
        icon: HiExclamationTriangle,
    },
    renewPending: {
        color: 'pending',
        availableActions: ['approve', 'return', 'decline'],
        icon: HiClock,
    },
    declined: {
        color: 'error',
        availableActions: ['directApprove', 'undecline'],
        icon: HiExclamationCircle,
    },
    invalid: {
        color: 'error',
        availableActions: [],
        icon: HiExclamationCircle,
    },
    valid: {
        color: 'success',
        availableActions: [],
        icon: HiShieldCheck,
    },
    verified: {
        color: 'success',
        availableActions: ['suspend', 'renew'],
        icon: HiShieldCheck,
    },
    unverified: {
        color: 'warning',
        availableActions: [],
        icon: HiExclamationTriangle,
    },
};

// user account status
export const userStatus = {
    suspended: {
        color: 'rgba(0,0,0,0.8)',
        availableActions: ['active' /*'message'*/],
        icon: HiShieldExclamation,
    },
    // declined: {
    //     color: 'error.main',
    //     colorCode: 'rgb(235,87,87)',
    // },
    // pending: {
    //     color: 'pending.main',
    //     availableActions: ['approve'],
    // },
    activated: {
        color: 'success.main',
        availableActions: ['disable' /*'message'*/],
        icon: HiCheckBadge,
    },
};

export const requestStatus = {
    notReplay: {
        color: 'primary.main',
    },
    replayed: {
        color: 'success.main',
    },
    unReplayed: {
        color: 'pending.main',
    },
};

export const ledgeStatus = {
    refunded: {
        color: 'error.main',
    },
    completed: {
        color: 'success.main',
    },
    pending: {
        color: 'pending.main',
    },
};

export const orderStatus = {
    pending: {
        color: 'pending.main',
        availableActions: ['comfirm', 'decline'],
    },
    paid: {
        color: 'success.main',
    },
    confirm: {
        color: 'success.main',
    },
    processing: {
        color: 'pending.main',
    },
    completed: {
        color: 'success.main',
    },
    failed: {
        color: 'error.main',
    },
    error: {
        color: 'error.main',
        availableActions: ['comfirm', 'decline'],
    },
};

export const clientCanEditStatus = ['renew', 'draft', 'declined'];
export const clientFunctionActivedStatus = ['verified', 'renew', 'renewPending'];
export const kycRequiredStatus = ['renew', 'draft', 'declined', 'returned'];
export const kycReviewingStatus = ['pending', 'renewPending'];
export const kycNeedDraftStatus = ['returned'];
export const kycInRenewStatus = ['renew', 'renewPending'];

export const kycStepConfig = (isCompany) => ({
    draft: {
        colorScheme: 'light.primary',
        titleKey: isCompany ? 'fillYourKYBInfo' : 'fillYourKYCInfo',
        descKey: isCompany ? 'fillYourKYBDesc' : 'fillYourKYCDesc',
        btnText: 'completeNow',
    },
    pending: {
        colorScheme: 'light.primary',
        titleKey: 'reviewingApplication',
        descKey: 'reviewingApplicationDesc',
    },
    renewPending: {
        colorScheme: 'light.primary',
        titleKey: 'reviewingApplication',
        descKey: 'reviewingApplicationDesc',
    },
    declined: {
        colorScheme: 'red',
        titleKey: 'applicationDeclined',
        btnText: 'completeNow',
        showReason: true,
    },
    returned: {
        colorScheme: 'yellow',
        titleKey: 'applicationReturned',
        btnText: 'completeNow',
        showReason: true,
    },
    renew: {
        colorScheme: 'ogrange',
        titleKey: 'needRenewInformation',
        descKey: 'needRenewInformationDesc',
        btnText: 'renewNow',
        showReason: true,
    },
    // verified: {
    //     colorScheme: 'success',
    //     titleKey: 'reviewingApplication',
    //     descKey: 'reviewingApplicationDesc',
    // },
});
