import {useAuthStore} from 'stores/authStore';
import {get} from 'lodash';

export const RNConfig = ({logout, uiState}) => ({
    onError: (error, key) => {
        if (logout && uiState) {
            if (error.status === 401) {
                const user = useAuthStore.getState().user;
                const id = get(user, 'id');
                const errorBody = get(error, 'response.body.error');
                const code = get(errorBody, 'code');
                uiState.setUnauthLogout(true);
                logout();


                // console.log(
                //     '401Errors2',
                //     errorBody,
                //     code,
                //     get(errorBody, 'info.reason'),
                // );
                // if (
                //     id &&
                //     get(error, 'response.body.status') === 'error' &&
                //     code
                // ) {
                //     // if (
                //     //     get(errorBody, 'info.reason') ===
                //     //     'LOGOUT_BY_ANOTHER_LOGIN'
                //     // ) {
                //     //     uiState.setLoginConflict(true);
                //     //     uiState.setConflictIp(get(errorBody, 'info.ip'));
                //     // } else {
                //     //     uiState.setLoginConflict(false);
                //     //     uiState.setConflictIp('');
                //     // }
                //     // uiState.setOpenUnauthLogout(true);
                //     // logout();
                // }
                // We can send the error to Sentry,
                // or show a notification UI.
            }
        }
    },
});
