import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';

import {useColorModeValueKey} from 'hooks/useColors';
import {useTranslation} from 'react-i18next';

import {isImageExtension, isPDFExtension} from 'utils/mime';
import {get, round, set} from 'lodash';
import {useDropzone} from 'react-dropzone';
import {HiOutlineDocument} from 'react-icons/hi';
import ImageViewer from './ImageViewer';
import useCustomToast from 'hooks/useCustomToast';
import CloudUp from 'components/Icon/CloudUp';
import {
    Box,
    Center,
    HStack,
    Icon,
    IconButton,
    Image,
    Spinner,
    Stack,
    Text,
    VStack,
    useToken,
} from '@chakra-ui/react';
import {CloseIcon} from '@chakra-ui/icons';

const TYPES = ['image', 'video', 'documents'];

const DisplayBlock = (props) => {
    const {uploading, disabled, onClean, file, index} = props;
    const bgColor = useColorModeValueKey('brighterBg');
    const [openViwer, setOpenViwer] = useState(false);
    const {t} = useTranslation('app');
    const uri = file?.uri || file;
    return (
        <Stack width={'100%'}>
            {isImageExtension(file?.path || uri) ? (
                <Center
                    cursor={'pointer'}
                    onClick={uploading ? null : () => setOpenViwer(true)}
                >
                    <Box>
                        <Image
                            src={uri}
                            objectFit={'contain'}
                            boxSize={'400px'}
                        />
                        {!disabled && (
                            <IconButton
                                size="sm"
                                position={'absolute'}
                                top={2}
                                right={2}
                                zIndex={1}
                                onClick={() => onClean(file, index)}
                                disabled={disabled}
                                icon={<CloseIcon boxSize={3} />}
                                // boxShadow={'lg'}
                                // variant={'ghost'}
                            />
                        )}
                        {uploading && (
                            <Center
                                zIndex={2}
                                position={'absolute'}
                                top={0}
                                bottom={0}
                                left={0}
                                right={0}
                                bg={'rgba(0,0,0,0.3)'}
                            >
                                <Spinner size={'sm'} />
                                <Text ml={2}>{t('uploading')}</Text>
                            </Center>
                        )}
                    </Box>
                </Center>
            ) : (
                <HStack
                    py={1}
                    px={2}
                    alignItems={'center'}
                    justifyContent="space-between"
                    width={'100%'}
                >
                    <Box
                        cursor={'pointer'}
                        flex={1}
                        onClick={() => window.open(uri, '_blank')}
                    >
                        <HStack alignItems={'center'}>
                            <Icon as={HiOutlineDocument} boxSize={5} mr={1} />
                            <Text noOfLines={1}>{file?.path || uri}</Text>
                        </HStack>
                    </Box>
                    <HStack alignItems={'center'} space={2}>
                        {uploading && <Spinner />}
                        <IconButton
                            size={'sm'}
                            onClick={() => onClean(file, index)}
                            disabled={disabled}
                            icon={<CloseIcon boxSize={3} />}
                            zIndex={1}
                        />
                    </HStack>
                </HStack>
            )}
            <ImageViewer
                images={[uri]}
                open={openViwer}
                onClose={() => setOpenViwer(false)}
            />
        </Stack>
    );
};

const UploadField = forwardRef((props, ref) => {
    const {
        file,
        onChangeFile = () => {},
        onUpload,
        helperText,
        // type = 'image',
        disabled = false,
        sizeLimit = 1014 * 1024 * 10,
        mutiple = false,
        accept,
        uploadZoneProps={}
    } = props;

    const grayColor = useColorModeValueKey('gray');
    const grayerWhiteColor = useColorModeValueKey('white');
    const {t} = useTranslation('app');
    const [uploading, setUploading] = useState({});
    const [files, setFiles] = useState(mutiple ? file : file ? [file] : []);
    const toast = useCustomToast();
    useImperativeHandle(ref, () => ({
        clean: () => {
            setFiles([]);
            onChangeFile(mutiple ? [] : null);
        },
        setDefault: ()=>{
            setFiles(file ? [file] : []);
        }
    }));
    const uploadFile_ = async (f) => {
        const id = Math.random();
        const uri = URL.createObjectURL(f);

        try {
            const obj = {...f, uri, id: id};
            setFiles((c) => [...c, obj]);
            setUploading((c) => ({...set(c, id, true)}));
            const response = await onUpload(f);
            onChangeFile(mutiple ? [...file, response] : response);
        } catch (error) {
            toast.show({title: t(`Error: ${error}`), status: 'error'});
        } finally {
            setUploading((c) => ({...set(c, id, false)}));
        }
    };

    const onDrop = useCallback(
        async (acceptedFiles) => {
            // Do something with the files
            // console.log(acceptedFiles);
            try {
                await Promise.allSettled(acceptedFiles.map(uploadFile_));
            } catch (error) {
                toast.show({title: t(`Error: ${error}`), status: 'error'});
            }
        },
        [file],
    );

    const onClean = (_, index) => {
        if (mutiple) {
            setFiles((c) => c.filter((item, i) => i !== index));
            onChangeFile(file.filter((item, i) => i !== index));
        } else {
            setFiles([]);
            onChangeFile(null);
        }
    };

    const hasFile = files.length > 0;
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        disabled: disabled,
        maxFiles: 1,
        maxSize: sizeLimit,
        accept: accept || {
            'image/png': ['.png', '.PNG'],
            'image/jpeg': ['.jpeg', '.jpg', '.JPG'],
            'application/pdf': ['.pdf'],
        },
    });

    const uploadZone = (
        <VStack
            p={4}
            backgroundColor={useColorModeValueKey('brighterBg')}
            rounded={'md'}
            borderColor={useColorModeValueKey('border')}
            transition={'background-color 200ms linear'}
            borderWidth={1}
            cursor={disabled ? 'not-allowed' : 'pointer'}
            {...getRootProps()}
            _focusVisible={{
                borderColor: useColorModeValueKey('primary'),
                boxShadow: `0 0 0 1px ${useColorModeValueKey('primary')}`,
            }}
            {...uploadZoneProps}
        >
            <input {...getInputProps()} />
            <Icon
                opacity={disabled ? 0.5 : 1}
                as={CloudUp}
                boxSize={{base: '40px', md: '60px'}}
            />
            <Text
                opacity={disabled ? 0.5 : 1}
                fontWeight={'600'}
                textAlign="center"
            >
                {t('dndf')}
            </Text>
            {helperText && (
                <Text mt={1} fontSize="xs" color={grayColor}>
                    {helperText}
                </Text>
            )}
        </VStack>
    );

    return (
        <>
            {hasFile ? (
                <Stack>
                    <VStack
                        space={3}
                        width={'100%'}
                        rounded={'md'}
                        overflow={'hidden'}
                        backgroundColor={useColorModeValueKey('brighterBg')}
                        borderColor={useColorModeValueKey('border')}
                        transition={'background-color 200ms linear'}
                        borderWidth={1}
                        position={'relative'}
                    >
                        {files.map((file, index) => (
                            <DisplayBlock
                                file={file}
                                key={index}
                                uploading={get(uploading, file?.id) || false}
                                disabled={disabled}
                                onClean={onClean}
                                index={index}
                            />
                        ))}
                    </VStack>
                    {mutiple && uploadZone}
                </Stack>
            ) : (
                uploadZone
            )}
        </>
    );
});

export default UploadField;
