import {get} from 'lodash';

export const processAddress = (rawData, prefix) => {
    return {
        [prefix + 'line1']: get(rawData, 'line1') || '',
        [prefix + 'Suburb']: get(rawData, 'city') || '',
        [prefix + 'State']: get(rawData, 'state') || '',
        [prefix + 'Country']: get(rawData, 'country') || '',
        [prefix + 'Postcode']: get(rawData, 'postcode') || '',
        [prefix + 'FullText']: get(rawData, 'fullText') || '',
    };
};

export const getAddressPayload = (formikValues, prefix) => {
    return {
        line1: formikValues[prefix + 'line1'],
        city: formikValues[prefix + 'Suburb'],
        state: formikValues[prefix + 'State'],
        country: formikValues[prefix + 'Country'],
        postcode: formikValues[prefix + 'Postcode'],
    };
};



export const getAddressLine = (address, prefix) => {
    return `${address[prefix + 'line1']}, ${address[prefix + 'Suburb']}, ${address[prefix + 'State']} ${address[prefix + 'Postcode']}, ${address[prefix + 'Country']}`;
};
